import React, { useEffect, useState } from 'react';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { PuffSpinner } from '../../../common/components/spinner/puff/puff';
import { useSelector, useDispatch } from 'react-redux';
import css from './messenger.module.css';
import {
  _updateUserOnlineStatus,
  _updateUserChatId,
  _selectedUser,
  _updateUserChatIdCoversation,
} from '../../../../redux/messenger/messengerActions';
import { MessageList } from './components/MessageList/MessageList';
import { ConversationList } from './components/ConversationList/ConversationList';

export const Messenger = ({ isFromView }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [spinner] = useState(false);
  const [selectedUser] = useState({
    senderId: ``,
    receiverId: ``,
    socketId: ``,
    practiceId: ``,
    name: ``,
    _chatRoomId: null,
  });

  useEffect(() => {
    // EVENT LISTNER : SET USER ONLINE AND OFFLINE STATUS THROUGH SOCKET
    newSocket.on(`userOnlineStatus`, (data) => {
      dispatch(_updateUserOnlineStatus(data));
    });

    // // SET USER CHAT ID
    newSocket.on(`newChatId`, (data) => {
      dispatch(_updateUserChatIdCoversation(data));
      dispatch(_updateUserChatId(data));
    });

    return () => {
      dispatch(_selectedUser(``));
    };
  }, []);

  if (spinner) {
    return (
      <div id={css.puff}>
        <PuffSpinner />
      </div>
    );
  } else {
    return (
      <div className={css.messenger}>
        <div className={css.sidebar}>
          <ConversationList />
        </div>
        <div className={isFromView ? css.ViewContent : css.content}>
          <MessageList isFromView={false} selectedUser={selectedUser} user={user} />
        </div>
      </div>
    );
  }
}

