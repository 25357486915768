import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { ToastContainer } from 'react-toastify';
import { postRequest, ADD_COMMENT_TO_SPECIFIC_TICKET, getRequest, GET_TICKET_DETAIL, patchRequest, UPDATE_TICKET_ISSUE_STATUS } from 'src/crud/crud';
import { PuffSpinner } from '../../../../../../../../common/components/spinner/puff/puff';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import Swal from 'sweetalert2';
import { momentWrapper } from 'src/momentWrapper';
import { SaveComment } from './SaveComment';
import 'react-quill/dist/quill.snow.css';
import { CommentsList } from '../../common/CommentsList';
import { WordsFormatter } from 'src/pages/common/components/WordsFormatter/WordsFormatter';
import { Box } from '@mui/material';
import { HtmlFormatter } from 'src/pages/common/components/HtmlFormatter';
import { cleanWordsFormatterVal, spreadUniqueKeyProp } from 'src/utils';
import { useUniqueId } from 'src/pages/common/hooks/useUniqueId';
import { errorToast } from 'src/pages/common/components/snackBar/toast';

export const SysAdminViewIssue = ({ selectedIssueModalHandler, setSelectedIssueModalHandler, selectedIssueId, setIssueListFlag }) => {
  const [issueDetail, setIssueDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [isIssueClosed, setIsIssueClosed] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [commentOnCloseTicket, setCommentOnCloseTicket] = useState(``);
  const [commnetSavingFlag, setCommentSavingFlag] = useState(false);
  const [closeTicketModal, setCloseTicketModal] = useState(false);
  const [commentEmptyOnApiSuccess, setCommentEmptyOnApiSuccess] = useState(false);
  const commentListId = useUniqueId();
  const user = useSelector((state) => state?.user?.user);

  const commentBox = useRef(null);

  useEffect(() => {
    if (commentBox.current) commentBox.current.scrollTop = commentBox.current.scrollHeight;
  }, [commentsList]);

  useEffect(() => {
    // API call to get specific issue details
    if (selectedIssueId) {
      setLoading(true);
      selectedTicketDetails(selectedIssueId);
    }
  }, [selectedIssueId]);

  const selectedTicketDetails = (selectedIssueId) => {
    getRequest(GET_TICKET_DETAIL + selectedIssueId)
      .then((response) => {
        setLoading(false);

        setIssueDetail(response?.data?.ticket);
        setCommentsList(response?.data?.ticket?.comments);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // ONLY SYSTEM ADMIN, CAN DO THIS
  const closeIssueHandler = (ticketId, groupId) => {
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "You want to close the ticket!",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, close it!",
    // }).then((result) => {
    // if (result.isConfirmed) {
    setIsIssueClosed(true);
    patchRequest(UPDATE_TICKET_ISSUE_STATUS + ticketId, { _practiceGroupId: groupId, issueStatus: `closed` })
      .then(() => {
        setCloseTicketModal(false);
        setIssueListFlag((p) => !p); // Refresh the issues list
        selectedTicketDetails(ticketId); // Get selected ticket latest data
        Swal.fire(`Success!`, `Ticket closed`, `success`);
      })
      .catch(() => {});

    // }
    // });
  };

  // SAVING COMMENT HANDLER

  const savingCommentHandlerOnCloseTicket = (ticketId, groupId) => {
    let filterValue = cleanWordsFormatterVal(commentOnCloseTicket);
    if (!filterValue) {
      errorToast(`Comment text is empty.`);
      return;
    }
    postRequest(ADD_COMMENT_TO_SPECIFIC_TICKET + ticketId, { _practiceGroupId: groupId, comment: commentOnCloseTicket })
      .then(() => {
        selectedTicketDetails(ticketId);
        setCommentOnCloseTicket(``);
        setCommentSavingFlag(false);
        setCommentEmptyOnApiSuccess(true);
        // Red dot notification event emit
        newSocket.emit(`getAllTicketsOfUser`, {
          userId: user._id,
        });
        closeIssueHandler(ticketId, groupId);
      })
      .catch(() => {
        setCommentSavingFlag(false);
      });
  };
  const closeModal = () => {
    setSelectedIssueModalHandler((selectedIssueModalHandler) => !selectedIssueModalHandler);
    setIssueListFlag((p) => !p); // Refresh the issues list
    newSocket.emit(`getAllTicketsOfUser`, {
      userId: user._id,
    });
  };

  return (
    <>
      <CustomizedDialogs
        title="Issue Detail"
        open={selectedIssueModalHandler}
        setOpen={() => closeModal()}
        size="sm"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <>
          <div className="d-flex justify-content-between">
            <div>
              {user?.isSystemAdministrator && issueDetail.issueStatus === `opened` && (
                <div className="form-check" style={{ width: `10em` }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => setCloseTicketModal(true)}
                    checked={isIssueClosed}
                    value=""
                    id="defaultCheck1"
                  />
                  <label className="form-check-label text-danger" htmlFor="defaultCheck1">
                    <b>Close Issue</b>
                  </label>
                </div>
              )}
              {(issueDetail.issueStatus === `opened` || issueDetail.issueStatus === `closed`) && (
                <div className="text-success" style={{ fontSize: `0.7em` }}>
                  Opened : {momentWrapper(issueDetail.openedTicketDate).format(`MM/DD/YYYY hh:mm A`)}
                </div>
              )}
              {issueDetail.issueStatus === `closed` && (
                <div className="text-danger" style={{ fontSize: `0.7em` }}>
                  Closed : {momentWrapper(issueDetail.closedTicketDate).format(`MM/DD/YYYY hh:mm A`)}
                </div>
              )}
              {issueDetail._created_by && (
                <>
                  <div className="text-secondary" style={{ fontSize: `0.7em` }}>
                    Email : {issueDetail._created_by.email}
                  </div>
                  <div className="text-secondary" style={{ fontSize: `0.7em` }}>
                    Phone : {issueDetail._created_by.phoneNumber}
                  </div>
                </>
              )}
            </div>
            <div>
              {issueDetail.issueType === `other` && <span className="badge badge-primary">Other</span>}
              {issueDetail.issueType === `payment` && <span className="badge badge-secondary">Payment</span>}
              {issueDetail.issueType === `feature` && <span className="badge badge-info">Feature</span>}
              {issueDetail.issueType === `technical` && <span className="badge badge-dark">Technical</span>}
              <span className="ml-1">
                {issueDetail.issuePriority === `normal` && <span className="badge badge-primary">Normal</span>}
                {issueDetail.issuePriority === `urgent` && <span className="badge badge-success">Urgent</span>}
                <span className="ml-1">
                  {issueDetail.issueStatus === `opened` && <span className="badge badge-info">Open</span>}
                  {issueDetail.issueStatus === `closed` && <span className="badge badge-danger">Closed</span>}
                </span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="description" className="ml-0">
                  Description
                </label>
                <div className="border-1 rounded-1">
                  <HtmlFormatter title={issueDetail?.issueTitle} />
                </div>
                {/* <textarea readOnly className="form-control" name="description" id="description" rows="3">
                </textarea> */}
              </div>
            </div>
          </div>
          <hr />
          <h6>Comments</h6>
          <div
            className="p-2"
            style={{
              border: `1px solid #ececec`,
              backgroundColor: `white`,
              overflowY: `auto`,
              borderRadius: `5px`,
              height: `35vh`,
            }}
            ref={commentBox}
          >
            {commentsList?.map((comment, ind) => {
              let key = `${commentListId}${ind + 1}`;
              return <CommentsList {...spreadUniqueKeyProp(key)} comment={comment} />;
            })}
          </div>
          <div>
            <SaveComment
              issueDetail={issueDetail}
              commnetSavingFlag={commnetSavingFlag}
              setCommentSavingFlag={setCommentSavingFlag}
              selectedTicketDetails={selectedTicketDetails}
            />
          </div>
        </>
        {loading && <PuffSpinner />}
      </CustomizedDialogs>
      {/* Close ticket modal for atleast one comment */}
      <CustomizedDialogs
        title="Ticket Close"
        open={closeTicketModal}
        setOpen={() => setCloseTicketModal(false)}
        size="sm"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <ToastContainer />
        <div className="form-group">
          <div className="form-group">
            <WordsFormatter
              placeHolder={`Leave a comment`}
              setKeyValue={setCommentOnCloseTicket}
              commentEmptyOnApiSuccess={commentEmptyOnApiSuccess}
              setCommentEmptyOnApiSuccess={setCommentEmptyOnApiSuccess}
              readyToSet={true}
            />
          </div>
          <div style={{ display: `flex`, justifyContent: `flex-end` }}>
            <Box sx={{ display: `flex`, justifyContent: `flex-end`, mt: { xs: `30%`, sm: `15%`, md: `15%`, lg: `15%`, xl: `15%` } }}>
              <button
                className="btn btn-success"
                type="button"
                size="sm"
                disabled={!commentOnCloseTicket.length}
                onClick={() => {
                  savingCommentHandlerOnCloseTicket(issueDetail._id, issueDetail?._practiceGroupId);
                }}
              >
                Comment and close ticket
              </button>
            </Box>
          </div>
        </div>
      </CustomizedDialogs>
    </>
  );
};
