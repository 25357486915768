import {
  EMPTY_DATA,
  SAVE_DATA,
  TRIGGER_SWIPE,
  SAVE_REP_SER_SUSPENSE,
  CLOSE_TRIGGERED_SWIPE,
  SAVE_LAST_CLOSED_REP,
  EMPTY_LAST_CLOSED_REP,
  REMOVE_FROM_DATA,
  SAVE_CLICKED_REP,
  REMOVE_FROM_CLICKED_REP,
  EMPTY_CLICKED_REP,
  PO_DATA_STATUS
} from './Reports.types';
import { returnIfArr } from 'src/utils';

const initialState = {
  readyToSignModal: {
    open: false,
    reportId: ``,
    startDate: ``,
    endDate: ``,
    patientId: ``,
    patientNumber: ``,
    actionTaken: 0,
    reportMonth: ``,
  },
  swipeAbleSeries: [],
  swipeSuspense: { loading: false, error: false },
  swipedReports: [],
  clickedReports: [],
  isOpenSwipe: false,
  hasPoMoreData: false,
  lastClosedSwipedRep: {},
};
export const readyToSignModal = (state = initialState, action) => {
  const { payLoad: actionPayLoad = {
    reportId: ``,
    patientId: ``
  } } = action;

  switch (action.type) {
    case PO_DATA_STATUS:
      return { ...state, hasPoMoreData: !action.payLoad }
    case SAVE_REP_SER_SUSPENSE:
      return { ...state, swipeSuspense: { ...state.swipeSuspense, ...actionPayLoad } }
    case `SIGNREPORT`:

      return {
        ...state,
        readyToSignModal: {
          ...state.readyToSignModal,
          ...actionPayLoad,
        }
      };
    case `REPORTACTION`:
      return { ...state, readyToSignModal: { ...state.readyToSignModal, actionTaken: state.readyToSignModal.actionTaken + 1 } };

    case SAVE_CLICKED_REP:
      return { ...state, clickedReports: [...state.clickedReports, actionPayLoad] };

    case REMOVE_FROM_CLICKED_REP:
      return { ...state, clickedReports: [...returnIfArr(state.clickedReports).filter((repId) => repId !== actionPayLoad)] };

    case EMPTY_CLICKED_REP:
      return { ...state, clickedReports: [] };
    case SAVE_DATA:

      return { ...state, swipeAbleSeries: [...new Set([...actionPayLoad].map(o => JSON.stringify(o)))].map(s => JSON.parse(s)) };
    case REMOVE_FROM_DATA:
      return {
        ...state,
        swipeAbleSeries: [...returnIfArr(state.swipeAbleSeries).filter((rep) => rep?._reportId !== actionPayLoad)],
      };
    case EMPTY_DATA:
      return { ...state, swipeAbleSeries: [] };
    case TRIGGER_SWIPE:
      return { ...state, isOpenSwipe: true };
    case CLOSE_TRIGGERED_SWIPE:
      return { ...state, isOpenSwipe: false };
    case SAVE_LAST_CLOSED_REP:
      return { ...state, lastClosedSwipedRep: { ...actionPayLoad } };
    case EMPTY_LAST_CLOSED_REP:
      return { ...state, ...(Object.keys(state?.lastClosedSwipedRep)?.length ? { lastClosedSwipedRep: {} } : {}) };
    default:
      return state;
  }
};
