import React, { useEffect } from 'react';

import Snackbar from '@mui/material/Snackbar';

import Alert from '@mui/material/Alert';

export const SnackBar = ({ isObj, open, setOpen, alertProps, alertMessage, alertDuration }) => {

    useEffect(() => {
        setTimeout(() => {
            if (isObj) {
                setOpen();
            } else {
                setOpen(false);
            }
        }, alertDuration);
    }, [alertDuration, setOpen, isObj]);

    return (
        <Snackbar anchorOrigin={{ vertical: `bottom`, horizontal: `right` }} open={open} autoHideDuration={6000}>
            <Alert {...alertProps}>{alertMessage}</Alert>
        </Snackbar>
    );
};
