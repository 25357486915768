import React from 'react';

export const SubscriptionError = () => {
  return (
    <div style={{ padding: `2%` }}>
      <h2>Practice is not subscribed, kindly contact your Practice Admin.</h2>
    </div>
  );
}

