import React from 'react';
import css from './Toolbar.module.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from 'src/utils';


const Toolbar = () => {
  const selectedUserRedux = useSelector((state) => state.messenger.selectedUser);
  const individualUser = useSelector((state) => state.messenger.individualUser);
  const userName = individualUser[selectedUserRedux]?.firstName
    ? individualUser[selectedUserRedux]?.firstName + ` ` + individualUser[selectedUserRedux]?.lastName
    : `Conversation`;

  return (
    <div className={css.toolbar}>
      {/* <div className={css.leftItems}>{leftItems}</div> */}
      {userName !== `Conversation` ? (
        <>
          <AccountCircleIcon
            style={{
              marginLeft: `10px`,
              color: `cornflowerblue`,
              fontSize: `37px`,
            }}
          />
          <span className={css.toolbarTitle} style={{ textTransform: `capitalize` }}>
            {capitalizeFirstLetter(userName)}
          </span>
        </>
      ) : (
        <></>
      )}
      {/* <div className={css.rightItems}>{rightItems}</div> */}
    </div>
  );
}

export default React.memo(Toolbar);
