import { useEffect } from "react";
import { newSocket } from "../HOC/SocketWrapper";



const specialHandler = (e) => {

    e.preventDefault();
    e.returnValue = ``;

    // reconnect socket if user mistakenly click on close icon of tab of 
    // browser but don't want to close the tab
    newSocket.disconnect();
    newSocket.connect();

    // Listen for the unload event to detect if the user chose to leave the page
    window.addEventListener(`unload`, function () {

        // disconnect socket if user click on leave page button
        newSocket.disconnect();

    });

};

export const useBrowserConfirmation = ({
    condition
}) => {

    const addConfirmation = () => {
        if (condition) {
            window.addEventListener(`beforeunload`, specialHandler);

        } else {
            window.removeEventListener(`beforeunload`, specialHandler);
        }
    }

    useEffect(() => {
        return () => window.removeEventListener(`beforeunload`, specialHandler);
    }, [])

    return addConfirmation();

}