import {
  Button, Collapse, IconButton, Stack,
  Tooltip, Typography, useMediaQuery
} from '@mui/material';
import css from './tasks.module.css';
import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequest, GET_ALL_TASKS } from 'src/crud/crud';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { Filters } from './Filters/Fillters';
import { ViewTask } from './ViewTask/ViewTask';
import { CreateTask } from './CreateTask/CreateTask';
import { AddBox } from '@material-ui/icons';
import { capitalizeFirstLetter, dynamicObjCreator, printInBrowser, returnIfArr, taskStatusPillCol } from 'src/utils';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import SearchIcon from '@mui/icons-material/Search';
import { sortIconHandler, sortKeyGiver } from '../patient2/PatientOverview/PatientOverView';
import { ToggleTasks } from './ToggleTasks/ToggleTasks';
import HomeIcon from '@mui/icons-material/Home';
import moment from 'moment';
import { BadgePill, MuiPill } from 'src/pages/common/components/BadgePill/BadgePill';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';
import { TruncateWraper } from 'src/pages/common/HOC/TruncateWraper';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';

const TaskManagement = () => {
  const { user } = useSelector(({ user }) => user);
  const { _id: userId } = user || false;
  const matches = useMediaQuery(`(max-width:768px)`);
  const matchesMedium = useMediaQuery(`(max-width:1050px)`);
  const matchesLarge = useMediaQuery(`(max-width:1460px)`);
  const [createTaskModelHandler, setCreateTaskModalHandler] = useState(false);
  const [selectedTask, setSelectedTask] = useState(``);
  const [selectedTaskModalHandler, setSelectedTaskModalHandler] = useState(false);
  const [openFilters, setOpenFilters] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  // SORT VALUES
  const [sortKey, setSortKey] = useState(`createdAt`);
  const [sortOrder, setSortOrder] = useState(`DSC`);
  const [loading, setLoading] = useState(false);
  const homeBtnRef = useRef(null);
  const hasAppliedSorting = useRef(false);
  // List of Tickets
  const [listOfTasks, setListOfTasks] = useState([]);
  // Special Flag, to get the list of issues again
  const [taskListFlag, setTaskListFlag] = useState(0);
  const [filtersObj, setFiltersObj] = useState({ assignedByMe: false });
  const { assignedByMe } = filtersObj;
  const [resetFilters, setResetFilters] = useState(false);

  useEffect(() => {
    setLoading(true);
    let prepareFilters = dynamicObjCreator({ ...filtersObj, ...(assignedByMe ? { assignedBy: userId } : { assignedTo: userId }) });
    let query =
      GET_ALL_TASKS +
      `?page=${currentPage}&recordsPerPage=${rowsPerPage}&sort=${JSON.stringify({
        [sortKey]: sortOrder === `ASC` ? 1 : -1,
      })}&filters=${JSON.stringify({
        ...prepareFilters,
      })}`;
    getRequest(query)
      .then((response) => {
        setLoading(false);
        setListOfTasks(response?.data?.data?.tasks);
        setTotalRows(response?.data?.data.pager?.totalRecords);
        setTotalPage(Math.ceil(response?.data?.data?.pager.totalRecords / response?.data?.data?.pager.recordsPerPage));
      })
      .catch(() => {
        setListOfTasks([]);
        setLoading(false);
      });
    let filters = Object.keys(prepareFilters);
    let defaultFilters = [assignedByMe ? `assignedBy` : `assignedTo`];
    let isDefault = !!filters.every((filter, ind) => filter.includes(defaultFilters[ind]));
    if (hasAppliedSorting.current || (taskListFlag && !isDefault)) {
      homeBtnRef.current.style.display = `inline-block`;
    }
  }, [rowsPerPage, currentPage, sortOrder, taskListFlag, sortKey, hasAppliedSorting, assignedByMe]);

  useEffect(() => {
    const makeTaskDueIfHasInTasks = (data) => {
      const { _id } = data;
      let hasInTasks = listOfTasks?.filter((task) => task?._id === _id);
      if (hasInTasks)
        setListOfTasks((p) => [
          ...(p || []).map((task) => {
            if (task && task._id === _id) {
              return { ...data };
            }
            return task ? { ...task } : null; // You can return null or handle undefined tasks as needed.
          }),
        ]);
    };
    newSocket.on(`taskStatusUpdated`, (data) => makeTaskDueIfHasInTasks(data));
  }, []);

  const openSpecificTaskHandler = (task) => {
    setSelectedTask(task);
    setSelectedTaskModalHandler(true);
  };

  const sortHandler = (order, key) => {
    hasAppliedSorting.current = true;
    setSortKey(key);
    setSortOrder(order);
  };

  let passArgs = { sortOrder: sortOrder, sortField: sortKey };

  const resetRowsAndSorting = () => {
    setRowsPerPage(10);
    setCurrentPage(1);
    setTotalRows(0);
    setTotalPage(1);
    setSortKey(`createdAt`);
    setSortOrder(`DSC`);
  };
  const resetPagination = (setFilters, closeFilters) => {
    resetRowsAndSorting();
    if (setFilters) setFiltersObj({ assignedByMe: false });
    setTaskListFlag((p) => p + 1);
    if (openFilters && closeFilters) setOpenFilters((p) => !p);
  };

  return (
    <Box sx={{ m: 4 }}>
      <Box sx={{ display: `flex`, justifyContent: `space-between`, pb: 3 }}>
        <Typography variant="h5" sx={{ marginY: `auto` }}>
          Task Manager
        </Typography>
        <Box sx={{ display: `flex`, justifyContent: `flex-end` }}>
          <Tooltip title="Add New Task" arrow placement="left">
            <Button variant="contained" color="success" startIcon={<AddBox />} onClick={() => setCreateTaskModalHandler(true)}>
              Add New Task
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <div className={`${css.table} card`}>
        {/* filters */}
        <Collapse in={openFilters} mountOnEnter>
          <Filters
            resetRowsAndSorting={resetRowsAndSorting}
            setFiltersObj={setFiltersObj}
            resetFilters={resetFilters}
            setResetFilters={setResetFilters}
            setTaskListFlag={() => setTaskListFlag((p) => p + 1)}
          />
        </Collapse>
        <Stack direction="row" justifyContent={`space-between`}>
          <ToggleTasks
            assignedByMe={assignedByMe}
            setAssignedByMe={(clickedOnAssignedByMe) => {
              resetPagination(false, true);
              setFiltersObj((p) => ({ ...p, assignedByMe: !!clickedOnAssignedByMe }));
            }}
          />
          <Box sx={{ width: `100%`, textAlign: `end` }}>
            <IconButton
              ref={homeBtnRef}
              sx={{ p: 2, color: `rgb(22, 153, 197)`, display: `none` }}
              onClick={() => {
                resetPagination(true, true);
                setResetFilters(true);
                if (hasAppliedSorting.current) hasAppliedSorting.current = false;
                homeBtnRef.current.style.display = `none`;
              }}
            >
              <HomeIcon />
            </IconButton>

            <ToolTipProvider
              tootlTipProps={{
                title: `${openFilters ? `Close` : `Search`}`,
                placement: `top`,
                arrow: true,
              }}
              element={
                <IconButton sx={{ p: 2 }} onClick={() => setOpenFilters((p) => !p)}>
                  {openFilters ? <CloseSharpIcon sx={{ color: `red` }} /> : <SearchIcon sx={{ color: `rgb(22, 153, 197)` }} />}
                </IconButton>
              }
            />
          </Box>
        </Stack>
        <div
          className="table-responsive"
          style={{ width: matches || matchesMedium ? `200% !important` : matchesLarge ? `150% !important` : `100% !important` }}
        >
          <table
            style={{ width: matches || matchesMedium ? `200%` : matchesLarge ? `150%` : `100%` }}
            className={`table  table-lg table-borderless table-striped ${css.tableInner}`}
          >
            <thead>
              <tr>
                {/* <th scope="col"></th> */}
                <th scope="col" className={css.customCol1} onClick={() => sortHandler(sortKeyGiver({ ...passArgs }, `taskName`), `taskName`)}>
                  Task Title
                  {sortIconHandler({ ...passArgs }, `taskName`)}
                </th>
                <th scope="col" className={css.customCol1} onClick={() => sortHandler(sortKeyGiver({ ...passArgs }, `taskStatus`), `taskStatus`)}>
                  Status
                  {sortIconHandler({ ...passArgs }, `taskStatus`)}
                </th>
                <th scope="col" className={css.customCol1} onClick={() => sortHandler(sortKeyGiver({ ...passArgs }, `taskPriority`), `taskPriority`)}>
                  Priority
                  {sortIconHandler({ ...passArgs }, `taskPriority`)}
                </th>
                <th scope="col" className={css.customCol1} onClick={() => sortHandler(sortKeyGiver({ ...passArgs }, `taskType`), `taskType`)}>
                  Task Type
                  {sortIconHandler({ ...passArgs }, `taskType`)}
                </th>

                <th scope="col" className={css.customCol2} onClick={() => sortHandler(sortKeyGiver({ ...passArgs }, `createdAt`), `createdAt`)}>
                  Created
                  {sortIconHandler({ ...passArgs }, `createdAt`)}
                </th>
                <th scope="col" className={css.customCol2} onClick={() => sortHandler(sortKeyGiver({ ...passArgs }, `lastClosedAt`), `lastClosedAt`)}>
                  Closed Date
                  {sortIconHandler({ ...passArgs }, `lastClosedAt`)}
                </th>
                <th scope="col" className={css.customCol2} onClick={() => sortHandler(sortKeyGiver({ ...passArgs }, `dueDate`), `dueDate`)}>
                  Due Date
                  {sortIconHandler({ ...passArgs }, `dueDate`)}
                </th>
                <th scope="col" className={css.customCol2}>
                  Patient
                </th>
                <th scope="col" className={css.customCol2}>
                  Assigned to
                </th>
                <th scope="col" className={css.customCol2}>
                  Assigned By
                </th>
              </tr>
            </thead>
            <tbody id={css.body}>
              {!listOfTasks?.length && !loading && (
                <td colSpan={8} style={{ textAlign: `center` }}>
                  No records found!{` `}
                </td>
              )}
              {listOfTasks?.map((task, index) => {
                return (
                  <tr
                    key={task}
                    id={css.rows}
                    onClick={() => {
                      setSelectedTaskModalHandler(true);
                      setSelectedTask(index);
                      openSpecificTaskHandler(task);
                    }}
                  >
                    {task?.taskName?.length >= 30 ? (
                      <TruncateWraper tag={`td`} tagStyles={{ maxWidth: `10.5vw` }}>
                        {task?.taskName}
                      </TruncateWraper>
                    ) : (
                      <td>{task?.taskName}</td>
                    )}

                    <td scope="col">
                      <MuiPill
                        pillProps={{
                          sx: {
                            backgroundColor: taskStatusPillCol[task?.taskStatus],
                            color: `white`,
                            borderRadius: `none`,
                            fontSize: `10px`,
                          },
                          size: `small`,
                        }}
                      >
                        {capitalizeFirstLetter(task?.taskStatus)}
                      </MuiPill>
                      <BadgePill type={task?.taskStatus === `opened` ? `badge-success` : `badge-danger`}></BadgePill>
                    </td>
                    <td scope="col">{capitalizeFirstLetter(task?.taskPriority)} </td>
                    <td scope="col">{task?.taskType} </td>
                    <td>{task?.createdAt ? moment(task?.createdAt).format(`MM/DD/YYYY`) : `-`}</td>
                    <td>{task?.lastClosedAt && task?.taskStatus === `closed` ? moment(task?.lastClosedAt).format(`MM/DD/YYYY`) : `-`}</td>

                    <td scope="col">{task?.dueDate?.length ? moment(task?.dueDate).format(`MM/DD/YYYY`) : `-`}</td>
                    <td scope="col" style={{ width: `20px` }}>
                      {task?._patientId?.lastName ? capitalizeFirstLetter(task?._patientId?.lastName + ` ` + task?._patientId?.firstName) : `N/A`}
                    </td>
                    <td scope="col">{capitalizeFirstLetter(`${task?.assignedTo?.lastName} ${task?.assignedTo?.firstName}`)} </td>
                    <td scope="col">{capitalizeFirstLetter(`${task?.assignedBy?.lastName} ${task?.assignedBy?.firstName}`)} </td>
                  </tr>
                );
              })}
            </tbody>
            <TableFooter
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalRows}
              setRowsPerPage={setRowsPerPage}
              setCurrentPage={setCurrentPage}
              totalPage={totalPage}
              isPatientView={false}
              isTask={true}
              data={listOfTasks}
            />
          </table>
        </div>
      </div>
      {selectedTaskModalHandler && (
        <ViewTask
          selectedTask={selectedTask}
          selectedTaskModalHandler={selectedTaskModalHandler}
          setSelectedTaskModalHandler={setSelectedTaskModalHandler}
          setTaskListFlag={setTaskListFlag}
        />
      )}
      {createTaskModelHandler && (
        <CreateTask
          createTaskModelHandler={createTaskModelHandler}
          setCreateTaskModalHandler={setCreateTaskModalHandler}
          setTaskListFlag={() => setTaskListFlag((p) => p + 1)}
        />
      )}

      {(user?.isSuperAdmin || user?.isProvider || user?.isClinicalStaff) && loading && <PulseSpinner />}
    </Box>
  );
};

export default TaskManagement;
