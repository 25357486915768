import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import css from './help.module.css';
import { Fade, Grow } from '@mui/material';

export const HelpDropDown = () => {
  const { ticketsCenter } = useSelector((state) => state)
  let ticketStatus = ticketsCenter.notifyAllTickets
  const [isOpen, setisOpen] = useState(false);
  const isOnVideo = useSelector((state) => state.patient.isOnVideo);
  const ref = React.useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setisOpen(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener(`click`, handleClickOutside, true);
    return () => {
      document.removeEventListener(`click`, handleClickOutside, true);
    };
  }, []);

  return (
    <div ref={ref}>
      <button disabled={isOnVideo} id={css.topBarBtnSmall} type="button" onClick={() => setisOpen((p) => !p)}>
        {ticketStatus ? !ticketStatus?.allRead && <span id={css.badge}>&#9679;</span> : null}
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 44 44">
          <g id="Group_1" data-name="Group 1" transform="translate(-759 -22)">
            <g id="help-circle_1_" data-name="help-circle (1)" transform="translate(761 24)">
              <circle
                id="Oval"
                cx="20"
                cy="20"
                r="20"
                fill="none"
                stroke="#6d7278"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="4"
              />
              <path
                id="Path"
                d="M0,4.01a6,6,0,0,1,11.66,2c0,4-6,6-6,6"
                transform="translate(14.18 9.99)"
                fill="none"
                stroke="#6d7278"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="4"
              />
              <path
                id="Path-2"
                data-name="Path"
                d="M.99,1h.02"
                transform="translate(19.01 29)"
                fill="none"
                stroke="#6d7278"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="4"
              />
            </g>
          </g>
        </svg>
      </button>
      <Grow style={{ transformOrigin: `0 0 0` }} {...(isOpen ? { timeout: 200 } : {})} mountOnEnter unmountOnExit in={isOpen}>
        <div className="mb-0 pb-0 pt-0" id={css.HelpDropDown}>
          <span className={css.menuArrow}></span>
          <table onClick={() => setisOpen(false)} className="table table-borderless table-hover mb-0">
            <tbody>
              <tr style={{ borderBottom: `1px solid rgb(211, 209, 209)` }} id={css.row}>
                <Fade in={isOpen} style={{ transitionDelay: isOpen ? `100ms` : `0ms` }}>
                  <Link to="/faqs" style={{ textDecoration: `none` }}>
                    <td className="pr-4" style={{ display: `block`, color: `#212529` }}>
                      FAQs
                    </td>
                  </Link>
                </Fade>
              </tr>
              <tr style={{ borderBottom: `1px solid rgb(211, 209, 209)` }} id={css.row}>
                <Fade in={isOpen} style={{ transitionDelay: isOpen ? `100ms` : `0ms` }}>
                  <Link to="/resources" style={{ textDecoration: `none` }}>
                    <td className="pr-4" style={{ display: `block`, color: `#212529` }}>
                      Resources
                    </td>
                  </Link>
                </Fade>
              </tr>
              <tr style={{ borderBottom: `1px solid rgb(211, 209, 209)` }} id={css.row}>
                <Fade in={isOpen} style={{ transitionDelay: isOpen ? `100ms` : `0ms` }}>
                  <Link to="/ticketing" style={{ textDecoration: `none` }}>
                    <td className="pr-4" style={{ display: `block`, color: `#212529` }}>
                      {ticketStatus ? !ticketStatus?.allRead && <span style={{ color: `red` }}>&#9679;</span> : null} Ticket Center
                    </td>
                  </Link>
                </Fade>
              </tr>
            </tbody>
          </table>
        </div>
      </Grow>
    </div>
  );
}


