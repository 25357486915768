import React from 'react';
import { useSelector } from 'react-redux';
import { setPracticeTimezone } from 'src/utils';
import { ConditionallyRenderWrapper } from './ConditionallyRenderWrapper';

export const TimeZoneProtectionWrapper = ({ children }) => {
  const {
    user: { user },
  } = useSelector((state) => state);
  const { timezone: practiceTimeZone } = user;
  return <ConditionallyRenderWrapper con={setPracticeTimezone(practiceTimeZone)}>{children}</ConditionallyRenderWrapper>;
};
