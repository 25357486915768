import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { CustomTabPanel } from '../administration/user/components/CustomTabPanel/CustomTabPanel'; // Import the CustomTabPanel component
import { TabsComponent } from '../administration/user/components/CustomTabPanel/Tabs'; // Import the TabsComponent component
import { ForAssignUsers } from './forAssignUsers';
import { ForReassignPatients } from './forReassignPatients';
import { ForDeleteSecondaryUser } from './forRemoveSecondaryUser';

export const UserPatientReassignment = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '90%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 6, marginLeft: 6 }}>
        {/* Use the TabsComponent component */}
        <TabsComponent value={value} handleChange={handleChange} />
      </Box>
      {/* Use the CustomTabPanel component */}
      <CustomTabPanel value={value} index={0}>
        <ForAssignUsers />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ForReassignPatients setValue={setValue} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ForDeleteSecondaryUser />
      </CustomTabPanel>
    </Box>
  );
};
