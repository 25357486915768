import { toast } from 'react-toastify';

export const successToast = (msg) =>
  toast.success(msg, {
    position: `bottom-right`,
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const errorWaning = (msg) =>
  toast.warn(msg, {
    position: `bottom-right`,
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const errorToast = (msg) =>
  toast.error(msg, {
    position: `bottom-right`,
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


