import { IMPORT_PATIENT_SUCCESS_MOUNT_UNMOUNT_FLAG } from './mountTypes';

const initialState = {
  onPatientImportSuccessFlag: false,
};

export const mountUnmountReducer = (state = initialState, action) => {
  if (action.type === IMPORT_PATIENT_SUCCESS_MOUNT_UNMOUNT_FLAG) {
    return {
      ...state,
      onPatientImportSuccessFlag: !state.onPatientImportSuccessFlag,
    };
  } else {
    return state;
  }
};
