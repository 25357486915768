import { GET_PATIENT_OVERVIEW_AUTO_REP, getRequest } from 'src/crud/crud';
import {
  EMPTY_DATA,
  SAVE_DATA,
  TRIGGER_SWIPE,
  UPDATE_DATA,
  CLOSE_TRIGGERED_SWIPE,
  SAVE_LAST_CLOSED_REP,
  EMPTY_LAST_CLOSED_REP,
  REMOVE_FROM_DATA,
  SAVE_CLICKED_REP,
  REMOVE_FROM_CLICKED_REP,
  EMPTY_CLICKED_REP,
  SAVE_REP_SER_SUSPENSE,
  PO_DATA_STATUS
} from './Reports.types';
import { patientOverViewMonthHandler } from 'src/utils';

export const emptyClickedReps = (payLoad) => {
  return {
    type: EMPTY_CLICKED_REP,
    payLoad,
  };
};

export const removeFromClickedRep = (payLoad) => {
  return {
    type: REMOVE_FROM_CLICKED_REP,
    payLoad,
  };
};

export const saveInClickedRep = (payLoad) => {
  return {
    type: SAVE_CLICKED_REP,
    payLoad,
  };
};

export const saveRepSeriesData = (payLoad) => {
  return {
    type: SAVE_DATA,
    payLoad,
  };
};
export const removeFromSeriesData = (payLoad) => {
  return {
    type: REMOVE_FROM_DATA,
    payLoad,
  };
};
export const upDateRepSeriesData = (payLoad) => {
  return {
    type: UPDATE_DATA,
    payLoad,
  };
};
export const emptySeriesData = (payLoad) => {
  return {
    type: EMPTY_DATA,
    payLoad,
  };
};
export const triggerSwipe = () => {
  return {
    type: TRIGGER_SWIPE,
  };
};
export const closetriggerSwipe = () => {
  return {
    type: CLOSE_TRIGGERED_SWIPE,
  };
};
export const saveLastClosedRep = (payLoad) => {
  return {
    type: SAVE_LAST_CLOSED_REP,
    payLoad,
  };
};
export const emptyLastClosedRep = (payLoad) => {
  return {
    type: EMPTY_LAST_CLOSED_REP,
    payLoad,
  };
};


export const saveSerSuspenser = (payLoad) => {
  return {
    type: SAVE_REP_SER_SUSPENSE,
    payLoad
  }
}
export const updatePatOverDataStatus = (payLoad) => {
  return {
    type: PO_DATA_STATUS,
    payLoad
  }
}


export const saveRepSerFromApi = (payLoad) => {
  return (dispatch) => {
    dispatch(saveSerSuspenser({ loading: true, error: false }))
    getRequest(`${GET_PATIENT_OVERVIEW_AUTO_REP}${payLoad}`).then(({ data: { reports = [] } = { reports: [] } }) => {
      const reportsToSave = reports?.length ? [...reports.map((elem) => {
        return { ...elem, ...(elem?.month && { month: patientOverViewMonthHandler(elem?.month) }), }
      })] : []
      dispatch(saveSerSuspenser({ loading: false }))
      dispatch(saveRepSeriesData([...reportsToSave]))
    }).catch((() => dispatch(saveSerSuspenser({ loading: false, error: true }))))
  }
}