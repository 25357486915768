export const AUTH_TOKEN = `AUTH_TOKEN`;
export const AUTH_TOKEN_REFRESH_TOKEN = `AUTH_TOKEN_REFRESH_TOKEN`;
export const SET_USER = `SET_USER`;
export const UPDATE_USER_SIGNATURE = `UPDATE_USER_SIGNATURE`;
export const LOGOUT = `LOGOUT`;
export const UPDATE_USER_NAME = `UPDATE_USER_NAME`;
export const UPDATE_PROVIDER_TYPE = `UPDATE_PROVIDER_TYPE`;
export const UPDATE_APP_REL_COUNT = `UPDATE_APP_REL_COUNT`;
export const SAVE_IN_TIMEOUT_ARR = `SAVE_IN_TIMEOUT_ARR`;
export const SAVE_TWIL_VOICE_AUTH = `SAVE_TWIL_VOICE_AUTH`;
export const UPDATE_PRACTICE_LOGO_PATH = `UPDATE_PRACTICE_LOGO_PATH`; // Defines an action type constant for updating the logo path of a practice.
export const UPDATE_USER_ROLES = `UPDATE_USER_ROLES`;
export const SAVEEXISTINGUSERDETAILS = `SAVEEXISTINGUSERDETAILS`;
export const REFRESH_TOKEN_API_FLAG = `REFRESH_TOKEN_API_FLAG`;
export const SWITCH_PRACTICE = `SWITCH_PRACTICE`;
export const SAVE_EXISTING_USER_DETAILS = `SAVE_EXISTING_USER_DETAILS`;
export const PRIMARY_AND_SECONDARY_CHANGE = `PRIMARY_AND_SECONDARY_CHANGE`;
export const SAVE_SOCKET_CONN = `SAVE_SOCKET_CONN`

/* RT Data */

export const SET_PENDING_USER_COUNT = `SET_PENDING_USER_COUNT`;
export const SET_PENDING_MSG_COUNT = `SET_PENDING_MSG_COUNT`;
export const SET_PENDING_NOTIFY_COUNT = `SET_PENDING_NOTIFY_COUNT`;
