import React, { useState, useEffect } from 'react';
import { postRequest, REMOTE_ADHERENCE } from '../../../../../../../crud/crud';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import { Box, Grid, Zoom, createMuiTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { analyticsUrlHandler, doIfArr, startDateAnalytics } from 'src/utils';
import { fromDateChangeHandler, pickerPropsHandler, toDateChangeHandler } from 'src/pages/home/modules/settings/Analytics/utilities';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { Filterbutton } from 'src/pages/home/modules/settings/Analytics/Components/Filterbutton';
import { momentWrapper } from 'src/momentWrapper';
import moment from 'moment';

export const AdherenceCategory = ({ clicked }) => {
  const [loading, setLoading] = useState({ loading: false, error: false });
  const [fromDate, setFromDate] = useState(startDateAnalytics());
  const [toDate, setToDate] = useState(moment(new Date()).endOf(`month`));

  const [filterBtn, setFilterBtn] = useState(false);
  const [graphLabels, setGraphLabels] = useState([]);
  const [showMsg, setErrorMsg] = useState(false);

  const [adherentPatients, setAdherentPatients] = useState([]);
  const [nonAdherentPatients, setNonAdherentPatients] = useState([]);
  const [noDataPatients, setNoDataPatients] = useState([]);
  const breakpointValues = {
    xxs: 0,
    xs: 400,
    sm: 600,
    smd: 730,
    md: 900,
    mlg: 1018,
    lg: 1080,
    blg: 1127,
    bxlg: 1140,
    mdl: 1254,
    xl: 1400,
    xxl: 1645,
  };
  const theme = createMuiTheme({ breakpoints: { values: breakpointValues } });
  const matchesBlg = useMediaQuery(theme.breakpoints.down(`blg`));
  const matchesBlgUp = useMediaQuery(theme.breakpoints.down(`bxlg`));
  const matchesMlg = useMediaQuery(theme.breakpoints.down(`mlg`));
  const matchesMdl = useMediaQuery(theme.breakpoints.down(`mdl`));
  const matchesDownSmd = useMediaQuery(theme.breakpoints.down(`smd`));
  const matchesdownXl = useMediaQuery(theme.breakpoints.down(`xl`));
  const matchesUpXl = useMediaQuery(theme.breakpoints.down(`xxl`));
  const matchesUpXxl = useMediaQuery(theme.breakpoints.up(`xxl`));
  const view = useSelector((state) => state?.analytics?.view);

  const apiCall = (view) => {
    let labelsTempArray = [];
    let nonAdherentPatients = [];
    let adherentPatients = [];
    let noDataPatients = [];
    setLoading({ loading: true, error: false });
    setFilterBtn(true);
    setGraphLabels([]);
    setNonAdherentPatients([]);
    setAdherentPatients([]);
    setNoDataPatients([]);
    let filters = analyticsUrlHandler(view);
    postRequest(REMOTE_ADHERENCE + `?filters=${filters}`, {
      startDate: momentWrapper(fromDate).startOf(`month`).toISOString(),
      endDate: momentWrapper(toDate).endOf(`month`).toISOString(),
    })
      .then((res) => {
        setLoading({ loading: false, error: false });
        setFilterBtn(false);
        doIfArr(res?.data?.numOfMinutesPerMonthByAdherenceAnalytics, () => {
          if (res.data.numOfMinutesPerMonthByAdherenceAnalytics) {
            for (const data of res.data.numOfMinutesPerMonthByAdherenceAnalytics) {
              labelsTempArray.push(data.monthName);
              let { nonAdherentMinutes, nonAdherentPercentage, adherentMinutes, adherentPercentage, noDataMinutes, noDataPercentage } = data;

              let getAdherentMinutes = adherentMinutes.split(` `)[0];
              let getNonAdherentMinutes = nonAdherentMinutes.split(` `)[0];
              let getNoDataMinutes = noDataMinutes.split(` `)[0];
              let adherentSeconds = adherentMinutes.split(` `)[2];
              let nonAdherentSeconds = nonAdherentMinutes.split(` `)[2];
              let noDataSeconds = noDataMinutes.split(` `)[2];
              let mergedAdherent = getAdherentMinutes + `.` + adherentSeconds;
              let mergedNonAdherent = getNonAdherentMinutes + `.` + nonAdherentSeconds;
              let mergedNoData = getNoDataMinutes + `.` + noDataSeconds;
              nonAdherentPatients.push({
                id: mergedNonAdherent,
                percentage: nonAdherentPercentage,
              });
              adherentPatients.push({
                id: mergedAdherent,
                percentage: adherentPercentage,
              });
              noDataPatients.push({
                id: mergedNoData,
                percentage: noDataPercentage,
              });
            }
          }
        });

        setGraphLabels([...labelsTempArray]);
        setNonAdherentPatients([...nonAdherentPatients]);
        setAdherentPatients([...adherentPatients]);
        setNoDataPatients([...noDataPatients]);
      })
      .catch(() => {
        setLoading({ loading: false, error: true });
        setFilterBtn(false);
      });
  };

  useEffect(() => {
    apiCall(view);
  }, [view]);

  let height;
  let mt;
  let marginLeft = 3;
  let marginRight = 3;

  if (matchesBlg) {
    height = 305;
    mt = 4.5;
  } else if (matchesBlgUp || matchesMdl || matchesdownXl || matchesUpXl) {
    height = 280;
    mt = 0.5;
  } else {
    height = 300;
    if (matchesDownSmd) {
      mt = 4.3;
    } else if (matchesMlg) {
      mt = 1;
    } else if (matchesUpXxl) {
      mt = -2;
    } else {
      mt = 3;
    }
  }

  return (
    <Zoom in={clicked} timeout={500} unmountOnExit>
      <Box
        sx={{
          backgroundColor: `white`,
          boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 16px 0 rgba(0, 0, 0, 0.05) `,
          borderRadius: `8px`,
        }}
      >
        <h4 className="px-3 pt-3 pb-2 mb-0 mb-0" style={{ fontWeight: 400 }}>
          Adherence category:
        </h4>
        {showMsg ? (
          <span className="ml-3" style={{ color: `red`, fontSize: `12px` }}>
            * Invalid date selection
          </span>
        ) : null}
        <Grid container sx={{ p: 2 }} rowSpacing={1} columnSpacing={1}>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`From`),
              }}
              dateInputValue={fromDate}
              onChange={(e) => fromDateChangeHandler(e, setFilterBtn, setErrorMsg, setFromDate, toDate)}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box sx={{ p: 0.7 }}>-</Box>
          </Grid>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            {` `}

            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`To`),
              }}
              dateInputValue={toDate}
              onChange={(e) => toDateChangeHandler(e, setFilterBtn, setErrorMsg, fromDate, setToDate)}
              maxDate={true}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={12} lg={3} xl={3}>
            <Filterbutton
              arg={{ payLoad: {}, setData: null, setLoading: null }}
              apiCall={() => apiCall(view)}
              filterBtn={filterBtn}
              loading={loading}
              ownApi={true}
            />
          </Grid>
        </Grid>
        <Box sx={{ height: height, mt: mt, marginLeft: marginLeft, marginRight: marginRight }}>
          <Bar
            data={{
              labels: graphLabels,
              datasets: [
                {
                  label: `Non-Adherent`,
                  backgroundColor: `#eb2f06`,
                  data: nonAdherentPatients.map((item) => item.id),
                  percentage: nonAdherentPatients.map((item) => item.percentage),
                },
                {
                  label: `Adherent`,
                  backgroundColor: `#17a2b8`,
                  data: adherentPatients.map((item) => item.id),
                  percentage: adherentPatients.map((item) => item.percentage),
                },
                {
                  label: `No Data`,
                  backgroundColor: `#95afc0`,
                  data: noDataPatients.map((item) => item.id),
                  percentage: noDataPatients.map((item) => item.percentage),
                },
              ],
            }}
            width={100}
            height={50}
            options={{
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    let label = ``;
                    let percentage = data.datasets[tooltipItem.datasetIndex].percentage;
                    let valueParts = tooltipItem.value.split(`.`);
                    let minutes = valueParts[0] || 0;
                    let seconds = valueParts[1] || 0;

                    label += `${percentage[tooltipItem.index]}%, ${minutes} min ${seconds} sec`;
                    return label;
                  },
                },
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: false,
                    },
                    scaleLabel: {
                      display: true,
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: `Time spent on RPM(minutes)`,
                    },
                    type: `linear`,
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                position: `top`,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Zoom>
  );
};
