import { Tooltip } from '@mui/material';
import React from 'react';

export const ToolTipProvider = ({ toolTipProps = { arrow: true, placement: 'top' }, sx, element }) => {
  const { arrow = true, placement = 'top', ...others } = toolTipProps;
  return (
    <Tooltip arrow={arrow} placement={placement} {...others} sx={{ ...(sx ? { ...sx } : {}) }}>
      {element}
    </Tooltip>
  );
};
