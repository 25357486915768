import React from 'react';
import { TemplateCompUniSelect } from './TemplateCompUniSelect';

export const Comp = ({ users, onChange, onBlur, error, value, label = '*Primary Provider' }) => {
  return (
    <TemplateCompUniSelect
      users={users}
      compProps={{
        controlProps: {
          error: error ? true : false,
          size: `small`,
          fullWidth: true,
          id: `bulkImportPrimaryProv`,
        },

        label: label,
        optionKey: `fullName`,
        optionValue: `_id`,
        selectProps: {
          value,
          helperText: error,
          label: label,
          name: `bulkImportPrimaryProv`,
          ...(onChange && { onChange }),
          ...(onBlur && { onBlur }),
        },
      }}
    />
  );
};

export const PrimaryProv = React.memo(Comp);
