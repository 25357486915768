import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  PATIENT_RESPIRONICS_SUMMARY_PDF_URL, postRequest, PATIENT_RESMED_SUMMARY_PDF_URL, PATIENT_RESMED_DETAIL_PDF_URL,
  PATIENT_REACT_HEALTH_SUMMARY_PDF
} from '../../../../../../../../../crud/crud';
import Iframe from 'react-iframe';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { REACT_HEALTH } from 'src/utils';

export const PdfViewer = ({ isOverView = false, detail, show, onHide, userId, start, end, reportId, setIsPdfSummaryReportAvailable, patientData }) => {


  const { resmed: { isRealTimeDataAvailable: realTimeDataAvailable }, patient: { hasPatientPastData = false } } = useSelector((state) => state)
  const patientManufacturer = useSelector((state) => state?.patient?.patientReport?.patient?.device?.manufacturer);
  let device = patientManufacturer ? patientManufacturer : patientData;
  const [fileURL, setFileURL] = useState(``);

  // RESMED API STATUS BASED, ENABLE/DISABLED
  const [isLiveDataAvailable, setIsLiveDataAvailable] = useState(null);

  useEffect(() => {
    let isResmedPatient = patientManufacturer === `resmed` || device === `resmed`
    if (userId) {
      let temp = null;
      if (!isLiveDataAvailable) {
        if (detail !== true) {
          if (isResmedPatient && hasPatientPastData || isOverView && isResmedPatient) {
            let url = PATIENT_RESMED_SUMMARY_PDF_URL + userId + `/?reportId=${reportId}`
            postRequest(url, {
              startDate: start,
              endDate: end,
            }, { responseType: `arraybuffer` })
              .then((res) => {
                setIsPdfSummaryReportAvailable(true);
                temp = new Blob([res.data], { type: `application/pdf` });
                setFileURL(URL.createObjectURL(temp));
                // setIframeRefreshFlag(true)
              })
              .catch((err) => {
                if (
                  err?.response?.status === 400 ||
                  err?.response?.status === 401 ||
                  err?.response?.status === 403 ||
                  err?.response?.status === 409 ||
                  err?.response?.status === 429 ||
                  err?.response?.status === 500 ||
                  err?.response?.status === 502
                ) {
                  setIsPdfSummaryReportAvailable(false);
                }
              });
          } else if (patientManufacturer === `respironics` || device === `respironics`) {
            let url = PATIENT_RESPIRONICS_SUMMARY_PDF_URL + userId + `/?reportId=${reportId}`
            postRequest(url, {
              startDate: start,
              endDate: end,
              type: `summary`,
            }, { responseType: `arraybuffer` })
              .then((res) => {
                setIsPdfSummaryReportAvailable(true);
                temp = new Blob([res.data], { type: `application/pdf` });
                setFileURL(URL.createObjectURL(temp));
                // setIframeRefreshFlag(true)
              })
              .catch((err) => {
                if (
                  err?.response?.status === 400 ||
                  err?.response?.status === 401 ||
                  err?.response?.status === 403 ||
                  err?.response?.status === 409 ||
                  err?.response?.status === 429 ||
                  err?.response?.status === 500 ||
                  err?.response?.status === 502
                ) {
                  setIsPdfSummaryReportAvailable(false);
                }
              });
          } else if (patientManufacturer === REACT_HEALTH || device === REACT_HEALTH) {
            // URL defined for React_Health with query_params.
            let url = PATIENT_REACT_HEALTH_SUMMARY_PDF + userId + `/?reportId=${reportId}`
            //Post Request For ReactHealth.
            postRequest(url, {
              startDate: start,
              endDate: end,
            }, { responseType: `arraybuffer` })
              .then((response) => {
                setIsPdfSummaryReportAvailable(true);
                temp = new Blob([response?.data], { type: `application/pdf` });
                setFileURL(URL.createObjectURL(temp));
                // setIframeRefreshFlag(true)
              })
              .catch((error) => {
                if (
                  error?.response?.status === 400 ||
                  error?.response?.status === 401 ||
                  error?.response?.status === 403 ||
                  error?.response?.status === 409 ||
                  error?.response?.status === 429 ||
                  error?.response?.status === 500 ||
                  error?.response?.status === 502
                ) {
                  setIsPdfSummaryReportAvailable(false);
                }
              });
          }
        } else {
          if (hasPatientPastData || isOverView && isResmedPatient) {
            let url = PATIENT_RESMED_DETAIL_PDF_URL + userId + `/?reportId=${reportId}`
            postRequest(url, {
              startDate: start,
              endDate: end,
            }, { responseType: `arraybuffer` })
              .then((res) => {
                setIsPdfSummaryReportAvailable(true);
                temp = new Blob([res.data], { type: `application/pdf` });
                setFileURL(URL.createObjectURL(temp));
                // setIframeRefreshFlag(true)
              })
              .catch((err) => {
                if (
                  err?.response?.status === 400 ||
                  err?.response?.status === 401 ||
                  err?.response?.status === 403 ||
                  err?.response?.status === 409 ||
                  err?.response?.status === 429 ||
                  err?.response?.status === 500 ||
                  err?.response?.status === 502
                ) {
                  setIsPdfSummaryReportAvailable(false);
                }
              });
          } else if (patientManufacturer === `respironics` || device === `respironics`) {
            let url = PATIENT_RESPIRONICS_SUMMARY_PDF_URL + userId + `/?reportId=${reportId}`
            postRequest(url, {
              startDate: start,
              endDate: end,
              type: `detail`,
            }, { responseType: `arraybuffer` })
              .then((res) => {
                setIsPdfSummaryReportAvailable(true);
                temp = new Blob([res.data], { type: `application/pdf` });
                setFileURL(URL.createObjectURL(temp));
                // setIframeRefreshFlag(true)
              })
              .catch((err) => {
                if (
                  err?.response?.status === 400 ||
                  err?.response?.status === 401 ||
                  err?.response?.status === 403 ||
                  err?.response?.status === 409 ||
                  err?.response?.status === 429 ||
                  err?.response?.status === 500 ||
                  err?.response?.status === 502
                ) {
                  setIsPdfSummaryReportAvailable(false);
                }
              });
          }
        }
      }
    }
  }, [detail, userId, hasPatientPastData, isOverView, isLiveDataAvailable, patientManufacturer]);

  // EFFECT IS USE, TO ENABLE/DISABLED -> PENDING PATIENT LIST + IMPORT PATIENT BUTTON
  useEffect(() => {
    if (realTimeDataAvailable) {
      if (realTimeDataAvailable.apiStatus === `available`) {
        setIsLiveDataAvailable(false);
      }
      if (realTimeDataAvailable.apiStatus === `unavailable`) {
        setIsLiveDataAvailable(true);
      }
      if (realTimeDataAvailable.apiStatus === `disabled`) {
        setIsLiveDataAvailable(true);
      }
    }
  }, [realTimeDataAvailable]);

  return (
    <div>
      <CustomizedDialogs noTitle={true} open={show} setOpen={onHide} size="md" fullWidth={true} showCloseButton={true}>
        <div className="modal-content">
          <div style={{ height: `80vh` }}>
            <Iframe
              url={fileURL}
              width="100%"
              height="100%"
              id="myId"
              style={{ border: `none` }}
              className="myClassname"
              display="initial"
              position="relative"
            />
          </div>
        </div>
      </CustomizedDialogs>
    </div>
  );
}

