import React, { useState, useEffect, useCallback } from 'react';
import { newSocket as socket } from './SocketWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { PATIENT_TIME_LOGS_UPDATE_URL, patchRequest } from 'src/crud/crud';
import { updateAllTimeLogsAfterAudioCall, updateAudioTimeLogs } from 'src/redux/patient/patientActions';
import { errorToast } from '../components/snackBar/toast';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Button
} from '@mui/material';

export const VoiceCallFeedBackWrapper = ({ children }) => {
  const {
    patient = { timer, patientData },
    timer: { callType },
  } = useSelector((state) => state);
  const { timer, patientData = { data: {} } } = patient;
  const { data = { interactiveCommunication: false } } = patientData;
  const { interactiveCommunication } = data;
  const dispatch = useDispatch();
  const [audioSocketData, setAudioSocketData] = useState({});
  const [feedbackModal, setFeedbackModal] = useState(false);
  const handleCloseFeedbackModal = (response, lftVoiceMail) => {
    callFunc(audioSocketData, response, lftVoiceMail);
    setFeedbackModal(false);
  };
  const toastHandler = (duration) => {
    return duration < 5 && errorToast(`Call duration is less than 5 seconds so Time Logs can,t be updated!`);
  };

  const callFunc = useCallback(
    (data, callSuccess, leftVoiceMail) => {
      if (data?.duration > 5) {
        let logCategory;
        let description;

        if (leftVoiceMail) {
          logCategory = "VOICEMAIL";
          description = "Left Voicemail";
        } else if (callSuccess) {
          logCategory = interactiveCommunication ? "INTERACTIVE_COMMUNICATION" : "NO_INTERACTIVE_COMMUNICATION";
          description = interactiveCommunication ? "Call Completed" : "Interactive Phone call Completed";
        } else {
          logCategory = "NO_INTERACTIVE_COMMUNICATION";
          description = "No Interactive Communication";
        }
        patchRequest(PATIENT_TIME_LOGS_UPDATE_URL + `/` + data._id, {
          callSuccess: callSuccess,
          leftVoiceMail: leftVoiceMail,
          logCategory: logCategory,
          description: description,
        })
          .then((res) => {
            dispatch(updateAllTimeLogsAfterAudioCall(res.data?.data));
          })
          .catch(() => { });
        dispatch(updateAudioTimeLogs({ data: data, callSuccess: callSuccess, leftVoiceMail: leftVoiceMail }));
      } else {
        dispatch(updateAudioTimeLogs({ data: data, callSuccess: callSuccess, leftVoiceMail: leftVoiceMail, turnOnTimer: true }));
        toastHandler(data?.duration);
      }
    },
    [timer]
  );

  useEffect(() => {
    socket.on(`audioCallLogs`, (data) => {
      if (data?.duration > 5) {
        dispatch(updateAllTimeLogsAfterAudioCall(data));
        if (interactiveCommunication) {
          callFunc(data, true, false);
          setFeedbackModal(false);
        } else {
          setFeedbackModal(true);
          toastHandler(data?.duration);
          setAudioSocketData(data);
        }
      } else if (data?.duration < 5) {
        dispatch(updateAudioTimeLogs({ data: null, callSuccess: null, runTimer: true }));
      }
    });
    return () => {
      socket.off(`audioCallLogs`);
    };
  }, [feedbackModal, callFunc, callType, interactiveCommunication]);

  return (
    <>
      {` `}
      <Dialog
        sx={{
          zIndex: 10000000000,
        }}
        open={feedbackModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Did this call satisfy the requirements for interactive communication?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: `center` }}>
          <Button onClick={() => handleCloseFeedbackModal(true, false)} variant="contained" style={{ backgroundColor: `green`, color: `white` }}>
            YES
          </Button>
          <Button
            onClick={() => handleCloseFeedbackModal(false, false)}
            variant="contained"
            style={{ backgroundColor: `red`, color: `white` }}
            autoFocus
          >
            NO
          </Button>
          <Button
            onClick={() => handleCloseFeedbackModal(false, true)}
            variant="contained"
            style={{ backgroundColor: `#1699C5`, color: `white` }}
            autoFocus
          >
            Left Voicemail
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
};