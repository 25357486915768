import React from 'react';
import Spinner from 'react-spinners/PulseLoader';

export const PulseSpinner = ({ position = `absolute` }) => {
  return (
    <div
      style={{
        position: position,
        top: 0,
        left: 0,
        backgroundColor: `rgba(0,0,0,0.3)`,
        zIndex: 20,
        display: `flex`,
        width: `100%`,
        height: `100%`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <Spinner
        size={25}
        color={`white`}
        loading={true}
      />
    </div>
  );
};

