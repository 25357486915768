import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './timeLogs.module.css';
import { changeAccumulatedTime, deletePatientTimeLog, getAllTimeLogs } from 'src/redux/patient/patientActions';
import { deleteRequest, DELETE_TIME_LOG } from '../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../common/components/spinner/spinner';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { momentWrapper } from 'src/momentWrapper';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import './datePicker/daterangepicker.css';
import { AddManualTimeLog } from './addManualTimeLog/AddManualTimeLog';
import DeleteIcon from '@mui/icons-material/Delete';
import { _patientBasicDetailTimerResetFlag, _patientTimerRefrshFlag } from '../../../../../../redux/timer/timerActions';
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { store } from 'src/redux/store';
import { returnIfArr } from 'src/utils';

let row = null;
let _manualTimeLogFields = null;

export const TimeLogs = ({ auth, patientId, reportId, setRefresh, timeLogModal, setTimeLogModal }) => {
  const {
    user: { user },
    resmed: { addNewLog },
    timer: { refreshManualTimeLogComponent },
  } = useSelector((state) => state);
  let location = useLocation();
  const dispatchRedux = useDispatch();
  const [loading, setLoading] = useState(false);
  const [addManualLog, setAddManualLog] = useState(false);
  const [addNewLogBtnDisable, setAddNewLogBtnDisabled] = useState(false);
  const [manualLogRefreshFlag, setManualLogSetRefreshFlag] = useState(false);
  const totalLogs = useSelector((state) => state.patient?.allTimeLogs?.timeLogs?.totalLogs);
  const datas = useSelector((state) => state.patient?.allTimeLogs?.timeLogs?.Logs);
  const loadingFromRedux = useSelector((state) => state.patient?.allTimeLogs?.loading);
  const patientData = useSelector((state) => state?.patient?.patientData);
  const { patientsList = { data: { patients: [] } } } = useSelector((state) => state.patient)
  const { data: { patients } = { patients: [] } } = patientsList
  useEffect(() => {
    setLoading(loadingFromRedux);
  }, [loadingFromRedux]);

  useEffect(() => {
    if (patientId && !user?.isBiller) {
      dispatchRedux(
        getAllTimeLogs({
          reportId: reportId,
          startDate: location?.startDateOfReport ? location?.startDateOfReport : momentWrapper().startOf(`month`).toISOString(),
          endDate: location?.endDateOfReport ? location?.endDateOfReport : momentWrapper().toISOString(),
          patientId: patientId,
          timeLogModal: timeLogModal,
        })
      );
    }
  }, [patientId, patientData?.data?.timeMonitored, addNewLog, manualLogRefreshFlag, refreshManualTimeLogComponent]);

  const cancleAddingNewLog = () => {
    setAddNewLogBtnDisabled(false);
    setAddManualLog(false);
  };
  const deleteTimeLog = (logId) => {
    Swal.fire({
      title: `Attention!`,
      text: `If interactive communication gets false or time goes less then 20 minutes the Corresponding report can get deleted.`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonColor: `#3085d6`,
      cancelButtonColor: `#d33`,
      confirmButtonText: `Yes, delete it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        deleteRequest(DELETE_TIME_LOG + `/` + logId)
          .then((res) => {



            if (res?.data?.queryResult?.duration) {
              const selectedPatientInList = returnIfArr(patients).find((patient) => patient?._id === patientData?.data?._id)
              const accumulatedTime = selectedPatientInList?.timeMonitored && selectedPatientInList.timeMonitored - res.data.queryResult.duration
              console.log(accumulatedTime, `selectedPatientInList`)
              dispatchRedux(changeAccumulatedTime({ accumulatedTime, _patientId: patientData?.data?._id }))
            }


            setManualLogSetRefreshFlag(!manualLogRefreshFlag);
            setRefresh(true);
            dispatchRedux(_patientBasicDetailTimerResetFlag(true));
            _patientTimerRefrshFlag(true); // bbbbb
            setLoading(false);
            successToast(`Time Log Deleted.`);
            Swal.fire(`Success!`, `Time Log Deleted`, `success`);
          })
          .catch((err) => {
            // errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
            setLoading(false);
          });
      }
    });
  };

  const addManualTimeLog = () => {
    setAddManualLog(true);
    setAddNewLogBtnDisabled(true);
    _manualTimeLogFields = (
      <AddManualTimeLog
        cancleBtnHandler={cancleAddingNewLog}
        auth={auth}
        patientId={patientId}
        successToast={successToast}
        errorToast={errorToast}
        // setData={setData}
        data={datas}
        reportId={reportId}
        setManualLogSetRefreshFlag={setManualLogSetRefreshFlag}
        manualLogRefreshFlag={manualLogRefreshFlag}
        setRefreshFlag={setRefresh}
      />
    );

    return _manualTimeLogFields;
  };

  row = datas?.map((log) => {
    return (
      <tr key={log?._id}>
        <th scope="row">{momentWrapper(log?.dateCreated)?.format(`MM/DD/YYYY`)}</th>
        {log?.startDate && log?.endDate ? <td>{momentWrapper(log?.startDate)?.format(`hh:mm:ss A`)}</td> : <td className="text-center">-</td>}
        {log?.startDate && log?.endDate ? <td>{momentWrapper(log?.endDate)?.format(`hh:mm:ss A`)}</td> : <td className="text-center">-</td>}
        <td>{moment.duration(log?.duration, `seconds`).format(`H[h,] m[m] s[s]`)}</td>
        <td style={{ textTransform: `capitalize` }}>{`${log?._created_by?.firstName} ${log?._created_by?.lastName}`}</td>
        <td>{`${log?.source}`}</td>
        <td>{`${log?.type}`}</td>
        <td>{`${log?.description?.length <= 23}` ? `${log?.description}` : `${log?.description?.slice(0, 23)}...`}</td>
        <td>
          <p style={{ cursor: `pointer` }} onClick={() => deleteTimeLog(log?._id)}>
            <DeleteIcon />
          </p>
        </td>
      </tr>
    );
  });

  return (
    <CustomizedDialogs title="TIME LOGS" open={timeLogModal} setOpen={() => setTimeLogModal(false)} size="md" fullWidth={true} showCloseButton={true}>
      <ToastContainer />
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <span style={{ fontSize: `small`, color: `grey` }}>{`${totalLogs} Record(s) Found`}</span>
          <div>
            <button
              type="button"
              disabled={addNewLogBtnDisable}
              style={{ display: addNewLogBtnDisable ? `none` : `` }}
              className="btn btn-outline-info"
              id={css.addNewtimeLog}
              onClick={() => addManualTimeLog()}
            >
              ADD MANUAL LOG
            </button>
          </div>
        </div>

        <div>{addManualLog ? _manualTimeLogFields : null}</div>

        <div className="table-responsive" style={{ minWidth: `80%` }}>
          <table className="table table-sm table-hover mb-0 mt-3">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Start Time</th>
                <th scope="col">End Time</th>
                <th scope="col">Duration</th>
                <th scope="col">Monitored By</th>
                <th scope="col">Source</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {datas?.length ? (
                row
              ) : (
                <tr>
                  <td className="text-center pt-3" colSpan="8">
                    No Record Found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {loading && <PulseSpinner />}
    </CustomizedDialogs>
  );
};
