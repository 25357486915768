import React, { useState, useEffect } from 'react';
import { ANALYTICS_ADHERENCE, postRequest } from '../../../../../../../crud/crud';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import { Box, Grid, Zoom, createMuiTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { fromDateChangeHandler, pickerPropsHandler, toDateChangeHandler } from 'src/pages/home/modules/settings/Analytics/utilities';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { Filterbutton } from 'src/pages/home/modules/settings/Analytics/Components/Filterbutton';
import { analyticsUrlHandler, doIfArr, startDateAnalytics } from 'src/utils';
import { momentWrapper } from 'src/momentWrapper';
import moment from 'moment';

export const Adherence = ({ clicked }) => {
  const [loading, setLoading] = useState({ loading: false, error: false });
  const [fromDate, setFromDate] = useState(startDateAnalytics());
  const [toDate, setToDate] = useState(moment(new Date()).endOf(`month`));
  const [filterBtn, setFilterBtn] = useState(false);
  const [graphLabels, setGraphLabels] = useState([]);
  const [showMsg, setErrorMsg] = useState(false);
  const [adherentPatients, setAdherentPatients] = useState([]);
  const [nonAdherentPatients, setNonAdherentPatients] = useState([]);
  const [noDataPatients, setNoDataPatients] = useState([]);
  const breakpointValues = {
    xxs: 0,
    xs: 400,
    sm: 600,
    md: 900,
    lg: 1080,
    blg: 1090,
    mdl: 1200,
    xl: 1400,
  };
  const theme = createMuiTheme({ breakpoints: { values: breakpointValues } });
  const matchesBlg = useMediaQuery(theme.breakpoints.down(`blg`));
  const matchesMdl = useMediaQuery(theme.breakpoints.down(`mdl`));
  const matches = useMediaQuery(theme.breakpoints.down(`lg`));
  const view = useSelector((state) => state?.analytics?.view);

  const apiCall = (view) => {
    let labelsTempArray = [];
    let nonAdherentPatients = [];
    let adherentPatients = [];
    let noDataPatients = [];

    setLoading({ loading: true, error: false });
    setFilterBtn(true);
    setGraphLabels([]);
    setNonAdherentPatients([]);
    setNoDataPatients([]);
    setAdherentPatients([]);
    let filters = analyticsUrlHandler(view);
    postRequest(ANALYTICS_ADHERENCE + `?filters=${filters}`, {
      startDate: momentWrapper(fromDate).startOf(`month`).toISOString(),
      endDate: momentWrapper(toDate).endOf(`month`).toISOString(),
    })
      .then((res) => {
        setLoading({ loading: false, error: false });
        setFilterBtn(false);

        doIfArr(res?.data?.numOfPatientsPerMonthAnalytics, () => {
          for (const data of res.data.numOfPatientsPerMonthAnalytics) {
            labelsTempArray.push(data.monthName);
            let {
              nonAdherentPatientsCount,
              nonAdherentPatientsPercentage,
              adherentPatientsCount,
              adherentPatientsPercentage,
              noDataCount,
              noDataPercentage,
            } = data;

            nonAdherentPatients.push({
              id: nonAdherentPatientsCount,
              percentage: nonAdherentPatientsPercentage,
            });
            adherentPatients.push({
              id: adherentPatientsCount,
              percentage: adherentPatientsPercentage,
            });
            noDataPatients.push({
              id: noDataCount,
              percentage: noDataPercentage,
            });
          }
        });

        setGraphLabels([...labelsTempArray]);
        setNonAdherentPatients([...nonAdherentPatients]);
        setNoDataPatients([...noDataPatients]);
        setAdherentPatients([...adherentPatients]);
      })
      .catch(() => {
        setLoading({ loading: false, error: true });
        setFilterBtn(false);
      });
  };

  useEffect(() => {
    apiCall(view);
  }, [view]);

  let height;
  let mt;
  if (matchesBlg) {
    height = 345;
    mt = 0;
  } else if (matchesMdl) {
    height = 325;
    mt = 2.5;
  } else {
    height = 320;
    mt = 3;
  }

  return (
    <Zoom in={clicked} timeout={500} unmountOnExit>
      <Box
        sx={{
          backgroundColor: `white`,
          boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 16px 0 rgba(0, 0, 0, 0.05) `,
          borderRadius: `8px`,
          height: matches || matchesMdl ? 520 : 500,
        }}
      >
        <h4 className="px-3 pt-3 pb-2 mb-0 mb-0" style={{ fontWeight: 400 }}>
          Adherence:
        </h4>
        {showMsg ? (
          <span className="ml-3" style={{ color: `red`, fontSize: `12px` }}>
            * Invalid date selection
          </span>
        ) : null}
        <Grid sx={{ p: 2 }} container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            {` `}

            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`From`),
              }}
              dateInputValue={fromDate}
              onChange={(e) => fromDateChangeHandler(e, setFilterBtn, setErrorMsg, setFromDate, toDate)}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box sx={{ p: 0.7 }}>-</Box>
          </Grid>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`To`),
              }}
              dateInputValue={toDate}
              maxDate={true}
              onChange={(e) => toDateChangeHandler(e, setFilterBtn, setErrorMsg, fromDate, setToDate)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={12} lg={3} xl={3}>
            {` `}
            <Filterbutton
              arg={{ payLoad: {}, setData: null, setLoading: null }}
              apiCall={() => apiCall(view)}
              filterBtn={filterBtn}
              loading={loading}
              ownApi={true}
            />
          </Grid>
        </Grid>
        <Box sx={{ height: height, mt: mt, marginLeft: 2, marginRight: 2 }}>
          <Bar
            data={{
              labels: graphLabels,
              datasets: [
                {
                  label: `Non-Adherent`,
                  backgroundColor: `#eb2f06`,
                  data: nonAdherentPatients.map((item) => item.id),
                  percentage: nonAdherentPatients.map((item) => item.percentage),
                },
                {
                  label: `Adherent`,
                  backgroundColor: `#17a2b8`,
                  data: adherentPatients.map((item) => item.id),
                  percentage: adherentPatients.map((item) => item.percentage),
                },
                {
                  label: `No data`,
                  backgroundColor: `#95afc0`,
                  data: noDataPatients.map((item) => item.id),
                  percentage: noDataPatients.map((item) => item.percentage),
                },
              ],
            }}
            width={100}
            height={300}
            options={{
              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {
                    let label = ``;
                    let percentage = data.datasets[tooltipItem.datasetIndex].percentage;
                    label += percentage[tooltipItem.index] + `%, ` + tooltipItem.value;
                    return label;
                  },
                },
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: false,
                    },
                    scaleLabel: {
                      display: true,
                      // labelString: 'Month',
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: `# of Patients`,
                    },
                    type: `linear`,
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                position: `top`,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Zoom>
  );
};
