import { returnIfArr } from 'src/utils';
import { LOGOUT } from '../action/types';
import {
  OPENED_TASK_ID,
  UPDATE_NOTIFY_TASK_ALERT,
  TASKS_NOTIFY_SINGLE_SAVE,
  EMPTY_TASK_NOTIFICATION,
  NOTIFY_TASK,
  SAVE_NOTIFY_TASK_ALERT,
  DELETE_NOTIFY_TASK_ALERT,
  REMOVE_FROM_TASK_NOTIFICATIONS,
  TASKS_LOADING,
} from './ticketsCenterTypes';

const initialState = {
  taskNotificationArray: [],
  taskAlerts: [],
  openedTaskId: ``,
  tasksLoading: { loading: false, error: false },
};
export const tasksManagerReducer = (state = initialState, action) => {
  const { type: actionType, payLoad: actionPayLoad } = action;
  let { taskAlerts, taskNotificationArray } = state;

  if (actionType === LOGOUT) {
    return { ...initialState };
  } else if (actionType === OPENED_TASK_ID) {
    return { ...state, openedTaskId: actionPayLoad };
  } else if (actionType === TASKS_LOADING) {
    return { ...state, tasksLoading: actionPayLoad };
  } else if (actionType === REMOVE_FROM_TASK_NOTIFICATIONS) {
    const { _id: payLoadId } = actionPayLoad;
    return {
      ...state,
      taskNotificationArray: [
        ...returnIfArr(taskNotificationArray).filter((task) => {
          const { _id: taskId } = task;
          return taskId !== payLoadId;
        }),
      ],
    };
  } else if (actionType === EMPTY_TASK_NOTIFICATION) {
    return { ...state, taskNotificationArray: [] };
  } else if (actionType === NOTIFY_TASK) {
    return { ...state, taskNotificationArray: [...actionPayLoad] };
  } else if (actionType === TASKS_NOTIFY_SINGLE_SAVE) {
    return { ...state, taskNotificationArray: [...taskNotificationArray, { ...actionPayLoad }] };
  } else if (actionType === UPDATE_NOTIFY_TASK_ALERT) {
    return {
      ...state,
      taskAlerts: taskAlerts?.map((task) => {
        const { _id } = task || ``;
        return { ...task, ...(_id === actionPayLoad ? { showingOnScreen: true } : {}) };
      }),
    };
  } else if (actionType === SAVE_NOTIFY_TASK_ALERT) {
    return { ...state, taskAlerts: [...taskAlerts, { ...actionPayLoad }] };
  } else if (actionType === DELETE_NOTIFY_TASK_ALERT) {
    const { _id } = actionPayLoad;
    let hasInTaskALerts = taskAlerts.find((task) => {
      return task?._id === _id;
    });
    if (hasInTaskALerts) {
      return {
        ...state,
        taskAlerts: [
          ...returnIfArr(taskAlerts).filter((task) => {
            return task?._id !== _id;
          }),
        ],
      };
    } else {
      return { ...state };
    }
  } else {
    return state;
  }
};
