import { Box } from '@mui/material'
import React from 'react'
import { capitalizeFirstLetter } from 'src/utils'

export const SingleUserLog = ({ user }) => {
    const { firstName, lastName } = user || ``
    let fullName = capitalizeFirstLetter(`${firstName} ${lastName}`)
    return (
        <Box sx={{ p: 1, pt: 0, fontWeight: 600, fontSize: `small` }}>{fullName}</Box>
    )
}
