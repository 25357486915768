import { Skeleton } from '@mui/material';
import React from 'react';

export const LoadingState = ({ type }) => {
  let width = 80;
  let arr = [
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
    {
      width: width,
      type: type,
    },
  ];

  return (
    <>
      {arr.map((e, i) => {
        return (
          <td key={i}>
            <Skeleton key={i} variant={e.type} />
          </td>
        );
      })}
    </>
  );
}
