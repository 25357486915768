import React from 'react';
import { keyframes } from '@emotion/react';
import { Box } from '@mui/material';

const animate = ({ horizontDir, startingDistance }) => {
  return keyframes` 0% {
    transform: rotate(0deg);
    translate: ${horizontDir === 'right' ? startingDistance : `-${startingDistance}`};
  }
  100% {
    transform: rotate(${horizontDir === 'right' ? '-360' : '360'}deg);
    translate:0px;
  }`;
};
const onlyRotateAnimate = (direction) => {
  return keyframes` 0% {
    transform: rotate(0deg);
   
  }
  100% {
    transform: rotate(${direction === 'forward' ? '-360' : '360'}deg);
  }`;
};
export const RotationAnimationWrapper = ({ children, animationProps }) => {
  const {
    isOnlyRotation = '',
    direction = 'forward',
    horizontDir = 'right',
    startingDistance = '40px',
    timing = '1s',
    iterations = `1`,
  } = animationProps;
  return (
    <Box
      sx={{
        animation: `${
          isOnlyRotation ? onlyRotateAnimate(direction) : animate({ direction, horizontDir, startingDistance })
        } ${timing} linear ${iterations}`,
      }}
    >
      {children}
    </Box>
  );
};
