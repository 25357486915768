import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const HistoryBlockWrraper = ({ children, blockNavigation }) => {
  const history = useHistory();

  let navigation = blockNavigation()
  useEffect(() => {
    const unblock = history.block(() => {
      return !(navigation);
    });
    return () => unblock();
  }, [navigation]);

  return children;
};
