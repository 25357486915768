import {
  INCOMMING_RESMED_RISK_NOTIFICATION,
  IS_REAL_DATA,
  INITIALIZE_RESMED_ARRAY_FOR_NOTIFICATION,
  RESET_REAL_DATA_FLAG,
  ADD_NEW_USER,
  PENDING_PATIENT_IS_AVAILABLE,
  ADD_NEW_PRACTICE,
  MANUALLY_NEW_PATIENT_ADDED,
  IMPORT_NEW_PATIENT_SUCCESSFULLY,
  ADD_NEW_LOG,
  ALERT_PATIENT_RISK_CHANGE,
  RISK_ALERT_PATIENT_REMOVE,
} from './resmedTypes';

export const isRealTimeDataAvailable = (flag) => {
  return {
    type: IS_REAL_DATA,
    payload: flag,
  };
};

export const addResmedRiskArrayObjectInitial = (payload) => {
  return {
    type: INITIALIZE_RESMED_ARRAY_FOR_NOTIFICATION,
    payload,
  };
};

export const alertPatientRiskChange = (data) => {
  return {
    type: ALERT_PATIENT_RISK_CHANGE,
    payload: data,
  };
};

export const resmedIncommingSMS = (inCommingSmsPayload) => {
  return {
    type: INCOMMING_RESMED_RISK_NOTIFICATION,
    payload: inCommingSmsPayload,
  };
};

export const removeRiskAlertPatient = (id) => {
  return {
    type: RISK_ALERT_PATIENT_REMOVE,
    payload: id,
  };
};

export const resetRealDataFlag = () => {
  return {
    type: RESET_REAL_DATA_FLAG,
  };
};

export const pendingPatientIsAvailabe = () => {
  return {
    type: PENDING_PATIENT_IS_AVAILABLE,
  };
};
// CHANGE FLAG, TO RERENDER THE PATIENT NOTIFICATIONS, WHEN NEW PATIENT IS ADDED MANUALLY
export const newPatientAddedManully = () => {
  return {
    type: MANUALLY_NEW_PATIENT_ADDED,
  };
};

// SET REFRESH FLAG, PATIENT IMPORT SUCCESSFLLY,
export const newImportPatientAddedSuccessfully = () => {
  return {
    type: IMPORT_NEW_PATIENT_SUCCESSFULLY,
  };
};

// ADD NEW TIME-LOG, FLAG, RERENDER COMPONENT
export const addNewTimeLogMountFlag = () => {
  return {
    type: ADD_NEW_LOG,
  };
};
// ADD NEW PRACTICE, RERENDER COMPONENT
export const addNewPracticeMountFlag = () => {
  return {
    type: ADD_NEW_PRACTICE,
  };
};
// ADD NEW USER, RERENDER COMPONENT
export const addNewUserMountFlag = () => {
  return {
    type: ADD_NEW_USER,
  };
};

// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _isRealTimeDataAvailable = (flag) => {
  return (dispatch) => {
    dispatch(isRealTimeDataAvailable(flag));
  };
};

// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _alertPatientRiskChange = (data) => {
  return (dispatch) => {
    dispatch(alertPatientRiskChange(data));
  };
};
// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _removeRiskAlertPatient = (id) => {
  return (dispatch) => {
    dispatch(removeRiskAlertPatient(id));
  };
};
