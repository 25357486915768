import React, { useState } from 'react';
import css from './ConversationSearch.module.css';

export const ConversationSearch = ({ allUser, filteredUserHandler }) => {
  const [searchString, setSearchString] = useState(``);

  const filterHandler = (string) => {
    setSearchString(string);
    let filteredUser = [];
    for (const i of allUser) {
      if (i.name.toLowerCase().includes(string.toLowerCase())) {
        filteredUser.push(i);
      }
    }
    filteredUserHandler(filteredUser);
  };

  return (
    <div className={css.conversationSearch}>
      <input
        value={searchString}
        onChange={(e) => filterHandler(e.target.value)}
        type="search"
        className={css.conversationSearchInput}
        placeholder="Search"
      />
    </div>
  );
}
