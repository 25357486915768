import React from 'react'
import { Box } from '@mui/material'
import { momentWrapper } from 'src/momentWrapper'
import { capitalizeFirstLetter } from 'src/utils'
import { useSelector } from 'react-redux'

export const TaskComment = ({ comment }) => {
    const { user: { _id: userId } } = useSelector(({ user }) => user)
    const { _added_by: commentedBy } = comment || {}
    const { _id: commentedById } = commentedBy || false
    let isMine = userId === commentedById

    const backgroundColor = isMine ? `rgba(223, 212, 119, 0.5)` : `rgba(119, 223, 218, 0.5)`;

    const fullName = capitalizeFirstLetter(comment?._added_by?.lastName + ' ' + comment?._added_by?.firstName);

    return (
        <Box
            sx={{
                padding: `5px 10px`,
                backgroundColor: { backgroundColor },
                textAlign: `justify`,
                borderRadius: `7px`,
                marginBottom: `5px`,
            }}
        >
            <Box sx={{ fontSize: `14px`, fontWeight: `bold`, color: `gray` }}>
                {fullName}
            </Box>
            <Box sx={{ fontSize: `small` }}>&nbsp;&nbsp;&nbsp; {comment?.comment}</Box>
            <Box sx={{ textAlign: `right`, fontWeight: `bold`, color: `gray` }}>
                {momentWrapper(comment?.createdAt).format(`MM/DD/YYYY hh:mm A`)}
            </Box>
        </Box>
    )
}