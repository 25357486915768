import { getUserFriendlyError } from './userfriendlyerror';
import $ from 'jquery';

/**
 * Show the given error.
 * @param $modal - modal for showing the error.
 * @param error - Error to be shown.
 */

export const showError = ($modal, error) => {
  const $alert = $(`div.alert`, $modal);
  const errorMessage = error.message ? `: ${error.message}` : '';
  const errorLabel = `${error.name}${errorMessage}`;

  $alert.html(getUserFriendlyError(error));
  $modal.modal({
    backdrop: 'static',
    focus: true,
    keyboard: false,
    show: true,
  });

  $(`#show-error-label`, $modal).text(errorLabel);
}