import { SET_PENDING_USER_COUNT, SET_PENDING_NOTIFY_COUNT, SET_PENDING_MSG_COUNT, LOGOUT } from '../action/types';

const initialState = {
  providerCount: 0,
  notifyCount: 0,
  msgCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PENDING_USER_COUNT:
      state.providerCount = action.payload;
      return {
        ...state,
      };
    case SET_PENDING_NOTIFY_COUNT:
      state.notifyCount = action.payload;
      return {
        ...state,
      };
    case SET_PENDING_MSG_COUNT:
      state.msgCount = action.payload;
      return {
        ...state,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
