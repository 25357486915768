export const SET_TIMER_TOGGLE_BUTTON = `SET_TIMER_TOGGLE_BUTTON`;
export const RESET_TIMER_VALUE = `RESET_TIMER_VALUE`;
export const START_VIDEO_CALL = `START_VIDEO_CALL`;
export const END_VIDEO_CALL = `END_VIDEO_CALL`;
export const START_AUDIO_CALL = `START_AUDIO_CALL`;
export const END_AUDIO_CALL = `END_AUDIO_CALL`;
export const NO_OF_SECONDS_FOR_PATIENT = `NO_OF_SECONDS_FOR_PATIENT`;
export const BASIC_DETAIL_TIMER_REFRESH_FLAG = `BASIC_DETAIL_TIMER_REFRESH_FLAG`;
export const ADD_AUDIO_CALL_TIMER = `ADD_AUDIO_CALL_TIMER`;
export const UPDATE_INDIVIDUAL_PATIENT_ACCUMULATED_TIME = `UPDATE_INDIVIDUAL_PATIENT_ACCUMULATED_TIME`;
export const TURN_ON_PATIENT_TIMER = `TURN_ON_PATIENT_TIMER`;
export const TURN_OFF_PATIENT_TIMER = `TURN_OFF_PATIENT_TIMER`;
export const TIMER_REFRESH_FLAG = `TIMER_REFRESH_FLAG`;
export const REFRESH_MANUAL_TIMELOG_COMPONENT = `REFRESH_MANUAL_TIMELOG_COMPONENT`;
export const PATIENT_REPORT_TIME_IN_SECONDS = `PATIENT_REPORT_TIME_IN_SECONDS`;
export const SPECIAL_TIMER_FLAG_CHECK_BEFORE_MAKE_ADDENDUM = `SPECIAL_TIMER_FLAG_CHECK_BEFORE_MAKE_ADDENDUM`;
export const UPDATE_TIME_OBJECT = `UPDATE_TIME_OBJECT`;
