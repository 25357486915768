import React from 'react';
import css from './messages.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { _selectedUser } from '../../../../../../redux/messenger/messengerActions';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';
import regexifyString from 'regexify-string';
import { capitalizeFirstLetter } from 'src/utils';

const msgDecorator = (data) => {
  return regexifyString({
    pattern: /@\[.+?\]\(.+?\)/g,
    // eslint-disable-next-line react/display-name
    decorator: (match) => {
      let userName = match.split(`[`)[1].split(`]`)[0];
      let userId = match.split(`(`)[1].split(`)`)[0];
      return (
        <span className="userMessage" key={userId}>
          <i>{userName}</i>
        </span>
      );
    },
    input: data,
  });
};

export const MessagesDropDown = () => {
  let history = useHistory();
  const dispatch = useDispatch()
  let counterFlag = 0;
  let tr = [];
  const isOnVideo = useSelector((state) => state.patient.isOnVideo);
  const { individualUser } = useSelector((state) => state?.messenger)
  const onNotificationClickUpdateReadStatusHandler = (id) => {
    dispatch(_selectedUser(id))
    newSocket.emit(`changeChatReadStatusOnFocus`, {
      senderId: individualUser[id].senderId,
      receiverId: individualUser[id].receiverId,
      practiceId: individualUser[id].practiceId,
      _chatRoomId: individualUser[id]._chatRoomId,
    });

    history.push(`/messenger`);
  };

  for (let c in individualUser) {
    if (individualUser[c]?.isMessageRead === false) {
      counterFlag++;
      let name = `${individualUser[c].firstName} ${individualUser[c].lastName}`;
      let tab = (
        <tr
          onClick={() => onNotificationClickUpdateReadStatusHandler(`${individualUser[c].receiverId}`)}
          key={`${c}`}
          style={{ borderBottom: `1px solid rgb(211, 209, 209)` }}
          id={css.unreadNote}
        >
          <td>
            <div className="d-flex align-items-center">
              <Avatar name={name} size="30" round="30px" className={css.avator} />
              {` `}
              <div style={{ width: `100%`, marginLeft: `3%` }}>
                <div className="d-flex justify-content-between align-items-start">
                  <p style={{ margin: 0, textTransform: `capitalize` }}>
                    {capitalizeFirstLetter(`${individualUser[c].firstName} ${individualUser[c].lastName}`)}
                  </p>
                  {/* <p style={{margin:0}}>4/12</p> */}
                </div>
                {individualUser[c]?.type &&
                  <p style={{ margin: 0, }}>
                    {`Sent An attachment.`}
                  </p>
                }
                <div className="d-flex justify-content-between align-items-end">
                  <p
                    style={{
                      margin: 0,
                      display: `inline-block`,
                      width: `180px`,
                      whiteSpace: `nowrap`,
                      overflow: `hidden`,
                      textOverflow: `ellipsis`,
                    }}
                  >
                    {msgDecorator(individualUser[c].lastMessage)}
                    <span
                      style={{
                        fontWeight: 400,
                        fontStyle: `italic`,
                        display: `block`,
                        paddingTop: `1px`,
                      }}
                    >
                      {individualUser[c]?.date}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </td>
        </tr>
      );
      tr.push(tab);
    }
  }

  if (counterFlag < 1) {
    let tab = (
      <tr key="welcome" style={{ borderBottom: `1px solid rgb(211, 209, 209)` }} id={css.unreadNote}>
        <td>
          <div className="d-flex align-items-center">
            <div style={{ width: `100%`, marginLeft: `3%` }}>
              <div className="d-flex justify-content-between align-items-start">
                <p style={{ margin: 0 }}>You don't have any notifications</p>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
    tr.push(tab);
  }

  return (
    <>
      <button disabled={isOnVideo} id={css.topBarBtnSmall} type="button" data-toggle="dropdown">
        {counterFlag > 0 ? <span id={css.badge}>{counterFlag}</span> : null}
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40">
          <g id="message-circle_2_" data-name="message-circle (2)" transform="translate(2 2)">
            <path
              id="Path"
              d="M36,17a16.76,16.76,0,0,1-1.8,7.6A17,17,0,0,1,19,34a16.76,16.76,0,0,1-7.6-1.8L0,36,3.8,24.6A16.76,16.76,0,0,1,2,17,17,17,0,0,1,11.4,1.8,16.76,16.76,0,0,1,19,0h1A16.96,16.96,0,0,1,36,16v1Z"
              transform="translate(0 0)"
              fill="none"
              stroke="#1699c5"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="4"
            />
          </g>
        </svg>
      </button>
      <div className="dropdown-menu dropdown-menu-right mb-0 pb-0 pt-0" id={css.MessagesDropDown} aria-labelledby="MessagesDropDown">
        <span className={css.menuArrow}></span>
        <table className="table table-borderless table-hover mb-0">
          <tbody>{tr.map((row) => row)}</tbody>
        </table>
      </div>
    </>
  );
};