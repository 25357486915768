import { VIEW, GROUPID, PRACTICEID, PROVIDERID } from '../Types/Analytics.types';
export const viewGiver = (payLoad) => {
  return {
    type: VIEW,
    payLoad: payLoad,
  };
};
export const groupIdSaver = (payLoad) => {
  return {
    type: GROUPID,
    payLoad: payLoad,
  };
};
export const practiceIdSaver = (payLoad) => {
  return {
    type: PRACTICEID,
    payLoad: payLoad,
  };
};
export const providerIdSaver = (payLoad) => {
  return {
    type: PROVIDERID,
    payLoad: payLoad,
  };
};
