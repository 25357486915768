export const SET_ALL_TASKS = `SET_ALL_TASKS`;
export const UPDATE_ALL_TASKS = `UPDATE_ALL_TASKS`;
export const NOTIFY_ALL_TASKS = `NOTIFY_ALL_TASKS`;
export const SET_NEW_TASKS = `SET_NEW_TASKS`;
export const SET_SELECTED_TASK = `SET_SELECTED_TASK`;
export const SET_NEW_COMMENT = `SET_NEW_COMMENT`;
export const NOTIFY_TASK = `NOTIFY_TASK`
export const SAVE_NOTIFY_TASK_ALERT = `SAVE_NOTIFY_TASK_ALERT`
export const UPDATE_NOTIFY_TASK_ALERT = `UPDATE_NOTIFY_TASK_ALERT`
export const DELETE_NOTIFY_TASK_ALERT = `DELETE_NOTIFY_TASK_ALERT`
export const TASKS_LOADING = `TASKS_LOADING`
export const TASKS_NOTIFY_SINGLE_SAVE = `TASKS_NOTIFY_SINGLE_SAVE`
export const EMPTY_TASK_NOTIFICATION = `EMPTY_TASK_NOTIFICATION`
export const REMOVE_FROM_TASK_NOTIFICATIONS = `REMOVE_FROM_TASK_NOTIFICATIONS`
export const OPENED_TASK_ID = `OPENED_TASK_ID`


