import { Button, TextField } from '@mui/material';
import React from 'react';
import { purifyInputValue } from 'src/utils';

export const InputField = (props) => {
  const { InputLabelProps, onChange } = props || false

  return (
    <TextField
      {...props}  {...(onChange ? {
        onChange: (e) => purifyInputValue({
          event: e,
          onChange: onChange
        })
      } : {})}
      {...(InputLabelProps ? { ...InputLabelProps } : {
        InputLabelProps: {
          style: {
            left: `-2%`,
          }
        }
      })}
    />
  );
};

export const FileInput = ({ inputProps, fileBtnProps }) => {
  const { uploadText } = fileBtnProps || false

  return (
    <>
      <input
        style={{ display: `none` }}
        type="file"
        {...inputProps}
      />
      <label htmlFor={inputProps?.id}>
        <Button {...fileBtnProps} >
          {uploadText || `Upload Image`}
        </Button>
      </label>
    </>
  )
}