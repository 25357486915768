import { BLANK_PRACTICE_GROUP, GET_COUNTS, GROUP_ID, HAS_REGISTERED } from './Types';

export const getGroupId = (payLoad) => {
  return {
    type: GROUP_ID,
    payLoad: payLoad,
  };
};
export const blankGroupId = () => {
  return {
    type: BLANK_PRACTICE_GROUP,
  };
};
export const refreshPracticeGroup = (payLoad) => {
  return {
    type: HAS_REGISTERED,
    payLoad: payLoad,
  };
};
export const getCounts = (payLoad) => {
  return {
    type: GET_COUNTS,
    payLoad: payLoad,
  };
};
