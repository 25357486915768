/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { FAQS_LIST, getRequest } from '../../../../../../../crud/crud';
import css from './Faqs.module.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { PuffSpinner } from '../../../../spinner/puff/puff';
import { Box, Fade, Grow, Slide, Zoom } from '@mui/material';
import PsychologySharpIcon from '@mui/icons-material/PsychologySharp';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { spreadUniqueKeyProp } from 'src/utils';
import { useUniqueId } from 'src/pages/common/hooks/useUniqueId';

const parse = require(`html-react-parser`);

export const Faqs = () => {
  const [faqs, setFaqs] = useState([]);
  const [faqIndex, setFaqIndex] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const faqId = useUniqueId()

  useEffect(() => {
    const fetchFaqs = async () => {
      setLoading(true);
      try {
        const data = await getRequest(FAQS_LIST);
        setLoading(false);
        setFaqIndex(new Array(data?.data?.data?.values?.length));

        manipulateData(data?.data?.data?.values);
      } catch (err) {
        setLoading(false);
      }
    }
    fetchFaqs();
    setChecked(true);
    return () => {
      setChecked(false);
    };
  }, []);

  const manipulateData = (data) => {
    const faqUpdated = data.map((faq) => {
      let url = null;
      if (faq[2]) {
        url = faq[2] ? urlDetector(faq[3])[0] : null;
      }
      return { question: faq[0], ans: faq[1], type: faq[2] ? faq[2] : null, data: url };
    });
    setFaqs(faqUpdated);
  };

  const handleToggle = (index) => {
    let copyFaqIndex = [...faqIndex];
    copyFaqIndex[index] = !copyFaqIndex[index];
    setFaqIndex(copyFaqIndex);
  };

  const urlDetector = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let url = message.match(urlRegex);
    return url || null;
  };

  const parseDom = (html) => {
    const options = {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === `remove`) {
          return null
        }
      },
    };
    let ans = parse(`${html}`, options);
    return ans;
  };

  return (
    <div className={css.faqsComponent}>
      <Slide mountOnEnter unmountOnExit direction="right" in={checked} timeout={300}>
        <Box className={css.iconBox}>
          <Box sx={{ mt: 3, mx: 2, position: `relative` }}>
            <PsychologySharpIcon sx={{ fontSize: `80px`, color: `#1699c5` }} />
            <Zoom in={checked} style={{ transitionDelay: checked ? `500ms` : `0ms` }}>
              <Box sx={{ position: `absolute`, right: `-2%`, fontSize: `35px`, top: `-32%`, transform: `rotate(35deg)` }}>
                <ContactSupportOutlinedIcon sx={{ color: `#2f4f4fa6`, fontSize: `35px`, transform: `rotate(35deg)` }} />
              </Box>
            </Zoom>
          </Box>
        </Box>
      </Slide>
      <Slide direction="up" mountOnEnter unmountOnExit in={checked} timeout={200}>
        <Box
          sx={{
            color: `#1699c5`,
            width: `100%`,
            display: `flex`,
            justifyContent: `end`,
            p: 4,
            pb: 1,
            mt: `-45px`,
            fontWeight: `500`,
            fontFamily: `revert`,
          }}
        >
          FAQ's
        </Box>
      </Slide>

      {/* <br></br> */}
      <p style={{ marginTop: `5px` }}></p>
      <Fade in={faqs?.length > 0} timeout={1000}>
        <div>
          {faqs?.length > 0 &&
            faqs.map((faq, index) => {
              let key = `${faqId}${index + 1}`
              return (
                <div {...spreadUniqueKeyProp(key)} className={css.faqComponent}>
                  <div className={css.question} onClick={() => handleToggle(index)}>
                    <h6 style={{ fontWeight: `540`, fontSize: `15px` }}>Q. {faq.question}</h6>
                    {faqIndex[index] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </div>
                  <Grow in={faqIndex[index]} mountOnEnter unmountOnExit style={{ transformOrigin: `50 0 0` }} {...(checked ? { timeout: 500 } : {})}>
                    <div className={css.answer}>
                      <>{parseDom(faq.ans)}</>

                      {faq.type === `Image` && (
                        <div style={{ textAlign: `center`, width: `100%` }}>
                          <span className={css.linkText}>
                            {<img src={faq.data} alt="images" style={{ textAlign: `center`, height: `250px`, width: `300px` }} />}
                          </span>
                        </div>
                      )}
                      {faq.type === `Link` && (
                        <div className={css.link}>
                          <span className={css.linkText}>
                            {
                              <a href={faq.data} style={{ fontSize: `11px` }} target="_blank">
                                Click here
                              </a>
                            }
                          </span>
                        </div>
                      )}
                      {faq.type === `Video` && (
                        <div style={{ textAlign: `center`, width: `100%` }}>
                          <span className={css.linkText}>
                            <iframe width="420" title="faq" height="315" src={faq.data}></iframe>
                          </span>
                        </div>
                      )}
                    </div>
                  </Grow>
                </div>
              );
            })}
        </div>
      </Fade>

      {loading ? <PuffSpinner /> : null}
    </div>
  );
}