import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Stack, Zoom, Tooltip } from '@mui/material';
import { PatientAnalytics } from './components/patient/patient';
import { RemoteMonitoringAnalytics } from './components/remote/remote';
import { TelemedicineAnalytics } from './components/telemedicine/telemedicine';
import { ReportingAnalytics } from './components/reporting/reporting';
import { AskForAnalytics } from '../settings/Analytics/Components/AskForAnalytics';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { providerIdSaver, viewGiver } from 'src/redux/systemadminAnalytics/Redux/Actions/Analytics.actions';
import { capitalizeFirstLetter, isDme } from 'src/utils';

export const AnalyticsSuperAdmin = () => {
  let openCheck = true;
  const { user: { user } } = useSelector((state) => state)
  const { isSuperAdmin } = user
  const { view, providerId } = useSelector((state) => state?.analytics);
  const dispatch = useDispatch();
  const viewDispatcher = () => {
    providerId && dispatch(providerIdSaver(``));
    if (!view?.includes(`W.R.Tpractice`)) {
      dispatch(viewGiver(`W.R.Tpractice`));
    }
  };
  useEffect(() => {
    viewDispatcher();
    return () => {
      providerId && dispatch(providerIdSaver(``));
      dispatch(viewGiver(``));
    };
  }, []);
  return (
    <div>
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          ...(!user?.isSystemAdministrator && !user?.isGroupAdmin && { padding: { xs: `30px 15px`, sm: `45px 30px` } }),
        }}
      >
        {isSuperAdmin && (
          <Stack direction="row" alignItems={`center`}>
            <Box>
              <Tooltip
                placement="top"
                arrow
                title={!view?.includes(`W.R.Tpractice`) ? `Click to get list default analytics of your practice!` : `Done !`}
              >
                <Button
                  onClick={() => viewDispatcher()}
                  variant="outlined"
                  sx={{
                    textTransform: `none`,
                    fontSize: `large`,
                    fontFamily: `system-ui`,
                  }}
                  size="small"
                >
                  {capitalizeFirstLetter(user?.firstName + ` ` + user?.lastName)}
                </Button>
              </Tooltip>
            </Box>
            <Zoom in={openCheck} mountOnEnter unmountOnExit>
              <Box sx={{ width: `100px`, textAlign: `center` }}>
                <HorizontalRuleIcon
                  sx={{
                    fontSize: `60px`,
                    padding: `0px !important`,
                    color: `#1975cf`,
                  }}
                />
              </Box>
            </Zoom>
            <Box>
              <AskForAnalytics openCheck={openCheck} />
            </Box>
          </Stack>
        )}
        <PatientAnalytics />
        {!isDme() && <RemoteMonitoringAnalytics />}
        {!user?.isClinicalStaff && <TelemedicineAnalytics />}
        {!isDme() && <ReportingAnalytics />}
      </Box>
    </div>
  );
}






