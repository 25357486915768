import React, { useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, Zoom, Select, InputLabel, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { groupIdSaver, practiceIdSaver, providerIdSaver, viewGiver } from 'src/redux/systemadminAnalytics/Redux/Actions/Analytics.actions';
import { GET_ALL_PROVIDERS, getRequest } from 'src/crud/crud';
import LinearProgress from '@mui/material/LinearProgress';
import GppMaybeSharpIcon from '@mui/icons-material/GppMaybeSharp';
import { capitalizeFirstLetter } from 'src/utils';
import { SearchInput } from 'src/pages/common/components/LIstSubheader/SearchInput';

export const AskForAnalytics = () => {
  const [providers, setProviders] = useState(``);
  const [searchValue, setsearchValue] = useState(``);
  const { user } = useSelector((state) => state?.user);
  const { providerId } = useSelector((state) => state?.analytics);
  const dispatch = useDispatch();
  let openCheck = true;
  useEffect(() => { }, []);
  const { practiceId, groupId } = useSelector((state) => state?.analytics);

  let filteredProviders = useMemo(() => {
    return (
      typeof providers === `object` &&
      providers.filter((e) => e?.firstName?.toLowerCase()?.includes(searchValue) || e?.lastName?.toLowerCase()?.includes(searchValue))
    );
  }, [providers, searchValue]);
  return (
    <Box sx={{ width: `150px` }}>
      <Zoom style={{ transitionDelay: openCheck ? `300ms` : `0ms` }} in={openCheck} timeout={500}>
        <FormControl fullWidth size="small">
          <InputLabel id="ProvidersAnalytics">Providers</InputLabel>
          <Select
            size="small"
            label="Providers"
            name="providersInputName"
            id="providersInputName"
            value={providerId}
            onFocus={() => {
              if (searchValue) {
                setsearchValue(``);
              }
              if (user?.isSystemAdministrator || user?.isGroupAdmin || user?.isSuperAdmin) {
                setProviders(`loading`);
                getRequest(GET_ALL_PROVIDERS)
                  .then((res) => {
                    setProviders(res?.data?.users);
                  })
                  .catch((e) => {
                    setProviders(`error`);
                  });
              }
            }}
            onChange={(e) => {
              dispatch(viewGiver(`W.R.Tprovider/${e.target.value}`));
              dispatch(providerIdSaver(e.target.value));
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: `300px`,
                },
              },
            }}
          >
            <SearchInput label="Search a provider!" keyValue={false} isObj={false} setSearchValue={setsearchValue} />
            {providers === `loading` && <LinearProgress sx={{ width: `100%` }} />}
            {providers === `error` && <GppMaybeSharpIcon sx={{ width: `100%`, color: `red` }} />}
            {typeof providers === `object` &&
              filteredProviders.map((e, i) => (
                <MenuItem
                  sx={{ fontWeight: 600 }}
                  onClick={() => {
                    if (groupId && practiceId) {
                      dispatch(groupIdSaver(``));
                      dispatch(practiceIdSaver(``));
                    } else if (practiceId) {
                      dispatch(practiceIdSaver(``));
                    } else if (groupId) {
                      dispatch(groupIdSaver(``));
                    }
                  }}
                  value={e?._id}
                  key={i}
                >
                  {capitalizeFirstLetter(e?.firstName + ` ` + e?.lastName)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Zoom>
    </Box>
  );
}
