import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TRIAL_PERIOD_OF_PRACTICE, postRequest } from 'src/crud/crud';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { trialPeriodOfPracticeOptions } from 'src/utils';
import Swal from 'sweetalert2';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import moment from 'moment';
import { Box } from '@mui/system';

export const TrialPeriodForPractice = () => {
  const { stripeSubscriptionId: subscriptionId } = useSelector((state) => state?.practice?.practiceInfo || {});
  const [selectedMonths, setSelectedMonths] = useState(``);

  const calculateDateRangeForAccordingMonthSendApiCall = (selectedValue) => {

    let trial_start;
    let trial_end;
    let isCurrentMonth = false;

    if (selectedValue == 1) {
      trial_start = moment().tz('UTC').toISOString();
      trial_end = moment().tz('UTC').endOf(`month`).toISOString();
      isCurrentMonth = true;
    } else if (selectedValue == 2) {
      trial_start = moment().tz('UTC').startOf(`month`).add(1, 'month').toISOString();
      trial_end = moment().tz('UTC').endOf(`month`).add(1, 'month').toISOString();
    } else if (selectedValue == 3) {
      trial_start = moment().tz('UTC').toISOString();
      trial_end = moment().tz('UTC').endOf(`month`).add(1, 'month').toISOString();
      isCurrentMonth = true;
    } else if (selectedValue == 4) {
      trial_start = moment().tz('UTC').startOf(`month`).add(1, 'month').toISOString();
      trial_end = moment().tz('UTC').endOf(`month`).add(2, 'month').toISOString();
    } else if (selectedValue == 5) {
      trial_start = moment().tz('UTC').toISOString();
      trial_end = moment().tz('UTC').endOf(`month`).add(2, 'month').toISOString();
      isCurrentMonth = true;
    }

    postRequest(TRIAL_PERIOD_OF_PRACTICE + `/` + subscriptionId, {
      trial_start,
      trial_end,
      isCurrentMonth,
    }).then(() => {
      successToast(`Practice set on Trial.`);
    }).catch((error) => { })
  };

  const alertForConfirmation = (selectedValue) => {
    return (
      Swal.fire({
        title: `Attention!`,
        text: `Are you sure want to trial this practice?`,
        icon: `warning`,
        showCancelButton: true,
        confirmButtonColor: `#3085d6`,
        cancelButtonColor: `#d33`,
        confirmButtonText: `Yes`,
      }).then((result) => {
        if (result?.isConfirmed) {
          setSelectedMonths(selectedValue)
          calculateDateRangeForAccordingMonthSendApiCall(selectedValue)
        }
      })
    );
  };

  return (
    <Box sx={{ display: `inline-block`, mr: 2, mt: 4, minWidth: `160px`, }}>
      <MuiSelect
        controlProps={{
          size: `small`,
          fullWidth: true,
          id: `trialPeriodOfPractice`,
        }}
        label="Trial Period"
        options={[...trialPeriodOfPracticeOptions]}
        optionKey={`name`}
        optionValue={`value`}
        selectProps={{
          label: `Trial Period`,
          name: `selectedMonths`,
          id: `trialPeriodOfPractice`,
          onChange: (event) => {
            const { target: { value } } = event;
            alertForConfirmation(value)
          },
          value: selectedMonths,
        }}
      />
    </Box>
  )
}