import React from 'react';
import { AdministrationUser } from '../../../../modules/administration/user/user';

export const GroupAdminUsers = () => {
  return (
    <>
      <AdministrationUser />
    </>
  );
}
