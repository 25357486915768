import React from 'react';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';

export const TablePagination = ({
  data,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  totalRows,
  setRowsPerPage,
  totalPage,
  isPatientView,
  hasStyle,
}) => {
  return (
    <TableFooter
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      rowsPerPage={rowsPerPage}
      totalRows={totalRows}
      setRowsPerPage={setRowsPerPage}
      totalPage={totalPage}
      isPatientView={isPatientView}
      hasStyle={hasStyle}
      data={data}
    />
  );
};
