import React, { useEffect, useState } from 'react';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';

export const Comp = ({ users, compProps = { selectProps: {} } }) => {
  const [mappeduSers, setMappedUsers] = useState([]);
  const { selectProps } = compProps;
  let prepareProps = {
    ...compProps,
    selectProps: {
      ...selectProps,
      MenuProps: {
        PaperProps: {
          style: {
            maxHeight: `250px`,
          },
        },
      },
    },
    options: [...mappeduSers],
  };
  useEffect(() => {
    let isFunc = typeof users === 'function';
    if (Array.isArray(users) || isFunc) {
      setMappedUsers(() => (isFunc ? users() : users), ['_id']);
    }
  }, [users]);
  console.log(mappeduSers, 'mappeduSers');
  return (
    <MuiSelect
      searchInpProps={{
        isSearchAble: true,
        searchKeys: ['fullName'],
      }}
      {...prepareProps}
    />
  );
};

export const TemplateCompUniSelect = React.memo(Comp);
