import React from 'react';
import { TemplateCompMultiSelect } from './TemplateCompMultiSelect';

export const Comp = ({ users, onChange, onBlur, value = [], error }) => {
  return (
    <TemplateCompMultiSelect
      users={users}
      compProps={{
        controlProps: {
          size: `small`,
          fullWidth: true,
          id: `bulkImportSecProv`,
          error: error ? true : false,
        },
        label: 'Secondary Provider',
        optionKey: `fullName`,
        optionValue: `_id`,
        selectProps: {
          con: true,
          label: `Secondary Provider`,
          helperText: error,
          name: `bulkImportSecProv`,
          value,
          ...(onChange && { onChange }),
          ...(onBlur && { onBlur }),
        },
      }}
    />
  );
};

export const SecProv = React.memo(Comp);
