import { INITIALIZE_APP_TIMER, TERMINATE_APP_TIMER } from "./appTimer.types"

const initialState = {
    appTimer: null
}

export const appTimerReducer = (state = initialState, action) => {
    const { type: actionType, payLoad: actionPayLoad } = action
    switch (actionType) {
        case INITIALIZE_APP_TIMER:
            return { ...state, appTimer: actionPayLoad }
        case TERMINATE_APP_TIMER:
            return { ...state, appTimer: actionPayLoad }
        default:
            return { ...state }
    }

}