import { Box, Zoom } from '@mui/material';
import React from 'react';

export const ZoomWrapper = ({ children, zoomProps }) => {
  return (
    <Zoom {...zoomProps} mountOnEnter unmountOnExit>
      <Box>{children}</Box>
    </Zoom>
  );
};
