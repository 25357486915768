import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postRequest, REFRESH_AUTH_TOKEN } from 'src/crud/crud';
import { setAuth } from 'src/redux/action/authTokenHandler';
import { setUserHandler } from 'src/redux/action/setUserHandler';
import { store } from 'src/redux/store';

let timer;

export const JwtExpWrapper = ({ children }) => {
    const { user: { user } } = useSelector((state) => state);
    const { tokenValidityTime } = user || false;
    const dispatch = useDispatch();
    const [refTokCount, setRefTokCount] = useState(0);

    useEffect(() => {
        if (tokenValidityTime || refTokCount) {
            const { user: { user } } = store.getState()
            const { refreshToken } = user
            timer = setTimeout(() => {
                postRequest(REFRESH_AUTH_TOKEN, {
                    refreshToken: refreshToken
                }).then((res) => {
                    const { data = {
                        freshToken: ``,
                        resExp: 0
                    } } = res
                    const { token: freshToken, tokenValidityTime: resExp } = data
                    if (freshToken) {
                        dispatch(setAuth(freshToken))
                        if (resExp) {
                            setRefTokCount((p) => p + 1)
                            dispatch(setUserHandler({ ...user, tokenValidityTime: resExp }))
                        }
                    }
                }).catch()
            }, tokenValidityTime)
        }
    }, [tokenValidityTime, refTokCount])

    useEffect(() => {
        return () => {
            clearTimeout(timer)
            setRefTokCount(0)
        }
    }, [])

    return (
        <>{children}</>
    )
}