import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent
} from '@mui/material';
import { capitalizeFirstLetter } from '../../../../../utils';

export const GroupAdminInfo = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        style={{ borderRadius: `4px` }}
      >
        <DialogContent>
          <div className="container-fluid w-100 mt-4" style={{ fontFamily: `GothamBook`, fontSize: `900px` }}>

            <div className="row d-flex justify-content-between">
              <div className="col-sm-5">
                <h5>Name :</h5>
                <h5>Email :</h5>
                <h5>User Name :</h5>
                <h5>Phone Number :</h5>
                <h5>Title :</h5>

              </div>
              <div className="col-sm-7">
                <h5>{capitalizeFirstLetter(props?.sigleGroupAdminData?.groupAdmindata?.firstName) + `  ` + capitalizeFirstLetter(props?.sigleGroupAdminData?.groupAdmindata?.lastName)} </h5>
                <h5>{props?.sigleGroupAdminData?.groupAdmindata?.email} </h5>
                <h5>{props?.sigleGroupAdminData?.groupAdmindata?.userName ? props?.sigleGroupAdminData?.groupAdmindata?.userName : `--`}</h5>
                <h5>{props?.sigleGroupAdminData?.groupAdmindata?.phoneNumber}</h5>
                <h5>{capitalizeFirstLetter(props?.sigleGroupAdminData?.groupAdmindata?.title)}</h5>

              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


