/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import { CREATE_TELEMEDICINE_ROOM, INDIVIDUAL_ROOM_TELEMEDICINE_TIME, DELETE_SPECIFIC_TELEMEDICINE_ROOM } from './telemedicineTypes';

const initialState = {
  telemedicine_rooms: {},
};

export const telemedicineReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TELEMEDICINE_ROOM:
      return {
        ...state,
        telemedicine_rooms: { ...action.payload },
      };
    case INDIVIDUAL_ROOM_TELEMEDICINE_TIME:
      let room = state.telemedicine_rooms;
      if (room[`${action.payload.roomId}`]) {
        room[`${action.payload.roomId}`].videoCallDurationTimeStamp = action.payload.videoCallDurationTimeStamp;
      }
      return {
        ...state,
        telemedicine_rooms: { ...room },
      };
    case DELETE_SPECIFIC_TELEMEDICINE_ROOM:
      let rooms = state.telemedicine_rooms;
      if (rooms[`${action.payload.roomId}`]) {
        delete rooms[`${action.payload.roomId}`];
      }
      return {
        ...state,
        telemedicine_rooms: { ...rooms },
      };
    default:
      return state;
  }
};
