import React from 'react';
import { postRequest, SMART_PHRASE_LIST } from '../../../../../../crud/crud';
import {
  Button, Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { setSmartPhrases } from 'src/redux/practices/practiceActions';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';

export const AddPhrase = (props) => {
  const dispatchRedux = useDispatch();
  const user = useSelector((state) => state.user.user);
  const smartPhraseFromRedux = useSelector((state) => state.practice.smartPhrases);
  const [open, setOpen] = React.useState(false);
  const [smartPhrase, setSmartPhraseValue] = React.useState(``);
  const [smartPhraseTitle, setSmartPhraseTitle] = React.useState(``);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSmartPhraseValue(``);
    setSmartPhraseTitle(``);
  };

  const addSmartPhrase = () => {
    if (!smartPhrase.trim() || !smartPhraseTitle.trim()) {
      errorToast(`All phrase fields are required`);
      return false;
    } else {
      let phrase = {
        title: smartPhraseTitle,
        text: smartPhrase,
        favourite: false,
      };
      let smartPhraseArray = smartPhraseFromRedux;
      postRequest(SMART_PHRASE_LIST, phrase)
        .then((response) => {
          props.setLoading(false);
          if (response.status === 201) {
            successToast(`Smart Phrase Added Successfully`);
            smartPhraseArray.push(response.data.phrase);
            dispatchRedux(setSmartPhrases(smartPhraseArray));
            setSmartPhraseValue(``);
            setSmartPhraseTitle(``);
            props.setToggle(!props.toggle);
            setOpen(false);
          }
        })
        .catch((error) => {
          props.setLoading(false);
        });
    }
  };

  function checkRoleOfUser() {
    if (user.isSuperAdmin) {
      return (
        <Button variant="outlined" sx={{
          borderRadius: `25px`,
          height: `30px`,
          minHeight: `30px`,
        }} color="primary" onClick={handleClickOpen}>
          Add phrase
        </Button>
      );
    }
  }

  return (
    <div>
      {checkRoleOfUser()}
      <CustomizedDialogs
        title={`Add Phrase`}
        open={open}
        setOpen={handleClose}
        size="md"
        customButton={true}
        showCloseButton={true}
        customButtonText="Add phrase"
        customButtonAction={() => addSmartPhrase()}
      >
        <Grid container columnSpacing={1} rowSpacing={1.5}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <InputField
              size='small'
              fullWidth={true}
              type="text"
              name="title"
              value={smartPhraseTitle}
              onChange={(e) => setSmartPhraseTitle(e.target.value)}
              label="Phrase Title"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <InputField
              multiline={true}
              size='small'
              fullWidth={true}
              minRows={7}
              maxRows={10}
              value={smartPhrase}
              onChange={(event) => setSmartPhraseValue(event.target.value)}
              name="note"
              label='Phrase Description'
            />
          </Grid>
        </Grid>
      </CustomizedDialogs>
    </div>
  );
};