import { Grid } from '@mui/material';
import DOMPurify from 'dompurify';
import React, { useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import { momentWrapper } from 'src/momentWrapper';
import css from './commonTickets.module.css';

export const CommentsList = ({ comment }) => {
  const ref = useRef(null);
  useEffect(() => {
    let cleanComment = DOMPurify.sanitize(comment?.comment)
    ref.current.innerHTML = cleanComment;
  }, [comment]);

  const handleBorderClasses = () => {
    if (comment?._created_by?.isProvider || comment?._created_by?.isClinicalStaff) {
      return `border border-primary`
    }
    return `border border-success`
  }

  return (
    <div
      key={comment._id}
      className={`mb-1 p-1 rounded-right ${handleBorderClasses()}`}
    >
      <Grid container>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <Avatar name={`${comment?._created_by?.firstName} ${comment?._created_by?.lastName}`} size="30" round="40px" />
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} sx={{ mt: 0.5 }}>
          <div ref={ref} className={`ql-editor p-0 ${css.renderQuill}`} />
        </Grid>
      </Grid>
      <p style={{ textAlign: `end` }}>{momentWrapper(comment?.createdAt).format(`MM/DD hh:mm A`)}</p>
    </div>
  );
};