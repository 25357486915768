import React, { useState, useEffect, useMemo } from 'react';
import { Switch, Route, Redirect, withRouter, useHistory } from 'react-router-dom';
import { ForgetPassword } from '../pages/auth/forgetPassword/forgetPassword';
import PatientForget from '../pages/auth/patientForgetPassword/patientForget';
import { ResetPassword } from '../pages/auth/resetPassword/resetPassword';
import { NintyDaysResetPassword } from '../pages/auth/resetPassword/nintyDaysResetPassword';
import { Login } from '../pages/auth/login/login';
import { Otp } from '../pages/auth/otp/otp';
import { VerifyEmail } from '../pages/auth/verify/verify';
import { ErrorPage } from '../pages/error/error';
import { SubscriptionError } from '../pages/subscriptionError';
import { VideoPublicClient } from '../pages/common/videoPublicClient/videoPublicClient';
import { useSelector } from 'react-redux';
import { ResponsiveDrawer as Layout } from 'src/pages/common/components/wrapper/components/sidebar/sidebar';
import Signup from '../pages/auth/patient/signup';
import { doInProdMode, } from 'src/utils';
import { TimerConfimation } from 'src/pages/home/modules/patient2/components/TimerConfiramtion/TimerConfimation';
import { TaskAlertWrapper as NotifyTaskAlerts } from 'src/pages/common/HOC/TaskAlertWrapper';
import { ToastContainer } from 'react-toastify';
import { JwtExpWrapper as FetchRefreshTokenOnJwtExp } from 'src/pages/common/HOC/JwtExpWrapper';
import { IdleUserWrapper as LogoutIdleUser } from 'src/pages/common/HOC/IdleUserWrapper';
import { SocketWrapper as EastablishSocket } from 'src/pages/common/HOC/SocketWrapper';
import { HistoryBlockWrraper } from 'src/pages/common/HOC/HIstoryBlockWrraper';
import { IncomingVoiceCallWrapper } from 'src/pages/common/HOC/IncomingVoiceCallWrapper';
import { SwitchPracticeWrapper } from 'src/pages/common/HOC/SwitchPracticeWrapper';
import { Dashboard } from 'src/pages/home/dashboard/commonDashboard/dashboard';
import { StripeProtectionWrapper } from 'src/pages/common/HOC/StripeProtectionWrapper';
import { privateRoutes } from './systemRoutes';
import { TimeZoneProtectionWrapper } from 'src/pages/common/HOC/TimeZoneProtectionWrapper';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';



const MainRouter = () => {
  const {
    user: parentUser,
    timer: { audioCall: isUserOnAudCall = false },
    patient: { isOnVideo: isUserOnVidCall = false },
  } = useSelector((state) => state);
  const [show, setShow] = useState(process.env.NODE_ENV === `development` ? true : false);
  const { user } = parentUser;
  const { userRole = ``, stripeSubscriptionId = `` } = user;
  const cachedRoutes = useMemo(() => privateRoutes.filter((route) => route.auth.includes(userRole)), [userRole, stripeSubscriptionId])
  useEffect(() => {
    doInProdMode(() => {
      setTimeout(() => {
        setShow(true);
      }, 3000);
    });

    return () => {
      doInProdMode(() => setShow(false));
    };
  }, []);

  return (
    <ConditionallyRenderWrapper con={show}>
      <ToastContainer />
      <Switch>
        <Route exact path="/error">
          <ErrorPage />
        </Route>
        {!user && (
          <Route exact path="/login">
            <Login />
          </Route>
        )}

        {!user && (
          <Route exact path="/forget">
            <ForgetPassword />
          </Route>
        )}

        {!user && (
          <Route exact path="/resetPassword/:secret/:id">
            {(props) => <ResetPassword {...props} />}
          </Route>
        )}
        {!user && (
          <Route exact path="/ninetyDaysResetPassword/:secret/:id">
            {(props) => <NintyDaysResetPassword {...props} />}
          </Route>
        )}

        {!user && (
          <Route exact path="/users/verify/:secret/:id/:_practiceId/:isExisting">
            {(props) => <VerifyEmail {...props} />}
          </Route>
        )}

        {!user && (
          <Route exact path="/patient/join/:id">
            <Signup />
          </Route>
        )}
        {!user && (
          <Route exact path="/patient/reset/:secret/:id">
            <PatientForget />
          </Route>
        )}

        {!user && (
          <Route exact path="/otp">
            <Otp />
          </Route>
        )}
        {!user && (
          <Route exact path="/public/videoCall/:token">
            {(props) => <VideoPublicClient {...props} isTelemedicine={false} />}
          </Route>
        )}
        {!user && (
          <Route exact path="/telemedicine/public/videoCall/:token">
            {(props) => <VideoPublicClient {...props} isTelemedicine={true} />}
          </Route>
        )}

        {!user && (
          <Route path="/">
            <Redirect to="/login" />
          </Route>
        )}

        {user && (
          <SwitchPracticeWrapper>
            <TimeZoneProtectionWrapper>
              <TimerConfimation />
              <EastablishSocket>
                <NotifyTaskAlerts>
                  <LogoutIdleUser>
                    <FetchRefreshTokenOnJwtExp>
                      <HistoryBlockWrraper
                        blockNavigation={() => {
                          if (isUserOnAudCall || isUserOnVidCall) return true;
                          return false;
                        }}>
                        <IncomingVoiceCallWrapper>
                          <Layout user={user}>
                            <Route exact path="/">
                              <StripeProtectionWrapper>
                                <Dashboard />
                              </StripeProtectionWrapper>
                            </Route>
                            {cachedRoutes
                              .map(({ path, comp: Component, props = {} }) => (
                                <Route exact path={path} key={path}>
                                  <StripeProtectionWrapper>
                                    <Component {...props} />
                                  </StripeProtectionWrapper>
                                </Route>
                              ))}
                          </Layout>
                        </IncomingVoiceCallWrapper>
                      </HistoryBlockWrraper>
                    </FetchRefreshTokenOnJwtExp>
                  </LogoutIdleUser>
                </NotifyTaskAlerts>
              </EastablishSocket>
            </TimeZoneProtectionWrapper>
          </SwitchPracticeWrapper>
        )}

      </Switch>
    </ConditionallyRenderWrapper>
  );
};

export default withRouter(MainRouter);