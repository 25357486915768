import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { momentWrapper } from 'src/momentWrapper';
import {
  listOfUsers, _listOfPatient, _recentMessage, _setIndividualUser,
  _updateUserOnlineStatus
} from 'src/redux/messenger/messengerActions';
import { resmedIncommingSMS, _alertPatientRiskChange, _isRealTimeDataAvailable } from 'src/redux/resmed/resmedActions';
import { store } from 'src/redux/store';
import { saveInTaskAlerts, saveSingleTask } from 'src/redux/taskManager/taskManagerActions';
import { getTicketsSysAdmin, notifyAllTickets } from 'src/redux/ticketsCenter/ticketsCenterActions';
import { notifyTwillioThunk, _providerClinicalStaffCommonSmsThread } from 'src/redux/twilio/twilioActions';
import io from 'socket.io-client';
import { SERVER_ADDRESS } from 'src/crud/axiosClient';
import { PuffSpinner } from '../components/spinner/puff/puff';
import moment from 'moment';
import { doIfNotTaskReporter, addInWatchListIfCanceledRef, autoLogoutIncominCallProt } from 'src/utils';
import { logoutHandlerS } from 'src/redux/action/logoutHandler';
import { saveSocketConn } from 'src/redux/action/setUserHandler';

export let newSocket;

export const SocketWrapper = ({ children }) => {
  const [establishSocket, setEstablishSocket] = useState(false);
  const dispatch = useDispatch();
  const {
    user: { user },
  } = useSelector((state) => state)
  const { _practiceId = `` } = user

  const registerSocketEvents = (newSocket) => {
    // LISTEN EVENT - TO LOGOUT USER
    newSocket.on(`isLoggedOut`, (data) => {
      if (data.isLoggedOut) {
        autoLogoutIncominCallProt();
        store.dispatch(logoutHandlerS());
        newSocket.close();
      }
    });

    // Twilio message comes from the patient

    // Socket in Messenger Component
    newSocket.on(`inboundMessage`, (data) => {
      let recentDate = data[0]?.date;
      recentDate = momentWrapper(recentDate).format(`MM DD YYYY hh:mm A`);

      if (data[0]?._chatRoomId) {
        dispatch(
          _recentMessage({
            message: data[0].message,
            id: data[0]._senderId,
            _chatRoomId: data[0]?._chatRoomId,
            isMessageRead: false,
            date: recentDate,
            type: data[0].type ? data[0].type : false,
          })
        );
      } else {
        dispatch(_recentMessage({ message: data[0].message, id: data[0]._senderId }));
      }
    });

    // Red dot emit event
    newSocket.emit(`getAllTicketsOfUser`, {
      userId: user._id,
    });

    // socket ee
    newSocket.on(`newPatientNotification`, () => { });

    // Red dot status for ticket Center Receiver
    newSocket.on(`getAllTicketsOfUser`, (data) => {
      dispatch(notifyAllTickets(data));
    });
    newSocket.on(`resmedAPIStatus`, (data) => {
      dispatch(_isRealTimeDataAvailable(data));
    });

    // EVENT EMIT - TO GET LIST OF USERS
    newSocket.emit(`listUsers`, {
      senderId: user._id,
      practiceId: user._practiceId,
    });

    newSocket.on(`listUsers`, (data) => {
      let importedData = data.map((e) => {
        let iterator = {};
        if (e.inboundMessageTime) {
          let date = momentWrapper(e.inboundMessageTime).format(`YYYY MM DD hh:mm A`);
          iterator = { ...e, inboundMessageTime: date, date: date };
        } else {
          return { ...e };
        }
        return iterator;
      });
      dispatch(listOfUsers(data));
      dispatch(_setIndividualUser(importedData));
    });

    // EVENT EMIT - TO GET LIST OF PATIENT
    newSocket.emit(`getClinicalOrProviderPatientList`, {
      senderId: user._id,
      practiceId: user._practiceId,
    });
    newSocket.on(`ticketStatusUpdated`, (data) => {
      if (!user?.isGroupAdmin && !user?.isSystemAdministrator) {
        dispatch({ type: `LATEST_ISSUE_STATUS`, payLoad: data?.issueStatus });
      }

      if (data?.readBySysAdmin && data?.readByUser) {
        return;
      } else if (data?.readBySysAdmin && !data?.readByUser && data?.issueStatus === `closed`) return null;
      else {
        let currentPage = store.getState()?.ticketsCenter?.currentPage;
        let rowsPerPage = store.getState()?.ticketsCenter?.selectedRows;
        if (user?.isSystemAdministrator) dispatch(getTicketsSysAdmin(rowsPerPage, currentPage));
        // dispatch({ type: 'NOTIFY', payLoad: data });
        //Take the fresh data on new comment added

        // Red dot emit event
        newSocket.emit(`getAllTicketsOfUser`, {
          userId: user._id,
        });
      }
    });

    newSocket.on(`getClinicalOrProviderPatientList`, (data) => {
      dispatch(_listOfPatient(data));
    });

    // INCOMING TWILIO SMS RECEIVER EVENT

    // INCOMING TWILIO SMS RECEIVER EVENT -- SPECIAL EVENT FOR NOTIFICATIONS

    newSocket.on(`smsNotification`, async (data) => {
      dispatch(notifyTwillioThunk(data));
    });

    newSocket.on(`riskChangeNotification`, (data) => {
      dispatch(resmedIncommingSMS(data));
    });

    // ALERT FOR PATIENT RISK CHANGE
    newSocket.on(`notificationAlertForPatientRiskChange`, (data) => {
      dispatch(_alertPatientRiskChange(data));
    });

    // TRANSMIT BOTH - PROVIDER AND CLINICAL TEXT SMS
    newSocket.on(`sendSMS`, (data) => {
      dispatch(_providerClinicalStaffCommonSmsThread(data));
    });

    // EVENT LISTNER : SET USER ONLINE AND OFFLINE STATUS THROUGH SOCKET
    newSocket.on(`userOnlineStatus`, (data) => {
      dispatch(_updateUserOnlineStatus(data));
    });
    newSocket.on(`taskReminder`, (data) => {
      dispatch(saveInTaskAlerts({ ...data, alertTime: moment(new Date()).toISOString(), throwOnScreen: true }));
    });
    const appendKeysTaskMang = (data) => {
      const task = {
        ...data,
        taskReminderNotificationReceived: true,
        taskCreationNotificationReceived: false,
      };
      dispatch(saveSingleTask(task));
    };
    newSocket.on(`taskCreated`, (data) => {
      doIfNotTaskReporter(data, appendKeysTaskMang);
    });
    newSocket.on(`taskComment`, (data) => {
      const { _id: taskId } = data;
      const { tasksManager } = store.getState();
      const { openedTaskId } = tasksManager;
      if (openedTaskId !== taskId) {
        appendKeysTaskMang(data);
      }
    });
  };
  const setupSocket = () => {
    if (user && !establishSocket) {
      newSocket = io(SERVER_ADDRESS, {
        query: {
          id: `${user._id}`,
          practiceId: `${user?._practiceId}`,
        },
        transports: [`websocket`],
        reconnection: true,
      });

      newSocket.on(`disconnect`, (conn) => {
        console.log(`ininnin`, conn)
        dispatch(saveSocketConn(newSocket?.connected))
      });
      newSocket.on(`connect_error`, (conn) => {
        console.log(`ininnin`, conn)
        setEstablishSocket(true);
        dispatch(saveSocketConn(newSocket?.connected))
      });
      newSocket.on(`connection`, (conn) => {
        console.log(`ininnin`, conn)
        dispatch(saveSocketConn(newSocket?.connected))
      });

      newSocket.on(`connect`, (conn) => {
        console.log(`ininnin`, conn)
        dispatch(saveSocketConn(newSocket?.connected))
        addInWatchListIfCanceledRef();
        setEstablishSocket(true);
      });

      registerSocketEvents(newSocket);
    }
  };
  useEffect(() => {
    setupSocket();
    return () => {
      if (newSocket) newSocket.close();
    };

  }, [_practiceId]);

  return <>{establishSocket ? children : <PuffSpinner />}</>;

};
