import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { ASSIGN_PATIENT_WATCHERS, GET_ALL_USERS, GET_LIST_ALL_USERS, getRequest, postRequest } from 'src/crud/crud';
import { useSelector } from 'react-redux';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useStyles } from './useStyles';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { makeFullName, searchPatientAndUser } from 'src/utils';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { ForAssignedUserDetail } from './assignedUserDetail';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { sortIconHandler, sortKeyGiver } from '../patient2/PatientOverview/PatientOverView';
import { FilterInputFields } from './filterFormFieldsForReassignUsersAndPatients.js';
import { TablePagination } from './forUserTablePagination';
import { UserTableHeader, UserTableRow } from './userTable';

export const ForAssignUsers = () => {
  const [listOfUsers, setListOfUsers] = useState([]);
  const [listOfTransferUsers, setListOfTransferUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loadingState, setLoading] = useState({ error: false, loading: false });
  const { loading } = loadingState;
  const [selectPatientAndUser, setSelectPatientAndUser] = useState(null);
  const [searchText, setValues] = useState(``);
  const { user } = useSelector((state) => state?.user);
  const { _practiceId } = useSelector((state) => state?.user?.user);
  const _practiceGroupId = useSelector((state) => state?.user?.user?._practiceGroupId);
  const [selectedUserFromTable, setSelectedUserFromTable] = useState({});
  const [modal, setModal] = useState(false);
  const [showFilterFields, setShowFilterFields] = useState(false);
  const [selectStatus, setSelectStatus] = useState(``);
  const [selectAllPatients, setIsSelectAllPatients] = useState(false);
  const [excludedPatientIds, setExcludedPatientIds] = useState([]);

  const [sortKey, setSortKey] = useState(`lastName`);
  const [sortOrder, setSortOrder] = useState(`ASC`);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  const ComponentStyles = useStyles();

  const loadingSetter = (key, value) => {
    setLoading((p) => ({ ...p, [key]: value }));
  };

  const updateSelectedOfUsers = (userId, flag) => {
    if (flag) {
      setListOfTransferUsers([...listOfTransferUsers, userId]);
    } else {
      let result = listOfTransferUsers.filter((user) => {
        return user !== userId;
      });
      setListOfTransferUsers([...result]);
    }
  };

  const updateSelectAllUsers = (listOfUsers, flag) => {
    const userId = listOfUsers?.map((user) => user?._id);
    if (flag) {
      setListOfTransferUsers(userId);
    } else {
      setListOfTransferUsers([]);
    }
  };

  const handleSelectUser = (userInformation) => {
    setSelectedUserFromTable(userInformation);
  };

  const handleFilterButtonClick = () => {
    setShowFilterFields((prev) => !prev);
  };

  const handleSelectedTwoUsersAndMore = () => {
    return !!(listOfTransferUsers?.length >= 2 || selectAllPatients);
  };

  const handleDisableUsers = () => {
    return selectedUsers?.length === 0;
  };

  const handleSelectedStatusChange = (newStatus) => {
    setSelectStatus(newStatus);
  };

  const sortHandler = (order, key) => {
    setSortKey(key);
    setSortOrder(order);
  };

  let passArgs = { sortOrder: sortOrder, sortField: sortKey };

  const sendKey = {
    0: { patientName: searchText },
    1: { primaryUserName: searchText },
    2: { secondaryUserName: searchText },
    null: { patientName: searchText },
  };

  let result;

  if (sortOrder === `ASC`) {
    result = `ASC`;
  } else if (sortOrder === `DSC`) {
    result = `DESC`;
  } else {
    result = ``;
  }

  let payload = {
    searchFilters: {
      ...sendKey[selectPatientAndUser],
      ...(selectStatus && { status: selectStatus }),
    },
    pageNumber: currentPage,
    pageSize: rowsPerPage,
    sortField: sortKey,
    sortOrder: result,
  };

  const getListOfUsers = () => {
    loadingSetter(`error`, false);
    loadingSetter(`loading`, true);
    if (!user?.isGroupAdmin) {
      postRequest(GET_LIST_ALL_USERS, payload)
        .then((response) => {
          loadingSetter(`loading`, false);
          setListOfUsers(response?.data?.data);
          setTotalRows(response?.data?.pager?.totalRecords);
          if (Math.ceil(response?.data?.pager?.totalRecords / response?.data?.pager?.recordsPerPage)) {
            setTotalPage(Math.ceil(response?.data?.pager?.totalRecords / response?.data?.pager?.recordsPerPage));
          }
        })
        .catch(() => {
          loadingSetter(`error`, true);
          loadingSetter(`loading`, false);
        });
    }
  };

  useEffect(() => {
    getListOfUsers();
  }, [rowsPerPage, currentPage, sortKey, sortOrder, selectStatus]);

  let payloadForAssignUsers = {
    userIds: selectedUsers,
    patientIds: listOfTransferUsers,
    selectAllPatients: selectAllPatients,
    excludedPatientIds: excludedPatientIds,
    practiceId: _practiceId,
    practiceGroupId: _practiceGroupId,
  };

  const handleAssignUsers = () => {
    loadingSetter(`error`, false);
    loadingSetter(`loading`, true);
    postRequest(ASSIGN_PATIENT_WATCHERS, payloadForAssignUsers)
      .then(() => {
        loadingSetter(`loading`, false);
        successToast(`Successfully Assigned`);
        setListOfTransferUsers([]);
        setIsSelectAllPatients(false);
      })
      .catch(() => {
        loadingSetter(`error`, true);
        loadingSetter(`loading`, false);
      });
  };

  const onChangeHandler = (value) => {
    setValues(value);
  };

  const getAllUsers = () => {
    getRequest(`${GET_ALL_USERS}/${_practiceId}`)
      .then((response) => {
        let mappedList = makeFullName(response?.data?.users, [`_id`]);
        setAllUsers(mappedList);
      })
      .catch(() => {
        loadingSetter(`error`, true);
        loadingSetter(`loading`, false);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleClick = () => {
    if (selectAllPatients) {
      setExcludedPatientIds([]);
    }
    setIsSelectAllPatients(!selectAllPatients);
  };

  return (
    <>
      {modal && <ForAssignedUserDetail modal={modal} setModal={() => setModal(false)} selectedUserFromTable={selectedUserFromTable} />}

      <Paper sx={{ borderRadius: `20px`, boxShadow: `0px 4px 8px rgba(1, 1, 1, 0.2)` }}>
        <Typography variant="h6" component="div" sx={{ pt: 6, pl: 6, pb: 6 }}>
          Notification Assignee
        </Typography>

        <Grid container sx={{ pb: 6 }}>
          {handleSelectedTwoUsersAndMore() ? (
            <Grid item xs={4.3} sm={4.3} md={4.3}>
              <Box sx={{ ml: 6 }}>
                <MuiSelect
                  searchInpProps={{
                    isSearchAble: true,
                    searchKeys: [`fullName`],
                  }}
                  multiSelectProps={{
                    isSelectAll: true,
                  }}
                  controlProps={{ size: `small`, fullWidth: true, id: `assignUserIdImport` }}
                  label="Assign Users"
                  isSimple={false}
                  options={[...allUsers]}
                  optionKey={`fullName`}
                  optionValue={`_id`}
                  selectProps={{
                    multiple: true,
                    label: `Assign Users`,
                    id: `assignUserIdImport`,
                    name: `state`,
                    onChange: (value) => {
                      setSelectedUsers(value);
                    },
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: `420px`, // set the maximum height of the options
                          width: `280px`, // set the width of the options
                        },
                      },
                    },
                    value: selectedUsers,
                  }}
                />
              </Box>
            </Grid>
          ) : (
            <Grid item xs={4} sm={4} md={4}>
              <Box sx={{ ml: 6 }}>
                <MuiSelect
                  controlProps={{ fullWidth: true, id: `searchPatientAndUser`, size: `small` }}
                  label="Search By Patient"
                  isSimple={false}
                  options={[...searchPatientAndUser]}
                  optionKey={`label`}
                  optionValue={`value`}
                  selectProps={{
                    label: `Search By Patient`,
                    id: `searchPatientAndUser`,
                    name: `selectPatientAndUser`,
                    onChange: (event) => {
                      setSelectPatientAndUser(event.target.value);
                    },
                    value: selectPatientAndUser,
                  }}
                />
              </Box>
            </Grid>
          )}

          {handleSelectedTwoUsersAndMore() ? null : (
            <>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <InputField
                  fullWidth
                  size="small"
                  id="search-field"
                  label="Search"
                  onChange={({ target: { value } }) => {
                    onChangeHandler(value);
                  }}
                  value={searchText}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => getListOfUsers()}>
                          <ToolTipProvider
                            toolTipProps={{
                              title: `Search`,
                              placement: `top`,
                              arrow: true,
                            }}
                            element={<Search sx={{ color: `#1699C5` }} />}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Button sx={{ ml: 6, mr: 4 }} variant="outlined" className={ComponentStyles.outlinedButton} onClick={handleFilterButtonClick}>
                  <FilterAltIcon /> Filter
                </Button>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Button sx={{ ml: 2, mr: 2 }} variant="contained" onClick={handleClick} className={ComponentStyles.selectAllPatients}>
                  Select All Patients
                </Button>
              </Grid>
            </>
          )}

          {handleSelectedTwoUsersAndMore() && (
            <>
              <Grid item xs={4} sm={4} md={4}>
                <Button
                  sx={{ ml: 4, mr: 4 }}
                  variant="contained"
                  className={ComponentStyles.containedButton}
                  onClick={handleAssignUsers}
                  disabled={handleDisableUsers()}
                >
                  Assign Users
                </Button>
              </Grid>
              <>
                {selectAllPatients && (
                  <Button variant="contained" onClick={handleClick} className={ComponentStyles.containedButtonInTable}>
                    Deselect All Patients
                  </Button>
                )}
              </>
            </>
          )}
        </Grid>

        <FilterInputFields showFilterFields={showFilterFields} onSelectedStatusChange={handleSelectedStatusChange} />

        <TableContainer>
          <Table>
            <UserTableHeader
              updateSelectAllUsers={updateSelectAllUsers}
              listOfUsers={listOfUsers}
              sortHandler={sortHandler}
              sortKeyGiver={sortKeyGiver}
              passArgs={passArgs}
              sortIconHandler={sortIconHandler}
              selectAllPatients={selectAllPatients}
            />
            <TableBody>
              {listOfUsers?.length ? (
                listOfUsers?.map((mappingUsers, index) => (
                  <UserTableRow
                    key={index?._id}
                    index={index}
                    user={mappingUsers}
                    listOfTransferUsers={listOfTransferUsers}
                    handleSelectUser={handleSelectUser}
                    selectAllPatients={selectAllPatients}
                    excludedPatientIds={excludedPatientIds}
                    setExcludedPatientIds={setExcludedPatientIds}
                    setModal={setModal}
                    handleSelectedTwoUsersAndMore={handleSelectedTwoUsersAndMore}
                    updateSelectedOfUsers={updateSelectedOfUsers}
                    showViewModal={true}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalRows}
              setRowsPerPage={setRowsPerPage}
              totalPage={totalPage}
              isPatientView={false}
              hasStyle={true}
              data={listOfUsers}
            />
          </Table>
        </TableContainer>
      </Paper>

      {loading && <PulseSpinner />}
    </>
  );
};
