import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { PatientMessenger } from './components/PatientMessenger';
import { getRequest } from 'src/crud/crud';
import { PracticeMessenger } from './components/PracticeMessenger';
import { SELECTED_PATIENT_REPORT } from 'src/redux/patient/patientTypes';
import { Charting } from './components/Charting';
import { Grid } from '@mui/material';
import { LoadingState } from '../components/LoadingState';
import { saveTimerReportId } from 'src/pages/home/modules/patient2/PatientComponent';
import { handleReportLoadingFlag, setHandleTimer } from 'src/redux/patient/patientActions';
import {
  capitalizeResmed,
  capitalizeRespironics,
  roundNumber,
  extraWordRemover,
  timeFormat,
  capitalizeFirstLetter,
  convertStoHm,
  timeFormatCallBack,
  returnNull,
  handleAHI,
  manufacturerForReact_Health,
} from 'src/utils';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import css from '../PatientOver.module.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { momentWrapper } from 'src/momentWrapper';
import { useHistory } from 'react-router-dom';
import { PressureDecider } from '../components/PressureDecider';
import { _selectedUser } from 'src/redux/messenger/messengerActions';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import { PdfViewer } from '../../components/patientDetails/moreDetails/viewData/pdfViewer/pdfViewer';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';
import { reportsDispatcher, resetRedStateonCloseRep } from '../TableRows/TableRows';
import { PuffSpinner } from 'src/pages/common/components/spinner/puff/puff';

export const daysParam = `/${momentWrapper(new Date()).daysInMonth()}days`;




export const ReportModal = ({ forwardPage }) => {
  const { readyToSignModal: readyToSign, swipeAbleSeries, swipeSuspense: { loading: loadingSerSus } } = useSelector((state) => state?.readyToSign);
  let user = useSelector((state) => state?.user);
  const dipatch = useDispatch();
  const [openSummaryReport, setOpenSummaryReport] = useState(false);
  const [isPdfSummaryReportAvailable, setIsPdfSummaryReportAvailable] = useState(false);
  const patientReport = useSelector((state) => state?.patient?.patientReport);
  const hideTimer = useSelector((state) => state.patient.hideTimer);
  const { startTime } = useSelector((state) => state.patient.timer);
  const { open = false, reportId = ``, startDate = ``, patientId, endDate = ``, reportMonth = 0 } = readyToSign;
  const history = useHistory();
  const maxWidthXSm = useMediaQuery(`(max-width:576px)`);
  const maxWidthSm = useMediaQuery(`(max-width:768px)`);
  const maxWidthMd = useMediaQuery(`(max-width:900px)`);
  const maxWidthLg = useMediaQuery(`(max-width:1200px)`);
  const maxWidthXl = useMediaQuery(`(max-width:1300px)`);
  const maxWidthXxl = useMediaQuery(`(max-width:1500px)`);
  const maxWidthXxxl = useMediaQuery(`(max-width:2200px)`);
  const widthGiver = () => {
    let width = ``;
    if (maxWidthXSm) {
      width = `300%`;
    } else if (maxWidthSm) {
      width = `300%`;
    } else if (maxWidthMd) {
      width = `200%`;
    } else if (maxWidthLg) {
      width = `180%`;
    } else if (maxWidthXl) {
      width = `150%`;
    } else if (maxWidthXxl) {
      width = `100%`;
    } else if (maxWidthXxxl) {
      width = `100%`;
    }
    return width;
  };
  let width = widthGiver();
  const [loading, setLoading] = useState({ loading: false, error: false });
  const dispatch = useDispatch();
  const dispatchTimer = (dispatchTime, isUnmount) => {
    if ((hideTimer && dispatchTime) || isUnmount) {
      saveTimerReportId({
        id: patientId,
        startTime: startTime,
        auth: user?.auth,
        selectedPatientIdRedux: false,
        isFromView: dispatchTime,
        customDesc: true,
        ...(isUnmount && { shouldTriggAct: () => dispatch({ type: `REPORTACTION`, payLoad: {} }) }),
      });
    }
  };


  const fetchRepData = (
    reportId,
    reportMonth,
    patientId) => {
    setLoading({ loading: true, error: false });
    const query = `?patientId=${JSON.stringify(patientId)}&reportId=${JSON.stringify(reportId)}&month=${reportMonth}`;
    const dataSaver = (res) => {
      const dataToSave = res?.data?.data?.[0] || {}
      dipatch(setHandleTimer(true));
      setLoading({ loading: false, error: false });
      const filterReport = res?.data?.data?.[0]?.reports?.find((rep) => rep?._id === reportId) || {}
      dispatch({
        type: SELECTED_PATIENT_REPORT,
        payload: { data: [{ ...dataToSave, reports: [{ ...filterReport }] }] },
      });
    }
    getRequest(`patients/report-overview${query}`)
      .then(dataSaver)
      .catch(() => setLoading({ loading: false, error: true }));
  }






  useEffect(() => {
    if (reportId) fetchRepData(reportId, reportMonth, patientId)
    else if (swipeAbleSeries?.length) reportsDispatcher({
      patientId: swipeAbleSeries?.[0]?._patientId,
      reportId: swipeAbleSeries?.[0]?._reportId, open: true,
      startDate: swipeAbleSeries?.[0]?.startDate,
      endDate: swipeAbleSeries?.[0]?.endDate,
      patientNumber: swipeAbleSeries?.[0]?.patientNumber
    }, swipeAbleSeries?.[0]?.month)
    return () => {
      if (!reportId) {
        setIsPdfSummaryReportAvailable(false)
      }
    }
  }, [reportId, reportMonth, patientId, swipeAbleSeries])

  useEffect(() => {
    return () => {
      setIsPdfSummaryReportAvailable(false)
      dispatch(_selectedUser(``));
      dispatch({
        type: SELECTED_PATIENT_REPORT,
        payload: null,
      });
      resetRedStateonCloseRep()
      dispatch(handleReportLoadingFlag(false))
    };
  }, []);




  const moveToPatientModule = (patientId) => {
    dispatchTimer(true);
    history.push({
      pathname: `/patient`,
      patientId,
    });
  };

  const onClose = (dispatchTime, isOnlyClose, isUnmount) => {
    if (isOnlyClose) {
      dispatch({
        type: `SIGNREPORT`,
        payLoad: {
          open: false,
          id: ``,
          startDate: ``,
          endDate: ``,
          patientId: ``,
          patientNumber: ``,
          reportMonth: ``,
        },
      });
      dispatch({
        type: SELECTED_PATIENT_REPORT,
        payload: null,
      });
    }

    if (dispatchTime || isUnmount) {
      dispatchTimer(dispatchTime, isUnmount);
    }
  };

  // The ColorCodedAHI function is store.
  let showColorCodedAHI = handleAHI(patientReport?.data?.[0]?.AHI);

  const renderDate = (date) => {
    if (date) return momentWrapper(date).format(`MM/DD/YYYY`)
    return `-`
  }
  return (
    <CustomizedDialogs
      hasHeight={true}
      title={`Report Period: ${renderDate(startDate) + ` - ` + renderDate(endDate)}`}
      open={open}
      setOpen={() => onClose(true, true, true)}
      isPdfSummaryReportAvailable={isPdfSummaryReportAvailable}
      setOpenSummaryReport={setOpenSummaryReport}
      size="xl"
      fullWidth={true}
    >
      <ConditionallyRenderWrapper con={reportId && !loadingSerSus} renderKey={<PuffSpinner />}>
        <div className="table-responsive" style={{ width: `${width} !important` }}>
          <table style={{ width: `${width}` }} className={`table  table-lg table-borderless table-striped`}>
            <thead>
              <tr style={{ textAlign: `center`, verticalAlign: `middle` }}>
                <td className={css.tdLess}>Last Name</td>
                <td className={css.tdLess}>First Name</td>
                <td className={css.tdLess}>Device Type</td>
                <td className={css.tdLess}>Manufacturer</td>
                <td className={css.tdLess}>Risk</td>
                <td className={css.tdLess}>Adherence</td>
                <td className={css.td}>Days Used {`>`} 4h</td>
                <td className={css.td}>Avg Use (total days)</td>
                <td className={css.tdLess}>Pressure 90th/95th %</td>
                <td className={css.tdLess}>AHI</td>
                <td className={css.tdLess}>Leak</td>
                <td className={css.tdLess}>RPM Time</td>
                <td className={css.tdLess}>Communication</td>
              </tr>
            </thead>
            <tbody>
              {loading?.loading && (
                <tr>
                  <LoadingState type={`text`} />
                </tr>
              )}
              {!loading?.error && !loading?.loading && (
                <tr className={css.tableRow}>
                  <td
                    style={{ cursor: `pointer` }}
                    onClick={() => {
                      moveToPatientModule(patientReport?.data?.[0]?._id);
                    }}
                  >
                    <ToolTipProvider
                      toolTipProps={{
                        title: `Click to get details`,
                        placement: `top`,
                        arrow: true,
                      }}
                      element={<span>{capitalizeFirstLetter(patientReport?.data?.[0]?.lastName)}</span>}
                    />
                  </td>
                  <td
                    style={{ cursor: `pointer` }}
                    onClick={() => {
                      moveToPatientModule(patientReport?.data?.[0]?._id);
                    }}
                  >
                    <ToolTipProvider
                      toolTipProps={{
                        title: `Click to get details`,
                        placement: `top`,
                        arrow: true,
                      }}
                      element={<span>{capitalizeFirstLetter(patientReport?.data?.[0]?.firstName)}</span>}
                    />
                  </td>
                  <td>
                    {patientReport?.data?.[0]?.device?.manufacturer === `respironics` ||
                      patientReport?.data?.[0]?.device?.manufacturer === `reactHealth`
                      ? patientReport?.data?.[0]?.device?.type
                      : patientReport?.data?.[0]?.device?.detail}
                  </td>
                  <td>
                    {patientReport?.data?.[0]?.device?.manufacturer && patientReport?.data?.[0]?.device?.manufacturer === `respironics`
                      ? capitalizeRespironics()
                      : patientReport?.data?.[0]?.device?.manufacturer === `reactHealth`
                        ? manufacturerForReact_Health()
                        : capitalizeResmed()}
                  </td>
                  <td>
                    {patientReport?.data?.[0]?.tier === `VERY HIGH RISK`
                      ? `Very High`
                      : returnNull({
                        key: patientReport?.data?.[0]?.tier,
                        successRetrun: extraWordRemover({ word: patientReport?.data?.[0]?.tier, capitalizeFirstLetter: true, isAllUpperCase: true }),
                        faliure: `No Data`,
                      })}
                  </td>
                  <td>
                    {patientReport?.data?.[0]?.isAdherent === null ? `No Data` : patientReport?.data?.[0]?.isAdherent ? `Adherent` : `Non-Adherent`}
                  </td>
                  <td>
                    {returnNull({
                      key: patientReport?.data?.[0]?.daysDeviceUsedGT4,
                      successRetrun: `${patientReport?.data?.[0]?.daysDeviceUsedGT4}/${patientReport?.data?.[0]?.TimedaysDeviceUsedGT4?.days} days`,
                      faliure: `-`,
                    })}
                  </td>
                  <td>
                    {convertStoHm(
                      patientReport?.data?.[0]?.daysDeviceUsedInsec,
                      true,
                      patientReport?.data?.[0]?.TimedaysDeviceUsedGT4?.days,
                      timeFormatCallBack
                    )}
                    {` `}
                  </td>
                  <td style={{ width: `4%` }}>
                    <PressureDecider
                      deviceMenuFacturer={patientReport?.data?.[0]?.device?.manufacturer}
                      pressure={patientReport?.data?.[0]?.pressure}
                    />
                  </td>
                  <td>
                    <ToolTipProvider
                      toolTipProps={{
                        title: `Events/hour`,
                        placement: `top`,
                        arrow: true,
                      }}
                      element={
                        <span style={{ color: showColorCodedAHI }}>
                          {roundNumber({
                            num: patientReport?.data?.[0]?.AHI,
                            roundTo: 1,
                          })}
                        </span>
                      }
                    />
                  </td>
                  <td>
                    <ToolTipProvider
                      toolTipProps={{
                        title: `L/min`,
                        placement: `top`,
                        arrow: true,
                      }}
                      element={
                        <div>
                          {roundNumber({
                            num: patientReport?.data?.[0]?.leak,
                            roundTo: 1,
                          })}
                        </div>
                      }
                    />
                  </td>
                  <td>
                    {returnNull({
                      key: patientReport?.data?.[0]?.timeMonitored,
                      successRetrun: timeFormat(patientReport?.data?.[0]?.timeMonitored),
                      faliure: `-`,
                    })}
                  </td>
                  <td>
                    {patientReport?.data?.[0]?.interactiveCommunication
                      ? returnNull({
                        key: patientReport?.data?.[0]?.interactiveCommunication,
                        successRetrun: <MissedVideoCallIcon style={{ color: `#1699c5` }} />,
                        faliure: `No`,
                      })
                      : patientReport?.data?.[0]?.leftVoiceMail
                        ? returnNull({
                          key: patientReport?.data?.[0]?.leftVoiceMail,
                          successRetrun: <VoicemailIcon style={{ color: `#1699c5` }} />,
                          faliure: `No`,
                        })
                        : null}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Grid sx={{ mt: 2 }} container spacing={1}>
          <Grid sx={{ p: 0.5 }} xs={4} sm={4} md={4} lg={4} xl={4}>
            <PatientMessenger patientRepData={patientReport?.data?.[0]} />
          </Grid>
          <Grid sx={{ p: 0.5 }} xs={4} sm={4} md={4} lg={4} xl={4}>
            <PracticeMessenger />
          </Grid>
          <Grid sx={{ p: 0.5 }} xs={4} sm={4} md={4} lg={4} xl={4}>
            <Charting loadingReport={loading.loading || loading.error} forwardPage={forwardPage} patientRepData={patientReport?.data?.[0]} onClose={onClose} />
          </Grid>
        </Grid>
        <ConditionallyRenderWrapper con={startDate && endDate}>
          <PdfViewer
            userId={patientReport?.data?.[0]?._id}
            start={startDate}
            end={endDate}
            reportId={patientReport?.data?.[0]?.reports?.[0]?._id}
            show={openSummaryReport}
            onHide={() => setOpenSummaryReport(false)}
            setIsPdfSummaryReportAvailable={setIsPdfSummaryReportAvailable}
            patientData={patientReport?.data?.[0]?.device?.manufacturer}
            isOverView={true}
          />
        </ConditionallyRenderWrapper>
      </ConditionallyRenderWrapper>


    </CustomizedDialogs>
  );
}