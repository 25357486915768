import React from 'react';
import { MissedCallNotifications } from './MissedCallNotifications';
import VoicemailIcon from '@mui/icons-material/Voicemail';

export const VoiceMailNotifications = ({ notifications = [], redirection, setNotification }) => {
  return (
    <MissedCallNotifications
      socketName={`missedVMNotification`}
      notifications={notifications}
      redirection={redirection}
      icon={VoicemailIcon}
      setNotification={setNotification}
    />
  );
};
