import React, { useState, useEffect, useRef } from 'react';
import { getRequest, REPORT_NOTES } from '../../../../../../../crud/crud';
import { momentWrapper } from 'src/momentWrapper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import css from './logs.module.css';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { capitalizeFirstLetter } from 'src/utils';
import { useSelector } from 'react-redux';

export const PatientLogs = ({ toggle, handleChartingToggle, patientId, reportId }) => {
  const [notes, setNotes] = useState([]);
  const { user: { user } } = useSelector((state) => state)
  const logBox = useRef(null);

  useEffect(() => {
    if (patientId && !user?.isBiller) {
      let url = REPORT_NOTES + patientId + `?reportId=${reportId}`
      getRequest(url)
        .then((response) => {
          setNotes(response.data.reportNotes);
        })
        .catch(() => { });
    }
  }, [patientId]);

  useEffect(() => {
    if (logBox.current) logBox.current.scrollTop = logBox.current.scrollHeight;
  }, [notes, toggle]);

  useEffect(() => {
    if (logBox.current) logBox.current.scrollTop = logBox.current.scrollHeight;
  }, [notes]);

  return (
    <div id={css.log}>
      <div
        style={{
          display: `flex`,
          borderTop: `1px solid #c1c1c1`,
          borderBottom: `1px solid #c1c1c1`,
          backgroundColor: `#1699c5`,
          height: `30px`,
          color: `white`,
        }}
      >
        {toggle === true ? <ExpandLessIcon onClick={handleChartingToggle} /> : <ExpandMoreIcon onClick={handleChartingToggle} />}
        Charting
      </div>
      {toggle && (
        <div id={css.msgActualBody}>
          <div
            id="customBody"
            className="p-2"
            style={{
              flex: 1,
              height: `98%`,
              backgroundColor: `white`,
              overflowY: `auto`,
            }}
            ref={logBox}
          >
            {notes.map((note, index) => {
              return (
                <>
                  <p
                    key={index}
                    className={`${css.cardMainMDPending} ${css.cardBody}`}
                    style={{
                      backgroundColor: note?.added_by_provider ? `rgba(223, 212, 119, 0.5)` : `rgba(119, 223, 218, 0.5)`,
                    }}
                  >
                    {note.note}
                    <p key={notes.length + 1} className={css.cardTimeStamp}>
                      {momentWrapper(note.dateCreated).local().format(`MM/DD hh:mm A`)}
                      {` , `}
                      {` `}
                      <span >{capitalizeFirstLetter(`${note?._created_by?.firstName} ${note?._created_by?.lastName}`)}</span>
                      {` , `}
                      {note?._created_by?.providerType && note?._created_by?.providerType !== `other` && (
                        <span className="ml-1">{note?._created_by?.providerType}</span>
                      )}
                    </p>
                  </p>
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
