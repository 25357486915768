import React, { useEffect } from 'react';

export const ConditionallyRenderWrapper = ({ children, con, renderKey = null, doOnConChange = false }) => {
    useEffect(() => {
        if (doOnConChange) doOnConChange()
    }, [doOnConChange, con])
    return <>{con ? children : renderKey}</>;
};


// import React from 'react'

// export const ConditionallyRenderWrapper = ({ children, con }) => {
//     return (
//         <>
//             {con ? children : null}
//         </>
//     )
// }