import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, MenuItem, Select, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { useSelector } from 'react-redux';
import { GET_ALL_PATIENTS_WRT_USER, getRequest, GET_ALL_USERS, postRequest } from 'src/crud/crud';
import LinearProgress from '@mui/material/LinearProgress';
import GppMaybeSharpIcon from '@mui/icons-material/GppMaybeSharp';
import { capitalizeFirstLetter } from 'src/utils';
import { SearchInput } from 'src/pages/common/components/LIstSubheader/SearchInput';

const Comp = ({ onBlur, setValue, isPatient, error, isValueComing, providerAssignedPatients }) => {
  const [listData, setListData] = useState(``);
  const [value, setValueState] = useState(``);
  const [searchValue, setSearchValue] = useState(``);
  const { _practiceId } = useSelector((state) => state?.user?.user);
  const isDefaultValSet = useRef(`no`);
  const isGoneForData = useRef(`no`);
  const decideRoute = isPatient ? `${GET_ALL_PATIENTS_WRT_USER}` : `${GET_ALL_USERS}/${_practiceId}`;
  const setStates = (res, valueOrKey) => {
    if (res) setListData(res?.data?.[valueOrKey]);
    else setListData(valueOrKey);
  };
  const doIfPatient = () => {
    if (isPatient) {
      setValue(``);
      setValueState(``);
    }
  };
  const errorCatcher = () => {
    setListData(`error`);
    doIfPatient();
  };

  const doOnSucces = (res) => {
    setStates(res, isPatient ? `data` : `users`);
  };
  const getData = () => {
    if (isGoneForData.current === `no`) {
      isGoneForData.current = `yes`;
      setListData(`loading`);

      if (isPatient)
        postRequest(decideRoute)
          .then((res) => doOnSucces(res))
          .catch(() => errorCatcher());
      else
        getRequest(decideRoute)
          .then((res) => doOnSucces(res))
          .catch(() => errorCatcher());
    }
  };

  useEffect(() => {
    if (isGoneForData.current === `yes`) isGoneForData.current = `no`;
    if (providerAssignedPatients) getData();
  }, [providerAssignedPatients, isGoneForData]);

  useEffect(() => {
    const setIfValueComing = () => {
      if (isValueComing && isDefaultValSet.current === `no`) {
        setStates(false, isValueComing);
        setValueState(isValueComing?.[0]?._id);
        isDefaultValSet.current = `yes`;
      }
    };
    setIfValueComing();

    if (!isPatient) getData();
  }, [isValueComing, isDefaultValSet, isGoneForData, isPatient]);

  let filteredProviders = useMemo(() => {
    return (
      typeof listData === `object` &&
      listData.filter((e) => e?.firstName?.toLowerCase()?.includes(searchValue) || e?.lastName?.toLowerCase()?.includes(searchValue))
    );
  }, [listData, searchValue]);

  const userType = isPatient ? `Patient` : `User`;
  const label = `Search a ${userType}!`;

  return (
    <Box>
      <FormControl fullWidth size="small">
        <InputLabel
          error={error}
          style={{
            left: `-2%`,
          }}
          id="ProvidersAnalytics"
        >
          {isPatient ? `Patient` : `Assigned to*`}
        </InputLabel>
        <Select
          size="small"
          label={isPatient ? `Patient` : `Assigned to`}
          name="providersInputName"
          id="providersInputName"
          value={value}
          {...(onBlur && {
            onBlur: (e) => {
              const {
                target: { value },
              } = e;
              onBlur(value);
            },
          })}
          error={error}
          {...(!isValueComing
            ? {
                onFocus: () => {
                  if (searchValue) {
                    setSearchValue(``);
                  }
                  if (!isPatient || (providerAssignedPatients && isPatient)) {
                    setListData(`loading`);
                    const doIfSuccess = (res) => {
                      isPatient ? setListData(res?.data?.data) : setListData(res?.data?.users);
                    };
                    if (isPatient) {
                      postRequest(decideRoute)
                        .then((res) => doIfSuccess(res))
                        .catch(() => errorCatcher());
                    } else
                      getRequest(decideRoute)
                        .then((res) => doIfSuccess(res))
                        .catch((e) => errorCatcher());
                  }
                },
              }
            : {})}
          onChange={(e) => {
            const {
              target: { value },
            } = e;
            setValue(e);
            setValueState(value);
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: `300px`,
              },
            },
          }}
        >
          <SearchInput label={label} keyValue={false} isObj={false} setSearchValue={setSearchValue} />
          {listData === `loading` && <LinearProgress sx={{ width: `100%` }} />}
          {listData === `error` && <GppMaybeSharpIcon sx={{ width: `100%`, color: `red` }} />}
          {typeof listData === `object` &&
            filteredProviders.map((e) => (
              <MenuItem sx={{ fontWeight: 600 }} onClick={() => setValue(e)} value={e?._id} key={e?._id}>
                {capitalizeFirstLetter(e?.firstName + ` ` + e?.lastName)}
              </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText sx={{ color: `red` }}>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export const AskForAssignee = React.memo(Comp);
