import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { GET_VALID_TIME } from 'src/crud/crud'
import { ConfirmationWrapper } from './ConfirmationWrapper'
import { SERVER_ADDRESS } from 'src/crud/axiosClient';

const timeValidation = (time) => {
    let diffInMin = moment(new Date()).diff(moment(time), `minutes`)
    return !(diffInMin);
}

export const TimeValidationWrapper = ({ children }) => {
    const [openTimeValidation, setOpenTimeValidation] = useState(false)
    useEffect(() => {
        axios.post(SERVER_ADDRESS + GET_VALID_TIME, {
            timeZone: moment.tz.guess()
        }).then((res) => {
            const { data: { timestamp } } = res
            let isCorrectTime = timeValidation(timestamp)
            if (!isCorrectTime) {
                setOpenTimeValidation(true)
            }
        }).catch((error) => {
            console.log(`error`)
        })
        return () => {
            setOpenTimeValidation(false)
        }
    }, [])

    return (
        <ConfirmationWrapper
            open={openTimeValidation}
            successFunc={() => setOpenTimeValidation(false)}
            successText={`Close`}
            title={`Time Validation`}
            description={`We have encountered that your machine time is not correct with respect to your time zone please use auto provided time.
            Otherwise misbehavior regarding app can be occurred!`}
        >{children}</ConfirmationWrapper>
    )
}
