import React from 'react'
import css from './AnimatedSpinner.module.css'

export const AnimatedSpinner = ({ hasBlueColor }) => {
  return (
    <div className={css.skCircle}>
      <div className={`${css.skCircle1} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle2} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle3} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle4} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle5} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle6} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle7} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle8} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle9} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle10} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle11} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
      <div className={`${css.skCircle12} ${css.skChild} ${hasBlueColor && css.makeBlueColor}`}></div>
    </div>

  )
}