export const SAVE_DATA = `SAVE_REP_SERIES_DATA`;
export const UPDATE_DATA = `UPDATE_REP_SERIES_DATA`;
export const EMPTY_DATA = `EMPTY_REP_SERIES_DATA`;
export const TRIGGER_SWIPE = `TRIGGER_REP_SWIPE`;
export const CLOSE_TRIGGERED_SWIPE = `CLOSE_TRIGGER_REP_SWIPE`;
export const SAVE_LAST_CLOSED_REP = `SAVE_LAST_CLOSED_REP`;
export const SAVE_CLICKED_REP = `SAVE_CLICKED_REP`;
export const EMPTY_LAST_CLOSED_REP = `EMPTY_LAST_CLOSED_REP`;
export const REMOVE_FROM_DATA = `REMOVE_FROM_DATA_SWIPE_SERIES`;
export const REMOVE_FROM_CLICKED_REP = `REMOVE_FROM_CLICKED_REP`;
export const EMPTY_CLICKED_REP = `EMPTY_CLICKED_REP`;
export const SAVE_REP_SER_SUSPENSE = `SAVE_REP_SER_SUSPENSE`;
export const PO_DATA_STATUS = `PO_DATA_STATUS`