import React, { useState } from 'react';
import { Grid, Stack, Box } from '@mui/material';
import { pickerPropsHandler } from 'src/pages/home/modules/settings/Analytics/utilities';
import { setUseState } from 'src/utils';
import { CustomBtn } from '../components/Button/CustomBtn';
import { DatePickerCustomize } from '../components/datePicker/DatePickerCustomize';
import moment from 'moment';
import { errorToast } from '../components/snackBar/toast';

export const useStartEndDateSelction = (props) => {
  const [values, setValues] = useState({ start: props?.startDate || ``, end: props?.endDate || `` });
  const { start, end } = values;
  const {
    resetDef = false,
    isStack = false,
    datePickerContProps = { styles: {} },
    actionProps = { text: ``, btnStyles: {}, btnAction: () => null },
    pickerProps = { startSign: `Start Date`, endSign: `End Date` },
    spacings = { spacing: 2 },
    gridBreakPoints = { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 },
  } = props;
  const { text: actionBtnText = ``, btnStyles, btnAction } = actionProps;
  const { startSign, endSign } = pickerProps;
  const { styles: contStyles } = datePickerContProps;

  const resetValues = () => {
    setValues({ start: ``, end: `` });
    if (resetDef) btnAction();
  };

  const wrapWithStartOfMonth = ({ isEnd, key, value }) => {
    let wrappedDate = isEnd ? moment(value).endOf(`month`).endOf(`day`) : moment(value).startOf(`month`).startOf(`day`);
    let endDateCalculation = () => {
      let selectedMonth = moment(value).month();
      let isCurrentMonthSelection = moment(new Date()).month() === selectedMonth;
      if (isCurrentMonthSelection) wrappedDate = moment(value).endOf(`day`);
    };
    if (isEnd) endDateCalculation();
    setUseState({ key, setState: setValues, value: value ? wrappedDate : `` });
  };

  const fieldProps = ({ valSign, dateVal, onChangeKey }) => {
    return {
      ...(contStyles ? { sx: { ...contStyles } } : {}),
      datePickerProps: {
        pickerProps: { ...pickerPropsHandler(valSign), fullWidth: true },
        dateInputValue: dateVal || null,
        onChange: (value) =>
          wrapWithStartOfMonth({
            key: onChangeKey,
            value,
            isEnd: onChangeKey === `end`,
          }),
      },
    };
  };

  const btnProps = (isAction) => {
    if (isAction) {
      return {
        btnProps: {
          ...actionProps,
          onClick: () => {
            if (start && end) {
              if (moment(end).isAfter(start) && moment(start).isBefore(end)) btnAction(start, end);
              else errorToast(`Invalid date selection.`);
            } else errorToast(`Please fill required fields.`);
          },
          style: { ...btnStyles },
        },
        element: actionBtnText,
      };
    }
    return {
      btnProps: {
        className: `btn btn-outline-success btn-sm`,
        style: { ...btnStyles },
        onClick: () => resetValues(),
      },
      element: `Reset`,
    };
  };

  const renderCont = {
    stack: (
      <Stack direction="row" {...spacings}>
        <DateField
          {...fieldProps({
            valSign: startSign,
            dateVal: start,
            onChangeKey: `start`,
          })}
        />
        <DateField
          {...fieldProps({
            valSign: endSign,
            dateVal: end,
            onChangeKey: `end`,
          })}
        />
        <Box>
          <CustomBtn {...btnProps(true)} />
        </Box>
        <Box>
          <CustomBtn {...{ ...btnProps(false) }} />
        </Box>
      </Stack>
    ),
    grid: (
      <Grid container {...spacings}>
        <Grid item {...gridBreakPoints}>
          <DateField
            {...fieldProps({
              valSign: startSign,
              dateVal: start,
              onChangeKey: `start`,
            })}
          />
        </Grid>
        <Grid item {...gridBreakPoints}>
          <DateField
            {...fieldProps({
              valSign: endSign,
              dateVal: end,
              onChangeKey: `end`,
            })}
          />
        </Grid>
        <Grid item {...gridBreakPoints}>
          <CustomBtn {...{ ...btnProps(false) }} />
        </Grid>
        <Grid item {...gridBreakPoints}>
          <CustomBtn {...btnProps(true)} />
        </Grid>
      </Grid>
    ),
  };
  const decideStyle = isStack ? `stack` : `grid`;

  return renderCont[decideStyle];
};

const DateField = ({ contStyles, datePickerProps }) => {
  return (
    <Box {...(contStyles ? { ...contStyles } : {})}>
      <DatePickerCustomize {...datePickerProps} />
    </Box>
  );
};