import React, { useState, useEffect, useRef } from 'react';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { useSelector } from 'react-redux';
import {
  getRequest,
  GET_CARD,
  GET_HISTORY,
  postRequest,
  UPDATE_PAYMENT_METHOD_CHECKOUT,
  CHECK_PENDING_VERIFICATION
} from 'src/crud/crud';
import css from './billingHistory.module.css';
import { PaymentInfo } from 'src/pages/home/modules/administration/payment/paymentInfo';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import Alert from "@mui/material/Alert";

export const BillingHistoryContext = React.createContext({
  isCardUpdate: false,
  toggleCard: () => { },
});

export const BillingHistory = () => {
    const stripe = useStripe();
  const renderCount = useRef(0);
  const [verificationUrl, setVerificationUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [histories, setHistory] = useState([]);
  const [card, setCard] = useState(``);
  const [showCardForm, setShowCardForm] = useState(false);
  const [sortKey, setSortKey] = useState(`patientCount`);
  const [sortOrder, setSortOrder] = useState(1);
  const {
    user,
    practice: { practiceInfo: practice },
  } = useSelector((state) => state);

  const getCardInfo = ()=> {
    const decideRoute = () => {
      let url;
      if (practice?._id) {
        url = GET_CARD + `/${practice?.stripeCustomerId}`;
      } else {
        url = GET_CARD + `/${user?.user?.stripeCustomerId}`;
      }
      return url;
    };
    let url = decideRoute();
    getRequest(url)
        .then((res) => {
          setLoading(false);
          setCard(res.data?.card);
        })
        .catch((err) => {
          setLoading(false);
        });
}

  console.error("Stripe customer id", user, "Practice customer id: ", practice);


  const getPendingVerification = () => {
    postRequest(CHECK_PENDING_VERIFICATION, {
      stripeCustomerId: user?.user?.stripeCustomerId || practice?.stripeCustomerId
    })
        .then(res => {
          if(res.status === 200 && res.data?.verification_url) {
            setVerificationUrl(res.data.verification_url);
          } else if(res.status === 404) {
            setVerificationUrl("");
          }
        });
  }

  useEffect(() => {
    if (user?.user?.auth || sortKey || sortOrder) {
      let query = `?field=${sortKey}&order=${sortOrder}`;
      let url = GET_HISTORY + `/${practice?._id ? practice?._id : user?.user?._practiceId}` + query;
      setLoading(true);
      getRequest(url)
        .then((res) => {
          setLoading(false);
          setHistory(res.data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }


    if (renderCount.current === 0) {
      renderCount.current = renderCount.current + 1;
      getPendingVerification();
      getCardInfo()
    }
  }, [sortKey, sortOrder, renderCount]);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    const retrieveSession = async () => {
      try {
        const res = await postRequest('stripe/retrieve-session', {
          sessionId: query.get('session_id'),
        });

        if(res.data?.verification_url) {
          // toggleVerificationModalOpen(true);
          setVerificationUrl(res.data.verification_url);
        }

      } catch (error) {
        console.error(error);
      }
      // console.error('RETREIVE SESSION CALL!!!!!!', res.data);
    };

    if (query.get('cancel') === 'true') {
      errorToast('Payment cancelled');
    } else if (query.get('session_id')) {
      retrieveSession().then(() => {
        getPendingVerification();
        getCardInfo();
      });
    }
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  const handleUpdatePaymentMethodCheckout = async () => {
    try {
      const res = await postRequest(UPDATE_PAYMENT_METHOD_CHECKOUT, {
        stripeCustomerId: user?.user?.stripeCustomerId || practice?.stripeCustomerId,
        stripeSubscriptionId: user?.stripeSubscriptionId || practice?.stripeSubscriptionId,
      });

      const { session = {id: ""} } = res.data;

      await stripe.redirectToCheckout({
        sessionId: session.id,
      });

    } catch (e) {
      console.error(e);
    }
  };

  const sortHandler = (key) => {
    if (key === `customized_invoice_pdf` || key === `invoice_pdf` || key === `card`) {
      return null;
    } else {
      setSortKey(key);
      if (sortOrder === -1) setSortOrder(1);
      else setSortOrder(-1);
    }
  };
  const toggleCard = () => setShowCardForm(!showCardForm);
  const getStripeInvoice = (id, isDraft, billingMonth) => {
    let route = isDraft ? `stripe/getLastMonthCustomInvoice/${id}?billingMonth=${billingMonth}` : `stripe/invoices/${id}`;
    getRequest(route)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          if (response?.data?.customized_invoice_pdf) {
            window.open(response.data.customized_invoice_pdf, `_blank`);
          } else if (response?.data?.pdf) {
            window.open(response?.data?.pdf, `_blank`);
          }
        }
      })
      .catch(() => { });
  };

  const sortWrapper = (key) => {
    let iconClass = "fa fa-sort ml-2";

    if (sortKey === key) {
      iconClass = sortOrder === 1 ? "fa fa-sort-asc ml-2" : "fa fa-sort-desc ml-2";
    }

    return <i className={iconClass} />;
  };

  return showCardForm ? (
    <BillingHistoryContext.Provider value={{ isCardUpdate: true, toggleCard }}>
      <PaymentInfo />
    </BillingHistoryContext.Provider>
  ) : (
    <div id={css.practiceContainer}>
      <div className="table-responsive" style={{ borderRadius: `10px 10px 10px 10px` }}>
        { verificationUrl && <Alert severity="info">
          <h5>ACH Manual Payment Method in progress</h5>
          <p>You will receive a Microdeposit of $0.01 within 2 days, click <a href={verificationUrl} style={{ fontSize: '16px' }} target="_blank">here</a> to proceed. Please come back to the app after verifying your microdeposit.</p>
        </Alert> }

        <table className="table table-striped">
          <thead style={{ borderBottom: `1px solid grey` }}>
            <tr style={{ backgroundColor: `white` }}>
              <td id={css.headTextContainer} colSpan={4}>
                <h4 id={css.headText}>Billing History</h4>
              </td>
              {card && (
                <td style={{ padding: `5% 1%` }} colSpan={3}>
                  <h5 id={css.CardText} className={user?.user?.isSystemAdministrator ? `d-none` : `d-block`}>
                    Active Payment Method: {card}
                  </h5>
                </td>
              )}
              {card && (
                <td style={{ padding: `5% 1%` }} colSpan={1} className={user?.user?.isSystemAdministrator ? `d-none` : ``}>
                  <button id={css.headButton} className="btn btn-info h-25" onClick={handleUpdatePaymentMethodCheckout}>
                    Change
                  </button>
                </td>
              )}
            </tr>
            <tr>
              <th scope="col" className={css.customCol0}></th>
              <th scope="col" className={css.customCol1} onClick={() => sortHandler(`card`)}>
                BILLING METHOD
              </th>
              <th scope="col" className={css.customCol1} onClick={() => sortHandler(`billingMonth`)}>
                BILLING PERIOD
                {sortWrapper(`billingMonth`)}
              </th>
              <th scope="col" className={css.customCol2} onClick={() => sortHandler(`amount`)}>
                AMOUNT
                {sortWrapper(`amount`)}
              </th>
              <th scope="col" className={css.customCol3} onClick={() => sortHandler(`providerCount`)}>
                NUMBER OF PROVIDERS
                {sortWrapper(`providerCount`)}
              </th>
              <th scope="col" className={css.customCol4} onClick={() => sortHandler(`patientCount`)}>
                NUMBER OF PATIENTS
                {sortWrapper(`patientCount`)}
              </th>
              <th scope="col" className={css.customCol4} onClick={() => sortHandler(`status`)}>
                STATUS
                {sortWrapper(`status`)}
              </th>
              <th
                scope="col"
                className={css.customCol4}
                onClick={() => sortHandler(histories?.customized_invoice_pdf ? `customized_invoice_pdf` : `invoice_pdf`)}
              >
                INVOICE
              </th>
            </tr>
          </thead>
          <tbody id={css.body}>
            {histories.length ? (
              histories.map((e) => {
                return (
                  <tr key={e?.id}>
                    <td scope="row"></td>
                    <td>{e.card || `N/A`}</td>
                    <td>{e.billingMonth}</td>
                    <td>$ {e.amount}</td>
                    <td>{e.providerCount}</td>
                    <td>{e.patientCount}</td>
                    <td>{e?.status?.toUpperCase()}</td>
                    <td>
                      <button
                        id={css.headButton}
                        className="btn btn-info h-25"
                        onClick={() => {
                          getStripeInvoice(
                            e?.status === `draft` ? e?._practiceId : e?.invoiceId,
                            e?.status === `draft`,
                            e?.billingMonth ? `${e?.billingMonth}` : false
                          );
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="8" style={{ textAlign: `center` }}>
                  No history found!
                </td>
              </tr>
            )}
            {loading && <PulseSpinner />}
          </tbody>
        </table>
      </div>
    </div>
  );
};
