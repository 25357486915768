import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _selectedUser } from '../../../../../../redux/messenger/messengerActions';
import { LatestMessage } from '../LatestMessage';
import css from './ConversationListItem.module.css';
import Avatar from 'react-avatar';
import { capitalizeFirstLetter } from 'src/utils';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const ConversationListItem = (props) => {
  const selectedUserRedux = useSelector((state) => state.messenger.selectedUser);
  const dispatch = useDispatch();
  const msgRef = useRef();
  let { receiverId, name, text, isOnline, type } = props.data || null

  // Function update Selected User object in messenger Reducer
  const selectUserHandler = () => {
    dispatch(_selectedUser(receiverId));
  };

  return (
    <div
      style={selectedUserRedux === props?.data?.receiverId ? { backgroundColor: `#f4f4f8` } : null}
      className={css.conversationListItem}
      onClick={selectUserHandler}
    >
      <Avatar name={name} size="40" round="40px" className={css.conversationPhoto} />

      <div className={css.conversationInfo}>
        <h1 className={css.conversationTitle}>
          {capitalizeFirstLetter(name)} {isOnline ? <span className="badge badge-pill badge-success">Online</span> : null}
        </h1>
        <p className={css.conversationSnippet} id="conversationSnippet" ref={msgRef}>
          <i>{type === `mms` ? <CloudDownloadIcon sx={{ color: `#1699c5` }} /> : <LatestMessage text={text} />}</i>
        </p>
      </div>
    </div>
  );
}

