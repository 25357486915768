import React, { useEffect, useState } from 'react';
import css from './practiceInfo.module.css';
import avatar from '../../../../../../../assets/hospital.jpg';
import { EditPractice } from '../editPractice/editPractice';
import { getRequest, PRACTICE_INFO_URL, PRACTICE_MAIN_ADMIN_INFO_URL } from '../../../../../../../crud/crud';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { Fade } from '@mui/material';
import { capitalizeFirstLetter } from 'src/utils';

export const PracticeInfo = () => {

  const [data, setData] = useState({});
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);


  let img = `https://robohash.org/108.162.219.96.png`;
  useEffect(() => {
    setLoading(true);
    getRequest(PRACTICE_INFO_URL)
      .then((res) => {
        setData(res.data.practice);
        getRequest(PRACTICE_MAIN_ADMIN_INFO_URL)
          .then((res) => {
            setUser(res.data.admin);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div id={css.practiceContainer} style={{ position: `relative` }}>
        <div className="table-responsive" style={{ borderRadius: `10px 10px 10px 10px` }}>
          <table className="table table-striped">
            <thead id={css.head}>
              <tr>
                <td id={css.headTextContainer} colSpan={6}>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <h4 id={css.headText}>Practice Info</h4> */}
                    <td style={{ border: `1px solid gainsboro` }}>
                      <Fade in={data?.practiceLogoPath || avatar}>
                        <img src={data?.practiceLogoPath ? data?.practiceLogoPath : avatar} alt="profile" height="41" width="127" />
                      </Fade>
                    </td>
                    {/* <button className="btn btn-outline-info h-25" id={css.headButton}>
                      ADD LOCATION +
                    </button> */}
                  </div>
                </td>
              </tr>
              {/* <tr>
              <td className="ml-5 mb-5">
                <Image src="https://robohash.org/108.162.219.96.png" rounded />
              </td>
            </tr> */}
            </thead>
            <tbody id={css.body}>
              <tr>
                <th scope="row" className={css.customCol1}>
                  Name:
                </th>
                <td colSpan={5}>{capitalizeFirstLetter(data.practiceName)}</td>
              </tr>
              <tr>
                <th scope="row" className={css.customCol1}>
                  Location:
                </th>
                <td className={css.customCol2}>
                  <Fade in={data?.line1}>
                    <span>{data?.line1}</span>
                  </Fade>
                </td>
                <td className={css.customCol3}>
                  <Fade in={data?.city}>
                    <span>{data?.city}</span>
                  </Fade>
                </td>
                <td className={css.customCol4}>
                  <Fade in={data?.state}>
                    <span>{data?.state}</span>
                  </Fade>
                </td>
                <td className={css.customCol5}>
                  <Fade in={data?.zipCode}>
                    <span>{data?.zipCode}</span>
                  </Fade>
                </td>
                <td className={css.customCol6}>
                  <button id={css.editButton} className="btn btn-info h-50" onClick={() => setShow(true)}>
                    EDIT
                  </button>
                </td>
              </tr>
              <tr>
                <th scope="row" className={css.customCol1}>
                  Contact:
                </th>

                <td>
                  Twilio Number:{` `}
                  <Fade in={data?.phoneNumber}>
                    <span>{data?.phoneNumber}</span>
                  </Fade>
                </td>

                <td>
                  <Fade in={data?.practicePhoneNumber}>
                    <span>{data?.practicePhoneNumber}</span>
                  </Fade>
                </td>
                <td colSpan={4}>
                  <Fade in={data?.email}>
                    <span>{data?.email}</span>
                  </Fade>
                </td>
              </tr>
              <tr>
                <th scope="row" className={css.customCol1}>
                  Admin:
                </th>
                <td>
                  <Fade in={user?.lastName}>
                    <span>{capitalizeFirstLetter(user?.lastName)}</span>
                  </Fade>

                  <br />
                  <Fade in={user?.phoneNumber}>
                    <span> {user?.phoneNumber}</span>
                  </Fade>
                </td>
                <td>
                  <Fade in={user?.firstName}>
                    <span> {capitalizeFirstLetter(user?.firstName)}</span>
                  </Fade>

                  <br />
                  <Fade in={user?.email}>
                    <span> {user?.email}</span>
                  </Fade>
                </td>
                <td colSpan={3}>
                  <Fade in={user?.title}>
                    <span> {user?.title}</span>
                  </Fade>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {loading ? <PuffSpinner /> : null}
      </div>
      {show && <EditPractice show={show} setShow={setShow} data={data} setData={setData} />}

    </>
  );
}




