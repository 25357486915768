import React, { useState } from 'react';
import css from './adherenceWidget.module.css';
import { PuffSpinner } from '../../../../../common/components/spinner/puff/puff';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import $ from 'jquery';

$(function () {
  $(`[data-toggle="tooltip"]`).tooltip();
});

export const AdherenceWidget = ({ adherenceArr }) => {
  const [loading] = useState(false);

  return (
    <div id={css.main}>
      <div className="d-flex justify-content-between align-items-center ml-4 mr-4 mt-4">
        <h4 style={{ fontWeight: 400 }}>Adherence</h4>
        <Tooltip
          title={
            <>
              <i style={{ fontSize: `10px` }}>
                Based on CMS <br />
                guidelines,
                <br />
                adherence is
                <br />
                defined as usage
                <br />
                greater than or
                <br />
                equal to 4hrs/night
                <br />
                on 21 days during
                <br />
                the last 30 days.
              </i>
            </>
          }
          placement="top"
          arrow
        >
          <button type="button" data-toggle="tooltip" data-placement="top" title="">
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </button>
        </Tooltip>
      </div>

      <h5 className="mt-2 ml-4" style={{ fontWeight: `bold`, fontSize: `x-small` }}>
        Period
      </h5>
      <h5 className="ml-4" style={{ fontSize: `small`, color: `rgb(201, 201, 201)` }}>
        Last 30 Days
      </h5>

      {/* <div className="m-4" id={css.statsBar}></div> */}
      <div className="m-4">
        <div className="progress" style={{ height: `35px`, borderRadius: `0.35rem` }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${adherenceArr.nonAdherentPercentage ? adherenceArr.nonAdherentPercentage : 0}%`, backgroundColor: `#eb2f06` }}
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${adherenceArr.adherentPercentage ? adherenceArr.adherentPercentage : 0}%`, backgroundColor: `#17a2b8` }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${adherenceArr.notAvailablePercentage ? adherenceArr.notAvailablePercentage : 0}%`, backgroundColor: `#95afc0` }}
            aria-valuenow="20"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-sm table-borderless table-striped">
          <tbody id={css.widgetTable}>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#eb2f06` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?Adherence=nonAdherent`}>Non-Adherent</Link>
              </td>
              <td style={{ fontSize: `small` }}>{adherenceArr.numberOfNonAdherentPatients ? adherenceArr.numberOfNonAdherentPatients : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {adherenceArr.nonAdherentPercentage ? adherenceArr.nonAdherentPercentage : 0}%
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#17a2b8` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?Adherence=adherent`}>Adherent</Link>
              </td>
              <td style={{ fontSize: `small` }}>{adherenceArr.numberOfAdherentPatients ? adherenceArr.numberOfAdherentPatients : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>{adherenceArr.adherentPercentage ? adherenceArr.adherentPercentage : 0}%</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#95afc0` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?Adherence=NO_DATA`}>{`No data > 30 days`}</Link>
              </td>
              <td style={{ fontSize: `small` }}>{adherenceArr.numberOfNotAvailableAdherance ? adherenceArr.numberOfNotAvailableAdherance : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {adherenceArr.notAvailablePercentage ? adherenceArr.notAvailablePercentage : 0}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {loading ? <PuffSpinner /> : null}
    </div>
  );
}

