import { Avatar, Box, Stack } from '@mui/material';
import React from 'react';
import { capitalizeFirstLetter } from 'src/utils';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';

import { momentWrapper } from 'src/momentWrapper';

export const MissedCallNotifications = ({ notifications = [], redirection, icon }) => {
  return <ViewNotifications notifications={notifications} redirection={redirection} icon={icon} />;
};

const ViewNotifications = ({ notifications = [], redirection, icon: Icon }) => {
  return (
    <ConditionallyRenderWrapper
      con={notifications?.length}
      renderKey={<Box sx={{ fontSize: `small`, textAlign: `center`, pb: 1 }}> You don't have any notifications</Box>}
    >
      {notifications?.map((not) => {
        return (
          <Box key={not?._id} sx={{ p: 1, borderBottom: `.5px solid #0000001f` }} onClick={() => redirection(not?.from, not?._id)}>
            <Stack direction="row" spacing={1}>
              <Box>
                <Avatar sx={{ background: `rgb(126, 55, 148)` }}>{`${not?.firstName?.split(``)?.[0]?.toUpperCase()} ${not?.lastName
                  ?.split(``)?.[0]
                  ?.toUpperCase()}`}</Avatar>
              </Box>
              <Box>
                <Box sx={{ fontWeight: 600, fontSize: `small` }}>{capitalizeFirstLetter(`${not?.firstName} ${not?.lastName}`)}</Box>
                <Box sx={{ fontSize: `small` }}>
                  <ConditionallyRenderWrapper con={not?.date || not?.createdAt} renderKey={`-`}>
                    {momentWrapper(not?.date || not?.createdAt).format(`MM/DD/YYYY hh:mm A`)}
                  </ConditionallyRenderWrapper>
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: `1`,
                  textAlign: `end`,
                  alignSelf: `center`,
                  '&:hover': {
                    cursor: `pointer`,
                  },
                }}
              >
                <Icon color={`error`} />
              </Box>
            </Stack>
          </Box>
        );
      })}
    </ConditionallyRenderWrapper>
  );
};
