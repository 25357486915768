import { VIEW, GROUPID, PRACTICEID, PROVIDERID } from '../Types/Analytics.types';

const initialState = {
  view: ``,
  practiceId: ``,
  groupId: ``,
  providerId: ``,
};
export const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW:
      return { ...state, view: action.payLoad };
    case GROUPID:
      return { ...state, groupId: action.payLoad };
    case PRACTICEID:
      return { ...state, practiceId: action.payLoad };
    case PROVIDERID:
      return { ...state, providerId: action.payLoad };
    default:
      return state;
  }
};
