import DOMPurify from 'dompurify';
import React, { useEffect, useRef } from 'react';

export const HtmlFormatter = ({ title }) => {
  const titleRef = useRef(null);
  useEffect(() => {
    if (title) {
      let cleanTitle = DOMPurify.sanitize(title);
      titleRef.current.innerHTML = cleanTitle;
    }
  }, [title]);
  return <div ref={titleRef}></div>;
};
