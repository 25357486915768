import { Box } from '@mui/material'
import React from 'react'
import { ToolTipProvider } from '../components/Tooltip/ToolTipProvider'
import css from './css/trucateWrapper.module.css'
export const TruncateWraper = ({ tag: Tag, children, tagStyles }) => {
    return <ToolTipProvider toolTipProps={{
        arrow: true,
        placement: `top`,
        title: children
    }} element={<Box><Tag className={css.truncateWrapper}  {...(tagStyles ? { style: { ...tagStyles } } : {})} >{children}</Tag></Box>} />


}
