import React from 'react';
import { TelemedicineDialer } from './components/telemedicineDialer/telemedicineDialer';

export const Telemedicine = () => {
  return (
    <>
      <TelemedicineDialer />
    </>
  );
}

