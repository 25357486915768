import { Button, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { useStyles } from './useStyles';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { useSelector } from 'react-redux';
import { GET_PATIENT_WATCHERS, getRequest, GET_ALL_USERS, ASSIGN_PATIENT_WATCHERS, postRequest, UNASSIGN_PATIENT_WATCHERS } from 'src/crud/crud';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { capitalizeFirstLetter, findDiffElemArr, makeFullName } from 'src/utils';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import CloseIcon from '@mui/icons-material/Close';
import { CustomTable } from 'src/pages/common/components/CustomTable/CustomTable';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';

export const ForAssignedUserDetail = ({ modal, setModal, selectedUserFromTable }) => {
  const [loadingState, setLoading] = useState({ error: false, loading: false });
  const [fetchUsers, setFetchUsers] = useState(0);
  const { user } = useSelector((state) => state?.user);
  const { _practiceId } = useSelector((state) => state?.user?.user);
  const _practiceGroupId = useSelector((state) => state?.user?.user?._practiceGroupId);
  const [listOfUsers, setListOfUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(2);
  const { loading } = loadingState;
  const { provider: { _id: primaryProvId } = { _id: `` }, clinicalUser: { _id: primaryClinicalId } = { _id: `` } } = selectedUserFromTable;
  const ComponentStyles = useStyles();

  const loadingSetter = (key, value) => {
    setLoading((p) => ({ ...p, [key]: value }));
  };

  const handleDisableAssignUsers = () => {
    return selectedUsers?.length === 0;
  };

  let payload = {
    userIds: selectedUsers,
    patientIds: [selectedUserFromTable?._id],
    selectAllPatients: false,
    excludedPatientIds: [],
    practiceId: _practiceId,
    practiceGroupId: _practiceGroupId,
  };

  const handleAssignUsers = () => {
    loadingSetter(`error`, false);
    loadingSetter(`loading`, true);
    postRequest(ASSIGN_PATIENT_WATCHERS, payload)
      .then(() => {
        loadingSetter(`loading`, false);
        successToast(`Successfully Assigned`);
        setSelectedUsers([]);
        setFetchUsers((p) => p + 1);
      })
      .catch(() => {
        loadingSetter(`error`, true);
        loadingSetter(`loading`, false);
      });
  };

  const getListOfUsers = () => {
    getRequest(`${GET_ALL_USERS}/${_practiceId}?filterBillerAdmin=true`)
      .then((response) => {
        let mappedList = makeFullName(response?.data?.users, [`_id`]);
        setListOfUsers(mappedList);
      })
      .catch(() => {
        loadingSetter(`error`, true);
        loadingSetter(`loading`, false);
      });
  };

  useEffect(() => {
    if (modal) getListOfUsers();
  }, [modal]);

  const handleUnassignUsers = (mappingUsers, setDepKey) => {
    let payloadForUnassign = {
      patientIds: [selectedUserFromTable?._id],
      userIds: [mappingUsers?._id],
      selectAllPatients: false,
      excludedPatientIds: [],
      practiceId: user?._practiceId,
      practiceGroupId: _practiceGroupId,
    };
    loadingSetter(`error`, false);
    loadingSetter(`loading`, true);
    postRequest(UNASSIGN_PATIENT_WATCHERS, payloadForUnassign)
      .then(() => {
        loadingSetter(`loading`, false);
        successToast(`Successfully UnAssigned`);
        setSelectedUsers([]);
        setDepKey();
      })
      .catch(() => {
        loadingSetter(`error`, true);
        loadingSetter(`loading`, false);
      });
  };

  const rootIds = (arr) => {
    if (Array.isArray(arr) && arr?.length)
      return arr.reduce((prev, next) => {
        return [...prev, next?._id];
      }, []);
  };

  return (
    modal && (
      <CustomizedDialogs
        noTitle={true}
        open={modal}
        setOpen={() => {
          setTotalUsers(2);
          setSelectedUsers([]);
          setModal(false);
        }}
        size="md"
        fullWidth={true}
        showCloseButton={false}
      >
        <Typography variant="h6" component="div">
          Assigned User Detail
        </Typography>

        <Box className={ComponentStyles.assignedUserModalChipStyle}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3} sm={3} md={3}>
              <Typography variant="h6" component="div" sx={{ color: `#7D798C` }}>
                Patient
              </Typography>
              <Typography variant="h6" component="div">
                {capitalizeFirstLetter(selectedUserFromTable?.fullName)}
              </Typography>
            </Grid>

            <Grid item xs={3} sm={3} md={3}>
              <Typography variant="h6" component="div" sx={{ color: `#7D798C` }}>
                Primary Provider
              </Typography>
              <Typography variant="h6" component="div">
                {capitalizeFirstLetter(selectedUserFromTable?.provider?.name)}
              </Typography>
            </Grid>

            <Grid item xs={3} sm={3} md={3}>
              <Typography variant="h6" component="div" sx={{ color: `#7D798C` }}>
                Primary ClinicalStaff
              </Typography>
              <Typography variant="h6" component="div">
                {capitalizeFirstLetter(selectedUserFromTable?.clinicalUser?.name)}
              </Typography>
            </Grid>

            <Grid item xs={3} sm={3} md={3}>
              <Typography variant="h6" component="div" sx={{ color: `#7D798C` }}>
                Total Users
              </Typography>
              <Typography variant="h6" component="div">
                {totalUsers}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h6" component="div" sx={{ mt: 5 }}>
          Assign New User
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={9} sm={9} md={9} sx={{ mt: 1 }}>
            <MuiSelect
              searchInpProps={{
                isSearchAble: true,
                searchKeys: [`fullName`],
              }}
              multiSelectProps={{
                isSelectAll: true,
              }}
              controlProps={{ size: `small`, fullWidth: true, id: `assignUserIdImport` }}
              label="Assign Users"
              isSimple={false}
              options={[...assignedUsers]}
              optionKey={`fullName`}
              optionValue={`_id`}
              selectProps={{
                multiple: true,
                label: `Assign Users`,
                id: `assignUserIdImport`,
                name: `state`,
                onChange: (value) => {
                  setSelectedUsers(value);
                },
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: `420px`, // set the maximum height of the options
                      width: `280px`, // set the width of the options
                    },
                  },
                },
                value: selectedUsers,
              }}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={3} sx={{ mt: 0.8 }}>
            <Button
              variant="contained"
              className={ComponentStyles.containedAssignButton}
              onClick={handleAssignUsers}
              disabled={handleDisableAssignUsers()}
            >
              Assign
            </Button>
          </Grid>
        </Grid>

        <Typography variant="h6" component="div" sx={{ mt: 5 }}>
          Assigned Secondary Users
        </Typography>

        <ConditionallyRenderWrapper con={listOfUsers?.length}>
          <CustomTable
            externalDep={fetchUsers}
            setDataKey={(res) => {
              let prepUsers = res?.data?.users ? rootIds([...res.data.users, { _id: primaryProvId }, { _id: primaryClinicalId }]) : [];
              let resUsers = findDiffElemArr({ arrayToFilter: listOfUsers, arrayFilterFrom: prepUsers, key: `_id` });
              setTotalUsers(res?.data?.users?.length + 2);
              setAssignedUsers(resUsers || []);
              return res?.data?.users;
            }}
            method={`get`}
            route={`${GET_PATIENT_WATCHERS}${selectedUserFromTable?._id}`}
            headers={[`First Name`, `Last Name`, `Title`, `Remove`]}
            keys={[
              {
                key: (obj) => obj?.firstName,
              },
              {
                key: (obj) => obj?.lastName,
              },
              {
                key: (obj) => obj?.title,
              },
              {
                key: (obj, setDepKey) => (
                  <ToolTipProvider
                    toolTipProps={{
                      title: `Un Assign`,
                      placement: `top`,
                      arrow: true,
                    }}
                    element={<CloseIcon onClick={() => handleUnassignUsers(obj, setDepKey)} className={ComponentStyles.iconColor} />}
                  />
                ),
              },
            ]}
            loadingSetter={loadingSetter}
          />
        </ConditionallyRenderWrapper>

        {loading && <PulseSpinner />}
      </CustomizedDialogs>
    )
  );
};
