import { useSelector } from 'react-redux'

export const useUserConditionalRendering = ({ superAdminAndProvider, superAdminAndClinicalStaff, superAdmin, provider,
    clinicalStaff, biller, systemAdmin, groupAdmin }) => {

    const { user: { user: { userRole } } } = useSelector((state) => state)
    let renderContent = {
        superAdminAndProvider: superAdminAndProvider || null,
        superAdminAndClinicalStaff: superAdminAndClinicalStaff || null,
        superAdmin: superAdmin || null,
        provider: provider || null,
        clinicalStaff: clinicalStaff || null,
        biller: biller || null,
        systemAdmin: systemAdmin || null,
        groupAdmin: groupAdmin || null
    }

    return renderContent[userRole]
}