import { getRequest, GET_TASK_NOTIFICATIONS_MISSING } from 'src/crud/crud';
import { store } from '../store';
import { UPDATE_NOTIFY_TASK_ALERT, TASKS_NOTIFY_SINGLE_SAVE, EMPTY_TASK_NOTIFICATION, OPENED_TASK_ID, REMOVE_FROM_TASK_NOTIFICATIONS, SET_NEW_TASKS, SET_SELECTED_TASK, NOTIFY_ALL_TASKS, SET_ALL_TASKS, UPDATE_ALL_TASKS, NOTIFY_TASK, SET_NEW_COMMENT, SAVE_NOTIFY_TASK_ALERT, TASKS_LOADING, DELETE_NOTIFY_TASK_ALERT } from './ticketsCenterTypes';

export const setAddComment = (payload) => {
    return {
        type: SET_NEW_COMMENT,
        payload,
    };
};

export const updateTaskALert = (payLoad) => {
    return {
        type: UPDATE_NOTIFY_TASK_ALERT,
        payLoad: payLoad
    }
}

export const saveInTaskAlerts = (payLoad) => {
    return {
        type: SAVE_NOTIFY_TASK_ALERT,
        payLoad: payLoad
    }
}
export const deleteFromTaskALerts = (payLoad) => {
    return {
        type: DELETE_NOTIFY_TASK_ALERT,
        payLoad: payLoad
    }
}
export const notifyTask = (payLoad) => {
    return {
        type: NOTIFY_TASK,
        payLoad: payLoad
    }
}

export const saveSingleTask = (payLoad) => {
    return {
        type: TASKS_NOTIFY_SINGLE_SAVE,
        payLoad: payLoad
    }
}

export const emptyTaskNotifications = () => {
    return {
        type: EMPTY_TASK_NOTIFICATION,
        payLoad: []
    }
}
export const saveOpenedTaskId = (payLoad) => {
    return {
        type: OPENED_TASK_ID,
        payLoad: payLoad
    }
}

export const removeFromTaskNotifications = (payLoad) => {
    return {
        type: REMOVE_FROM_TASK_NOTIFICATIONS,
        payLoad: payLoad
    }
}

export const tasksLoading = (payLoad) => {
    return {
        type: TASKS_LOADING,
        payLoad: payLoad
    }
}


export const setNewTaskRedux = (payload) => {
    return {
        type: SET_NEW_TASKS,
        payload,
    };
};
export const _setSelectedTask = (payload) => {
    return {
        type: SET_SELECTED_TASK,
        payload,
    };
};
export const setTotalTasks = (payload) => {
    return {
        type: SET_ALL_TASKS,
        payload,
    };
};
export const notifyAllTasks = (payload) => {
    return {
        type: NOTIFY_ALL_TASKS,
        payload,
    };
};

export const updateAllTasks = (payload) => {
    return {
        type: UPDATE_ALL_TASKS,
        payload,
    };
};




export const saveInTaskNotifyArray = () => {
    const { user: { user: { _id: userId } } } = store?.getState() || ``
    return (dispatch) => {
        dispatch(tasksLoading({ loading: true, error: false }))
        let route = `${GET_TASK_NOTIFICATIONS_MISSING}`
        getRequest(route).then((res) => {
            const defaultOb = { res: { data: { data: [] } } }
            const { data: { data: notifications } } = res || defaultOb
            const filteredNotifications = notifications?.filter((notification) => {
                let isLoggedInAssignee = notification?.assignedTo?._id === notification?.assignedBy?._id && notification?.assignedBy?._id === userId && notification?.assignedTo?._id === userId
                return !isLoggedInAssignee || isLoggedInAssignee && !notification?.taskReminderNotificationReceived
            }
            )
            dispatch(notifyTask(filteredNotifications))
            dispatch(tasksLoading({ loading: false, error: false }))
        }).catch(() => dispatch(tasksLoading({ loading: false, error: true })))
    }
}