import React, { useState } from 'react';
import Moment from 'react-moment';
import css from '../components/listAllPractice/listAllPractice.module.css';
import { useDispatch } from 'react-redux';
import { AdvanceFilter } from './Filter';
import { useHistory } from 'react-router-dom';
import { ErrorOutlined } from '@mui/icons-material';
import { Skelton } from 'src/pages/common/components/Skelton/Skelton';
import { Zoom } from '@mui/material';
import { getCounts, getGroupId } from 'src/redux/PracticeGroups/Actions';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';
import { GroupAdminInfo } from './groupAdminInfo';
import { capitalizeFirstLetter, moneyFormatter, printInBrowser } from '../../../../../utils';
let dynamicRow = null;

export const ListAllGroups = () => {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [sortKey, setSortKey] = useState(`createdAt`);
  const [sortOrder, setSortOrder] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [sigleGroupAdminData, setSigleGroupAdminData] = React.useState({});
  const [loading, setLoading] = useState({ loading: false, error: false });
  const dispatch = useDispatch();

  const sortHandler = (key) => {
    //
    setSortKey(key);
    if (sortOrder === -1) setSortOrder(1);
    else setSortOrder(-1);
  };

  const redirectToUserManagment = (groupId, payLoad) => {
    printInBrowser({ key: `rowValues`, value: payLoad })
    if (groupId === `groupInfo`) {
      setSigleGroupAdminData(payLoad);
      setOpen(!open);
    } else {
      dispatch(getGroupId(groupId));
      dispatch(getCounts({ ...payLoad?.counts, totalincomByGroup: payLoad?.incomByGroup?.totalincomByGroup ? payLoad?.incomByGroup?.totalincomByGroup : `` }));
      history.push(`SystemAdmin/Group/practices`);
    }
  };

  dynamicRow = data?.map((row) => {
    const { incomByGroup } = row || {}
    const { totalincomByGroup } = incomByGroup || false
    printInBrowser({ key: `socket`, value: totalincomByGroup })
    return (
      <React.Fragment key={row._id}>
        <Zoom in={data?.length} timeout={500} mountOnEnter unmountOnExit>
          <tr style={{ cursor: `pointer` }}>
            <td style={{ textAlign: `center` }}>{row?.pgNumber}</td>
            <td onClick={() => redirectToUserManagment(row._id, row)}>{capitalizeFirstLetter(row.name)}</td>
            <td style={{ textAlign: `center` }} onClick={() => redirectToUserManagment(`groupInfo`, row)}>
              {row?.groupAdmindata ? capitalizeFirstLetter(row?.groupAdmindata?.firstName + ` ` + row?.groupAdmindata?.lastName) : `-`}
            </td>
            <td style={{ textAlign: `center` }}>
              <Moment date={row.createdAt} format="MM/DD/YYYY" />
            </td>
            <td style={{ textAlign: `center` }}>{row?.counts?.activePractices}</td>
            <td style={{ textAlign: `center` }}>{row?.counts?.activeProviders}</td>
            <td style={{ textAlign: `center` }}>{row?.counts?.activePatients}</td>
            <td style={{ textAlign: `center` }}>{row?.counts?.inactivePatients}</td>
            <td style={{ textAlign: `center` }}>{row?.counts?.openedTickets}</td>
            <td style={{ textAlign: `center` }}>{totalincomByGroup ? moneyFormatter(totalincomByGroup) : `-`}</td>
          </tr>
        </Zoom>
      </React.Fragment>
    );
  });
  const renderTablleBody = () => {
    if (loading?.error) {
      return (
        <tr>
          <td colSpan="10" style={{ textAlign: `center` }}>
            <ErrorOutlined sx={{ fontSize: `medium`, color: `red` }} />
          </td>
        </tr>
      );
    } else if (loading?.loading) {
      return <Skelton width={20} isGreater={true} />;
    } else if (data?.length) {
      return dynamicRow;
    } else {
      return (
        <tr>
          <td colSpan="10" style={{ textAlign: `center` }}>
            <span>No Record Found</span>
          </td>
        </tr>
      );
    }
  };

  printInBrowser({ key: `pagination`, value: { cP: currentPage, tp: totalPage } })
  return (
    <div id={css.main} style={{ position: `relative` }}>
      <AdvanceFilter
        rowPerPage={rowsPerPage}
        page={currentPage}
        dataHandler={setData}
        loadingHandler={setLoading}
        totalRowHandler={setTotalRows}
        totalPageHandler={setTotalPage}
        sortOrder={sortOrder}
        sortKey={sortKey}
        data={data}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
      />
      <div className="table-responsive">
        <table className="table table-sm table-borderless table-striped">
          <thead>
            <tr
              style={{
                width: `10%`,
                textAlign: `center`,
                verticalAlign: `middle`,
              }}
            >
              <th scope="col" style={{ width: `10%`, cursor: `pointer`, minWidth: `106px` }}>
                {/* onClick={() => sortHandler('pid')} */}
                ID
              </th>
              <th
                scope="col"
                style={{
                  width: `45%`,
                  textAlign: `start`,
                  cursor: `pointer`,
                  minWidth: `374px`,
                }}
                onClick={() => sortHandler(`name`)}
              >
                Group Name
                {sortKey === `name` && sortOrder === 1 ? (
                  <i className="fa fa-sort-asc ml-2" />
                ) : sortKey === `name` && sortOrder === -1 ? (
                  <i className="fa fa-sort-desc ml-2" />
                ) : (
                  <i className="fa fa-sort ml-2" />
                )}
              </th>
              <th scope="col" style={{ width: `45%`, minWidth: `200px` }}>
                Group Admin
              </th>
              <th scope="col" style={{ width: `20%`, cursor: `pointer`, minWidth: `210px` }} onClick={() => sortHandler(`createdAt`)}>
                Registration Date
                {sortKey === `createdAt` && sortOrder === 1 ? (
                  <i className="fa fa-sort-asc ml-2" />
                ) : sortKey === `createdAt` && sortOrder === -1 ? (
                  <i className="fa fa-sort-desc ml-2" />
                ) : (
                  <i className="fa fa-sort ml-2" />
                )}
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                # Of Practices
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                # Of Providers
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                # Of Active Patients
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                # Of In-Active Patients
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                # Of Open Tickets
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                Income By Group
              </th>
            </tr>
          </thead>
          <tbody>{renderTablleBody()}</tbody>
          <TableFooter
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            setRowsPerPage={setRowsPerPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            isPatientView={false}
            data={data}
          />
        </table>
      </div>

      <GroupAdminInfo setOpen={setOpen} open={open} sigleGroupAdminData={sigleGroupAdminData} />
    </div>
  );
}

