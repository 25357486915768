
import { LOGOUT } from './types';


export const logoutHandlerS = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: ``,
  });
};
