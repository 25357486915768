/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import NavigateNextSharpIcon from '@mui/icons-material/NavigateNextSharp';
import NavigateBeforeSharpIcon from '@mui/icons-material/NavigateBeforeSharp';
import { momentWrapper } from 'src/momentWrapper';
import { getRequest } from 'src/crud/crud';
import { errorToast } from 'src/pages/common/components/snackBar/toast';

export const PastDataNavigation = ({ url, auth, setData, userId, telemedicinePatientId, loading, setLoading, isCurrent, setisCurrent, selectedPatient, setFromDate, setToDate, fromDate, toDate, }) => {
  const [isPre, setisPre] = useState(``);
  let navigationMonthYear = {
    month: momentWrapper(fromDate).format(`MMMM`),
    year: momentWrapper(fromDate).year(),
  };
  let oneDate = momentWrapper();
  let currentYear = momentWrapper(oneDate).year();
  let currentMonth = oneDate.format(`MMMM`);

  let endDateNavigation = {
    month: momentWrapper(toDate).format(`MMMM`),
    year: momentWrapper(toDate).year(),
  };
  useEffect(() => {
    if (isPre === `pre` || isPre === `next`) {
      setToDate(momentWrapper(fromDate).add(5, `months`).startOf(`month`).format(`YYYY-MM`));
      setisCurrent(`no`);
      setTimeout(() => {
        setisCurrent(``);
      }, 500);
    }
  }, [fromDate, isPre, setToDate, isCurrent, setisCurrent]);

  useEffect(() => {
    const getPastData = () => {
      const dates = {
        startDate: selectedPatient ? fromDate : null,
        endDate: selectedPatient ? toDate : null,
      };
      if (isCurrent === `no`) {
        if (userId || telemedicinePatientId) {
          setLoading(true);
          getRequest(url)
            .then((response) => {
              setLoading(false);

              setData(response.data.reports);
            })
            .catch((err) => {
              setLoading(false);
              // errorToast(err.response?.data?.message);
            });
        }
      } else {
        return null;
      }
    }
    getPastData();
  }, [url, toDate, fromDate, isCurrent, auth, userId, telemedicinePatientId, selectedPatient, setLoading, setData]);

  const dateHandler = (isPrevious) => {
    if (isPrevious) {
      setisPre(`pre`);
      setFromDate(momentWrapper(fromDate).subtract(6, `months`).startOf(`month`).format(`YYYY-MM`));
    } else {
      setisPre(`next`);
      setFromDate(momentWrapper(fromDate).add(6, `months`).startOf(`month`).format(`YYYY-MM`));
    }
  };

  return (
    <Grid container>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Tooltip title="Previous" placement="top" arrow>
          <IconButton
            disabled={typeof loading == `undefined` ? true : loading ? true : false}
            onClick={() => {
              dateHandler(true);
            }}
          >
            <NavigateBeforeSharpIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid sx={{ textAlign: `end` }} item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Tooltip title="Next" placement="top" arrow>
          <IconButton
            disabled={
              (navigationMonthYear.month === currentMonth && navigationMonthYear.year === currentYear) ||
                (endDateNavigation.month === currentMonth && endDateNavigation.year === currentYear) ||
                momentWrapper(toDate).isAfter(momentWrapper(oneDate)) ||
                typeof loading == `undefined` ||
                loading
                ? true
                : false
            }
            onClick={() => {
              dateHandler(false);
            }}
          >
            <NavigateNextSharpIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
