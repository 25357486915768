import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ConversationListItem } from '../ConversationListItem/ConversationListItem';
import { ConversationSearch } from '../ConversationSearch';
import './ConversationList.module.css';

export const ConversationList = ({ isFromView }) => {
  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  // Conversation List of User
  const conversationList = useSelector((state) => state.messenger.conversationList);
  // Current User
  const user = useSelector((state) => state.user.user);
  const individualUser = useSelector((state) => state.messenger.individualUser);

  useEffect(() => {
    let newConversations = conversationList
      .map((result) => ({

        name: `${result.userName}`,
        receiverId: result._id,
        practiceId: result._practiceId,
        socketId: result.socketId,
        senderId: user._id,
        text: individualUser[result._id].lastMessage ? individualUser[result._id].lastMessage : `No conversation found`,
        isOnline: individualUser[result._id].isOnline ? individualUser[result._id].isOnline : false,
        _chatRoomId: result._chatRoomId ? result._chatRoomId : null,
        isMessageRead: individualUser[result._id].isMessageRead,
        status: result.status,
        isFile: !!individualUser[result._id].isFile,
        type: individualUser[result._id].type ? individualUser[result._id].type : false
      }))
      .filter((result) => result.status === `active`);

    setConversations(newConversations);
    setFilteredConversations(newConversations);
  }, [individualUser]);

  //
  return (
    <div className="conversationListComponent" style={{ height: isFromView ? `46vh` : `88vh`, overflowY: `auto` }}>
      <div
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
          marginTop: `7px`,
          paddingLeft: `10px`,
          paddingRight: `10px`,
        }}
      >
        <span
          style={{
            fontSize: `20px`,
            fontWeight: 700,
          }}
        >
          Chats
        </span>
      </div>
      <ConversationSearch allUser={conversations} filteredUserHandler={setFilteredConversations} />
      {filteredConversations.map((conversation) => {
        return <ConversationListItem key={conversation.receiverId} data={conversation} roomIdList={conversationList} />;
      })}
    </div>
  );
}
