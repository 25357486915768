import React from 'react';
import { Grid, MenuItem, TextField, ListSubheader } from '@mui/material';
import { resmedDeviceDetail, respironicsDeviceType, reactHealthDeviceType, allPatientStatusFilter } from 'src/utils';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';

export const Filters = ({ filtersObj, setFilters, validate, setFilterFlag, setPagination }) => {
  const onChangeHandler = (e, key) => {
    let { value } = e.target;
    setFilters((p) => ({ ...p, [key]: value }));
  };
  React.useEffect(() => {
    return () => {
      setFilters({
        search: ``,
        manufacturer: ``,
        tier: ``,
        reportStatus: ``,
        isAdherent: ``,
        status: ``,
      });
    };
  }, []);
  return (
    <Grid container spacing={1.5} sx={{ p: 1.5 }}>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          onChange={(e) => onChangeHandler(e, `manufacturer`)}
          label="Manufacturer"
          fullWidth
          type="text"
          id="manufacturer"
          name="manufacturer"
          select
          value={filtersObj?.manufacturer}
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value="respironics">Respironics</MenuItem>
          <MenuItem value="resmed">ResMed</MenuItem>
          <MenuItem value="reactHealth">React Health</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          select
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          onChange={(e) => onChangeHandler(e, `search`)}
          label="Device Type"
          fullWidth
          type="text"
          id="search"
          name="search"
          value={filtersObj?.search}
        >
          <MenuItem value="">Select</MenuItem>
          <ListSubheader>ResMed</ListSubheader>
          {resmedDeviceDetail.map((e) => (
            <MenuItem disabled={filtersObj?.manufacturer === `respironics` || filtersObj?.manufacturer === `reactHealth` ? true : false} key={e} value={e}>
              {e}
            </MenuItem>
          ))}
          <ListSubheader>Respironics</ListSubheader>
          {respironicsDeviceType.map((e) => (
            <MenuItem disabled={filtersObj?.manufacturer === `resmed` || filtersObj?.manufacturer === `reactHealth` ? true : false} key={e} value={e}>
              {e}
            </MenuItem>
          ))}
          <ListSubheader>React Health</ListSubheader>
          {reactHealthDeviceType.map((mappedDeviceType) => (
            <MenuItem disabled={filtersObj?.manufacturer === `respironics` || filtersObj?.manufacturer === `resmed` ? true : false} key={mappedDeviceType} value={mappedDeviceType}>
              {mappedDeviceType}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          onChange={(e) => onChangeHandler(e, `tier`)}
          label="Risk"
          fullWidth
          type="text"
          id="riskPatientOverview"
          name="riskPatientOverview"
          value={filtersObj?.tier}
          select
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value="VERY HIGH RISK">Very High</MenuItem>
          <MenuItem value="HIGH RISK">High</MenuItem>
          <MenuItem value="MODERATE RISK">Medium</MenuItem>
          <MenuItem value="LOW RISK">Low</MenuItem>
          <MenuItem value="NO_DATA">No Data</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          label="Adherence"
          fullWidth
          select
          id="isAdherent"
          name="isAdherent"
          value={filtersObj?.isAdherent || ``}
          onChange={(e) => onChangeHandler(e, `isAdherent`)}
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value={`Adherent`}>Adherent</MenuItem>
          <MenuItem value={`Non-Adherent`}>Non-Adherent</MenuItem>
          <MenuItem value={`No-Data`}>No Data</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <MuiSelect
          controlProps={{ size: `small`, fullWidth: true, id: `allPatientStatusFilter` }}
          label="Patient Status"
          isSimple={false}
          options={[...allPatientStatusFilter]}
          optionKey={`label`}
          optionValue={`value`}
          selectProps={{
            label: `Patient Status`,
            id: `allPatientStatusFilter`,
            name: `selectedStatus`,
            onChange: (event) => {
              onChangeHandler(event, `status`);
            },
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: `420px`, // set the maximum height of the options
                  width: `280px`, // set the width of the options
                },
              },
            },
            value: filtersObj?.status,
          }}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          label="Reports"
          fullWidth
          select
          id="reports"
          name="reports"
          value={filtersObj?.reportStatus}
          onChange={(e) => onChangeHandler(e, `reportStatus`)}
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value="readyToSign">Ready to Sign</MenuItem>
          <MenuItem value="signed">Signed Report</MenuItem>
          <MenuItem value="no_report">No Report</MenuItem>
        </TextField>
      </Grid>
      <Grid sx={{ textAlign: `end` }} item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ToolTipProvider
          toolTipProps={{
            title: `Filter`,
            placement: `top`,
            arrow: true,
          }}

          element={
            <button
              style={{
                width: `70px`,
              }}
              onClick={() => {
                let hasValidated = validate();
                if (hasValidated) {
                  setFilterFlag((p) => p + 1);
                  setPagination((p) => ({ ...p, sortField: `lastName`, sortOrder: `ASC`, currentPage: 1, rowsPerPage: 25 }));
                }
              }}
              className={`btn btn-sm btn-outline-primary`}
            >
              Filter
            </button>
          }

        />
      </Grid>
    </Grid >
  );
}
