import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import css from '../components/listAllPractice/advanceFilter/advanceFilter.module.css';
import { getRequest, GET_ALL_GROUPS } from 'src/crud/crud';
import HomeIcon from '@mui/icons-material/Home';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { Box, Fade, IconButton } from '@mui/material';
import { DatePickerCustomizeForFilter } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import momentOriginal from 'moment';
import { InputField } from 'src/pages/common/components/InputField/InputField';

export const AdvanceFilter = ({
  rowPerPage,
  page,
  dataHandler,
  loadingHandler,
  totalRowHandler,
  totalPageHandler,
  sortKey,
  sortOrder,
  setCurrentPage,
  setRowsPerPage,
}) => {
  const { resmed: { addNewPractice }, user: { auth } } = useSelector((state) => state)
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState(``);
  const [startDate, setStartDate] = useState(``);
  const [endDate, setEndDate] = useState(``);

  const [flag, setFlag] = useState(0);
  const refreshGroups = useSelector((state) => state?.practiceGroup?.isRegistered);
  let payLoad = {
    page,
    ...(rowPerPage && { recordsPerPage: rowPerPage }),
    sortField: JSON.stringify(sortKey),
    sortOrder: sortOrder,
    filters: {
      ...(startDate && { startDate: momentOriginal(startDate).format(`YYYY-MM-DD`) }),
      ...(endDate && { endDate: momentOriginal(endDate).format(`YYYY-MM-DD`) }),
      ...(name && { name: name }),
    },
  };

  const queryParams = (queryObj) => {
    //
    let queryString = ``;
    for (const item in queryObj) {
      if (item === `page`) {
        queryString += `?${item}=${queryObj[item]}`;
      } else if (item !== `filters`) {
        queryString += `&${item}=${queryObj[item]}`;
      } else if (item === `filters`) {
        queryString += `&${item}=${JSON.stringify(queryObj.filters)}`;
      } else if (item == `sort`) {
        queryString += `&${item}=${queryObj[item]}`;
      }
    }

    return queryString;
  };

  useEffect(() => {
    const getGroups = async () => {
      let query = queryParams(payLoad);
      let navigateHome = document.getElementById(`groupsNavigateHomeButon`);
      if (refreshGroups || auth || query) {
        loadingHandler({ loading: true, error: false });
        getRequest(GET_ALL_GROUPS + query)
          .then((res) => {
            if (
              Object.keys(payLoad?.filters).length &&
              navigateHome &&
              document.getElementById(`groupsNavigateHomeButon`).style.display === `none` &&
              flag > 0
            ) {
              document.getElementById(`groupsNavigateHomeButon`).style.display = `block`;
            }
            loadingHandler({ loading: false, error: false });
            dataHandler(res?.data?.data);
            totalRowHandler(res?.data?.totalRecords);
            if (Math.ceil(res?.data?.totalRecords / res?.data?.recordsPerPage)) {
              totalPageHandler(Math.ceil(res?.data?.totalRecords / res?.data?.recordsPerPage));
            }
          })
          .catch(() => {
            loadingHandler({ loading: false, error: true });

          });
      }
    };
    getGroups();
  }, [flag, rowPerPage, page, sortKey, sortOrder, addNewPractice, auth, refreshGroups]);

  const resetHandler = () => {
    setStartDate(``);
    setEndDate(``);
    setName(``);
  };

  const filterHandler = () => {
    const getValues = [startDate?.toString(), endDate?.toString(), name];
    const checkInputs = getValues.filter((e) => {
      return e?.length > 0;
    });

    if (checkInputs?.length > 0) {
      setFlag((p) => p + 1);
    }
  };

  return (
    <div>
      <div className="collapse" id="advanceFilterCard">
        <div
          style={{
            backgroundColor: `white`,
            padding: `5% 2%`,
            margin: `2% 0%`,
          }}
        >
          <div className="w-100">
            <div className="d-flex justify-content-center">
              <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                <ToolTipProvider
                  toolTipProps={{
                    arrow: true,
                    placement: `top`,
                    title: `Start Date`,
                  }}
                  element={
                    <DatePickerCustomizeForFilter
                      dateInputValue={startDate}
                      setDateInputValue={setStartDate}
                    />
                    // <input
                    //   autoComplete="off"
                    //   onChange={(event) => setStartDate(event.target.value)}
                    //   value={startDate}
                    //   type={`date`}
                    //   // onFocus={() => setStartDate(new Date())}
                    //   style={{ width: `100%`, padding: `2%` }}
                    //   placeholder="Start Date"
                    // />
                  }
                />
              </div>
              <div className={css.widthCustom}>
                <ToolTipProvider
                  toolTipProps={{
                    arrow: true,
                    placement: `top`,
                    title: `End Date`,
                  }}
                  element={
                    <DatePickerCustomizeForFilter
                      dateInputValue={endDate}
                      setDateInputValue={setEndDate}
                    />
                  }
                />
              </div>
              <div className={css.widthCustom}>
                <InputField
                  fullWidth={true}
                  size='small'
                  autoComplete="off"
                  onChange={(event) => setName(event.target.value)}
                  value={name}
                  type="text"
                  label="Group Name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {window.innerWidth > 768 ? (
        <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontWeight: `400`, marginLeft: `3%` }}>Registered Groups</h4>
          <div className="d-flex justify-content-end">
            <Fade in={flag !== 0} mountOnEnter unmountOnExit>
              <Box>
                <ToolTipProvider
                  toolTipProps={{
                    title: `Navigate to default page!`,
                    placement: `top`,
                    arrow: true,
                  }}
                  element={
                    <IconButton
                      id="groupsNavigateHomeButon"
                      onClick={() => {
                        document.getElementById(`groupsNavigateHomeButon`).style.display = `none`;
                        if (startDate?.toString()?.length > 0) {
                          setStartDate(``);
                        }
                        if (endDate?.toString()?.length > 0) {
                          setEndDate(``);
                        }
                        if (name?.length > 0) {
                          setName(``);
                        }

                        setFlag((p) => p + 1);
                        setCurrentPage(1);
                        setRowsPerPage(10);
                      }}
                      sx={{ color: `rgb(22, 153, 197)`, marginRight: `9px`, padding: `0px` }}
                    >
                      <HomeIcon sx={{ fontSize: `40px` }} />
                    </IconButton>
                  }
                />
              </Box>
            </Fade>
            <button className={visible ? css.customResetButton : `d-none`} type="button" onClick={resetHandler}>
              <i className="fa fa-times"></i> Reset
            </button>
            <button className={visible ? css.customApplyButton : `d-none`} type="button" onClick={filterHandler}>
              <i className="fa fa-search"></i> Apply
            </button>
            <button
              className={css.customFilterButton}
              type="button"
              data-toggle="collapse"
              data-target="#advanceFilterCard"
              onClick={() => setVisible(!visible)}
            >
              <i className="fa fa-filter"></i> Filter
            </button>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontWeight: `400`, fontSize: 15, marginLeft: `6%` }}>Registered Groups</h4>
          <div className="d-flex justify-content-end" style={{ marginRight: `6%` }}>
            <Fade in={flag !== 0} mountOnEnter unmountOnExit>
              <Box sx={{ display: `inline-block` }}>
                <ToolTipProvider
                  toolTipProps={{
                    title: `Navigate to default page!`,
                    placement: `top`,
                    arrow: true,
                  }}
                  element={
                    <IconButton
                      id="groupsNavigateHomeButon"
                      onClick={() => {
                        document.getElementById(`groupsNavigateHomeButon`).style.display = `none`;

                        if (startDate?.toString()?.length > 0) {
                          setStartDate(``);
                        }
                        if (endDate?.toString()?.length > 0) {
                          setEndDate(``);
                        }
                        if (name?.length > 0) {
                          setName(``);
                        }

                        setFlag((p) => p + 1);
                        setCurrentPage(1);
                        setRowsPerPage(10);
                      }}
                      sx={{ color: `rgb(22, 153, 197)` }}
                    >
                      <HomeIcon />
                    </IconButton>
                  }
                />
              </Box>
            </Fade>
            <button className={visible ? null : `d-none`} type="button" onClick={resetHandler}>
              <i className="fa fa-times" style={{ color: `#c41717` }}></i>
            </button>
            <button className={visible ? null : `d-none`} type="button" onClick={filterHandler}>
              <i className="fa fa-search" style={{ color: `green` }}></i>
            </button>
            <button type="button" data-toggle="collapse" data-target="#advanceFilterCard" onClick={() => setVisible(!visible)}>
              <i className="fa fa-filter" style={{ color: `#1699C5` }}></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};



