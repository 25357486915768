import React, { useEffect, useState, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SplitForm } from './SplitForm';
import config from 'src/config.js';
import css from './paymentInfo.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { CREATE_CUSTOMER, postRequest } from 'src/crud/crud';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { setUserHandler } from 'src/redux/action/setUserHandler';
import { BillingHistoryContext } from 'src/pages/home/modules/administration/payment/billingHistory';
import { setAllPractices } from 'src/redux/practices/practiceActions';
import { TwillioNumProtectionWrapper } from 'src/pages/common/HOC/TwillioNumProtectionWrapper';

const stripePromise = loadStripe(config.STRIPE_PUBLISH_KEY);
export const PaymentInfo = () => {
  const context = useContext(BillingHistoryContext);
  const {
    user: { user },
    practice: { practiceInfo: practice },
  } = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!context.isCardUpdate && !user.stripeCustomerId) {
      let payLoad = {
        email: practice?.email ? practice?.email : user?.practiceEmail,
      };
      postRequest(CREATE_CUSTOMER, payLoad)
        .then((res) => {
          setLoading(false);
          if (user?.isGroupAdmin) {
            dispatch(setAllPractices({ ...practice, stripeCustomerId: res.data.id }));
          } else {
            dispatch(setUserHandler({ ...user, stripeCustomerId: res.data.id }));
          }
        })
        .catch(() => setLoading(false)
        );
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <TwillioNumProtectionWrapper>
      <div>
        {loading && <PulseSpinner />}
        <Elements stripe={stripePromise}>
          <div id={css.practiceContainer}>
            <div className="table-responsive" style={{ borderRadius: `10px 10px 10px 10px` }}>
              <table className="table table-striped">
                <thead id={css.head}>
                  <tr>
                    <td id={css.headTextContainer} colSpan={3}>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 id={css.headText}>On board (using Card or ACH direct debit)</h4>
                      </div>
                    </td>
                  </tr>
                </thead>
                <SplitForm />
              </table>
            </div>
          </div>
        </Elements>
      </div>
    </TwillioNumProtectionWrapper>
  );
};
