import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import css from './login.module.css';
import {
  VERIFY_OTP, postRequest, LOGIN_URL, LOGIN_BY_ROLE,
  CHECK_MULTILPLE_SESSIONS
} from '../../../crud/crud';
import { PulseSpinner } from '../../common/components/spinner/spinner';
import { Logo } from 'src/pages/common/components/Logo/Logo';
import { UserRoles } from 'src/router/UserRoles/UserRoles';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { CustomBtn } from 'src/pages/common/components/Button/CustomBtn';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { Box, Zoom } from '@mui/material';
import { SERVER_ADDRESS } from 'src/crud/axiosClient';
import { ToastContainer } from 'react-toastify';
import { userRoleDecider, userAuthHandler, getTwillioVoiceAuth } from 'src/utils';
import { FooterWrapper } from 'src/pages/common/HOC/FooterWrapper';
import { ConfirmationWrapper } from 'src/pages/common/HOC/ConfirmationWrapper';

export const Login = ({ isSwitch }) => {
  let history = useHistory();
  const [userName, setUserName] = useState(``);
  // For Username Field Error State.
  const [userNameError, setUserNameError] = useState({
    status: false,
    msg: ``,
  });
  // For Password Field Error State.
  const [passwordError, setPasswordError] = useState({
    status: false,
    msg: ``,
  });
  const [password, setPassword] = useState(``);
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState(false);
  const [otpFlag, setOtpFlag] = useState(false);
  const [otp, setOtp] = useState(``);
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState(``);
  const [userData, setUserData] = useState([]);
  const [destroyMultipleSessionConf, setDestroyMultipleSessionConf] = useState(false);

  useEffect(() => {
    if (userData?.systemRoles?.length === 1 && !isSwitch) {
      setLoading(true);
      postRequest(LOGIN_BY_ROLE + userData?.systemRoles[0]?._practiceId?._id, { uniqueString: userData?.uniqueString, userId: userData?._id })
        .then((res) => {
          setLoading(false);
          let userRole = userRoleDecider({ ...res?.data?.user });
          userAuthHandler({
            token: res?.data?.token,
            userProps: {
              ...res?.data?.user,
              systemRoles: userData?.systemRoles,
              refreshToken: res?.data?.refreshToken,
              userRole: userRole,
              enableSwitch: false,
              practiceLogoPath: `${SERVER_ADDRESS}${userData?.systemRoles[0]?._practiceId?.practiceLogoPath}`,
            },
          });
          successToast(`You Are Logged In!`);
          history.push(`/`)
          getTwillioVoiceAuth(res?.data?.user)
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [userData]);

  const validateInput = () => {
    // Check Username if Password Field is empty.
    let tempForUserName = { status: false, msg: `` };
    if (!userName) {
      tempForUserName.status = true;
      tempForUserName.msg = `Username is required`;
      setUserNameError(tempForUserName);
    } else {
      setUserNameError(tempForUserName);
    }

    // Check Password Field if Password Field is empty.
    let tempForPassword = { status: false, msg: `` };
    if (!password) {
      tempForPassword.status = true;
      tempForPassword.msg = `Password is required`;
      setPasswordError(tempForPassword);
    } else {
      setPasswordError(tempForPassword);
    }
  };

  const wrappWithSessionsCheck = (callFun) => {
    setLoading(true);
    postRequest(CHECK_MULTILPLE_SESSIONS, { username: userName, password: password })
      .then((res) => {
        const { isAlreadyLoggedIn } = res.data || false;
        if (isAlreadyLoggedIn) {
          setDestroyMultipleSessionConf((p) => !p);
        } else {
          callFun();
        }
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  const actionHandler = (userData, token) => {
    if (!userData?.isSystemAdministrator && !userData?.isGroupAdmin) {
      setUserData(userData);
    } else {
      let userRole = userRoleDecider({ ...userData });
      userAuthHandler({ token, userProps: { ...userData, userRole: userRole, enableSwitch: false } });
      history.push(`/`)
    }
  };
  const verifyOtpHandler = () => {
    postRequest(VERIFY_OTP, { username: userName, password: password, otp: otp })
      .then((res) => {
        const { data = {} } = res;
        const { signedTokenData = {} } = data;
        const { userData = {}, token = ``, refreshToken = `` } = signedTokenData || false;
        actionHandler({ ...userData, refreshToken: refreshToken }, token);
      })
      .catch((err) => {
        setOtpError(true);
        setOtpErrorMessage(err?.response?.data?.error);
        setLoading(false);
      });
  };

  const multipleSessionsDestroyer = () => {
    postRequest(LOGIN_URL, { username: userName, password: password })
      .then((res) => {
        if (res?.data?.otp === true) {
          setOtpFlag(true);
          setLoading(false);
        } else {
          const { data = {} } = res;
          const { signedTokenData } = data;
          const { userData = {}, token = `` } = signedTokenData;
          const { refreshToken = `` } = signedTokenData;
          setLoading(false);
          actionHandler({ ...userData, refreshToken: refreshToken }, token);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 409 && err?.response?.data?.passwordResetRequired) {
          let userId = err?.response?.data?.userId;
          let secretToken = err?.response?.data?.secretToken;
          history.push(`/ninetyDaysResetPassword/${secretToken}/${userId}`);
        }
        setError(true);
        setLoading(false);
      });
  };

  const otpHandler = (event) => {
    event.preventDefault();
    setError(false);
    wrappWithSessionsCheck(verifyOtpHandler);
  };

  const loginHandler = (event) => {
    event.preventDefault();
    // Check username status is true to stop the API call.
    let tempForUserName = { status: false, msg: `` };
    if (!userName) {
      tempForUserName.status = true;
      tempForUserName.msg = `Username is required`;
      setUserNameError(tempForUserName);
      return;
    }
    // Check password status is true to stop the API call.
    if (passwordError?.status) {
      return;
    }
    setError(false);
    wrappWithSessionsCheck(multipleSessionsDestroyer);
  };

  const hasData = useMemo(() => {
    let data = typeof userData === `object` && Object.keys(userData);
    let returnValue = false;
    if (data?.length && userData?.systemRoles?.length > 1) {
      returnValue = true;
    }
    return returnValue;
  }, [userData]);

  return (
    <ConfirmationWrapper
      description={`You have already logged in from another session if you want to proceed all other sessions will be logged out !`}
      title={`Multiple Sessions!`}
      inverse={true}
      open={destroyMultipleSessionConf}
      successText="Yes"
      cancelText={`No`}
      successFunc={() => {
        if (otpFlag) verifyOtpHandler();
        else multipleSessionsDestroyer();
        setDestroyMultipleSessionConf((p) => !p);
      }}
      cancelFunc={() => {
        setLoading(false);
        setDestroyMultipleSessionConf((p) => !p);
      }}
    >
      {!hasData ? (
        <FooterWrapper>
          <Box>
            <ToastContainer />
            {loading && <PulseSpinner />}
            <div>
              <div id={css.container}>
                <Logo isFromRoles={false} />
                {error ? (
                  <div id={css.bannerError}>
                    <p id={css.bannerErrorText}>Invalid Credentials.</p>
                  </div>
                ) : null}
                {otpError && (
                  <div id={css.bannerError}>
                    <p id={css.bannerErrorText}>{otpErrorMessage || null}</p>
                  </div>
                )}
                <form id={css.form}>
                  <Zoom in={!hasData} mountOnEnter unmountOnExit style={{ transitionDelay: `100ms` }}>
                    <div>
                      <InputField
                        fullWidth={true}
                        sx={{ mt: 2 }}
                        InputLabelProps={{
                          style: {
                            left: `-1.5%`,
                          },
                        }}
                        size={`small`}
                        helperText={userNameError.msg}
                        error={!!userNameError.status}
                        type={`text`}
                        label={`Username`}
                        disabled={otpFlag}
                        onChange={(textFieldChangingEvent) => {
                          setUserName(textFieldChangingEvent?.target?.value);
                          setError(false);
                        }}
                        onBlur={() => validateInput()}
                        value={userName}
                      />
                    </div>
                  </Zoom>

                  <Zoom in={!hasData} mountOnEnter unmountOnExit style={{ transitionDelay: `300ms` }}>
                    <div>
                      <InputField
                        fullWidth={true}
                        sx={{
                          mt: 2,
                        }}
                        InputLabelProps={{
                          style: {
                            left: `-1.5%`,
                          },
                        }}
                        size={`small`}
                        helperText={passwordError.msg}
                        error={!!passwordError.status}
                        type={`password`}
                        label={`Password`}
                        disabled={otpFlag}
                        onChange={(textFieldChangingEvent) => {
                          setPassword(textFieldChangingEvent?.target?.value);
                          setError(false);
                        }}
                        onBlur={() => validateInput()}
                        value={password}
                      />
                    </div>
                  </Zoom>

                  {otpFlag && (
                    <InputField
                      sx={{ mt: 2 }}
                      InputLabelProps={{
                        style: {
                          left: `-1.5%`,
                        },
                      }}
                      size={`small`}
                      type={`text`}
                      label={`OTP`}
                      onChange={(textFieldChangingEvent) => {
                        setOtp(textFieldChangingEvent?.target?.value);
                      }}
                      value={otp}
                    />
                  )}

                  {otpFlag ? (
                    <div id={css.buttonHolder}>
                      <CustomBtn
                        element="VERIFY"
                        btnProps={{
                          onClick: otpHandler,
                          id: css.button,
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <Zoom in={!hasData} mountOnEnter unmountOnExit style={{ transitionDelay: `350ms` }}>
                        <div>
                          <Link to="/forget">Forgot Username or Password</Link>
                          <div id={css.remember}>
                            <input type="checkbox" name="remember" onClick={() => setRemember(!remember)} />
                            <label htmlFor="remember">Remember me</label>
                          </div>
                        </div>
                      </Zoom>

                      <Zoom in={!hasData} mountOnEnter unmountOnExit style={{ transitionDelay: `400ms` }}>
                        <div id={css.buttonHolder}>
                          <CustomBtn
                            element="LOGIN"
                            btnProps={{
                              onClick: loginHandler,
                              id: css.button,
                            }}
                          />
                        </div>
                      </Zoom>
                    </>
                  )}
                </form>
              </div>
            </div>
          </Box>
        </FooterWrapper>
      ) : (
        <UserRoles userData={userData} isSwitch={!!isSwitch} />
      )}
    </ConfirmationWrapper>
  );
};
