import React, { useEffect, useRef, useState } from 'react';
import { Paper, Typography, Button, Grid, Table, TableBody, TableCell, TableRow, TableContainer } from '@mui/material';
import { GET_ALL_USERS, GET_LIST_ALL_USERS, UNASSIGN_PATIENT_WATCHERS, getRequest, postRequest } from 'src/crud/crud';
import { useSelector } from 'react-redux';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { useStyles } from './useStyles';
import { makeFullName } from 'src/utils';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { sortIconHandler, sortKeyGiver } from '../patient2/PatientOverview/PatientOverView';
import { UserTableHeader, UserTableRow } from './userTable';
import { TablePagination } from './forUserTablePagination';

export const ForDeleteSecondaryUser = () => {
  const [listOfUsers, setListOfUsers] = useState([]);
  const [fetchUsers, setFetchUsers] = useState(0);
  const [listOfUnassignUsers, setListOfUnassignUsers] = useState([]);
  const [loadingState, setLoading] = useState({ error: false, loading: false });
  const { loading } = loadingState;
  const { user } = useSelector((state) => state?.user);
  const _practiceGroupId = useSelector((state) => state?.user?.user?._practiceGroupId);
  const [searchUserId, setSearchUserId] = useState(``);
  const [MDData, setMDData] = useState([]);
  const [assignMD, setAssignMD] = useState(``);
  const [selectAllPatients, setIsSelectAllPatients] = useState(false);
  const [excludedPatientIds, setExcludedPatientIds] = useState([]);
  const countRef = useRef(0);

  const [sortKey, setSortKey] = useState(`lastName`);
  const [sortOrder, setSortOrder] = useState(`ASC`);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  const ComponentStyles = useStyles();

  const loadingSetter = (key, value) => {
    setLoading((p) => ({ ...p, [key]: value }));
  };

  const updateSelectedOfUsers = (userId, flag) => {
    if (flag) {
      setListOfUnassignUsers([...listOfUnassignUsers, userId]);
    } else {
      let result = listOfUnassignUsers.filter((user) => {
        return user !== userId;
      });
      setListOfUnassignUsers([...result]);
    }
  };

  const updateSelectAllUsers = (listOfUsers, flag) => {
    const userId = listOfUsers?.map((user) => user?._id);
    if (flag) {
      setListOfUnassignUsers(userId);
    } else {
      setListOfUnassignUsers([]);
    }
  };

  const sortHandler = (order, key) => {
    setSortKey(key);
    setSortOrder(order);
  };

  let passArgs = { sortOrder: sortOrder, sortField: sortKey };

  let result;

  if (sortOrder === `ASC`) {
    result = `ASC`;
  } else if (sortOrder === `DSC`) {
    result = `DESC`;
  } else {
    result = ``;
  }

  let payload = {
    searchFilters: {
      secondaryUserName: assignMD,
    },
    pageNumber: currentPage,
    pageSize: rowsPerPage,
    sortField: sortKey,
    sortOrder: result,
  };

  useEffect(() => {
    if (countRef.current >= 1 || fetchUsers) {
      loadingSetter(`error`, false);
      loadingSetter(`loading`, true);
      if (!user?.isGroupAdmin) {
        postRequest(GET_LIST_ALL_USERS, payload)
          .then((response) => {
            loadingSetter(`loading`, false);
            setListOfUsers(response?.data?.data);
            setTotalRows(response?.data?.pager?.totalRecords);
            if (Math.ceil(response?.data?.pager?.totalRecords / response?.data?.pager?.recordsPerPage)) {
              setTotalPage(Math.ceil(response?.data?.pager?.totalRecords / response?.data?.pager?.recordsPerPage));
            }
            setSearchUserId(response?.data?.searchedUserId);
          })
          .catch(() => {
            loadingSetter(`error`, true);
            loadingSetter(`loading`, false);
          });
      }
    }
  }, [rowsPerPage, currentPage, countRef.current, sortKey, sortOrder, fetchUsers]);

  useEffect(() => {
    if (!MDData.length) {
      getRequest(`${GET_ALL_USERS}/${user._practiceId}?filterBillerAdmin=true`)
        .then((response) => {
          let mappedList = makeFullName(response?.data?.users, [`_id`]);
          setMDData(mappedList);
        })
        .catch(() => {
          errorToast(`Unable to Fetch Data`);
        });
    }
  }, []);

  const handleUnassignedUsers = () => {
    let payloadForUnassign = {
      patientIds: listOfUnassignUsers,
      userIds: [searchUserId],
      selectAllPatients: selectAllPatients,
      excludedPatientIds: excludedPatientIds,
      practiceId: user?._practiceId,
      practiceGroupId: _practiceGroupId,
    };
    loadingSetter(`error`, false);
    loadingSetter(`loading`, true);
    postRequest(UNASSIGN_PATIENT_WATCHERS, payloadForUnassign)
      .then(() => {
        loadingSetter(`loading`, false);
        successToast(`Successfully UnAssigned`);
        setListOfUnassignUsers([]);
        setIsSelectAllPatients(false);
        setFetchUsers((p) => p + 1);
      })
      .catch(() => {
        loadingSetter(`error`, true);
        loadingSetter(`loading`, false);
      });
  };

  const handleDisableButton = () => {
    return !(listOfUnassignUsers?.length > 0 || selectAllPatients);
  };

  const handleClick = () => {
    if (selectAllPatients) {
      setExcludedPatientIds([]);
    }
    setIsSelectAllPatients(!selectAllPatients);
  };

  return (
    <>
      <Paper sx={{ borderRadius: `20px`, boxShadow: `0px 4px 8px rgba(1, 1, 1, 0.2)`, marginBottom: `40px` }}>
        <Typography variant="h6" component="div" sx={{ pt: 6, pl: 6, pb: 6 }}>
          Remove Assignee
        </Typography>

        <Grid container>
          <Grid item xs={4} sm={4} md={4} sx={{ ml: 6, pl: 6, pb: 6 }}>
            <MuiSelect
              isSimple={false}
              optionKey={`fullName`}
              optionValue={`fullName`}
              options={[...MDData]}
              controlProps={{
                id: `addPatientAssignMd`,
                fullWidth: true,
                size: `small`,
              }}
              label="Select User"
              selectProps={{
                label: `Select User`,
                id: `addPatientAssignMd`,
                onChange: (event) => {
                  setAssignMD(event.target.value);
                  countRef.current += 1;
                },
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: `420px`, // set the maximum height of the options
                      width: `280px`, // set the width of the options
                    },
                  },
                },
                value: assignMD,
                name: `assignMD`,
              }}
            />
          </Grid>

          <Grid item xs={2} sm={2} md={2}></Grid>

          <Grid item xs={4} sm={4} md={4}>
            <Button variant="contained" className={ComponentStyles.containedButton} disabled={handleDisableButton()} onClick={handleUnassignedUsers}>
              Unassign User
            </Button>

            {selectAllPatients ? (
              <Button sx={{ ml: 8 }} variant="contained" onClick={handleClick} className={ComponentStyles.containedButtonInTable}>
                DeSelect All Patients
              </Button>
            ) : (
              <Button
                sx={{ ml: 8 }}
                variant="contained"
                onClick={handleClick}
                disabled={!!(!listOfUsers?.length > 0 || listOfUnassignUsers?.length > 0)}
                className={ComponentStyles.containedButtonInTable}
              >
                Select All Patients
              </Button>
            )}
          </Grid>
        </Grid>

        <TableContainer>
          <Table>
            <UserTableHeader
              updateSelectAllUsers={updateSelectAllUsers}
              listOfUsers={listOfUsers}
              sortHandler={sortHandler}
              sortKeyGiver={sortKeyGiver}
              passArgs={passArgs}
              sortIconHandler={sortIconHandler}
              selectAllPatients={selectAllPatients}
            />
            <TableBody>
              {listOfUsers?.length ? (
                listOfUsers?.map((mappingUsers, index) => (
                  <UserTableRow
                    key={index?._id}
                    index={index}
                    user={mappingUsers}
                    listOfTransferUsers={listOfUnassignUsers}
                    updateSelectedOfUsers={updateSelectedOfUsers}
                    selectAllPatients={selectAllPatients}
                    excludedPatientIds={excludedPatientIds}
                    setExcludedPatientIds={setExcludedPatientIds}
                    showViewModal={false}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {countRef.current === 0 ? `Please select a user to perform the search.` : `No data available`}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalRows}
              setRowsPerPage={setRowsPerPage}
              totalPage={totalPage}
              isPatientView={false}
              hasStyle={true}
              data={listOfUsers}
            />
          </Table>
        </TableContainer>
      </Paper>

      {loading && <PulseSpinner />}
    </>
  );
};
