import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PracticeMessageCard } from 'src/pages/home/modules/patient2/components/patientCommunication/message/card';
import { Box, Fade } from '@mui/material';
import { SendMsg } from 'src/pages/home/modules/patient2/components/patientCommunication/message/SendMsg';
import { _twilioOutGoingSMS } from 'src/redux/twilio/twilioActions';
import { messagesApiCall, setAllMessages } from 'src/redux/patient/patientActions';
import { chatSkelton } from 'src/pages/home/modules/patient2/components/patientCommunication/message/message';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import axios from 'axios'

export const PatientMessenger = () => {
  const { patientId = ``, patientNumber = `` } = useSelector((state) => state?.readyToSign?.readyToSignModal);
  const messages = useSelector((state) => state.patient.messages);
  const msgLoading = useSelector((state) => state.patient.messageLoadingFlag);
  const chatBox = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (chatBox.current && messages?.length > 1) chatBox.current.scrollTop = chatBox.current.scrollHeight;
  }, [messages]);

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (patientId) dispatch(messagesApiCall(patientId, source.token));
    return () => {
      dispatch(setAllMessages([]))
      source.cancel()
    }
  }, [patientId]);



  const hasDataCome = !msgLoading.loading && !msgLoading.error

  return (
    <Box sx={{ border: `1px solid #2299c5`, borderRadius: `5px` }}>
      <Box
        sx={{
          backgroundColor: `rgb(22, 153, 197)`,
          color: `white`,
          padding: `10px`,
          fontFamily: `sans-serif`,
          textAlign: `start`,
          borderTopLeftRadius: `4px`,
          borderTopRightRadius: `4px`,
        }}
      >
        Patient Communication
      </Box>
      {msgLoading.error && (
        <Box sx={{ width: `100%`, textAlign: `center` }}>
          <ReportProblemIcon sx={{ color: `red` }} />
        </Box>
      )}
      {msgLoading.loading ? <Box sx={{ height: `44.3vh`, overflow: `auto` }}>{chatSkelton}</Box>
        : hasDataCome && (
          <Box ref={chatBox} sx={{ height: `44.3vh`, overflowY: `scroll` }}>
            {messages?.map((m, i) => {
              return (
                <Fade in={m} key={i + m + `key`}>
                  <Box key={i + m + `key1`}>
                    <PracticeMessageCard i={i} msg={m} />
                  </Box>
                </Fade>
              );
            })}
          </Box>
        )}
      <Box sx={{ mt: 1 }}>
        <SendMsg
          slectedPhraseId={`patientMessengerPatientOverViewPhrases`}
          patientId={patientId}
          twilioOutGoingSMS={(payLoad) => dispatch(_twilioOutGoingSMS(payLoad))}
          overView={true}
          chatBox={chatBox}
          conditionalStyles={{ bottom: `132px`, width: `24%`, left: `1.6%`, position: `absolute` }}
          patientNumber={patientNumber}
        />
      </Box>
    </Box>
  );
};
