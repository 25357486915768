import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Box, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { ConditionallyRenderWrapper } from './ConditionallyRenderWrapper';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { defFontColor } from 'src/utils';
import { MuiPopOver } from '../components/MuiPopOver/MuiPopOver';
import { ToolTipProvider } from '../components/Tooltip/ToolTipProvider';

export const ReactModalWrapper = ({
  hasBlurredBg = false,
  children,
  size = '50%',
  modalContStyles = { backgroundColor: 'aliceblue' },
  parentId,
  shouldOpen = false,
  title = 'Title',
  onClose,
  shouldMove = false,
}) => {
  const defModalPos = {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
  const [modalPostion, setModalPostion] = useState({
    ...defModalPos,
  });
  const [modalId, setModalId] = useState('');
  const [openRotations, setOpenRotations] = useState(false);
  const [anchorElem, setAnchorElem] = useState(null);
  const renderCount = useRef(0);

  const prepareMcontStyles = {
    ...(modalContStyles && { ...modalContStyles }),
    padding: '10px',
    borderRadius: '10px',
    width: size,
    position: `fixed`,
    ...modalPostion,
    zIndex: '10000',
  };
  const removeElem = () => {
    console.log('iamcaledddd');
    let elemToRem = document.getElementById(parentId);
    if (elemToRem?.innerHTML) {
      console.log('iamcaledddd', elemToRem.innerHTML);
      document.body.removeChild(elemToRem);
      setModalId('');
      setAnchorElem(null);
      setOpenRotations(false);
      setModalPostion({ ...defModalPos });
      renderCount.current = 0;
    }
  };

  useEffect(() => {
    if (shouldOpen) {
      const elem = document.createElement('div');
      elem.setAttribute('id', parentId);
      document.body.appendChild(elem);
      setModalId(parentId);
      renderCount.current = renderCount.current + 1;
    }
    return () => removeElem();
  }, [parentId, shouldOpen]);

  const onClosePopOver = () => {
    setOpenRotations((p) => !p);
    setAnchorElem(null);
  };

  const modalPositionHandler = (position) => {
    console.log(position, 'hmmPos');
    if (position === 'def') {
      setModalPostion(defModalPos);
    } else {
      let split = position.split(' ');
      setModalPostion({ [split[0]]: '5%', [split[1]]: '5%' });
    }
    setOpenRotations((p) => !p);
  };

  console.log(openRotations, 'openRotations');
  return modalId ? (
    ReactDOM.createPortal(
      <BlurredBg shouldBlur={hasBlurredBg}>
        <Box sx={{ ...prepareMcontStyles }}>
          <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
            <Box sx={{ fontWeight: 600 }}>{title}</Box>
            <ConditionallyRenderWrapper con={!!onClose}>
              <Box>
                <IconButton onClick={onClose} size="large" color={'error'}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </ConditionallyRenderWrapper>
          </Stack>
          <Box>{children}</Box>
          <ConditionallyRenderWrapper con={shouldMove}>
            <Box sx={{ position: 'absolute', bottom: '5px', right: '10px', zIndex: '10000' }}>
              <ToolTipProvider
                toolTipProps={{
                  title: 'Moving options',
                  PopperProps: {
                    style: {
                      zIndex: 1000000,
                    },
                  },
                }}
                element={
                  <IconButton
                    size="small"
                    variant="contained"
                    sx={{
                      backgroundColor: defFontColor,

                      color: 'white',
                      '&:hover': {
                        backgroundColor: defFontColor,
                        color: 'white',
                      },
                    }}
                    onClick={(e) => {
                      setOpenRotations((p) => !p);
                      setAnchorElem(e.currentTarget);
                    }}
                  >
                    <UnfoldMoreIcon sx={{ fontSize: '15px' }} />
                  </IconButton>
                }
              />
              <MuiPopOver sx={{ zIndex: 1000000 }} open={openRotations} onClose={onClosePopOver} anchorEl={anchorElem}>
                <MenuItem selected={modalPostion?.top === '50%' && modalPostion.left === '50%'} onClick={() => modalPositionHandler('def')}>
                  Default Postion
                </MenuItem>
                <MenuItem selected={modalPostion?.top === '5%' && modalPostion.right === '5%'} onClick={() => modalPositionHandler('top right')}>
                  Top-right
                </MenuItem>
                <MenuItem selected={modalPostion?.top === '5%' && modalPostion.left === '5%'} onClick={() => modalPositionHandler('top left')}>
                  Top-left
                </MenuItem>
                <MenuItem
                  selected={modalPostion?.bottom === '5%' && modalPostion.right === '5%'}
                  onClick={() => modalPositionHandler('bottom right')}
                >
                  Bottom-right
                </MenuItem>
                <MenuItem selected={modalPostion?.bottom === '5%' && modalPostion.left === '5%'} onClick={() => modalPositionHandler('bottom left')}>
                  Bottom-left
                </MenuItem>
              </MuiPopOver>
            </Box>
          </ConditionallyRenderWrapper>
        </Box>
      </BlurredBg>,
      document.getElementById(parentId)
    )
  ) : (
    <></>
  );
};

const BlurredBg = ({ children, shouldBlur }) => {
  return (
    <Box
      {...(shouldBlur && {
        sx: {
          width: '100%',
          height: '100vh',
          top: '50%',
          left: '50%',
          WebkitTransform: 'translate(-50%, -50%)',
          MozTransform: 'translate(-50%, -50%)',
          MsTransform: 'translate(-50%, -50%)',
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          zIndex: '10000',
          backdropFilter: 'blur(10px)',
        },
      })}
    >
      {children}
    </Box>
  );
};

ReactModalWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  shouldOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
};
