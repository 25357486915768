import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Compose } from '../Compose/compose';
import Toolbar from '../Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import css from './MessageList.module.css';
import { Message } from '../Message/Message';
import Spinner from 'react-spinners/PuffLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { _recentMessage, _updateMessageReadStatus } from '../../../../../../redux/messenger/messengerActions';
import { userInfoNotify } from 'src/utils';
import { PreviewMmsFile } from '../Message/PreviewMmsFile';
import { errorToast } from 'src/pages/common/components/snackBar/toast';

export const MessageList = ({ isFromView, patientName }) => {
  const individualUser = useSelector((state) => state.messenger.individualUser);
  const selectedUserRedux = useSelector((state) => state.messenger.selectedUser);
  const selectedUserReduxRef = useRef(selectedUserRedux);
  const listOfPatients = useSelector((state) => state.messenger.listOfPatients);
  const [messages, setMessages] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const pageNumberRef = useRef(pageNumber);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const paginationLoaderRef = useRef(paginationLoader);
  const [conversationAvailable, setConversationAvailable] = useState(false);
  const conversationAvailableRef = useRef(conversationAvailable);
  const dispatch = useDispatch();

  useEffect(() => {
    selectedUserReduxRef.current = selectedUserRedux;
    if (selectedUserRedux.length > 0) {
      setSpinner(true);
      newSocket.emit(`getAllMessages`, {
        senderId: individualUser[selectedUserRedux].senderId,
        practiceId: individualUser[selectedUserRedux].practiceId,
        receiverId: individualUser[selectedUserRedux].receiverId,
        socketId: individualUser[selectedUserRedux].socketId,
        chatId: individualUser[selectedUserRedux]._chatRoomId,
        pageNumber: 1,
      });
    }

    listOfPatients.map((patient) => {
      patient[`id`] = patient._id;
      patient[`display`] = `${patient.firstName} ${patient.lastName}`;
    });

    newSocket.on(`getAllMessages`, (data) => {
      setSpinner(false);
      data = data.map((msg) => {
        return { ...msg, status: `sent` };
      });
      if (data.length > 0) {
        if (data && data[0]?._chatRoomId && data[0]?._chatRoomId == individualUser[selectedUserRedux]?._chatRoomId) {
          setMessages((prevState) => {
            return [...prevState, ...data];
          });
        }
      }
      if (pageNumberRef.current > 1 && data.length === 0) {
        setConversationAvailable(true);
        conversationAvailableRef.current = true;
      } else {
        setConversationAvailable(false);
        conversationAvailableRef.current = false;
      }
      setPaginationLoader(false);
      paginationLoaderRef.current = false;
    });

    return () => {
      setMessages([]);
      setPageNumber(1);
      setPaginationLoader(false);
      setConversationAvailable(false);
      pageNumberRef.current = 1;
      newSocket.off(`getAllMessages`);
    };
  }, [selectedUserRedux]);

  useEffect(() => {
    newSocket.on(`inboundMessage`, async (data) => {
      let remap = await data.map((iteration) => {
        return { ...iteration, ...(iteration?.type === `mms` ? { status: `realTimeReceived` } : {}) };
      });
      updateMessages([...remap]);
    });
  }, []);

  useEffect(() => {
    newSocket.on(`deleteMessage`, (deletedMessage) => {
      setMessages((p) => {
        return p ? [...p.filter((msg) => msg && msg._id !== deletedMessage._id)] : [];
      });
    })
  }, []);

  useEffect(() => {
    // SET USER MESSAGE READ STATUS THROUGH SOCKET
    newSocket.on(`isReadStatus`, (data) => {
      dispatch(_updateMessageReadStatus(data));
    });
  }, []);

  const dispatchMostRecentMsg = (payLoad) => {
    dispatch(
      _recentMessage({
        ...payLoad,
      })
    );
  };
  console.log(messages, `whataremessages`)
  const newMessages = (msg, cb) => {
    if (newSocket?.connected) {
      const targetChatRoomIdObj = individualUser[selectedUserRedux];
      const msgSend = [
        {
          ...msg[0],
          _chatRoomId: targetChatRoomIdObj?._chatRoomId,
          ...(userInfoNotify()
            ? {
              ...userInfoNotify(),
            }
            : {}),
        },
      ];
      newSocket.emit(`chatMessage`, msgSend);
      setMessages((prevState) => [...msg, ...prevState]);
      dispatchMostRecentMsg({
        message: msg[0].message,
        id: msg[0]._receiverId,
        _chatRoomId: targetChatRoomIdObj?._chatRoomId,
      });
      if (cb) cb();
    } else errorToast(`Please try again after a while!`);
  };

  const updateMessages = (data) => {
    if (data && data.length > 0 && data[0]._senderId && data[0]._senderId == selectedUserReduxRef.current) {
      setMessages((prevState) => {
        return [...data, ...prevState];
      });
    }
  };
  const loadMore = () => {
    if (!conversationAvailable) {
      setPageNumber((prevState) => {
        pageNumberRef.current = prevState + 1;
        return pageNumberRef.current;
      });
      setPaginationLoader(true);
      newSocket.emit(`getAllMessages`, {
        senderId: individualUser[selectedUserRedux].senderId,
        practiceId: individualUser[selectedUserRedux].practiceId,
        receiverId: individualUser[selectedUserRedux].receiverId,
        socketId: individualUser[selectedUserRedux].socketId,
        chatId: individualUser[selectedUserRedux]._chatRoomId,
        pageNumber: pageNumberRef.current,
      });
    }
  };

  return (
    <div className={isFromView ? css.viewMessagesList : css.messageList}>
      <div style={{ height: `6vh`, backgroundColor: `white` }}>
        <Toolbar />
      </div>

      <div
        id="scrollableDiv"
        style={{
          height: isFromView ? `38vh` : `68vh`,
          overflow: `auto`,
          display: `flex`,
          flexDirection: `column-reverse`,
          padding: `10px`,
        }}
      >
        {spinner ? (
          <div
            style={{
              display: `flex`,
              height: `100%`,
              justifyContent: `center`,
              width: `100%`,
              alignItems: `center`,
            }}
          >
            <Spinner size={150} color={`#6dd0f2`} loading={true} />
          </div>
        ) : (
          <>
            <InfiniteScroll
              dataLength={messages.length}
              next={loadMore}
              style={{
                display: `flex`,
                flexDirection: `column-reverse`,
                overflow: `hidden`,
              }} //To put endMessage and loader to the top.
              inverse={true}
              hasMore={true}
              loader={
                paginationLoader && (
                  <div style={{ display: `flex`, width: `100%`, justifyContent: `center` }}>
                    <CircularProgress style={{ height: `25px`, width: `25px` }} />
                  </div>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              {messages?.length > 0 &&
                messages.map((message, index) => {
                  const { type = `text` } = message;
                  const isMine = message._receiverId === selectedUserRedux;
                  const timeStamp = message.createdAt;
                  const startsSequence = true;
                  const endsSequence = true;
                  const showTimestamp = true;
                  const msgType = type?.toLowerCase();
                  return (
                    <div key={index}>
                      {msgType === `mms` ? (
                        <PreviewMmsFile setMessages={setMessages}>
                          {{ ...message, ...(message?.isMine ? { ...message?.isMine } : { isMine: isMine }) }}
                        </PreviewMmsFile>
                      ) : (
                        <Message
                          last="no"
                          key={index?._id}
                          isMine={isMine}
                          startsSequence={startsSequence}
                          endsSequence={endsSequence}
                          showTimestamp={showTimestamp}
                          data={message.message}
                          timeStamp={timeStamp}
                        />
                      )}
                    </div>
                  );
                  // }
                })}
              {conversationAvailable && (
                <center>
                  <p>No more Conversation Available</p>
                </center>
              )}
            </InfiniteScroll>
          </>
        )
        }
      </div>
      <div style={{ height: `10vh` }}>
        {!spinner && individualUser[selectedUserRedux]?.practiceId ? (
          <Compose
            dispatchMostRecentMsg={dispatchMostRecentMsg}
            patientName={patientName}
            newMsgHandler={newMessages}
            selectedUser={individualUser[selectedUserRedux].receiverId}
            practiceId={individualUser[selectedUserRedux].practiceId}
            senderId={individualUser[selectedUserRedux].senderId}
            socketId={individualUser[selectedUserRedux].socketId}
            listOfPatients={listOfPatients}
            chatId={individualUser[selectedUserRedux]._chatRoomId}
            isFromView={isFromView}
            setMessages={setMessages}
            messages={messages}
          />
        ) : null}
      </div>
    </div>
  );
};
