import React, { useRef, useState } from 'react';
import { RiskCategory } from './riskCategory/RiskCategory';
import { AdherenceCategory } from './adherenceCategory/AdherenceCategory';
import { Efficiency } from './efficiency/Efficiency';
import { Grid, Slide } from '@mui/material';

export const RemoteMonitoringAnalytics = () => {
  const [clicked, setClicked] = useState(false);
  const containerRef = useRef(null);
  return (
    <React.Fragment ref={containerRef}>
      <div className="d-flex justify-content-between w-100 mt-4">
        <h4 style={{ fontWeight: 625 }}>Remote Patient Monitoring Analytics</h4>
        <button type="button" onClick={() => setClicked((p) => !p)} style={{ outline: `none` }}>
          <i className={clicked ? `fa fa-chevron-up` : `fa fa-chevron-down`} aria-hidden="true"></i>
        </button>
      </div>
      <hr className="w-100 m-0" />
      {/* style={{display : 'block'}}  very important*/}

      <Slide in={clicked} direction="up" timeout={600} mountOnEnter unmountOnExit container={containerRef.current}>
        <Grid sx={{ mt: 1 }} container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            {` `}
            <RiskCategory clicked={clicked} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <AdherenceCategory clicked={clicked} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            {` `}
            <Efficiency clicked={clicked} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Monitored clicked={clicked} />
          </Grid> */}
        </Grid>
      </Slide>
    </React.Fragment>
  );
}

