import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  LIST_ALL_USER_URL, postRequest, LIST_ALL_USER_URL_PRACTICE, getRequest,
  LIST_ALL_USER_URL_PRACTICE_GROUP,
} from '../../../../../../../../crud/crud';
import { Fade, Box, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { userStatus } from 'src/utils';

const styles = {
  inputStyles: {
    '& .MuiOutlinedInput-root': {
      color: `white`,
      '& fieldset': {
        borderColor: `white`,
      },
      '&:hover fieldset': {
        borderColor: `white`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `white`,
      },
    },
  },
  selectInputSyles: {
    color: `white`,
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: `white`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `white`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `white`,
    },
    '.MuiSvgIcon-root ': {
      fill: `white !important`,
    },
  },
  inputLabelProps: {
    style: {
      color: `white`,
      left: `-2%`,
    },
  },
  selectLabelStyles: {
    color: `white`,
  },
};
const { inputStyles, inputLabelProps: labelProps, selectLabelStyles, selectInputSyles } = styles;

export const AdvanceFilter = ({
  rowPerPage,
  page,
  dataHandler,
  loadingHandler,
  totalRowHandler,
  totalPageHandler,
  sortKey,
  sortOrder,
  setCurrentPage,
  setRowsPerPage,
}) => {
  const {
    user: { user },
    practice: { practiceInfo: practice },
    resmed: { addNewUser },
  } = useSelector((state) => state);
  let location = useLocation();
  const [firstName, setFirstName] = useState(``);
  const [lastName, setLastName] = useState(``);
  const [title, setTitle] = useState(``);
  const [npi, setNPI] = useState(``);
  const [role, setRole] = useState(``);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isProvider, setIsProvider] = useState(false);
  const [isClinicalStaff, setIsClinicalStaff] = useState(false);
  const [isBillerRole, setIsBillerRole] = useState(false);
  const [patients, setPatients] = useState(``);
  const [status, setStatus] = useState(``);
  const [addedBy, setAddedBy] = useState(``);
  const groupId = useSelector((state) => state?.practiceGroup?.groupId);
  const _practiceGroupId = user?._practiceGroupId;
  const [flag, setFlag] = useState(0);

  //
  let payLoad = {
    page,
    ...(rowPerPage && { recordsPerPage: rowPerPage }),
    sort: JSON.stringify({ [sortKey]: sortOrder }),
    filters: {
      ...(firstName?.length > 0 && { firstName: firstName }),
      ...(lastName?.length > 0 && { lastName: lastName }),
      // ...(status?.length > 0 && { status: status }),
      status: status,
      ...(patients?.length > 0 && { patients: patients }),
      ...(title?.length > 0 && { title: title }),
      ...(addedBy?.length > 0 && { created_by: addedBy }),
      ...(npi?.length > 0 && { NPI: npi }),
      ...(isProvider && { isProvider: isProvider }),
      ...(isSuperAdmin && { isSuperAdmin: isSuperAdmin }),
      ...(isClinicalStaff && { isClinicalStaff: isClinicalStaff }),
      ...(isBillerRole && { isBiller: isBillerRole }),
    },
    ...(user?.isGroupAdmin && { _practiceGroupId: _practiceGroupId }),
  };

  let payLoadForOthers = {
    page,
    ...(rowPerPage && { recordsPerPage: rowPerPage }),
    sort: JSON.stringify({ [sortKey]: sortOrder }),
    ...(firstName?.length > 0 && { firstName: firstName }),
    ...(lastName?.length > 0 && { lastName: lastName }),
    status: status,
    ...(patients?.length > 0 && { patients: patients }),
    ...(title?.length > 0 && { title: title }),
    ...(addedBy?.length > 0 && { created_by: addedBy }),
    ...(npi?.length > 0 && { NPI: npi }),
    ...(isProvider && { isProvider: isProvider }),
    ...(isSuperAdmin && { isSuperAdmin: isSuperAdmin }),
    ...(isClinicalStaff && { isClinicalStaff: isClinicalStaff }),
    ...(isBillerRole && { isBiller: isBillerRole }),
    ...(user?.isSystemAdministrator ? { _practiceGroupId: groupId } : { _practiceGroupId: _practiceGroupId }),
  };

  const queryParams = (queryObj) => {
    let queryString = ``;
    for (const item in queryObj) {
      if (item === `page`) {
        queryString += `?${item}=${queryObj[item]}`;
      } else if (item === `filters`) {
        queryString += `&${item}=${JSON.stringify(queryObj.filters)}`;
      } else {
        queryString += `&${item}=${queryObj[item]}`;
      }
    }

    return queryString;
  };

  const enableHomeButton = (obj) => {
    let navigateHome = document.getElementById(`listAlluserNavigaeHome`);
    if (Object.keys(obj).length && navigateHome && document.getElementById(`listAlluserNavigaeHome`).style.display === `none` && flag > 0) {
      document.getElementById(`listAlluserNavigaeHome`).style.display = `inline-block`;
    }
  };

  const npiMask = (val) => {
    let temp;
    let x = val.replace(/\D/g, ``).match(/(\d{0,10})/);
    if (!x[2]) {
      temp = x[1];
    } else {
      temp = `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    }
    setNPI(temp);
  };

  useEffect(() => {
    loadingHandler(true);
    let statusRole = location?.pathname.split(`/`)[1];
    let query = queryParams(payLoad);
    if (statusRole === `systemAdmin`) {
      postRequest(LIST_ALL_USER_URL_PRACTICE + practice?._id, payLoadForOthers)
        .then((res) => {
          enableHomeButton(payLoad.filters);
          dataHandler(res?.data?.users?.userDataArrayWithNumberOfDays);
          totalRowHandler(res?.data?.users?.pager?.totalRecords);
          if (Math.ceil(res?.data?.users?.pager?.totalRecords / res?.data?.users?.pager?.recordsPerPage)) {
            totalPageHandler(Math.ceil(res?.data?.users?.pager?.totalRecords / res?.data?.users?.pager?.recordsPerPage));
          }
          loadingHandler(false);
        })
        .catch(() => {
          loadingHandler(false);
        });
    } else if (user?.isGroupAdmin) {
      enableHomeButton(payLoad.filters);
      getRequest(LIST_ALL_USER_URL_PRACTICE_GROUP + practice._id + query)
        .then((res) => {
          dataHandler(res.data.users.userDataArrayWithNumberOfDays);
          totalRowHandler(res?.data?.users?.pager?.totalRecords);
          if (Math.ceil(res.data.users.pager.totalRecords / res.data.users.pager.recordsPerPage)) {
            totalPageHandler(Math.ceil(res.data.users.pager.totalRecords / res.data.users.pager.recordsPerPage));
          }
          loadingHandler(false);
        })
        .catch((err) => {
          loadingHandler(false);
        });
    } else {
      enableHomeButton(payLoad.filters);
      postRequest(LIST_ALL_USER_URL, payLoadForOthers)
        .then((res) => {
          dataHandler(res?.data?.users?.userDataArrayWithNumberOfDays);
          totalRowHandler(res?.data?.users?.pager?.totalRecords);
          if (Math.ceil(res?.data?.users?.pager?.totalRecords / res?.data?.users?.pager?.recordsPerPage)) {
            totalPageHandler(Math.ceil(res?.data?.users?.pager?.totalRecords / res?.data?.users?.pager?.recordsPerPage));
          }
          loadingHandler(false);
        })
        .catch(() => {
          loadingHandler(false);
        });
    }
  }, [flag, user, rowPerPage, page, sortKey, sortOrder, addNewUser]);
  const checkAndSetValues = () => {
    if (addedBy?.length) {
      setAddedBy(``);
    }
    if (patients?.length) {
      setPatients(``);
    }
    if (isClinicalStaff) {
      setIsClinicalStaff(``);
    }
    if (isBillerRole) {
      setIsBillerRole(false);
    }
    if (isProvider) {
      setIsProvider(false);
    }
    if (isSuperAdmin) {
      setIsSuperAdmin(false);
    }
    if (role?.length) {
      setRole(``);
    }
    if (npi?.length) {
      setNPI(``);
    }
    if (title?.length) {
      setTitle(``);
    }
    if (lastName?.length) {
      setLastName(``);
    }
    if (firstName?.length) {
      setFirstName(``);
    }
    if (status?.length) {
      setStatus(``);
    }
  };
  const resetHandler = () => {
    setFirstName(``);
    setLastName(``);
    setTitle(``);
    setNPI(``);
    setRole(``);
    setIsSuperAdmin(false);
    setIsProvider(false);
    setIsClinicalStaff(false);
    setIsBillerRole(false);
    setPatients(``);
    setStatus(``);
    setAddedBy(``);
  };

  const selectRoleHandler = (role) => {
    switch (role) {
      case `Admin`:
        setIsClinicalStaff(false);
        setIsProvider(false);
        setIsBillerRole(false);
        setIsSuperAdmin(true);
        setRole(role);
        break;
      case `Provider`:
        setIsSuperAdmin(false);
        setIsClinicalStaff(false);
        setIsProvider(true);
        setIsBillerRole(false);
        setRole(role);
        break;
      case `Clinical Staff`:
        setIsSuperAdmin(false);
        setIsProvider(false);
        setIsClinicalStaff(true);
        setIsBillerRole(false);
        setRole(role);
        break;
      case `Biller`:
        setIsSuperAdmin(false);
        setIsProvider(false);
        setIsBillerRole(true);
        setRole(role);
        break;
      default:
        setIsClinicalStaff(false);
        setIsProvider(false);
        setIsBillerRole(false);
        setIsSuperAdmin(false);
        setRole(``);
        break;
    }
  };

  const filterHandler = () => {
    setCurrentPage(1);
    setFlag((val) => val + 1);
  };

  return (
    <tr className="collapse" id="advanceUserFilterCard">
      <th scope="col"></th>
      <th scope="col">
        <InputField
          InputLabelProps={{
            ...labelProps,
          }}
          sx={{ ...inputStyles }}
          label="Last Name"
          size="small"
          fullWidth={true}
          autoComplete="off"
          onChange={(event) => setLastName(event.target.value)}
          value={lastName}
          type="text"
        />
      </th>
      <th scope="col">
        <InputField
          InputLabelProps={{
            ...labelProps,
          }}
          sx={{ ...inputStyles }}
          label="First Name"
          size="small"
          fullWidth={true}
          autoComplete="off"
          onChange={(event) => setFirstName(event.target.value)}
          value={firstName}
          type="text"
        />
      </th>
      <th scope="col">
        <InputField
          InputLabelProps={{
            ...labelProps,
          }}
          sx={{ ...inputStyles }}
          label="Title"
          size="small"
          fullWidth={true}
          autoComplete="off"
          onChange={(event) => setTitle(event.target.value)}
          value={title}
          type="text"
        />
      </th>
      <th scope="col">
        <InputField
          InputLabelProps={{
            ...labelProps,
          }}
          sx={{ ...inputStyles }}
          label="NPI"
          size="small"
          fullWidth={true}
          autoComplete="off"
          onChange={(event) => npiMask(event.target.value)}
          value={npi}
          type="text"
        />
      </th>
      <th scope="col">
        <MuiSelect
          controlProps={{ size: `small`, fullWidth: true, id: `userRole` }}
          label="User Role"
          isSimple={true}
          options={[`Admin`, `Provider`, `Clinical Staff`, `Biller`]}
          selectProps={{
            labelStyle: { ...selectLabelStyles },
            sx: {
              ...selectInputSyles,
            },
            label: `User Role`,
            id: `userRole`,
            name: `userSTatus`,
            onChange: (event) => selectRoleHandler(event.target.value),
            value: role,
          }}
        />
      </th>
      <th scope="col">
        <InputField
          InputLabelProps={{
            ...labelProps,
          }}
          sx={{ ...inputStyles }}
          label="Patients Count"
          size="small"
          fullWidth={true}
          autoComplete="off"
          onChange={(event) => setPatients(event.target.value)}
          value={patients}
          type="text"
        />
      </th>
      <th scope="col">
        <MuiSelect
          controlProps={{ size: `small`, fullWidth: true, id: `userSTatus` }}
          optionKey={`label`}
          optionValue={`value`}
          label="Status"
          isSimple={false}
          options={[...userStatus]}
          selectProps={{
            labelStyle: { ...selectLabelStyles },
            sx: {
              ...selectInputSyles,
            },
            label: `Status`,
            id: `userSTatus`,
            name: `userSTatus`,
            onChange: (event) => setStatus(event.target.value),
            value: status,
          }}
        />
      </th>
      <th scope="col">
        <InputField
          InputLabelProps={{
            ...labelProps,
          }}
          sx={{ ...inputStyles }}
          label="Added By"
          size="small"
          fullWidth={true}
          autoComplete="off"
          onChange={(event) => setAddedBy(event.target.value)}
          value={addedBy}
          type="text"
        />
      </th>
      <th scope="col">
        <div className="d-flex justify-content-around">
          <button type="button" onClick={resetHandler}>
            <i className="fa fa-times" style={{ color: `white`, fontSize: `large` }}></i>
          </button>
          <button type="button" onClick={filterHandler}>
            <i className="fa fa-search" style={{ color: `white`, fontSize: `large` }}></i>
          </button>
          <Fade in={flag !== 0} mountOnEnter unmountOnExit>
            <Box sx={{ display: `inline-block` }}>
              <ToolTipProvider
                toolTipProps={{
                  title: `Navigate to default page!`,
                  placement: `top`,
                  arrow: true,
                }}
                element={
                  <IconButton
                    id="listAlluserNavigaeHome"
                    onClick={() => {
                      document.getElementById(`listAlluserNavigaeHome`).style.display = `none`;
                      checkAndSetValues();
                      setFlag((p) => p + 1);
                      setCurrentPage(1);
                      setRowsPerPage(10);
                    }}
                    sx={{ color: `white`, marginRight: `9px`, padding: `0px` }}
                  >
                    <HomeIcon sx={{ fontSize: `22px` }} />
                  </IconButton>
                }
              />
            </Box>
          </Fade>
        </div>
      </th>
    </tr>
  );
};
