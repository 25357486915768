import $ from 'jquery';
import { room } from './joinroom';
/**
 * Mute/unmute your media in a Room.
 * @param {Room} room - The Room you have joined
 * @param {'audio'|'video'} kind - The type of media you want to mute/unmute
 * @param {'mute'|'unmute'} action - Whether you want to mute/unmute
 */
function muteOrUnmuteYourMedia(room, kind, action) {
  const publications = kind === `audio` ? room.localParticipant.audioTracks : room.localParticipant.videoTracks;

  publications.forEach(function (publication) {
    if (action === `mute`) {
      publication.track.disable();
    } else {
      publication.track.enable();
    }
  });
}

/**
 * Mute your audio in a Room.
 * @param {Room} room - The Room you have joined
 * @returns {void}
 */
const muteYourAudio = (room) => {
  muteOrUnmuteYourMedia(room, `audio`, `mute`);
}

/**
 * Mute your video in a Room.
 * @param {Room} room - The Room you have joined
 * @returns {void}
 */
function muteYourVideo(room) {
  muteOrUnmuteYourMedia(room, `video`, `mute`);
}

/**
 * Unmute your audio in a Room.
 * @param {Room} room - The Room you have joined
 * @returns {void}
 */
function unmuteYourAudio(room) {
  muteOrUnmuteYourMedia(room, `audio`, `unmute`);
}

/**
 * Unmute your video in a Room.
 * @param {Room} room - The Room you have joined
 * @returns {void}
 */
function unmuteYourVideo(room) {
  muteOrUnmuteYourMedia(room, `video`, `unmute`);
}

export default function LocalMediaControls($main, key) {
  // Muting audio track and video tracks click handlers
  if (key === `audio`) {
    const $muteAudioBtn = $(`#muteAudioBtn`, $main);
    const mute = !$muteAudioBtn.hasClass(`muted`);

    if (mute) {
      muteYourAudio(room);
      $muteAudioBtn.addClass(`muted`);
    } else {
      unmuteYourAudio(room);
      $muteAudioBtn.removeClass(`muted`);
    }
  }

  if (key === `video`) {
    const $muteVideoBtn = $(`#muteVideoBtn`, $main);
    const mute = !$muteVideoBtn.hasClass(`muted`);

    if (mute) {
      muteYourVideo(room);
      $muteVideoBtn.addClass(`muted`);
    } else {
      unmuteYourVideo(room);
      $muteVideoBtn.removeClass(`muted`);
    }
  }
}
