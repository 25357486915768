import React, { useEffect } from 'react';
import css from './Logs.module.css';
import moment from 'moment';

import PhoneIcon from '@mui/icons-material/Phone';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import { Fade } from '@mui/material';
// ERROR - TOAST

export const CallLogs = ({ telemedicineLogs, calling, videoCalling }) => {
  useEffect(() => { }, [telemedicineLogs]);

  return (
    <div style={{ width: `100%`, marginBottom: `20px`, backgroundColor: `white` }}>
      <div style={{ marginTop: `20px`, backgroundColor: `white`, textAlign: `center`, padding: `8px` }}>
        <h3>Call History</h3>
      </div>
      <div
        style={{
          display: `flex`,
          width: `100%`,
          paddingTop: `10px`,
          borderTop: `1px solid grey`,
          justifyContent: `space-between`,
          backgroundColor: `white`,
          padding: `10px`,
          borderBottom: `1px solid lightGray`,
        }}
      >
        <span style={{ width: `5%`, fontWeight: `600`, textAlign: `center` }}>#</span>

        <span style={{ width: `15%`, fontWeight: `600`, textAlign: `center` }}>Call Type</span>
        <span style={{ width: `20%`, fontWeight: `600`, textAlign: `center` }}>PhoneNumber</span>
        {/* <span>{moment(telemedicineLog?.createdAt, 'MM/DD/YYYY').format('YYYY-MM-DD')}</span> */}
        <span style={{ width: `20%`, fontWeight: `600`, textAlign: `center` }}>Date</span>
        <span style={{ width: `20%`, fontWeight: `600`, textAlign: `center` }}>Duration</span>
        <span style={{ width: `20%`, textAlign: `center`, fontWeight: `600` }}>Action</span>
      </div>

      <div>
        {telemedicineLogs?.length > 0 &&
          telemedicineLogs.map((telemedicineLog, index) => {
            return (
              <Fade in={telemedicineLog ? true : false} mountOnEnter unmountOnExit>
                <div
                  style={{
                    display: `flex`,
                    width: `100%`,
                    alignItems: `center`,
                    justifyContent: `space-between`,
                    backgroundColor: `white`,
                    padding: `1px`,
                    paddingRight: `10px`,
                    paddingLeft: `10px`,
                    borderBottom: `0.5px solid lightGray`,
                  }}
                  key={index}
                >
                  <span style={{ width: `5%`, color: `grey`, fontSize: `15px`, fontWeight: `600`, textAlign: `center` }}>{index + 1}</span>

                  <span style={{ width: `15%`, textAlign: `center` }}>{telemedicineLog?.source}</span>
                  <span style={{ width: `20%`, textAlign: `center` }}>{telemedicineLog?.phoneNumber ? telemedicineLog?.phoneNumber : ``}</span>
                  {/* <span>{moment(telemedicineLog?.createdAt, 'MM/DD/YYYY').format('YYYY-MM-DD')}</span> */}
                  <span style={{ width: `20%`, textAlign: `center` }}>{moment(telemedicineLog?.createdAt, `YYYY/MM/DD`).format(`MM-DD-YYYY`)}</span>
                  <span style={{ width: `20%`, textAlign: `center` }}>
                    {Math.floor(telemedicineLog?.duration / 60)} min {telemedicineLog?.duration - Math.floor(telemedicineLog?.duration / 60) * 60} sec
                  </span>
                  <div style={{ width: `20%`, textAlign: `center` }}>
                    <button
                      disabled={telemedicineLog?.phoneNumber ? false : true}
                      id={css.dialPadButton}
                      className="btn"
                      style={{ width: `50px`, backgroundColor: `green` }}
                      //   style={{ pointerEvents: videoCallStatus?.startFlag ? 'none' : null }}
                      onClick={() => calling(telemedicineLog?.phoneNumber)}
                    >
                      <PhoneIcon />
                      {/* <i className="fa fa-phone" aria-hidden="true"></i> */}
                    </button>

                    <button
                      id={css.dialPadButton}
                      className="btn"
                      disabled={telemedicineLog?.phoneNumber ? false : true}
                      onClick={() => videoCalling(telemedicineLog?.phoneNumber)}
                      style={{ width: `50px`, marginLeft: `10px` }}
                    >
                      <MissedVideoCallIcon />
                    </button>
                  </div>
                </div>
              </Fade>

            );
          })}
      </div>
    </div>
  );
}

