import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, Zoom } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PuffSpinner } from '../components/spinner/puff/puff';

export const ConfirmationWrapper = ({
  sx,
  open,
  successFunc,
  cancelFunc,
  successText,
  cancelText,
  title,
  description,
  children,
  renderContent,
  maxWidth,
  fullWidth,
  inverse,
  loading,
  onClose,
  rejectCol = `#03b403`,
  shouldShowRed = false,
  exceptionalAction: ExceptionalAction,
  succesBtnPrps = {},
  cancelBtnPrps = {},
}) => {

  return (
    <>
      <Dialog
        {...(maxWidth && { maxWidth: maxWidth })}
        {...(fullWidth && { fullWidth: fullWidth })}
        open={open}
        onClose={(_, reason) => {
          if (reason !== `backdropClick`) {
            cancelFunc();
          }
        }}
        aria-describedby="alert-dialog-slide-description"
        {...(sx && { sx: { ...sx } })}
      >
        {` `}
        {loading && <PuffSpinner />}
        <DialogTitle>
          {title}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: `absolute`,
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          {description && <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText>}
          <Zoom in={!!renderContent} mountOnEnter unmountOnExit>
            <Box sx={{ mt: 0.5 }}>{renderContent}</Box>
          </Zoom>
        </DialogContent>
        <DialogActions>
          <Button
            {...(inverse ? { ...cancelBtnPrps } : { ...succesBtnPrps })}
            sx={{
              backgroundColor: inverse || shouldShowRed ? `red` : `#1699C5`,
              textTransform: `none`,
              color: `white`,
              '&:hover': {
                backgroundColor: inverse || shouldShowRed ? `darkred` : `#078dba`,
              },
            }}
            onClick={() => (inverse ? cancelFunc() : successFunc())}
          >
            {inverse ? cancelText : successText}
          </Button>
          {ExceptionalAction && <ExceptionalAction />}
          {cancelFunc && (
            <Button
              {...(inverse ? { ...succesBtnPrps } : { ...cancelBtnPrps })}
              sx={{
                backgroundColor: inverse ? `green` : rejectCol,
                color: `white`,
                textTransform: `none`,
                '&:hover': {
                  backgroundColor: inverse ? `darkgreen` : `darkred`,
                },
              }}
              onClick={() => (inverse ? successFunc() : cancelFunc())}
            >
              {inverse ? successText : cancelText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
};
