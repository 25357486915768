export const LIST_OF_CONVERSATION = `LIST_OF_CONVERSATION`;
export const SOCKET = `SOCKET`;
export const RECENT_MESSAGE = `RECENT_MESSAGE`;
export const SELECTED_USER = `SELECTED_USER`;
export const INDIVIDUAL_USER = `INDIVIDUAL USER`;
export const RESET_REDUX = `RESET_REDUX`;
export const UPDATE_ONLINE_STATUS = `UPDATE_ONLINE_STATUS`;
export const UPDATE_USER_CHAT_ID = `UPDATE_USER_CHAT_ID`;
export const UPDATE_MESSAGE_READ_STATUS = `UPDATE_MESSAGE_READ_STATUS`;
export const GET_PATIENT_LIST = `GET_PATIENT_LIST`;
export const UPDATE_USER_CHATID_COVERSATION = `UPDATE_USER_CHATID_COVERSATION`;
