import React, { useState, useEffect } from 'react';
import { copyRightLicense, UDI, versionNum } from 'src/utils';
import css from './Footer.module.css';

export const Footer = ({ showOnlytLiscense }) => {
  const [browserDetect, setBrowserDetect] = useState(false);
  useEffect(() => {
    let isChrome = navigator.userAgentData?.brands?.some((b) => b.brand === `Google Chrome`);
    setBrowserDetect(isChrome);
  }, []);
  return (
    <div id={css.footer}>
      <hr id={css.divider} />
      <p id={css.footerUdi}>
        {UDI} {versionNum}
      </p>
      <p id={css.footerText}> {copyRightLicense} </p>
      {!browserDetect && !showOnlytLiscense ? <p id={css.footerText}>For best experience please use Google Chrome.</p> : null}
    </div>
  );
};
