import React, { useState } from 'react';
import { ReassignPatient } from '../patient2/components/reassignPatient/reassignPatient';

export const ForReassignPatients = ({ setValue }) => {
  const [reassignModal, setReassignModal] = useState(true);

  return (
    <ReassignPatient
      reassignModal={reassignModal}
      setValue={setValue}
      setReassignModal={setReassignModal}
    />
  );
};
