import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postRequest, ADD_PATIENT_MONITORING_TIME_LOG_URL } from '../../../../../../../crud/crud';
import 'react-datetime/css/react-datetime.css';
import { momentWrapper } from 'src/momentWrapper';
import Datetime from 'react-datetime';
import css from './AddManualTimeLog.module.css';
import { ToastContainer } from 'react-toastify';
import {
  _patientBasicDetailTimerResetFlag, _patientTimerRefrshFlag,
} from '../../../../../../../redux/timer/timerActions';
import { changeLeftVoiceStatus, updateTimeLog } from 'src/redux/patient/patientActions';
import Swal from 'sweetalert2';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Button
} from '@mui/material';

export const AddManualTimeLog = ({
  cancleBtnHandler,
  patientId,
  successToast,
  errorToast,
  reportId,
  setManualLogSetRefreshFlag,
  manualLogRefreshFlag,
}) => {
  const [desc, setDesc] = useState(``);
  const [addLogDisabled, setAddLogDisabled] = useState(false);
  const [descriptionFlag, setDescriptionFlag] = useState(false);
  const [dateTimeFlag, setDateTimeFlag] = useState(false);
  const [fromDateIsRequired, setFromDateIsRequired] = useState(false);
  const [toDateIsRequired, setToDateIsRequired] = useState(false);
  const [timeLogSource, setTimeLogSource] = useState(`Report View`);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const fromDate = useRef(null);
  const toDate = useRef(null);
  const dispatchRedux = useDispatch();
  const userData = useSelector((state) => state.patient.patientData);

  const handleCloseFeedbackModal = (call, leftVoiceMail) => {
    let startTimeTemp = momentWrapper(fromDate.current.state.inputValue, `MM/DD/YYYY h:mm A`).toISOString();
    let endTimeTemp = momentWrapper(toDate.current.state.inputValue, `MM/DD/YYYY h:mm A`).toISOString();

    let toDateFormatted = momentWrapper(toDate.current.state.inputValue);
    let fromDateFormatted = momentWrapper(fromDate.current.state.inputValue);

    let timeDiff = toDateFormatted.diff(fromDateFormatted, `seconds`);
    if (timeDiff < 1) {
      errorToast(`Zero second can't be added`);
      return;
    }
    if (timeDiff > 3600) {
      Swal.fire({
        title: `Are you sure?`,
        text: `You are Adding a time log greater than 1 hour`,
        icon: `warning`,
        showCancelButton: true,
        confirmButtonColor: `#3085d6`,
        cancelButtonColor: `#d33`,
        confirmButtonText: `Yes, Add it!`,
      }).then((result) => {
        if (result.isConfirmed) {
          postRequest(
            ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${reportId}`,
            {
              startDate: startTimeTemp,
              endDate: endTimeTemp,
              startOfMonth: momentWrapper().startOf(`month`).toISOString(),
              now: momentWrapper().toISOString(),
              source: timeLogSource,
              type: `Manual`,
              description: desc,
              // callSuccess: call ? true : false,
              leftVoiceMail: leftVoiceMail,
              logCategory: leftVoiceMail ? `VOICEMAIL` : call ? `INTERACTIVE_COMMUNICATION` : `NO_INTERACTIVE_COMMUNICATION`,
            },
            patientId
          )
            .then((res) => {
              setAddLogDisabled(false);
              successToast(`Time Log Added.`);
              dispatchRedux(updateTimeLog(res?.data?.timeLog));
              cancelLog();
              setManualLogSetRefreshFlag(!manualLogRefreshFlag);
              dispatchRedux(_patientBasicDetailTimerResetFlag(true));
              _patientTimerRefrshFlag(true); // bbbbb
            })
            .catch((err) => {
              setAddLogDisabled(false);
              errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
            });
        }

        setAddLogDisabled(false);
        setFeedbackModal(false);
      });
    } else {
      postRequest(
        ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${reportId}`,
        {
          startDate: startTimeTemp,
          endDate: endTimeTemp,
          startOfMonth: momentWrapper().startOf(`month`).toISOString(),
          now: momentWrapper().toISOString(),
          source: timeLogSource,
          type: `Manual`,
          description: desc,
          callSuccess: call ? true : false,
          leftVoiceMail: leftVoiceMail,
          logCategory: leftVoiceMail ? `VOICEMAIL` : call ? `INTERACTIVE_COMMUNICATION` : `NO_INTERACTIVE_COMMUNICATION`,
        },
      )
        .then((res) => {
          dispatchRedux(changeLeftVoiceStatus({ id: patientId, flag: leftVoiceMail ? true : false }))
          setFeedbackModal(false);
          setAddLogDisabled(false);

          successToast(`Time Log Added.`);
          dispatchRedux(updateTimeLog(res?.data?.timeLog));
          cancelLog();
          setManualLogSetRefreshFlag(!manualLogRefreshFlag);
          dispatchRedux(_patientBasicDetailTimerResetFlag(true));
          _patientTimerRefrshFlag(true); // bbbbb
        })
        .catch((err) => {
          setFeedbackModal(false);
          setAddLogDisabled(false);

          // if (err.response.status == 409) {
          //   errorToast(err?.response?.data?.message);
          // } else {
          //   errorToast(`Time log not added`);
          // }
        });
    }
  };

  const addLogs = () => {
    // APPLY DIFFERENT KIND OF VALIDATIONS
    if (!fromDate.current.state.inputValue) {
      setFromDateIsRequired(true);
      return;
    } else {
      setFromDateIsRequired(false);
    }

    if (!toDate.current.state.inputValue) {
      setToDateIsRequired(true);
      return;
    } else {
      setToDateIsRequired(false);
    }
    if (!desc) {
      setDescriptionFlag(true);
      return;
    } else {
      setDescriptionFlag(false);
    }

    let startTimeTemp = momentWrapper(fromDate.current.state.inputValue, `MM/DD/YYYY h:mm A`).toISOString();
    let endTimeTemp = momentWrapper(toDate.current.state.inputValue, `MM/DD/YYYY h:mm A`).toISOString();

    let toDateFormatted = momentWrapper(toDate.current.state.inputValue);
    let fromDateFormatted = momentWrapper(fromDate.current.state.inputValue);

    let timeDiff = toDateFormatted.diff(fromDateFormatted, `seconds`);

    if (checkDate(startTimeTemp, endTimeTemp)) {
      setDateTimeFlag(true);
      return;
    } else {
      setDateTimeFlag(false);
    }
    if (timeDiff < 1) {
      errorToast(`Zero second can't be added`);
      return;
    }

    setAddLogDisabled(true);

    if (timeLogSource === `Audio Call` || timeLogSource === `Video Call`) {
      if (!userData?.data?.interactiveCommunication) {
        setFeedbackModal(true);
      } else {
        if (timeDiff > 3600) {
          Swal.fire({
            title: `Are you sure?`,
            text: `You are Adding a time log greater than 1 hour`,
            icon: `warning`,
            showCancelButton: true,
            confirmButtonColor: `#3085d6`,
            cancelButtonColor: `#d33`,
            confirmButtonText: `Yes, Add it!`,
          }).then((result) => {
            if (result.isConfirmed) {
              postRequest(
                ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${reportId}`,
                {
                  startDate: startTimeTemp,
                  endDate: endTimeTemp,
                  startOfMonth: momentWrapper().startOf(`month`).toISOString(),
                  now: momentWrapper().toISOString(),
                  source: timeLogSource,
                  type: `Manual`,
                  description: desc,
                  logCategory: `INTERACTIVE_COMMUNICATION`
                },
              )
                .then((res) => {
                  setAddLogDisabled(false);

                  successToast(`Time Log Added.`);
                  dispatchRedux(updateTimeLog(res?.data?.timeLog));
                  cancelLog();
                  setManualLogSetRefreshFlag(!manualLogRefreshFlag);
                  dispatchRedux(_patientBasicDetailTimerResetFlag(true));
                  _patientTimerRefrshFlag(true); // bbbbb
                })
                .catch((err) => {
                  setAddLogDisabled(false);
                  errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
                });
            }

            setAddLogDisabled(false);
          });
        } else {
          postRequest(
            ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${reportId}`,
            {
              startDate: startTimeTemp,
              endDate: endTimeTemp,
              startOfMonth: momentWrapper().startOf(`month`).toISOString(),
              now: momentWrapper().toISOString(),
              source: timeLogSource,
              type: `Manual`,
              description: desc,
              logCategory: `INTERACTIVE_COMMUNICATION`
            },

          )
            .then((res) => {
              setFeedbackModal(false);
              setAddLogDisabled(false);

              successToast(`Time Log Added.`);
              dispatchRedux(updateTimeLog(res?.data?.timeLog));
              cancelLog();
              setManualLogSetRefreshFlag(!manualLogRefreshFlag);
              dispatchRedux(_patientBasicDetailTimerResetFlag(true));
              _patientTimerRefrshFlag(true); // bbbbb
            })
            .catch((err) => {
              setFeedbackModal(false);
              setAddLogDisabled(false);
              // if (err.response.status == 409) {
              //   errorToast(err?.response?.data?.message);
              // } else {
              //   errorToast(`Time log not added`);
              // }
            });
        }
      }
    } else {
      if (timeDiff > 3600) {
        Swal.fire({
          title: `Are you sure?`,
          text: `You are Adding a time log greater than 1 hour`,
          icon: `warning`,
          showCancelButton: true,
          confirmButtonColor: `#3085d6`,
          cancelButtonColor: `#d33`,
          confirmButtonText: `Yes, Add it!`,
        }).then((result) => {
          if (result.isConfirmed) {
            postRequest(
              ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${reportId}`,
              {
                startDate: startTimeTemp,
                endDate: endTimeTemp,
                startOfMonth: momentWrapper().startOf(`month`).toISOString(),
                now: momentWrapper().toISOString(),
                source: timeLogSource,
                type: `Manual`,
                description: desc,
                logCategory: timeLogSource
              },

            )
              .then((res) => {
                setAddLogDisabled(false);

                successToast(`Time Log Added.`);
                dispatchRedux(updateTimeLog(res?.data?.timeLog));
                cancelLog();
                setManualLogSetRefreshFlag(!manualLogRefreshFlag);
                dispatchRedux(_patientBasicDetailTimerResetFlag(true));
                _patientTimerRefrshFlag(true); // bbbbb
              })
              .catch((err) => {
                setAddLogDisabled(false);
                errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
              });
          }

          setAddLogDisabled(false);
        });
      } else {
        postRequest(
          ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${reportId}`,
          {
            startDate: startTimeTemp,
            endDate: endTimeTemp,
            startOfMonth: momentWrapper().startOf(`month`).toISOString(),
            now: momentWrapper().toISOString(),
            source: timeLogSource,
            type: `Manual`,
            description: desc,
            logCategory: timeLogSource
          },

        )
          .then((res) => {
            setFeedbackModal(false);
            setAddLogDisabled(false);

            successToast(`Time Log Added.`);
            dispatchRedux(updateTimeLog(res?.data?.timeLog));
            cancelLog();
            setManualLogSetRefreshFlag(!manualLogRefreshFlag);
            dispatchRedux(_patientBasicDetailTimerResetFlag(true));
            _patientTimerRefrshFlag(true); // bbbbb
          })
          .catch((err) => {
            setFeedbackModal(false);
            setAddLogDisabled(false);
            // if (err.response.status == 409) {
            //   errorToast(err?.response?.data?.message);
            // } else {
            //   errorToast(`Time log not added`);
            // }
          });
      }
    }
  };
  const cancelLog = () => {
    setDesc(``);
    cancleBtnHandler();
  };

  const checkDate = (start, end) => {
    let mStart = momentWrapper(start);
    let mEnd = momentWrapper(end);
    return mEnd.isBefore(mStart);
  }

  return (
    <>
      <Dialog
        open={feedbackModal}
        // onClose={handleCloseFeedbackModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Did this call satisfy the requirements for interactive communication?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: `center` }}>
          <Button onClick={() => handleCloseFeedbackModal(true, false)} variant="contained" style={{ backgroundColor: `green`, color: `white` }}>
            YES
          </Button>
          <Button onClick={() => handleCloseFeedbackModal(false, false)} variant="contained" style={{ backgroundColor: `red`, color: `white` }} autoFocus>
            NO
          </Button>
          {timeLogSource === `Video Call` ? (
            null
          ) : (
            <Button onClick={() => handleCloseFeedbackModal(false, true)} variant="contained" style={{ backgroundColor: `#1699C5`, color: `white` }} autoFocus>
              Left Voicemail
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <div>
        <ToastContainer />
        <div style={{ display: `flex`, justifyContent: `space-between` }}>
          <div className="form-group mb-1">
            <label className="ml-0">From*</label>
            <div style={{ width: `100%` }}>
              <Datetime ref={fromDate} className={fromDateIsRequired ? `is-invalid` : null} />
              <div className="invalid-feedback">* Date is required</div>
            </div>
          </div>
          <div className="form-group mb-1" style={{ marginRight: `30px` }}>
            <label className="ml-0">To*</label>
            <div style={{ width: `100%` }}>
              <Datetime ref={toDate} className={toDateIsRequired || dateTimeFlag ? `is-invalid` : null} />
              <div hidden={dateTimeFlag} className="invalid-feedback">
                * Date is required
              </div>
              <div hidden={toDateIsRequired} className="invalid-feedback">
                * End date should be greater than start date
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="ml-0" htmlFor="sourceType">
            Source*
          </label>
          <div>
            <select className="form-control" id="sourceType" name="source" onChange={(e) => setTimeLogSource(e.target.value)} value={timeLogSource}>
              <option value="Report View">Report View</option>
              <option value="Texting">Texting</option>
              <option value="Audio Call">Audio Call</option>
              <option value="Video Call">Video Call</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="ml-0">Description*</label>
          <div>
            <textarea
              className={`form-control ${descriptionFlag ? ` is-invalid` : null}`}
              name="textarea"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
            <div className="invalid-feedback">* Please add description</div>
          </div>
        </div>
        <div>
          <button type="button" className="btn btn-outline-info btn-sm" id={css.buttonStyle} disabled={addLogDisabled} onClick={() => addLogs()}>
            Add Log
          </button>
          <button type="button" className="btn btn-outline-danger btn-sm ml-2" id={css.buttonStyle} onClick={() => cancelLog()}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

