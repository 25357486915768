import React from 'react';
import { PracticeInfo } from './components/practiceInfo/practiceInfo';
import css from '../admin.module.css';
import Box from '@mui/material/Box';

export const AdministrationPractice = () => {
  return (
    <Box sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
      <div id={css.main}>
        <PracticeInfo />
      </div>
    </Box>
  );
}
