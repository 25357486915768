/* eslint-disable no-prototype-builtins */
import React, { useEffect, useRef, useState } from 'react';
import css from './billerRoleTable.module.css';
import {
  GET_REPORTS_BY_STATUS_BILLER_ROLE, getRequest, UPDATE_REPORT_BILL_STATUS, patchRequest,
  UPDATE_ALL_REPORT_BILL_STATUS,
} from '../../../../../../crud/crud';
import { PuffSpinner } from '../../../../../common/components/spinner/puff/puff';
import Swal from 'sweetalert2';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';
import { capitalizeFirstLetter } from 'src/utils';
import { useStartEndDateSelction } from 'src/pages/common/hooks/useStartEndDateSelction';
import { Box, Stack } from '@mui/material';

export const BillerRoleTable = ({ csvButtonDisabled, createBillingCsvFile }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const renderTotalRep = useRef(null);
  const [combineFilteredReports, setCombineFilteredReports] = useState([]);
  const [allBillReportFlagCheck, setAllBillReportFlagCheck] = useState(false);
  const lastMonth = moment().subtract(1, `months`);
  const [dateRangeSelection, setDateRangeSelection] = useState({ startDate: lastMonth, endDate: moment(lastMonth).endOf(`month`) });
  const { startDate, endDate } = dateRangeSelection;
  const [reportStatus, setReportStatus] = useState(`READY_TO_BILL`);
  const [fetchReportsFlag, setFetchReportsFlag] = useState(0);
  const theme = useTheme();

  const isMedium = useMediaQuery(theme.breakpoints.down(`md`));

  const isLarge = useMediaQuery(theme.breakpoints.down(`lg`));

  const isLarger = useMediaQuery(theme.breakpoints.up(`lg`));
  const isLargerExt = useMediaQuery(theme.breakpoints.up(`xl`));
  let monthArray = [``, `January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`];
  const dataMapHelper = async (response) => {
    let billerReports = response?.data?.data.map((e) => {
      let month = e.month.toString();
      let array = month.split(``);
      let isGreater = array[array.length - 2] + array[array.length - 1];
      let currentMonthIndex;
      if (parseInt(isGreater) >= 10) {
        let currentMonth = monthArray[parseInt(isGreater)];
        let reportDate = currentMonth + ` ` + array.slice(0, 4).join(``);
        return { ...e, month: reportDate };
      } else {
        currentMonthIndex = array[array?.length - 1];
        let currentMonth = monthArray[parseInt(currentMonthIndex)];
        let reportDate = currentMonth + ` ` + array.slice(0, 4).join(``);
        return { ...e, month: reportDate };
      }
    });
    return billerReports;
  };
  const mappingPromise = (response) => {
    let mappedData = new Promise((resolve, reject) => {
      let mappedData = dataMapHelper(response);
      resolve(mappedData);
      reject(`rejected`);
    });
    mappedData.then((res) => {
      setCombineFilteredReports(res);
    });
  };

  useEffect(() => {
    if (fetchReportsFlag || !fetchReportsFlag) {
      setLoading(true);

      let decidedDates = `&startDate=${moment(startDate).format(`YYYY-MM`)}&endDate=${moment(endDate).format(`YYYY-MM`)}`;

      const getReports = () => {
        let query = `${GET_REPORTS_BY_STATUS_BILLER_ROLE}/?pageNumber=${currentPage}&pageSize=${rowsPerPage}${decidedDates}&status=${reportStatus}`;
        getRequest(query)
          .then((response) => {
            setLoading(false);
            mappingPromise(response);
            setTotalRows(response?.data?.pager?.totalRecords);
            setTotalPage(Math.ceil(response?.data?.pager?.totalRecords / parseInt(response?.data?.pager?.pageSize)));
            renderTotalRep.current.innerHTML = response?.data?.pager?.totalRecords;
          })
          .catch((error) => {
            setLoading(false);
          });
      };
      getReports();
    }
  }, [reportStatus, fetchReportsFlag, dateRangeSelection, currentPage, rowsPerPage]);
  // HANDLING CHECKBOX

  const billedHandler = (e, reportId, actionObj) => {
    e.target.disabled = true;

    patchRequest(UPDATE_REPORT_BILL_STATUS + reportId, {})
      .then(() => {
        setFetchReportsFlag((p) => p + 1);
      })
      .catch();
  };

  const resetPagination = () => {
    if (currentPage !== 1) setCurrentPage(1);
    if (rowsPerPage !== 10) setRowsPerPage(10);
    setFetchReportsFlag((p) => p + 1);
  };
  // HANDLE ALL BILLED LIST UPDATE
  const billedAllHandler = () => {
    setAllBillReportFlagCheck(true);
    const billedId = combineFilteredReports?.map((report) => report._id);
    Swal.fire({
      title: `Are you sure?`,
      text: `You want to bill all reports!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonColor: `#3085d6`,
      cancelButtonColor: `#d33`,
      confirmButtonText: `Yes, bill it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        const payLoad = { reportsIds: billedId };
        patchRequest(UPDATE_ALL_REPORT_BILL_STATUS, payLoad)
          .then(() => {
            setCombineFilteredReports([]);
            renderTotalRep.current.innerHTML = 0;
            Swal.fire(`Success!`, `All Reports Billed`, `success`);
          })
          .catch((err) => { });
      } else {
        setAllBillReportFlagCheck(false);
      }
    });
  };

  let renderdateRangeSelection = useStartEndDateSelction({
    startDate: dateRangeSelection?.startDate,
    endDate: dateRangeSelection?.endDate,
    resetDef: !!fetchReportsFlag,
    spacings: {
      columnSpacing: 1,
      rowSpacing: 1,
    },
    datePickerContProps: {
      styles: {
        mt: 1,
      },
    },
    pickerProps: {
      startSign: `Start Month`,
      endSign: `End Month`,
    },
    actionProps: {
      btnAction: (startDate, endDate) => {
        setDateRangeSelection({ startDate, endDate });
        resetPagination();
      },

      btnStyles: {
        padding: `5px`,
        width: `100%`,
      },
      text: `Fetch Reports`,
    },
  });

  const setPaddingLeft = ({
    firstSuc, secSuc, firstSucVal, secondSucVal, failureVal
  }) => {
    if (firstSuc) {
      return firstSucVal
    }
    return secSuc ? secondSucVal : failureVal
  }

  return (
    <div style={{ position: `relative` }}>
      <table className="table table-striped">
        <tr style={{ backgroundColor: `white` }}>
          <Box sx={{ p: 2 }}>
            <Stack direction="row" justifyContent={`space-between`} alignItems="center" spacing={1}>
              <Box>
                <h6 id={css.headText}>
                  TOTAL BILLING REPORTS (<span ref={renderTotalRep}></span>)
                </h6>
              </Box>
              <Box>{renderdateRangeSelection}</Box>
              <Box>
                <Stack spacing={1} direction="column" justifyContent={`space-between`}>
                  <Box>
                    <select
                      type="button"
                      onChange={({ target: { value } }) => {
                        setReportStatus(value);
                        resetPagination();
                      }}
                      disabled={csvButtonDisabled}
                      className="btn btn-outline-info btn-md"
                      style={{ fontSize: `9px`, width: `100%` }}
                    >
                      <option value="READY_TO_BILL">Ready to Bill Reports</option>
                      <option value="BILLED">Billed</option>
                    </select>
                  </Box>
                  <Box>
                    <button
                      type="button"
                      onClick={() =>
                        createBillingCsvFile(
                          reportStatus,
                          dateRangeSelection.startDate ? moment(dateRangeSelection.startDate).startOf(`day`).format(`YYYY-MM`) : null,
                          dateRangeSelection.endDate ? moment(dateRangeSelection.endDate).endOf(`day`).format(`YYYY-MM`) : null
                        )
                      }
                      disabled={csvButtonDisabled}
                      className="btn btn-outline-info btn-md"
                      style={{ fontSize: `9px`, width: `100%` }}
                    >
                      Download CSV
                    </button>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </tr>
        <thead style={{ borderBottom: `1px solid grey`, display: `block` }}>
          <tr>
            <th scope="col" className={css.customCol0}></th>
            <th scope="col" className={css.customCol1}>
              LAST NAME
            </th>
            <th scope="col" className={css.customCol2} style={{
              paddingLeft: setPaddingLeft(
                {
                  firstSuc: isMedium, secSuc: isLarge, firstSucVal: `3%`, secondSucVal: `3.5%`, failureVal: `5%`
                }
              )
            }}>
              FIRST NAME
            </th>
            <th scope="col" className={css.customCol3} style={{
              paddingLeft:
                setPaddingLeft(
                  {
                    firstSuc: isLarger, secSuc: isLargerExt, firstSucVal: `8%`, secondSucVal: `15%`, failureVal: `5%`
                  }
                )
            }}>
              Month
            </th>
            {reportStatus !== `BILLED` ? (
              <th scope="col" className={css.customCol4} style={{ paddingLeft: `5%` }}>
                Mark all as billed
              </th>
            ) : (
              <th scope="col" className={css.customCol4} style={{ paddingLeft: `5%` }}>
                Billed Reports
              </th>
            )}
            {reportStatus !== `BILLED` && combineFilteredReports?.length ? (
              <th scope="col" className={css.customCol4}>
                <input type="checkbox" checked={allBillReportFlagCheck} onChange={() => billedAllHandler()} />
              </th>
            ) : (
              <th scope="col" className={css.customCol4}>
                <input type="checkbox" checked={true} />
              </th>
            )}
          </tr>
        </thead>
        <tbody id={css.body} style={{ display: `block`, height: `250px`, overflowY: `auto` }}>
          {combineFilteredReports?.length ? (
            combineFilteredReports?.map((report) => {
              //
              return (
                <tr key={report?._id}>
                  <td className={css.customCol0}></td>
                  <td className="text-capitalize" style={{ minWidth: `120px`, width: `30%` }}>
                    {capitalizeFirstLetter(`${report?.patient?.lastName} `)}
                  </td>
                  <td className="text-capitalize" style={{ minWidth: `120px`, width: `30%` }}>
                    {capitalizeFirstLetter(`${report?.patient?.firstName}`)}
                    {` `}
                    {report?.reportStatus === `ADDENDUMED` ? <span className="badge badge-pill badge-info">A</span> : null}
                  </td>
                  <td style={{ minWidth: `120px`, width: `30%` }}>{report?.month}</td>
                  <td scope="col" className={css.customCol4} style={{ minWidth: `120px`, width: `30%` }}></td>
                  {reportStatus !== `BILLED` ? (
                    <td style={{ minWidth: `120px`, width: `30%` }}>
                      <input
                        type="checkbox"
                        onChange={(e) => billedHandler(e, report._id, report?.isDuplicated ? { isAddendumBilled: true } : { isBilled: true })}
                      />
                    </td>
                  ) : (
                    <td style={{ minWidth: `120px`, width: `30%` }}>
                      <input type="checkbox" checked={true} />
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr style={{ width: `100%`, display: `block` }}>
              <td colSpan="10" style={{ textAlign: `center`, display: `block` }}>
                No Record Found.
              </td>
            </tr>
          )}
        </tbody>
        <TableFooter
          totalPage={totalPage}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          setRowsPerPage={setRowsPerPage}
          setCurrentPage={setCurrentPage}
          pagination={null}
          setPagination={null}
          isPatientView={false}
          data={combineFilteredReports}
        />
      </table>
      {loading ? <PuffSpinner /> : null}
    </div>
  );
};