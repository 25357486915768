import React, { useState, useEffect } from 'react';
import {
  postRequest,
  PATIENT_VIEW_DATA_RESMED_URL,
  PATIENT_VIEW_DATA_RESPIRONICS_URL,
  getRequest,
  SEND_REPORT_TO_MD,
  SEND_ADDENDUM_REPORT,
  UPDATE_REPORT_BILL_STATUS,
  patchRequest,
  PATIENT_CREATE_REPORT_URL,
  CREATE_ADDENDUM_REPORT,
  CREATE_ADDENDUM_REPORT_RESPIRONICS,
  PATIENT_CREATE_REPORT_URL_RESPIRONICS,
  PATIENT_VIEW_DATA_REACT_HEALTH,
  PATIENT_CREATE_REPORT_URL_REACT_HEALTH,
  CREATE_ADDENDUM_REPORT_REACT_HEALTH,
} from '../../../../../../../../crud/crud';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { momentWrapper } from 'src/momentWrapper';
import momentOriginal from 'moment';
import css from './viewData.module.css';
import { PuffSpinner } from '../../../../../../../common/components/spinner/puff/puff';
import { ViewDataSummary } from './tabComponents/summary';
import { ViewDataDetail } from './tabComponents/detail';
import { ViewDataMonitAir } from './tabComponents/monitAir';
import { ReportPdfViewer } from '../createReport/reportPdfViewer/reportPdfViewer';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, defaultInputRanges } from 'react-date-range';
import { handleUpdatePatientData, handleReportLoadingFlag, updatePatientDataFlag } from 'src/redux/patient/patientActions';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import {
  doIfUserIsFromCareTeam,
  isDme,
  isPrimaryPatient,
  predefinedRanges,
  printInBrowser,
  redirectToDashBoard,
  wrappWithTimerCheck5s,
} from 'src/utils';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import moment from 'moment';

export const PatientsViewData = () => {
  const location = useLocation();
  const dispatchRedux = useDispatch();
  const [showModal, setModal] = useState(false);
  const patientDataFromReport = useSelector((state) => state.patient.patientReport?.patient);

  const [dateRangeSelection, setDateRangeSelection] = useState([
    {
      startDate: momentWrapper().startOf(`day`).subtract(29, `days`).format(`MM/DD/YYYY`),
      endDate: momentWrapper().format(`MM/DD/YYYY`),
      key: `selection`,
    },
  ]);
  const [persistDateRangeSelection, setPersistDateRangeSelection] = useState([
    {
      startDate: momentWrapper().startOf(`day`).subtract(30, `days`).format(`MM/DD/YYYY`),
      endDate: momentWrapper().endOf(`day`).subtract(1, `days`).format(`MM/DD/YYYY`),
      key: `selection`,
    },
  ]);

  const selectedPatient = useSelector((state) => state.patient?.patientReport?.patient);
  const patientData = useSelector((state) => state.patient.patientData);
  const selectedPatientReport = useSelector((state) => state?.patient?.patientReport?.selectedPatientReport?.data);
  const selectedPatientReportAndPatient = useSelector((state) => state?.patient?.patientReport);
  const patientManufacturer = useSelector((state) => state?.patient?.patientReport?.patient?.device?.manufacturer);
  const patientsList = useSelector((state) => state.patient.patientsList);
  const timer = useSelector((state) => state.patient.timer);
  const loginUser = useSelector((state) => state?.user?.user);
  const reportLoadingFlag = useSelector((state) => state?.patient?.reportLoadingFlag);
  const [data, setData] = useState(``);
  const [currentTab, setCurrentTab] = useState(`summary`);
  const [visibleFlag, setVisibleFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const [setIframeRefreshFlag] = useState(false);
  const selectedPatientIdFromReport = useSelector((state) => state.patient.patientReport?.patient?._id);
  const { startTime } = useSelector((state) => state.patient.timer);
  const targetPatient = patientsList?.data?.patients?.filter((patient) => {
    return patient?._id === selectedPatientIdFromReport;
  })[0];
  const [patientTimeInSeconds, setPatientTimeInSeconds] = useState(0);
  // const [fileURL, setFileURL] = useState();
  // const [reportCreatedFlag, setReportCreatedFlag] = useState(false);
  const isChatting = useSelector((state) => state?.patient?.isChatting);
  const descriptionForTimer = useSelector((state) => state?.patient?.descriptionText);

  const history = useHistory();

  /*
    Initializing default date ranges separately
  */

  useEffect(() => {
    let { startDateOfReport, endDateOfReport } = location;

    /*
        if report if of previous month and, is being signed in the current month,
        then end date of the report should be end of that month.
      */
    if (location && location.startDateOfReport && location.endDateOfReport) {
      const currentMonth = parseInt(momentWrapper().format(`YYYYMM`));

      const reportMonth = parseInt(momentWrapper(endDateOfReport).format(`YYYYMM`));

      if (currentMonth > reportMonth) {
        endDateOfReport = momentWrapper(endDateOfReport).endOf(`month`).toDate();
      }
    }

    let startDate = startDateOfReport
      ? momentWrapper(startDateOfReport).startOf(`day`).toDate()
      : momentWrapper().startOf(`day`).subtract(30, `days`).toDate();

    let endDate = endDateOfReport ? momentWrapper(endDateOfReport).endOf(`day`).toDate() : momentWrapper().subtract(1, `day`).endOf(`day`).toDate();

    // setting default date range for "dateRangeSelection"
    setDateRangeSelection([
      {
        startDate: momentWrapper(startDate).format(`MM/DD/YYYY`),
        endDate: momentWrapper(endDate).format(`MM/DD/YYYY`),
        key: `selection`,
      },
    ]);

    // setting default date range for "dateRangeSelection"
    setPersistDateRangeSelection([
      {
        startDate: momentWrapper(startDate).format(`MM/DD/YYYY`),
        endDate: momentWrapper(endDate).format(`MM/DD/YYYY`),
        key: `selection`,
      },
    ]);
  }, [location, selectedPatient]);
  useEffect(() => {
    if (targetPatient) {
      setPatientTimeInSeconds(
        patientsList?.data?.patients?.filter((patient) => {
          return patient?._id === selectedPatientIdFromReport;
        })[0]?.timeMonitored
      );
    }
  }, [targetPatient?._id, targetPatient?.timeMonitored]);
  const { patient } = selectedPatientReportAndPatient || {};
  const { _id: patientId, device } = patient || {};
  const { manufacturer } = device || false;

  useEffect(() => {
    let startDate = momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toDate();
    let endDate = momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).endOf(`day`).toDate();
    if (manufacturer === `resmed` && typeof patientId === `string`) {
      printInBrowser({ key: `asbasas`, value: { loc: location, rep: manufacturer, currentTab: currentTab } });
      setLoading(true);
      let url = PATIENT_VIEW_DATA_RESMED_URL + patientId + `/?reportId=${location.reportId ? location.reportId : null}`;
      postRequest(url, { startDate, endDate })
        .then((res) => {
          printInBrowser({ key: `sessionData`, value: res.data });
          setData(res.data);
          setLoading(false);
          dispatchRedux(updatePatientDataFlag(true));
        })
        .catch(() => {
          setLoading(false);
          setData(``);
        });
    } else if (manufacturer === `reactHealth` && typeof patientId === `string`) {
      setLoading(true);
      let url =
        PATIENT_VIEW_DATA_REACT_HEALTH +
        patientId +
        `/?reportId=${location.reportId ? location.reportId : null}&startDate=${momentWrapper(startDate).toISOString()}&endDate=${momentWrapper(
          endDate
        ).toISOString()}`;
      getRequest(url)
        .then((response) => {
          setData(response?.data);
          setLoading(false);
        })
        .catch(() => {
          setData(``);
          setLoading(false);
        });
    } else if (manufacturer === `respironics` && typeof patientId === `string`) {
      setLoading(true);
      let url =
        PATIENT_VIEW_DATA_RESPIRONICS_URL +
        patientId +
        `/?reportId=${location.reportId ? location.reportId : null}&startDate=${momentWrapper(startDate).toISOString()}&endDate=${momentWrapper(
          endDate
        ).toISOString()}`;
      getRequest(url)
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch(() => {
          setData(``);
          setLoading(false);
        });
    }
  }, [location, patientId, manufacturer, currentTab]);

  console.log(patientTimeInSeconds, `aasffgfdhggfhfg`)

  const getDataHandler = (picker) => {
    let { startDate, endDate } = picker.selection;
    setDateRangeSelection([
      {
        startDate: momentOriginal(startDate).format(`MM/DD/YYYY`),
        endDate: momentOriginal(endDate).format(`MM/DD/YYYY`),
        key: `selection`,
      },
    ]);
  };

  const applyDateRangeApiCall = () => {
    setModal(false);
    setPersistDateRangeSelection(dateRangeSelection);

    let startDate = momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toDate();
    let endDate = momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).endOf(`day`).toDate();

    if (selectedPatient && startDate && endDate) {
      let url =
        PATIENT_VIEW_DATA_RESMED_URL + selectedPatientReportAndPatient?.patient?._id + `/?reportId=${location.reportId ? location.reportId : null}`;
      setLoading(true);
      setData(``);
      if (selectedPatientReportAndPatient?.patient?.device?.manufacturer === `resmed`) {
        postRequest(url, {
          startDate,
          endDate,
        })
          .then((res) => {
            //
            setData(res.data);
            setLoading(false);
            dispatchRedux(updatePatientDataFlag(true));
          })
          .catch(() => {
            setLoading(false);
          });
      } else if (selectedPatientReportAndPatient?.patient?.device?.manufacturer === `reactHealth`) {
        let url =
          PATIENT_VIEW_DATA_REACT_HEALTH +
          selectedPatientReportAndPatient?.patient?._id +
          `/?reportId=${location.reportId ? location.reportId : null}&startDate=${momentWrapper(startDate).toISOString()}&endDate=${momentWrapper(
            endDate
          ).toISOString()}`;
        getRequest(url, {
          startDate,
          endDate,
        })
          .then((response) => {
            setData(response?.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        let url =
          PATIENT_VIEW_DATA_RESPIRONICS_URL +
          selectedPatientReportAndPatient?.patient?._id +
          `/?reportId=${location.reportId ? location.reportId : null}&startDate=${momentWrapper(startDate).toISOString()}&endDate=${momentWrapper(
            endDate
          ).toISOString()}`;
        getRequest(url)
          .then((res) => {
            setData(res.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const sendReportToMedicalDoctor = () => {
    setLoading(true);
    postRequest(SEND_REPORT_TO_MD + patientData?.data?._id, {
      reportMonth: Number(momentWrapper().format(`YYYYMM`)),
    })
      .then((response) => {
        setLoading(false);
        dispatchRedux(handleUpdatePatientData(patientDataFromReport));
        successToast(response?.data?.message);
      })
      .catch(() => setLoading(false));
  };

  const sendAddendumHandler = () => {
    setLoading(true);

    postRequest(SEND_ADDENDUM_REPORT + selectedPatientReport?._id)
      .then((res) => {
        setLoading(false);
        dispatchRedux(handleUpdatePatientData(patientDataFromReport));

        successToast(res?.data?.message);
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err.response.data.message ? err.response.data.message : err.response.data.error);
      });
  };

  // 4.Mark as Billed
  const markAsBilled = () => {
    setLoading(true);
    let url = `${UPDATE_REPORT_BILL_STATUS}${location?.reportId ? location?.reportId : selectedPatientReport?._id}`;
    patchRequest(url)
      .then(() => {
        dispatchRedux(handleUpdatePatientData(patientDataFromReport));
        successToast(`Report has been builed successfully`);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Sign as MD
  const handleSignasMD = async () => {
    let temp = null;
    if (loginUser?.defaultSignature?.base64String) {
      setLoading(true);

      dispatchRedux(handleReportLoadingFlag(true));
      setLoading(true);
      if (patientManufacturer === `resmed`) {
        let url =
          PATIENT_CREATE_REPORT_URL +
          targetPatient?._id +
          `/?reportId=${location.reportId ? location.reportId : selectedPatientReport?._id ? selectedPatientReport?._id : null}`;
        postRequest(
          url,
          {
            signature: loginUser.defaultSignature.base64String,
            startDate: momentWrapper().startOf(`month`).toISOString(),
            endDate: momentWrapper().toISOString(),
          },
          { responseType: `arraybuffer` }
        )
          .then((res) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              // dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Created successfully`);
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));
              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
          });
      } else if (patientManufacturer === `reactHealth`) {
        let url =
          PATIENT_CREATE_REPORT_URL_REACT_HEALTH +
          targetPatient?._id +
          `/?reportId=${location.reportId ? location.reportId : selectedPatientReport?._id ? selectedPatientReport?._id : null}`;
        postRequest(
          url,
          {
            signature: loginUser.defaultSignature.base64String,
            startDate: momentWrapper().startOf(`month`).toISOString(),
            endDate: momentWrapper().toISOString(),
          },
          { responseType: `arraybuffer` }
        )
          .then((response) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              // dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Created successfully`);
              temp = new Blob([response.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));
              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
          });
      } else if (patientManufacturer === `respironics`) {
        let url =
          PATIENT_CREATE_REPORT_URL_RESPIRONICS +
          targetPatient?._id +
          `/?reportId=${location.reportId ? location.reportId : selectedPatientReport?._id ? selectedPatientReport?._id : null}`;
        postRequest(
          url,
          {
            signature: loginUser.defaultSignature.base64String,
            startDate: momentWrapper().startOf(`month`).toISOString(),
            endDate: momentWrapper().toISOString(),
          },
          { responseType: `arraybuffer` }
        )
          .then((res) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              // dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Created successfully`);
              // STEP - 1
              // OPEN MODAL, TOO VIEW REPORT
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));

              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
            errorToast(err?.response?.status === 404 && `Session data not available`);
          });
      }
    } else {
      errorToast(`Please create your Signature before signing the report`);
    }
  };

  // Make Addendum
  const makeAddendum = async () => {
    if (loginUser?.defaultSignature?.base64String) {
      setLoading(true);
      dispatchRedux(handleReportLoadingFlag(true));
      let temp = null;
      if (patientManufacturer === `resmed`) {
        let url = CREATE_ADDENDUM_REPORT + targetPatient?._id + `/?reportId=${location?.reportId ? location?.reportId : selectedPatientReport?._id}`;
        postRequest(
          url,
          {
            signature: loginUser.defaultSignature.base64String,
          },
          { responseType: `arraybuffer` }
        )
          .then((res) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              // dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Addendum successfully`);
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));

              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
            errorToast(err?.response?.status === 404 && `Session data not available`);
          });
      } else if (patientManufacturer === `reactHealth`) {
        let url =
          CREATE_ADDENDUM_REPORT_REACT_HEALTH +
          targetPatient?._id +
          `/?reportId=${location?.reportId ? location?.reportId : selectedPatientReport?._id}`;
        postRequest(
          url,
          {
            signature: loginUser.defaultSignature.base64String,
          },
          { responseType: `arraybuffer` }
        )
          .then((response) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              // dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Addendum successfully`);
              temp = new Blob([response.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));
              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
            errorToast(err?.response?.status === 404 && `Session data not available`);
          });
      } else if (patientManufacturer === `respironics`) {
        let url =
          CREATE_ADDENDUM_REPORT_RESPIRONICS +
          targetPatient?._id +
          `/?reportId=${location?.reportId ? location?.reportId : selectedPatientReport?._id}`;
        postRequest(
          url,
          {
            signature: loginUser.defaultSignature.base64String,
          },
          { responseType: `arraybuffer` }
        )
          .then((res) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              // dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Addendum successfully`);
              // STEP - 1
              // OPEN MODAL, TOO VIEW REPORT
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));

              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
            errorToast(err?.response?.status === 404 && `Session data not available`);
          });
      }
    } else {
      errorToast(`Please create your Signature before signing the report`);
    }
  };

  const sendAddendum = () => {
    loginUser?.isClinicalStaff &&
      (selectedPatientReport?.reportStatus === `SIGNED` || selectedPatientReport?.reportStatus === `ADDENDUMED`) &&
      (selectedPatientReport?.billingStatus === `READY_TO_BILL` || selectedPatientReport?.billingStatus === `BILLED`) &&
      sendAddendumHandler();
  };

  const sendToMd = () => {
    if (loginUser?.isClinicalStaff && patientTimeInSeconds / 60 > 20 && patientData?.data?.interactiveCommunication) {
      sendReportToMedicalDoctor();
    }
  };

  // const predefinedRanges = createStaticRanges([
  //   {
  //     label: `Last 30 days`,
  //     range() {
  //       return {
  //         startDate: moment().startOf(`day`).subtract(30, `days`).startOf(`day`).format(`MM/DD/YYYY`),
  //         endDate: moment().endOf(`day`).subtract(1, `days`).format(`MM/DD/YYYY`),
  //       };
  //     },
  //   },
  //   {
  //     label: `Last 7 days`,
  //     range() {
  //       return {
  //         startDate: moment().startOf(`day`).subtract(7, `days`).startOf(`day`).format(`MM/DD/YYYY`),
  //         endDate: moment().endOf(`day`).subtract(1, `days`).format(`MM/DD/YYYY`),
  //       };
  //     },
  //   },
  //   {
  //     label: `Last month`,
  //     range() {
  //       return {
  //         startDate: moment().startOf(`month`).subtract(1, `day`).startOf(`month`).format(`MM/DD/YYYY`),
  //         endDate: moment().startOf(`month`).subtract(1, `day`).endOf(`month`).format(`MM/DD/YYYY`),
  //       };
  //     },
  //   },
  //   {
  //     label: `This month`,
  //     range() {
  //       return {
  //         startDate: moment().startOf(`month`).format(`MM/DD/YYYY`),
  //         endDate: moment().subtract(1, `days`).endOf(`day`).format(`MM/DD/YYYY`),
  //       };
  //     },
  //   },
  //   {
  //     label: `Today`,
  //     range() {
  //       return {
  //         startDate: moment().startOf(`day`).format(`MM/DD/YYYY`),
  //         endDate: moment().endOf(`day`).format(`MM/DD/YYYY`),
  //       };
  //     },
  //   },
  //   {
  //     label: `Yesterday`,
  //     range() {
  //       return {
  //         startDate: moment().subtract(1, `day`).startOf(`day`).format(`MM/DD/YYYY`),
  //         endDate: moment().subtract(1, `day`).endOf(`day`).format(`MM/DD/YYYY`),
  //       };
  //     },
  //   },
  // ]);

  const closeDateRangeModal = () => {
    setModal(false);
    setDateRangeSelection(persistDateRangeSelection);
  };

  let isValidUser = doIfUserIsFromCareTeam();

  console.log(currentTab, `,currentTab`)

  return (
    <div id={css.viewDataMain} className="mb-4">
      {/* {user && */}
      <>
        <div className="mb-3 pt-1 w-100 d-flex">
          <InputField
            size="small"
            fullWidth={true}
            // disabled={selectedPatientIdFromReport ? false : true}
            type="text"
            name="from"
            id="from-date"
            disabled={location?.reportId || !selectedPatientIdFromReport ? true : false}
            onClick={() => setModal(true)}
            value={`${persistDateRangeSelection[0]?.startDate} - ${persistDateRangeSelection[0]?.endDate}`}
          />
          <CustomizedDialogs
            noTitle={true}
            open={showModal}
            setOpen={() => closeDateRangeModal()}
            size="md"
            fullWidth={true}
            showCloseButton={false}
            customButton={true}
            customButtonText="Apply"
            customButtonAction={() => applyDateRangeApiCall()}
          >
            <div style={{ margin: -7 }}>
              <DateRangePicker
                maxDate={moment().endOf(`day`).toDate()}
                startDatePlaceholder="From"
                endDatePlaceholder="to"
                onChange={(item) => getDataHandler(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[
                  {
                    startDate: momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toDate(),
                    endDate: momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).toDate(),
                    key: `selection`,
                  },
                ]}
                staticRanges={predefinedRanges}
                inputRanges={[defaultInputRanges[0]]}
                direction="horizontal"
              />
            </div>
          </CustomizedDialogs>
        </div>

        <div className="d-flex justify-content-between">
          <div id={css.customViewDataHead}>
            <div id={currentTab === `summary` ? css.menuItemSelected : css.menuItem} onClick={() => setCurrentTab(`summary`)} className="p-3 mr-1">
              Summary Trend
            </div>
            <div id={currentTab === `detail` ? css.menuItemSelected : css.menuItem} onClick={() => setCurrentTab(`detail`)} className="p-3 mr-1">
              Detailed Trend
            </div>
            <div id={currentTab === `monitAir` ? css.menuItemSelected : css.menuItem} onClick={() => setCurrentTab(`monitAir`)} className="p-3">
              MonitAir Trend
            </div>
            {!isDme() && (
              <div
                id={currentTab === `createReport` ? css.menuItemSelected : css.menuItem}
                onClick={() => setCurrentTab(`createReport`)}
                className="p-3"
              >
                Create Report
              </div>
            )}
          </div>

          <button
            className="btn"
            id={css.collapseButton}
            onClick={() => setVisibleFlag(!visibleFlag)}
            type="button"
            data-toggle="collapse"
            data-target="#patientViewDataComponent"
            aria-expanded="true"
            aria-controls="patientBasicInfo"
          >
            {visibleFlag ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-right" aria-hidden="true"></i>}
          </button>
        </div>
        <div className="collapse show" id="patientViewDataComponent" style={{ position: `relative` }}>
          {selectedPatient ? (
            currentTab === `detail` ? (
              <div id={css.patientViewData} className="w-100 pl-3 pr-3 pb-2">
                <ViewDataDetail
                  user={selectedPatient}
                  data={data}
                  setIframeRefreshFlag={setIframeRefreshFlag}
                  start={momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toDate()}
                  end={momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).startOf(`day`).toDate()}
                  userId={selectedPatient?._id}
                  reportId={selectedPatientReport?._id}
                  dateHandler={getDataHandler}
                  selectedPatient={selectedPatient}
                />
              </div>
            ) : currentTab === `createReport` ? (
              <div className="dropdown">
                <table className="table table-borderless table-hover mb-0">
                  <tbody>
                    {/* Send Addendum */}
                    <tr
                      style={{
                        borderBottom: `1px solid rgb(211, 209, 209)`,
                      }}
                      id={css.row}
                    >
                      {loginUser?.isClinicalStaff && (
                        <td
                          style={{
                            cursor:
                              loginUser?.isClinicalStaff &&
                                (selectedPatientReport?.reportStatus === `SIGNED` || selectedPatientReport?.reportStatus === `ADDENDUMED`) &&
                                (selectedPatientReport?.billingStatus === `READY_TO_BILL` || selectedPatientReport?.billingStatus === `BILLED`)
                                ? `pointer`
                                : `not-allowed`,
                          }}
                        >
                          <h5
                            style={
                              loginUser?.isClinicalStaff &&
                                (selectedPatientReport?.reportStatus === `SIGNED` || selectedPatientReport?.reportStatus === `ADDENDUMED`) &&
                                (selectedPatientReport?.billingStatus === `READY_TO_BILL` || selectedPatientReport?.billingStatus === `BILLED`)
                                ? {
                                  fontSize: `small`,
                                  color: `black`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                                : {
                                  fontSize: `small`,
                                  color: `rgb(214, 213, 213)`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                            }
                            onClick={() => {
                              if (
                                loginUser?.isClinicalStaff &&
                                (selectedPatientReport?.reportStatus === `SIGNED` || selectedPatientReport?.reportStatus === `ADDENDUMED`) &&
                                (selectedPatientReport?.billingStatus === `READY_TO_BILL` || selectedPatientReport?.billingStatus === `BILLED`)
                              ) {
                                wrappWithTimerCheck5s(sendAddendum, true);
                              }
                            }}
                          >
                            {/* Clinical staff option */}
                            Send addendum
                          </h5>
                        </td>
                      )}

                      {loginUser?.isProvider && isPrimaryPatient() && (
                        <td
                          style={{
                            cursor:
                              isValidUser &&
                                loginUser?.isProvider &&
                                (selectedPatientReport?.reportStatus === `SIGNED` ||
                                  selectedPatientReport?.reportStatus === `READY_TO_ADDENDUM` ||
                                  selectedPatientReport?.reportStatus == `ADDENDUMED`) &&
                                !reportLoadingFlag
                                ? `pointer`
                                : `not-allowed`,
                          }}
                          data-toggle={
                            isValidUser &&
                              loginUser?.isProvider &&
                              (selectedPatientReport?.reportStatus === `SIGNED` ||
                                selectedPatientReport?.reportStatus === `READY_TO_ADDENDUM` ||
                                selectedPatientReport?.reportStatus == `ADDENDUMED`) &&
                              !reportLoadingFlag
                              ? `modal`
                              : null
                          }
                          data-target={
                            isValidUser &&
                              loginUser?.isProvider &&
                              (selectedPatientReport?.reportStatus === `SIGNED` ||
                                selectedPatientReport?.reportStatus === `READY_TO_ADDENDUM` ||
                                selectedPatientReport?.reportStatus == `ADDENDUMED`) &&
                              !reportLoadingFlag
                              ? `#signaturePadModal`
                              : null
                          }
                        >
                          <h5
                            style={
                              isValidUser &&
                                loginUser?.isProvider &&
                                (selectedPatientReport?.reportStatus === `SIGNED` ||
                                  selectedPatientReport?.reportStatus === `READY_TO_ADDENDUM` ||
                                  selectedPatientReport?.reportStatus == `ADDENDUMED`) &&
                                !reportLoadingFlag
                                ? {
                                  fontSize: `small`,
                                  color: `black`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                                : {
                                  fontSize: `small`,
                                  color: `rgb(214, 213, 213)`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                            }
                            onClick={() => {
                              if (
                                isValidUser &&
                                loginUser?.isProvider &&
                                (selectedPatientReport?.reportStatus === `SIGNED` ||
                                  selectedPatientReport?.reportStatus === `READY_TO_ADDENDUM` ||
                                  selectedPatientReport?.reportStatus == `ADDENDUMED`) &&
                                !reportLoadingFlag
                              ) {
                                wrappWithTimerCheck5s(makeAddendum, true);
                              }
                            }}
                          >
                            {/* Provider option */}
                            Make addendum
                          </h5>
                        </td>
                      )}
                    </tr>
                    {/* Send to MD */}
                    <tr
                      style={{
                        borderBottom: `1px solid rgb(211, 209, 209)`,
                      }}
                      id={css.row}
                    >
                      {loginUser?.isClinicalStaff && (
                        <td
                          onClick={() => {
                            if (
                              loginUser?.isClinicalStaff &&
                              patientTimeInSeconds / 60 > 20 &&
                              patientData?.data?.interactiveCommunication &&
                              !selectedPatientReport
                            ) {
                              wrappWithTimerCheck5s(sendToMd, true);
                            }
                          }}
                          style={{
                            cursor:
                              loginUser?.isClinicalStaff &&
                                patientTimeInSeconds / 60 > 20 &&
                                patientData?.data?.interactiveCommunication &&
                                !selectedPatientReport
                                ? `pointer`
                                : `not-allowed`,
                          }}
                        >
                          <h5
                            style={
                              loginUser?.isClinicalStaff &&
                                patientTimeInSeconds / 60 > 20 &&
                                patientData?.data?.interactiveCommunication &&
                                !selectedPatientReport
                                ? {
                                  fontSize: `small`,
                                  color: `black`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                                : {
                                  fontSize: `small`,
                                  color: `rgb(214, 213, 213)`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                            }
                          >
                            Send to MD
                          </h5>
                        </td>
                      )}
                    </tr>
                    {/* Sign as MD */}
                    <tr
                      style={{
                        borderBottom: `1px solid rgb(211, 209, 209)`,
                      }}
                      id={css.row}
                    >
                      {loginUser?.isProvider && isPrimaryPatient() && (
                        <td
                          data-toggle={
                            isValidUser &&
                              patientTimeInSeconds / 60 > 20 &&
                              loginUser?.isProvider &&
                              patientData?.data?.interactiveCommunication &&
                              (selectedPatientReport
                                ? selectedPatientReport?.reportStatus === `READY_TO_SIGN` &&
                                selectedPatientReport?.billingStatus === `NOT_READY_TO_BILL`
                                : true) &&
                              !reportLoadingFlag
                              ? `modal`
                              : null
                          }
                          data-target={
                            isValidUser &&
                              patientTimeInSeconds / 60 > 20 &&
                              loginUser?.isProvider &&
                              patientData?.data?.interactiveCommunication &&
                              (selectedPatientReport
                                ? selectedPatientReport?.reportStatus === `READY_TO_SIGN` &&
                                selectedPatientReport?.billingStatus === `NOT_READY_TO_BILL`
                                : true) &&
                              !reportLoadingFlag
                              ? `#signaturePadModal`
                              : null
                          }
                          style={{
                            cursor:
                              isValidUser &&
                                patientTimeInSeconds / 60 > 20 &&
                                loginUser?.isProvider &&
                                patientData?.data?.interactiveCommunication &&
                                (selectedPatientReport
                                  ? selectedPatientReport?.reportStatus === `READY_TO_SIGN` &&
                                  selectedPatientReport?.billingStatus === `NOT_READY_TO_BILL`
                                  : true) &&
                                !reportLoadingFlag
                                ? `pointer`
                                : `not-allowed`,
                          }}
                        >
                          <h5
                            style={
                              isValidUser &&
                                patientTimeInSeconds / 60 > 20 &&
                                loginUser?.isProvider &&
                                patientData?.data?.interactiveCommunication &&
                                (selectedPatientReport
                                  ? selectedPatientReport?.reportStatus === `READY_TO_SIGN` &&
                                  selectedPatientReport?.billingStatus === `NOT_READY_TO_BILL`
                                  : true) &&
                                !reportLoadingFlag
                                ? {
                                  fontSize: `small`,
                                  color: `black`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                                : {
                                  fontSize: `small`,
                                  color: `rgb(214, 213, 213)`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                            }
                            onClick={() => {
                              if (
                                isValidUser &&
                                patientTimeInSeconds / 60 > 20 &&
                                loginUser?.isProvider &&
                                patientData?.data?.interactiveCommunication &&
                                (selectedPatientReport
                                  ? selectedPatientReport?.reportStatus === `READY_TO_SIGN` &&
                                  selectedPatientReport?.billingStatus === `NOT_READY_TO_BILL`
                                  : true) &&
                                !reportLoadingFlag
                              ) {
                                wrappWithTimerCheck5s(handleSignasMD, true);
                              }
                            }}
                          >
                            Sign as MD
                          </h5>
                        </td>
                      )}
                    </tr>

                    {/* Mark as Billed */}
                    {!loginUser?.isClinicalStaff && isPrimaryPatient() && (
                      <tr
                        style={{
                          borderBottom: `1px solid rgb(211, 209, 209)`,
                        }}
                        id={css.row}
                      >
                        <td
                          style={{
                            cursor: loginUser?.isProvider && selectedPatientReport?.billingStatus === `READY_TO_BILL` ? `pointer` : `not-allowed`,
                          }}
                        >
                          <h5
                            style={
                              loginUser?.isProvider && selectedPatientReport?.billingStatus === `READY_TO_BILL`
                                ? {
                                  fontSize: `small`,
                                  color: `black`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                                : {
                                  fontSize: `small`,
                                  color: `rgb(214, 213, 213)`,
                                  padding: `10px`,
                                  margin: 0,
                                }
                            }
                            onClick={() => {
                              if (loginUser?.isProvider && selectedPatientReport?.billingStatus === `READY_TO_BILL`) {
                                wrappWithTimerCheck5s(markAsBilled, true);
                              }
                            }}
                          >
                            Mark as billed
                          </h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : currentTab === `monitAir` ? (
              <div id={css.patientViewData} className="w-100 pl-3 pr-3 pb-2">
                <ViewDataMonitAir user={selectedPatient?._id} />
              </div>
            ) : (
              <div id={css.patientViewData} className="w-100 pl-3 pr-3 pb-2">
                <ViewDataSummary
                  setIframeRefreshFlag={setIframeRefreshFlag}
                  data={data}
                  start={momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toDate()}
                  end={momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).endOf(`day`).toDate()}
                  userId={selectedPatient?._id}
                  reportId={selectedPatientReport?._id}
                  dateHandler={getDataHandler}
                  selectedPatient={selectedPatient}
                />
              </div>
            )
          ) : (
            <div style={{ color: `#c1c1c1` }} id={css.patientViewData} className="d-flex justify-content-center align-items-center">
              Click patient from list to show its summary.
            </div>
          )}
          {loading && <PuffSpinner />}
        </div>
        {/* <ReportPdfViewer show={reportCreatedFlag} setShow={setReportCreatedFlag} fileURL={fileURL} /> */}
      </>
    </div>
  );
};
