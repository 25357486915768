import React from 'react';
import { ListSubheader, Stack, TextField, Box, MenuItem } from '@mui/material';

export const NavigatePractices = ({ pagination, setPagination }) => {
  let { currentPage, rowsPerPage, totalRows, totalPage } = pagination;
  const onChangeHandler = (value, key) => {
    setPagination((p) => ({
      ...p,
      [key]: value,
      filterFlag: p.filterFlag + 1,
    }));
  };
  return (
    <ListSubheader>
      <Stack sx={{ mt: 1 }} direction={`row`}>
        <Box>
          {` `}
          <TextField
            select
            label="Rows-Per-Page"
            name="paginationSearchPractices"
            id="paginationSearchPractices"
            value={rowsPerPage}
            size="small"
            onChange={(e) => {
              let parsedValue = parseInt(e.target.value);
              onChangeHandler(parsedValue, `rowsPerPage`);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </TextField>
        </Box>
        <Box sx={{ marginLeft: `20px` }}>
          {` `}
          <p>
            {currentPage * rowsPerPage -
              rowsPerPage +
              1 +
              `-` +
              (currentPage * rowsPerPage > totalRows ? totalRows : currentPage * rowsPerPage) +
              ` of ` +
              totalRows}
          </p>
        </Box>
        <Box sx={{ marginLeft: `20px` }}>
          <button
            onClick={() => {
              onChangeHandler(1, `currentPage`);
            }}
            disabled={currentPage !== 1 ? false : true}
          >
            <i className="fa fa-angle-double-left"></i>
          </button>
          <button
            onClick={() => {
              let navigateBack = currentPage - 1;
              onChangeHandler(navigateBack, `currentPage`);
            }}
            disabled={currentPage !== 1 ? false : true}
          >
            <i className="fa fa-angle-left"></i>
          </button>
          <button
            onClick={() => {
              let navigateForward = currentPage + 1;
              onChangeHandler(navigateForward, `currentPage`);
            }}
            disabled={currentPage !== totalPage ? false : true}
          >
            <i className="fa fa-angle-right"></i>
          </button>
          <button
            onClick={() => {
              onChangeHandler(totalPage, `totalPage`);
            }}
            disabled={currentPage !== totalPage ? false : true}
          >
            <i className="fa fa-angle-double-right"></i>
          </button>
        </Box>
      </Stack>
    </ListSubheader>
  );
}
