import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import css from './list-all-tickets.module.css';
import { AdvanceFilter } from './advanceFilter/advance-filter';
import { SysAdminViewIssue } from './sysAdminViewIssue/sysAdmin-view-issue';
import { PuffSpinner } from '../../../../../../../common/components/spinner/puff/puff';
import { bootsTrapBadgeClasses, capitalizeFirstLetter, ticketStatuses } from 'src/utils';
import { getTicketsSysAdmin, updateAllTickets } from '../../../../../../../../redux/ticketsCenter/ticketsCenterActions';
import { Fade, useMediaQuery, Zoom } from '@mui/material';
import { momentWrapper } from 'src/momentWrapper';
import { CapitalizeFirstLetterComp } from 'src/pages/common/HOC/CapitalizeFirstLetterComp';
import { HtmlFormatter } from 'src/pages/common/components/HtmlFormatter';
import { CollapseSingleWords } from 'src/pages/common/HOC/CollapseSingleWords';
import { TruncateWraper } from 'src/pages/common/HOC/TruncateWraper';

export const ListAllTickets = () => {
  const [searchFilters, setSearchFilters] = useState({});
  const [selectedIssue, setSelectedIssue] = useState(``);
  const [selectedIssueModalHandler, setSelectedIssueModalHandler] = useState(false);
  const [issueListFlag, setIssueListFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const data = useSelector((state) => state?.ticketsCenter?.data);
  const totalRows = useSelector((state) => state?.ticketsCenter?.totalRows);
  const totalPage = useSelector((state) => state?.ticketsCenter?.totalPage);
  const loading = useSelector((state) => state?.ticketsCenter?.loading);
  const dispatch = useDispatch();
  const matches = useMediaQuery(`(max-width:768px)`);
  const matchesMedium = useMediaQuery(`(max-width:1050px)`);
  const matchesLarge = useMediaQuery(`(max-width:1460px)`);

  useEffect(() => {
    if (issueListFlag || !issueListFlag) {
      let prepareObj = Object.keys(searchFilters)?.length ? { searchFilters: { ...searchFilters } } : false;
      dispatch({ type: `FILTERS_TICKETS`, payLoad: prepareObj });
      dispatch(getTicketsSysAdmin(rowsPerPage, currentPage, prepareObj));
    }
  }, [issueListFlag, rowsPerPage, currentPage, searchFilters]);

  useEffect(() => {
    return () => dispatch({ type: `BLANK_TICKETS` });
  }, []);

  const openSpecificIssueHandler = (issueId) => {
    setSelectedIssue(issueId);
    setSelectedIssueModalHandler(true);
    dispatch(updateAllTickets({ role: `system`, ticketId: issueId }));
  };

  let widthStyle;

  if (matches || matchesMedium) {
    widthStyle = `200% !important`;
  } else if (matchesLarge) {
    widthStyle = `150% !important`;
  } else {
    widthStyle = `100% !important`;
  }

  const style = { width: widthStyle };

  let customWidth;

  if (matches || matchesMedium) {
    customWidth = `200%`;
  } else if (matchesLarge) {
    customWidth = `150%`;
  } else {
    customWidth = `100%`;
  }

  const styleWidth = { width: customWidth };

  const resetPagination = () => {
    setRowsPerPage(10);
    setCurrentPage(1);
  };

  return (
    <div id={css.main} style={{ position: `relative` }}>
      <AdvanceFilter resetPagination={resetPagination} setSearchFilters={setSearchFilters} setIssueListFlag={setIssueListFlag} />
      <div className="table-responsive" style={style}>
        <table style={styleWidth} className="table table-sm table-borderless table-striped">
          <thead>
            <tr
              style={{
                width: `10%`,
                textAlign: `start`,
                verticalAlign: `middle`,
              }}
            >
              <th scope="col"></th>
              <th scope="col">TITLE</th>
              <th scope="col">TYPE</th>
              <th scope="col">PRIORITY</th>
              <th>STATUS</th>
              <th>PRACTICE NAME</th>
              <th>OPEN DATE</th>
              <th>CLOSE DATE</th>
              <th>CREATED BY</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((ticket) => {
                const handleBadgleClass = (cond, success, failure) => {
                  if (cond) {
                    return success;
                  }
                  return failure;
                };
                return (
                  <Zoom key={ticket._id} in={data?.length} mountOnEnter unmountOnExit timeout={500}>
                    <tr className="text-start">
                      <td>
                        <Fade in={!ticket?.readBySysAdmin} timeout={500}>
                          <span style={{ color: `red` }}>&#9679;</span>
                        </Fade>
                      </td>
                      <CollapseSingleWords
                        prps={{
                          validHeight: 55,
                          descTxt: `Click the icon to show the title`,
                          contStyles: { fontSize: { xs: `10px`, sm: `10px`, md: `10px`, lg: `x-small`, xl: `small` } },
                          descTxtStyles: { mt: 1.5 },
                        }}
                      >
                        <TruncateWraper
                          tag={`td`}
                          tagStyles={{
                            WebkitLineClamp: `1`,
                            maxWidth: `25.5vw`,
                          }}
                        >
                          <HtmlFormatter title={ticket.issueTitle} />
                        </TruncateWraper>
                      </CollapseSingleWords>
                      <td style={{ textTransform: `capitalize` }}>{ticket.issueType}</td>
                      <td style={{ textTransform: `capitalize` }}>
                        <span
                          className={`badge badge-${handleBadgleClass(
                            ticket.issuePriority === bootsTrapBadgeClasses.normal,
                            bootsTrapBadgeClasses.primary,
                            bootsTrapBadgeClasses.info
                          )}`}
                        >
                          {ticket.issuePriority}
                        </span>
                      </td>
                      <td style={{ textTransform: `capitalize` }}>
                        <span
                          className={`badge badge-${handleBadgleClass(
                            ticket.issueStatus === ticketStatuses.opened,
                            bootsTrapBadgeClasses.success,
                            bootsTrapBadgeClasses.danger
                          )}`}
                        >
                          {ticket.issueStatus}
                        </span>
                      </td>
                      <td className="text-truncate" style={{ maxWidth: `1.5vw` }}>
                        {capitalizeFirstLetter(ticket?.practiceName)}
                      </td>
                      <td>{momentWrapper(ticket.openedTicketDate).format(`MM/DD/YYYY`)}</td>
                      <td>{ticket?.issueStatus === `closed` ? <Moment date={ticket.closedTicketDate} format="MM/DD/YYYY" /> : `-`}</td>
                      <td style={{ textTransform: `capitalize` }}>
                        <CapitalizeFirstLetterComp>{`${ticket?.created_by?.firstName} ${ticket?.created_by?.lastName}`}</CapitalizeFirstLetterComp>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="text-white"
                          style={{ width: `3em`, backgroundColor: `#1699c5` }}
                          onClick={() => openSpecificIssueHandler(`${ticket._id}`)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </Zoom>
                );
              })
            ) : (
              <tr>
                <td colSpan="9" style={{ textAlign: `center` }}>
                  No Record Found.
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr style={{ backgroundColor: `white`, margin: `2% 0%` }}>
              <td colSpan="9">
                <div className="d-flex justify-content-end align-items-center">
                  <p id={css.customTableFooterText}>Rows per page:</p>
                  <select
                    value={rowsPerPage}
                    id={css.rowsPerPage}
                    onChange={(e) => {
                      dispatch({ type: `SET_ROWS`, payLoad: parseInt(e.target.value) });
                      setCurrentPage(1);
                      setRowsPerPage(Number(e.target.value));
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <p id={css.customTableFooterText}>
                    {currentPage * rowsPerPage -
                      rowsPerPage +
                      1 +
                      `-` +
                      (currentPage * rowsPerPage > totalRows ? totalRows : currentPage * rowsPerPage) +
                      ` of ` +
                      totalRows}
                  </p>
                  <button
                    id={currentPage !== 1 ? css.customPageinationButton : css.customPageinationButtonDisabled}
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(1)}
                  >
                    <i className="fa fa-angle-double-left"></i>
                  </button>
                  <button
                    id={currentPage !== 1 ? css.customPageinationButton1 : css.customPageinationButton1Disabled}
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((val) => val - 1)}
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                  <button
                    id={currentPage !== totalPage || !data?.length ? css.customPageinationButton1 : css.customPageinationButton1Disabled}
                    disabled={currentPage === totalPage || !data?.length}
                    onClick={() => setCurrentPage((val) => val + 1)}
                  >
                    <i className="fa fa-angle-right"></i>
                  </button>
                  <button
                    id={currentPage !== totalPage || !data?.length ? css.customPageinationButton : css.customPageinationButtonDisabled}
                    disabled={currentPage === totalPage || !data?.length}
                    onClick={() => setCurrentPage(totalPage)}
                  >
                    <i className="fa fa-angle-double-right"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
        {loading?.loading && <PuffSpinner />}
        {selectedIssueModalHandler && (
          <SysAdminViewIssue
            selectedIssueId={selectedIssue}
            selectedIssueModalHandler={selectedIssueModalHandler}
            setSelectedIssueModalHandler={setSelectedIssueModalHandler}
            setIssueListFlag={setIssueListFlag}
          />
        )}
      </div>
    </div>
  );
};
