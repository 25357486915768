import React, { useState, useEffect } from 'react';
import { momentWrapper } from 'src/momentWrapper';
import './Message.css';
import { useHistory } from 'react-router-dom';
import regexifyString from 'regexify-string';
import { capitalizeFirstLetter } from 'src/utils';

export const Message = (props) => {
  const history = useHistory();
  const {
    data,
    isMine,
    timeStamp,
    startsSequence,
    endsSequence,
    showTimestamp,
  } = props;
  const [msg, setMsg] = useState(``);

  useEffect(() => {
    let msgObj = { msg: data };
    let msgAfterAddLinks = regexifyString({
      pattern: /@\[.+?\]\(.+?\)/g,
      decorator: (match) => {
        let userName = match.split(`[`)[1].split(`]`)[0];
        let userId = match.split(`(`)[1].split(`)`)[0];

        return (
          <button
            // to={`/patient`}
            className="userMessage"
            style={{ color: isMine ? `white` : `black`, border: `none`, outline: `none` }}
            onClick={() => moveToPatientModule(userId)}
            key={userId}
          >
            <i>{capitalizeFirstLetter(userName)}</i>
          </button>
        );
      },
      input: msgObj.msg,
    });
    setMsg(msgObj.msg);
    setMsg(msgAfterAddLinks);
  }, [data]);

  const friendlyTimestamp = momentWrapper(timeStamp).format(`LLLL`);

  const moveToPatientModule = (patientId) => {
    history.push({ pathname: `/patient`, patientId });
  };

  const classes = [`message`];
  if (isMine) {
    classes.push(`mine`);
  }
  if (startsSequence) {
    classes.push(`start`);
  }
  if (endsSequence) {
    classes.push(`end`);
  }

  const className = classes.join(` `);

  return (
    <div className={className}>
      <div className="bubbleContainer">
        <div className="bubble" title={friendlyTimestamp}>
          {msg}
          {showTimestamp && (
            <div className="timestamp" style={{ color: isMine ? `white` : `black` }}>
              {friendlyTimestamp}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
