import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import GppMaybeRoundedIcon from '@mui/icons-material/GppMaybeRounded';

export const Filterbutton = ({ arg, apiCall, filterBtn, loading, ownApi }) => {
  let { payLoad, setLoading, setData } = arg;
  return (
    <button
      disabled={filterBtn}
      onClick={() => (ownApi ? apiCall() : apiCall(payLoad, setData, setLoading))}
      className={`btn btn-outline-${loading?.error ? `danger` : `primary`} w-100`}
    >
      {loading?.loading && <CircularProgress size={20} thickness={6} />}
      {loading?.error && <GppMaybeRoundedIcon />}
      {!loading?.loading && !loading?.error && <span>Filter</span>}
    </button>
  );
}
