import { NOTIFY_ALL_TICKETS } from './ticketsCenterTypes';
let arrayTicketCenter = [];
const initialState = {
  arrayTicketCenter,
  filters: {},
  currentPage: 1,
  selectedRows: 10,
  totalPage: 1,
  totalRows: 0,
  latestIssueStatus: false,
  data: [],
  loading: {
    loading: false,
    error: false,
  },
};
export const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case `NOTIFY`:
      const { _id } = action.payLoad;
      let hasId = state.data.every((e) => e._id.includes(_id));
      let dataState = [...state.data];
      if (!hasId) dataState.pop();

      if (!hasId && state.currentPage === 1) {
        return { ...state, data: [action.payLoad, ...dataState], totalRows: state.totalRows + 1 };
      } else {
        return { ...state };
      }
    case `LATEST_ISSUE_STATUS`:
      return { ...state, latestIssueStatus: action.payLoad };
    case `CHANGE_CURRENT_PAGE`:
      return { ...state, currentPage: action.payLoad };

    case `HAS_READ`:
      let hasMatchd = state.data.filter((e) => e._id.includes(action.payLoad));

      if (hasMatchd) {
        return { ...state, data: state.data.map((e) => (action.payLoad === e._id ? { ...e, readBySysAdmin: true } : { ...e })) };
      } else {
        return { ...state };
      }
    case `SET_ROWS`:
      return {
        ...state,
        selectedRows: action.payLoad,
        currentPage: 1,
      };
    case NOTIFY_ALL_TICKETS:
      return { ...state, notifyAllTickets: action.payload };
    case `SET_LOADING`:
      return { ...state, loading: action.payLoad };
    case `FILTERS_TICKETS`:
      console.log(action.payLoad, `action.payLoad`)
      return { ...state, filters: action.payLoad };
    case `SET_DATA`:
      return { ...state, data: action.payLoad };
    case `SET_TOTAL_PAGE`:
      return { ...state, totalPage: action.payLoad };
    case `SET_TOTAL_ROWS`:
      return { ...state, totalRows: action.payLoad };
    case `BLANK_TICKETS`:
      return {
        ...state,
        data: [],
        totalPage: 1,
        totalRows: 0,
        selectedRows: 10,
        filters: {},
        loading: {
          loading: false,
          error: false,
        },
      };
    default:
      return state;
  }
};
