import React, { useState, useMemo } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Checkbox, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import { SearchInput } from '../LIstSubheader/SearchInput';
import { MuiPill } from '../BadgePill/BadgePill';

const useStyles = makeStyles((theme) => ({
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

export const MuiSelect = ({
  label,
  controlProps,
  options,
  optionKey,
  optionValue,
  isSimple,
  selectProps,
  showNone,
  multiSelectProps = {},
  searchInpProps = { isSearchAble: false, searchKeys: [], searchInputLabel: 'Search...' },
}) => {
  const { labelStyle, helperText, multiple = false, value = selectProps?.multiple ? [] : '', onChange = () => null } = selectProps || false;

  const renderLable = (option, isRenderChip) => {
    if (isRenderChip) return isSimple ? option : options?.find((opt) => opt[optionValue] === option)?.[optionKey];
    return isSimple ? option : option[optionKey];
  };

  let prepareSelProps = {
    ...selectProps,
    ...(multiple
      ? {
          renderValue: (selected) => (
            <Stack
              sx={{
                display: 'initial',
                WebBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '90%',
                WebkitLineClamp: '1',
                whiteSpace: 'nowrap',
              }}
              gap={1}
              direction="row"
              flexWrap="wrap"
            >
              {selected?.map((val) => {
                return (
                  <MuiPill
                    size="small"
                    key={val}
                    pillProps={{
                      sx: {
                        backgroundColor: '#1699c5',
                        color: 'white',
                      },
                    }}
                  >
                    {renderLable(val, true)}
                  </MuiPill>
                );
              })}
            </Stack>
          ),
        }
      : {}),
    onChange: (e) => {
      if (multiple) {
        const {
          target: { value: selectValue },
        } = e;
        if (selectValue[selectValue.length - 1] === 'all') {
          onChange(
            value.length === options.length
              ? []
              : options?.map((opt) => {
                  return opt[optionValue];
                })
          );
          return;
        }
        onChange(selectValue);
      } else onChange(e);
    },
  };
  const { id: controlId, error } = controlProps || false;
  const { isSearchAble, searchKeys, searchInputLabel } = searchInpProps;
  const { isSelectAll = false } = multiSelectProps;
  const [searchValue, setSearchValue] = useState(``);
  const classes = useStyles();
  const isAllSelected = options?.length > 0 && value?.length === options?.length;

  let filteredSearchItems = useMemo(() => {
    if (isSearchAble) {
      const makeFilter = () => {
        return (
          Array.isArray(options) &&
          searchKeys.map((e) => {
            return options.filter((elem) => elem[e]?.toLowerCase()?.includes(searchValue?.toLowerCase()));
          })
        );
      };
      let largestArray = makeFilter().reduce((acc, current) => {
        if (current.length > acc.length) {
          return current;
        } else {
          return acc;
        }
      }, []);
      return largestArray;
    }
    return options;
  }, [searchKeys, searchValue, isSearchAble]);

  return (
    <FormControl {...controlProps}>
      <InputLabel
        {...(error
          ? { sx: { color: `red`, left: `-2%`, ...(labelStyle ? { ...labelStyle } : {}) } }
          : { sx: { left: `-2%`, ...(labelStyle ? { ...labelStyle } : {}) } })}
        {...(controlId ? { id: controlId } : {})}
      >
        {label}
      </InputLabel>
      <Select {...prepareSelProps} {...(error ? { error: true } : { error: false })}>
        {isSearchAble && <SearchInput label={searchInputLabel} keyValue={false} isObj={false} setSearchValue={setSearchValue} />}
        {multiple && isSelectAll && (
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : '',
            }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={value.length > 0 && value.length < options.length}
              />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
          </MenuItem>
        )}
        {showNone && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {filteredSearchItems.map((option, index) => (
          <MenuItem key={option[optionValue + index]} value={isSimple ? option : option[optionValue]}>
            {multiple ? (
              <ListItemIcon>
                <ListItemIcon>
                  <Checkbox checked={value.indexOf(isSimple ? option : option[optionValue]) > -1} />
                </ListItemIcon>
                <ListItemText sx={{ alignSelf: 'center', fontWeight: 200, color: 'black' }} primary={renderLable(option)} />
              </ListItemIcon>
            ) : (
              renderLable(option)
            )}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
