import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { InputField } from '../InputField/InputField';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

export const DatePickerCustomize = ({ showCross, onError, maxDate, onChange, dateInputValue, setDateInputValue, name, serial, step1RespironicsNumber, pickerProps, disabled, isFutureSelection, InputLabelProps }) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        onError={() => onError ? onError() : null}
        {...(pickerProps && { ...pickerProps })}
        disabled={(step1RespironicsNumber?.length && (!!(step1RespironicsNumber?.length > 0 || serial === `hasSerial`))) || disabled}
        value={dateInputValue || null}
        onChange={(newValue) => {
          if (onChange) {
            onChange(newValue?.$d)
          } else {
            setDateInputValue(newValue?.$d);
          }
        }}
        {...(!maxDate && !isFutureSelection ? { maxDate: new Date() } : {})}
        renderInput={(params) => (
          <div  {...(showCross ? { style: { position: `relative`, display: `inline-block`, ...(pickerProps?.fullWidth ? { width: `100%` } : {}) } } : {})}  >
            <InputField   {...(InputLabelProps && { InputLabelProps: { ...InputLabelProps } })} {...(pickerProps?.size && { size: pickerProps?.size })}  {...(pickerProps?.fullWidth && { fullWidth: true })}   {...(name && { name: name })}  {...params} />
            {showCross && dateInputValue ? <IconButton sx={{
              position: `absolute`,
              right: `28px`,
              color: `red`
            }}
              onClick={() => {
                if (setDateInputValue) setDateInputValue(``)
                else onChange(``)
              }}
            >
              <ClearIcon />
            </IconButton> : null}
          </div>
        )}
      />
    </LocalizationProvider>
  );
}

export const DateTimePickerMui = (props) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        {...props}
        {...(props?.onAccept && {
          onAccept: (value) => props.onAccept(value?.$d)
        })}
        onChange={(value) => props.onChange ? props.onChange(value) : null}
        renderInput={(params) => {
          return <InputField {...(props?.onClick ? { onClick: props.onClick } : {})} {...(props.onBlur && {
            onBlur: (e) => {
              const { target: { value } } = e
              props.onBlur(value)
            }
          })} size={`small`} fullWidth={true}   {...params} {...(props?.error && { error: true })} />
        }}

      />
    </LocalizationProvider>
  )
}

export const DatePickerCustomizeForFilter = ({ dateInputValue, setDateInputValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        value={dateInputValue || null}
        onChange={(event) => setDateInputValue(event?.$d)}
        pickerProps={{ size: `small` }}

        renderInput={(params) =>
          <InputField {...params}
            fullWidth
            size='small'
            placeholder='Start Date'
          />
        }
      />
    </LocalizationProvider>
  );
}
