import React, { useRef, useState } from 'react';
import { Completed } from './completed/Completed';
import { Grid, Slide } from '@mui/material';

export const ReportingAnalytics = () => {
  const [clicked, setClicked] = useState(false);
  const containerRef = useRef(null);
  return (
    <React.Fragment ref={containerRef}>
      <div className="d-flex justify-content-between w-100 mt-4">
        <h4 style={{ fontWeight: 625 }}>Reporting Analytics</h4>
        <button type="button" onClick={() => setClicked((p) => !p)} style={{ outline: `none` }}>
          <i className={clicked ? `fa fa-chevron-up` : `fa fa-chevron-down`} aria-hidden="true"></i>
        </button>
      </div>
      <hr className="w-100 m-0" />

      <Slide in={clicked} direction="up" timeout={600} mountOnEnter unmountOnExit container={containerRef.current}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Completed clicked={clicked} />
          </Grid>
        </Grid>
      </Slide>
      {/* <ReadyToSign/>
            <ReadtToBill/>  */}
    </React.Fragment>
  );
}

