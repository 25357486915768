import React, { useState } from 'react';
import css from './riskWidget.module.css';
import { PuffSpinner } from '../../../../../common/components/spinner/puff/puff';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import $ from 'jquery';

$(function () {
  $(`[data-toggle="tooltip"]`).tooltip();
});

export const MonitoringRiskWidget = ({ riskStratification }) => {
  const [loading] = useState(false);

  return (
    <div id={css.main}>
      <div className="d-flex justify-content-between align-items-center ml-4 mr-4 mt-4">
        <h4 style={{ fontWeight: 400 }}>Risk</h4>
        <Tooltip
          title={
            <>
              <i style={{ fontSize: `10px` }}>
                MonitAir stratifies patients based on
                <br />
                usage and residual AHI, during
                <br />
                the last 30 days.
                <br />
                <br />
                <b>Very high risk</b> patients have usage &lt;
                <br />
                6hrs/night for 21/30 days and AHI &gt; 5<br />
                for 21/30 days.
                <br />
                <br />
                <b>High risk</b> patients have usage &lt;
                <br />
                6hrs/night for 21/30 days and AHI &lt; 5<br />
                for 21/30 days.
                <br />
                <br />
                <b>Medium risk</b> patients have usage &gt;
                <br />
                6hrs/night for 21/30 days and AHI &gt; 5<br />
                for 21/30 days.
                <br />
                <br />
                <b>Low risk</b> patients have usage &gt;
                <br />
                6hrs/night for 21/30 days and AHI &lt; 5<br />
                for 21/30 days.
                <br />
              </i>
            </>
          }
          placement="right"
          arrow
        >
          <button type="button" data-toggle="tooltip" data-placement="top" title="">
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </button>
        </Tooltip>
      </div>

      <h5 className="mt-2 ml-4" style={{ fontWeight: `bold`, fontSize: `x-small` }}>
        Period
      </h5>
      <h5 className="ml-4" style={{ fontSize: `small`, color: `rgb(201, 201, 201)` }}>
        Last 30 Days
      </h5>

      {/* <div className="m-4" id={css.statsBar}></div> */}
      <div className="m-4">
        <div className="progress" style={{ height: `35px`, borderRadius: `0.35rem` }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${riskStratification.veryHighRiskPercentage ? riskStratification.veryHighRiskPercentage : 0}%`,
              backgroundColor: `#eb2f06`,
            }}
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${riskStratification.highRiskPercentage ? riskStratification.highRiskPercentage : 0}%`, backgroundColor: `#a70909` }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${riskStratification.moderateRiskPercentage ? riskStratification.moderateRiskPercentage : 0}%`,
              backgroundColor: `#227093`,
            }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${riskStratification.lowRiskPercentage ? riskStratification.lowRiskPercentage : 0}%`, backgroundColor: `#17a2b8` }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${riskStratification.notAvailablePercentage ? riskStratification.notAvailablePercentage : 0}%`,
              backgroundColor: `#95afc0`,
            }}
            aria-valuenow="20"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-sm table-borderless table-striped">
          <tbody id={css.widgetTable}>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#eb2f06` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?risk=veryHigh`}>Very High Risk</Link>
              </td>
              <td style={{ fontSize: `small` }}>
                {riskStratification.numberOfVeryHighRiskPatients ? riskStratification.numberOfVeryHighRiskPatients : 0}
              </td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {riskStratification.veryHighRiskPercentage ? riskStratification.veryHighRiskPercentage : 0}%
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#a70909` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?risk=high`}>High Risk</Link>
              </td>
              <td style={{ fontSize: `small` }}>{riskStratification.numberOfHighRiskPatients ? riskStratification.numberOfHighRiskPatients : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {riskStratification.highRiskPercentage ? riskStratification.highRiskPercentage : 0}%
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#227093` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?risk=moderate`}>Medium Risk</Link>
              </td>
              <td style={{ fontSize: `small` }}>
                {riskStratification.numberOfModerateRiskPatients ? riskStratification.numberOfModerateRiskPatients : 0}
              </td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {riskStratification.moderateRiskPercentage ? riskStratification.moderateRiskPercentage : 0}%
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#17a2b8` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?risk=low`}>Low Risk</Link>
              </td>
              <td style={{ fontSize: `small` }}>{riskStratification.numberOfLowRiskPatients ? riskStratification.numberOfLowRiskPatients : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {riskStratification.lowRiskPercentage ? riskStratification.lowRiskPercentage : 0}%
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#95afc0` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?Adherence=NO_DATA`}>{`No data > 30 days`}</Link>
              </td>
              <td style={{ fontSize: `small` }}>{riskStratification.numberOfNotAvailableRisks ? riskStratification.numberOfNotAvailableRisks : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {riskStratification.notAvailablePercentage ? riskStratification.notAvailablePercentage : 0}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {loading ? <PuffSpinner /> : null}
    </div>
  );
}