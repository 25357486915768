import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, Checkbox, Button, TableHead } from '@mui/material';
import { capitalizeFirstLetter } from 'src/utils';
import { momentWrapper } from 'src/momentWrapper';
import { useStyles } from './useStyles';

export const UserTableHeader = ({ updateSelectAllUsers, listOfUsers, sortHandler, sortKeyGiver, passArgs, sortIconHandler, selectAllPatients }) => {
  const ComponentStyles = useStyles();

  const tableHeaders = [
    {
      label: `Last Name`,
      key: `lastName`,
    },
    {
      label: `First Name`,
      key: `firstName`,
    },
    {
      label: `DOB`,
      key: `dob`,
    },
    {
      label: `Provider`,
      key: `provider`,
    },
    {
      label: `Clinical Staff`,
      key: `clinicalStaff`,
    },
    {
      label: `Status`,
      key: `status`,
    },
    {
      label: `Device Mfg`,
      key: `manufacture`,
    },
  ];

  return (
    <TableHead className={ComponentStyles.tableHeader}>
      <TableRow>
        <TableCell>
          <Checkbox
            onChange={(event) => {
              updateSelectAllUsers(listOfUsers, event.target.checked);
            }}
            disabled={selectAllPatients}
          />
        </TableCell>
        {tableHeaders?.map((header) => (
          <TableCell
            key={header.key}
            className={ComponentStyles.tableHeading}
            onClick={() => sortHandler(sortKeyGiver({ ...passArgs }, header.key), header.key)}
          >
            {header.label}
            {sortIconHandler({ ...passArgs }, header.key)}
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

export const UserTableRow = ({
  user,
  listOfTransferUsers = [],
  handleSelectUser,
  setModal = false,
  handleSelectedTwoUsersAndMore,
  updateSelectedOfUsers,
  index,
  showViewModal,
  selectAllPatients,
  excludedPatientIds,
  setExcludedPatientIds,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const newUserId = user?._id;
  const ComponentStyles = useStyles();

  useEffect(() => {
    if (selectAllPatients) {
      setIsChecked(!excludedPatientIds.includes(newUserId));
    } else {
      setIsChecked(listOfTransferUsers.includes(newUserId));
    }
  }, [selectAllPatients, listOfTransferUsers, newUserId]);

  const handleCheckboxChange = () => {
    if (!selectAllPatients) {
      const newChecked = !isChecked;
      setIsChecked(newChecked);
      updateSelectedOfUsers(newUserId, newChecked);
    } else if (isChecked) {
      setExcludedPatientIds((prevArray) => [...prevArray, newUserId]);
    } else {
      setExcludedPatientIds((prevArray) => prevArray.filter((id) => id !== newUserId));
    }
  };

  return (
    <TableRow key={index} className={index % 2 === 0 ? ComponentStyles.tableRowEven : ComponentStyles.tableRowOdd}>
      <TableCell>
        <Checkbox
          checked={isChecked}
          onChange={(event) => {
            setIsChecked(event?.target?.checked);
            handleCheckboxChange();
          }}
        />
      </TableCell>
      <TableCell>{capitalizeFirstLetter(user?.lastName)}</TableCell>
      <TableCell>{capitalizeFirstLetter(user?.firstName)}</TableCell>
      <TableCell>{momentWrapper(user?.dateOfBirth, `YYYY-MM-DD`).format(`MM/DD/YYYY`)}</TableCell>
      <TableCell>{`${capitalizeFirstLetter(user?.provider?.name)}`}</TableCell>
      <TableCell>{`${capitalizeFirstLetter(user?.clinicalUser?.name)}`}</TableCell>
      <TableCell>{capitalizeFirstLetter(user?.status?.current)}</TableCell>
      <TableCell>{capitalizeFirstLetter(user?.device?.manufacturer)}</TableCell>
      <TableCell>
        {showViewModal && (
          <Button
            variant="contained"
            className={ComponentStyles.containedButtonInTable}
            disabled={handleSelectedTwoUsersAndMore()}
            onClick={() => {
              handleSelectUser(user);
              setModal(true);
            }}
          >
            View
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
