import { useEffect, useRef } from 'react'
import { assignUniqueId } from 'src/utils'

export const useUniqueId = () => {
  let id = useRef(0)
  useEffect(() => {
    id.current = assignUniqueId()
  }, [])

  return id.current ? id.current : false
}