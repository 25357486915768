import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

const CustomizeModal = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== `hasHeight`,
})(({ theme, hasHeight }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    ...(hasHeight
      ? {
        height: `80vh`,
      }
      : {}),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CustomizeModalTitle = (props) => {
  const { children, onClose, disabled, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: `absolute`,
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

CustomizeModalTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export const CustomizedDialogs = ({
  size,
  noTitle,
  open,
  title,
  setOpen,
  children,
  fullWidth,
  customButton,
  customCheckbox,
  customCheckboxValue,
  customCheckboxAction,
  notShowDividers,
  customCheckboxLabel,
  showCloseButton,
  customButtonText,
  customButtonAction,
  customButtonDisable,
  disabled,
  hasHeight,
  customBtnStyles,
  isPdfSummaryReportAvailable,
  setOpenSummaryReport,
  paperProps = false,
}) => {
  const isOnVideo = useSelector((state) => state.patient.isOnVideo);

  const renderTitle = () => {
    if (noTitle) {
      return <DialogContent style={{ marginTop: 20 }}>{children}</DialogContent>
    }
    return notShowDividers ? <DialogContent>{children}</DialogContent>
      :
      <DialogContent dividers>{children}</DialogContent>
  }

  const spredBtnStyles = () => {
    if (customBtnStyles) {
      return {
        sx: {
          ...customBtnStyles
        }
      }
    }
    return null
  }

  const spreadVariant = () => {
    if (customBtnStyles?.variant) {
      return { variant: customBtnStyles?.variant }
    }
    return {}
  }

  return (
    <div>
      <CustomizeModal
        {...(paperProps && { PaperProps: { ...paperProps } })}
        onClose={(_, reason) => {
          if (reason !== `backdropClick`) {
            setOpen();
          }
        }}
        sx={{ ...(isOnVideo ? { zIndex: 5000 } : {}) }}
        maxWidth={size}
        fullWidth={fullWidth}
        open={open}
        aria-labelledby="customized-dialog-title"
        {...(hasHeight && { hasHeight })}
      >
        <CustomizeModalTitle
          id="customized-dialog-title"
          onClose={setOpen}
          disabled={!!disabled}
          {...(isPdfSummaryReportAvailable
            ? {
              sx: {
                display: `flex`,
              },
            }
            : {})}
        >
          {title}
          {isPdfSummaryReportAvailable ? (
            <Box>
              <Button
                sx={{ ml: 6, backgroundColor: `#1699c5`, textTransform: `none` }}
                variant={`contained`}
                disabled={!isPdfSummaryReportAvailable}
                onClick={() => (isPdfSummaryReportAvailable ? setOpenSummaryReport(true) : null)}
              >
                Click here to view summary
              </Button>
            </Box>
          ) : null}
        </CustomizeModalTitle>
        {renderTitle()}
        <DialogActions>
          {showCloseButton ? (
            <Button autoFocus onClick={setOpen}>
              Close
            </Button>
          ) : null}
          {customButton ? (
            <Button {...spreadVariant()} {...spredBtnStyles()} autoFocus disabled={customButtonDisable} onClick={customButtonAction}>
              {customButtonText}
            </Button>
          ) : null}
          {customCheckboxLabel ? <p style={{ fontWeight: `bold` }}>{customCheckboxLabel}</p> : null}
          {customCheckbox ? <Checkbox checked={customCheckboxValue} onClick={customCheckboxAction} /> : null}
        </DialogActions>
      </CustomizeModal>
    </div>
  );
};