import { Box, Button, Grid, IconButton, InputAdornment } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker, defaultInputRanges } from 'react-date-range';
import { momentWrapper } from 'src/momentWrapper';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import {
  checkInputsArray, predefinedRanges, _taskPriorityOptions, _taskTypeOptions,
  _taskStatusOptions
} from 'src/utils';
import CloseIcon from '@mui/icons-material/Close';

export const Filters = ({ setFiltersObj, resetFilters, setResetFilters, setTaskListFlag, resetRowsAndSorting }) => {
  let initailValueDates = {
    startDate: momentWrapper().startOf(`day`).subtract(29, `days`).toDate(),
    endDate: new Date(),
  };
  const [filters, setFilters] = useState({
    hasChangedDate: false,
    taskType: ``,
    taskPriority: ``,
    taskStatus: ``,
    dueDate: {
      startDate: initailValueDates?.startDate,
      endDate: initailValueDates?.endDate,
      key: `selection`,
      cloneStartDate: ``,
      cloneEndDate: ``,
      isMadeFilter: false,
    },
  });

  const {
    taskType,
    taskPriority,
    taskStatus,
    dueDate: { startDate, endDate, cloneStartDate, cloneEndDate, isMadeFilter },
  } = filters;

  const resetValues = () => {
    setFilters({
      hasChangedDate: false,
      taskType: ``,
      taskPriority: ``,
      taskStatus: ``,
      dueDate: {
        startDate: initailValueDates?.startDate,
        endDate: initailValueDates?.endDate,
        key: `selection`,
        cloneStartDate: ``,
        cloneEndDate: ``,
        isMadeFilter: false,
      },
    });
  };

  useEffect(() => {

    if (resetFilters) {
      // reset filters by clicking on home button
      resetValues();

      // set reset filters again to false after resetting all filters to empty
      setResetFilters(false);
    }

  }, [resetFilters]);

  const onChangeHandler = (key, e) => {
    const { value } = e.target;
    setFilters((p) => ({ ...p, [key]: value }));
  };

  let valuesChecking = [
    taskType,
    taskPriority,
    taskStatus,
    startDate && isMadeFilter ? momentWrapper(startDate).toISOString() : ``,
    endDate && isMadeFilter ? momentWrapper(endDate).toISOString() : ``,
  ];

  const validator = () => {
    let hasChecked = checkInputsArray([...valuesChecking]);
    if (hasChecked?.length) {
      let spreadFilters = { ...filters };
      for (let key in spreadFilters) {
        if (key === `dueDate` && spreadFilters[key]?.isMadeFilter) {
          let dates = {
            startDate: spreadFilters[key][`startDate`] ? moment(spreadFilters[key][`startDate`]).startOf(`day`).toISOString() : ``,
            endDate: spreadFilters[key][`endDate`] ? moment(spreadFilters[key][`endDate`]).endOf(`day`).toISOString() : ``,
          };
          spreadFilters = { ...spreadFilters, ...dates };
          delete spreadFilters[`dueDate`];
        } else {
          spreadFilters = { ...spreadFilters, startDate: ``, endDate: `` };
        }
      }
      setFiltersObj((p) => ({ ...p, ...spreadFilters }));
      setTaskListFlag();
      resetRowsAndSorting();
    } else {
      errorToast(`Please fill out some enteries! `);
    }
  };

  const resetHandler = () => {
    let hasChecked = checkInputsArray([...valuesChecking]);
    if (hasChecked) {
      setFilters({
        taskType: ``,
        taskPriority: ``,
        taskStatus: ``,
        dueDate: {
          startDate: initailValueDates?.startDate,
          endDate: initailValueDates?.endDate,
          key: `selection`,
          cloneStartDate: ``,
          cloneEndDate: ``,
          isMadeFilter: false,
        },
      });
    } else {
      return;
    }
  };

  const [showModal, setModal] = useState(false);
  const closeDateRangeModal = () => {
    setModal(false);
  };

  const getDataHandler = (picker) => {
    let { startDate, endDate } = picker.selection;
    setFilters((p) => ({
      ...p,
      dueDate: { ...p?.dueDate, startDate: new Date(startDate), endDate: new Date(endDate), key: `selection` },
    }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <InputField
            type="text"
            size="small"
            label="Due Date"
            fullWidth={true}
            name="from"
            id="from-date"
            {...((cloneStartDate || cloneEndDate) && {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <CloseIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setFilters((p) => ({
                            ...p,
                            dueDate: {
                              ...p?.dueDate,
                              cloneStartDate: ``,
                              cloneEndDate: ``,
                              isMadeFilter: false,
                            },
                          }));
                        }}
                        sx={{ color: `red` }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            })}
            onClick={() => setModal(true)}
            value={cloneStartDate ? `${cloneStartDate} - ${cloneEndDate}` : ``}
          />
          <CustomizedDialogs
            noTitle={true}
            open={showModal}
            setOpen={() => closeDateRangeModal()}
            size="md"
            fullWidth={true}
            showCloseButton={false}
            customButton={true}
            customButtonText="Apply"
            customButtonAction={() => {
              setModal(false);
              setFilters((p) => ({
                ...p,
                dueDate: {
                  ...p?.dueDate,
                  cloneStartDate: moment(startDate).format(`MM/DD/YYYY`),
                  cloneEndDate: moment(endDate).format(`MM/DD/YYYY`),
                  isMadeFilter: true,
                },
              }));
            }}
          >
            <div style={{ margin: -7 }}>
              <DateRangePicker
                startDatePlaceholder="From"
                endDatePlaceholder="to"
                onChange={(item) => getDataHandler(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[
                  {
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    key: `selection`,
                  },
                ]}
                staticRanges={predefinedRanges}
                inputRanges={[defaultInputRanges[0]]}
                direction="horizontal"
              />
            </div>
          </CustomizedDialogs>
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <MuiSelect
            showNone={true}
            controlProps={{
              size: `small`,
              fullWidth: true,
              id: `taskType`,
            }}
            label="Task Type"
            options={[..._taskTypeOptions]}
            optionKey={`name`}
            optionValue={`value`}
            selectProps={{
              label: `Task Type`,
              value: taskType,
              name: `taskType`,
              onChange: (event) => {
                onChangeHandler(`taskType`, event);
              },
            }}
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <MuiSelect
            showNone={true}
            controlProps={{
              size: `small`,
              fullWidth: true,
              id: `taskStatus`,
            }}
            label="Task Status"
            options={[..._taskStatusOptions]}
            optionKey={`name`}
            optionValue={`value`}
            selectProps={{
              label: `Task Status`,
              value: taskStatus,
              name: `taskStatus`,
              onChange: (event) => {
                onChangeHandler(`taskStatus`, event);
              },
            }}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <MuiSelect
            showNone={true}
            controlProps={{
              size: `small`,
              fullWidth: true,
              id: `taskPriority`,
            }}
            label="Task Priority"
            options={[..._taskPriorityOptions]}
            optionKey={`name`}
            optionValue={`value`}
            selectProps={{
              label: `Task Priority`,
              value: taskPriority,
              name: `taskPriority`,
              onChange: (event) => {
                onChangeHandler(`taskPriority`, event);
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ marginY: `auto` }}>
          <Box sx={{ display: `flex`, alignItems: `center`, float: `right` }}>
            <Box sx={{ display: `flex`, gap: `11px` }}>
              <Button startIcon={<i className="fa fa-times"></i>} color="error" variant="contained" onClick={resetHandler}>
                Reset
              </Button>
              <Button startIcon={<i className="fa fa-search fa-sm"></i>} color="success" variant="contained" onClick={validator}>
                Apply
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};