import React, { useState, useEffect } from 'react';
import css from './PatientBasicInfoTelemedicine.module.css';
import { momentWrapper } from 'src/momentWrapper';
import momentOriginal from 'moment';
import { useSelector } from 'react-redux';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { getRequest, PATIENT_VIEW_DATA_RESPIRONICS_URL, PATIENT_VIEW_DATA_RESMED_URL, postRequest } from '../../../../../../crud/crud';
import { PatientPastData } from '../../../patient2/components/patientDetails/moreDetails/pastData/pastData';
import { ViewDataSummary } from '../../../patient2/components/patientDetails/moreDetails/viewData/tabComponents/summary';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { capitalizeFirstLetter } from 'src/utils';
import { InputField } from 'src/pages/common/components/InputField/InputField';



export const PatientBasicInfoTelemedicine = ({ data, sets, summaryData, setSummaryData }) => {
  const auth = useSelector((state) => state.user.auth);

  const [showModal, setModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(`viewData`);

  const [dateRangeSelection, setDateRangeSelection] = useState([
    {
      startDate: momentWrapper().startOf(`day`).subtract(30, `days`).format(`MM/DD/YYYY`),
      endDate: momentWrapper().endOf(`day`).subtract(1, `days`).format(`MM/DD/YYYY`),
      key: `selection`,
    },
  ]);
  const [persistDateRangeSelection, setPersistDateRangeSelection] = useState([
    {
      startDate: momentWrapper().startOf(`day`).subtract(30, `days`).format(`MM/DD/YYYY`),
      endDate: momentWrapper().endOf(`day`).subtract(1, `days`).format(`MM/DD/YYYY`),
      key: `selection`,
    },
  ]);

  useEffect(() => {
    let startDate = momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toISOString();

    let endDate = momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).endOf(`day`).toISOString();

    if (data?.device?.manufacturer === `resmed` && data) {
      let url = PATIENT_VIEW_DATA_RESMED_URL + data._id + `/?reportId=${null}`
      postRequest(url, {
        startDate,
        endDate,
      })
        .then((res) => {

          setSummaryData(res.data);
        })
        .catch(() => {
          setSummaryData();
        });
    } else if (data?.device?.manufacturer === `respironics` && data) {
      let url = PATIENT_VIEW_DATA_RESPIRONICS_URL + data?._id + `/?reportId=${null}&startDate=${startDate}&endDate=${endDate}`
      getRequest(url)
        .then((res) => {

          setSummaryData(res.data);
        })
        .catch(() => {
          setSummaryData();
        });
    }
  }, [auth, data]);

  const getDataHandler = (picker) => {
    let { startDate, endDate } = picker.selection;

    setDateRangeSelection([
      {
        startDate: momentOriginal(startDate).format(`MM/DD/YYYY`),
        endDate: momentOriginal(endDate).format(`MM/DD/YYYY`),
        key: `selection`,
      },
    ]);
  };
  const applyDateRangeApiCall = () => {
    setModal(false);
    setPersistDateRangeSelection(dateRangeSelection);

    let startDate = momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toISOString();
    let endDate = momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).endOf(`day`).toISOString();

    if (data && startDate && endDate) {
      setSummaryData(``);
      if (data?.device?.manufacturer === `resmed`) {
        let url = PATIENT_VIEW_DATA_RESMED_URL + data._id + `/?reportId=${null}`
        postRequest(url, {
          startDate,
          endDate,
        })
          .then((res) => {
            //
            setSummaryData(res.data);
          })
          .catch(() => {
          });
      } else if (data?.device?.manufacturer === `respironics`) {
        let url = PATIENT_VIEW_DATA_RESPIRONICS_URL + data?._id + `/?reportId=${null}&startDate=${startDate}&endDate=${endDate}`
        getRequest(url)
          .then((res) => {
            setSummaryData(res.data);
          })
          .catch(() => {
          });
      }
    }
  };
  const predefinedRanges = createStaticRanges([
    {
      label: `Last 30 days`,
      range() {
        return {
          startDate: momentWrapper().startOf(`day`).subtract(30, `days`).startOf(`day`).format(`MM/DD/YYYY`),
          endDate: momentWrapper().endOf(`day`).subtract(1, `days`).format(`MM/DD/YYYY`),
        };
      },
    },
    {
      label: `Last 7 days`,
      range() {
        return {
          startDate: momentWrapper().startOf(`day`).subtract(7, `days`).startOf(`day`).format(`MM/DD/YYYY`),
          endDate: momentWrapper().endOf(`day`).subtract(1, `days`).format(`MM/DD/YYYY`),
        };
      },
    },
    {
      label: `Last month`,
      range() {
        return {
          startDate: momentWrapper().startOf(`month`).subtract(1, `day`).startOf(`month`).format(`MM/DD/YYYY`),
          endDate: momentWrapper().startOf(`month`).subtract(1, `day`).endOf(`month`).format(`MM/DD/YYYY`),
        };
      },
    },
    {
      label: `This month`,
      range() {
        return {
          startDate: momentWrapper().startOf(`month`).format(`MM/DD/YYYY`),
          endDate: momentWrapper().subtract(1, `days`).endOf(`day`).format(`MM/DD/YYYY`),
        };
      },
    },
    {
      label: `Today`,
      range() {
        return {
          startDate: momentWrapper().startOf(`day`).format(`MM/DD/YYYY`),
          endDate: momentWrapper().endOf(`day`).format(`MM/DD/YYYY`),
        };
      },
    },
    {
      label: `Yesterday`,
      range() {
        return {
          startDate: momentWrapper().subtract(1, `day`).startOf(`day`).format(`MM/DD/YYYY`),
          endDate: momentWrapper().subtract(1, `day`).endOf(`day`).format(`MM/DD/YYYY`),
        };
      },
    },
  ]);

  const closeDateRangeModal = () => {
    setModal(false);
    setDateRangeSelection(persistDateRangeSelection);
  };

  return (
    <div id={css.main} style={{ width: data ? `70%` : `100%` }}>
      {` `}
      {data ? (
        <div className="pt-3">
          <div className="d-flex" style={{ marginBottom: `5%` }}>
            <button
              className="btn ml-3"
              style={{
                fontSize: `1vw`,
                borderRadius: `32px`,
                backgroundColor: selectedTab === `viewData` ? `#1699c5` : `transparent`,
                color: selectedTab === `viewData` ? `#fff` : `#000`,
                border: selectedTab != `viewData` ? `1px solid rgb(218, 218, 218)` : null,
              }}
              onClick={() => setSelectedTab(`viewData`)}
            >
              View Data
            </button>
            <button
              // disabled={!patientContext?.selectedPatient?._id}
              className="btn ml-3"
              style={{
                fontSize: `1vw`,
                borderRadius: `32px`,
                backgroundColor: selectedTab === `past` ? `#1699c5` : `transparent`,
                color: selectedTab === `past` ? `#fff` : `#000`,
                border: selectedTab != `past` ? `1px solid rgb(218, 218, 218)` : null,
              }}
              onClick={() => setSelectedTab(`past`)}
            >
              Past Data
            </button>
          </div>
          <div className="row mb-2">
            <div className="col-8 d-flex">
              <h4
                style={{
                  color: `black`,
                  fontSize: `large`,
                  fontWeight: `600`,
                  margin: `0 15px 0 0`,
                }}
                className="text-capitalize"
              >{capitalizeFirstLetter(`${data?.lastName}, ${data?.firstName}`)}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <div
                style={{
                  color: `#c1c1c1`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                DOB : {momentWrapper(data?.dateOfBirth, `YYYY-MM-DD`).format(`MM/DD/YYYY`)} (
                {momentWrapper().diff(momentWrapper(data?.dateOfBirth, `YYYY-MM-DD`), `years`)} yo)
              </div>
              <div
                style={{
                  color: `#c1c1c1`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Phone : {data?.phoneNumber}
              </div>
              <div
                style={{
                  color: `#c1c1c1`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Enrollment Date : {momentWrapper(data?.createdAt).format(`MM/DD/YYYY`)}
              </div>
              <br />
              <div
                style={{
                  color: `black`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Primary Dx: {data?.primaryDiagnosis}
              </div>
              <div
                style={{
                  color: `black`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Secondary Dx: {data?.secondaryDiagnosis}
              </div>
              <div
                style={{
                  color: `black`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Insurance Company: {data?.insuranceCompany}
              </div>

              <br />
              {/* GETTING FIRST NAME AND LAST NAME FROM REDUX - PROVIDER */}

              <div
                className="text-capitalize"
                style={{
                  color: `black`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Provider: {capitalizeFirstLetter(`${data?.provider?.firstName} ${data?.provider?.lastName}, `)}
                {data?.provider?.title}
              </div>

              {/* GETTING FIRST NAME AND LAST NAME FROM REDUX - CLINICAL STAFF */}

              <div
                className="text-capitalize"
                style={{
                  color: `black`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Clinical Staff: {capitalizeFirstLetter(`${data?.clinicalUser?.firstName} ${data?.clinicalUser?.lastName}, `)}
                {data?.clinicalUser?.title}
              </div>

              <br />

              <div
                className="text-capitalize"
                style={{
                  color: `black`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Baseline AHI: {`${data?.baseLineAHI}`}
              </div>

              {data?.MRN &&
                <div
                  className="text-capitalize"
                  style={{
                    color: `black`,
                    fontSize: `small`,
                    fontWeight: `600`,
                    marginTop: `10px`,
                  }}
                >
                  MRN: <span style={{ fontWeight: `500` }}>{data?.MRN}</span>
                </div>
              }

              <br />
              <div className="d-flex justify-content-between">
                <div
                  style={{
                    color: `black`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Risk:
                  {data?.tier === `VERY HIGH RISK` ? (
                    <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(235, 47, 6)` }} aria-hidden="true" />
                  ) : data?.tier === `HIGH RISK` ? (
                    <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(167, 9, 9)` }} aria-hidden="true" />
                  ) : data?.tier === `MODERATE RISK` ? (
                    <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(34, 112, 147)` }} aria-hidden="true" />
                  ) : data?.tier === `LOW RISK` ? (
                    <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(23, 162, 184)` }} aria-hidden="true" />
                  ) : (
                    <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(149, 175, 192)` }} aria-hidden="true" />
                  )}
                  {data?.tier === `VERY HIGH RISK`
                    ? `Very High`
                    : data?.tier === `HIGH RISK`
                      ? `High`
                      : data?.tier === `MODERATE RISK`
                        ? `Medium`
                        : data?.tier === `LOW RISK`
                          ? `Low`
                          : `No data > 30 days`}
                </div>
                <div
                  style={{
                    color: `black`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Adherence:
                  {data?.isAdherent === true ? (
                    <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(23, 162, 184)` }} aria-hidden="true" />
                  ) : data?.isAdherent === false ? (
                    <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(235, 47, 6)` }} aria-hidden="true" />
                  ) : (
                    <i className="fa fa-circle ml-1 mr-1" style={{ color: `rgb(149, 175, 192)` }} aria-hidden="true" />
                  )}
                  {data?.isAdherent === true ? `Adherent` : data?.isAdherent === false ? `Non-Adherent` : `No data > 30 days`}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div
                style={{
                  color: `#c1c1c1`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                {data?.device?.manufacturer === `resmed` ? `ResMed` : `Respironics`}
              </div>
              <div
                style={{
                  color: `#c1c1c1`,
                  fontSize: `small`,
                  fontWeight: `600`,
                  textTransform: `capitalize`,
                }}
              >
                {data?.deviceOrgName}
              </div>
              <div
                style={{
                  color: `#c1c1c1`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Setup date : {momentWrapper(data?.setupDate).format(`MM/DD/YYYY`)}
              </div>
              {data?.device?.manufacturer === `resmed` ? (
                <div
                  style={{
                    color: `#c1c1c1`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Mode : {data?.device?.mode !== null ? data?.device?.mode : `N/A`}
                </div>
              ) : (
                <div
                  style={{
                    color: `#c1c1c1`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Type : {data?.device?.type !== null ? data?.device?.type : `N/A`}
                </div>
              )}
              {data?.device?.manufacturer === `resmed` ? (
                <div
                  style={{
                    color: `#c1c1c1`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Description : {data?.device?.detail !== null ? data?.device?.detail : `N/A`}
                </div>
              ) : (
                <div
                  style={{
                    color: `#c1c1c1`,
                    fontSize: `small`,
                    fontWeight: `600`,
                  }}
                >
                  Source : {data?.device?.dataSource !== null ? data?.device?.dataSource : `N/A`}
                </div>
              )}

              {sets?.length > 0 &&
                sets?.map((set) => {
                  return (
                    <div
                      style={{
                        color: `#c1c1c1`,
                        fontSize: `small`,
                        fontWeight: `600`,
                      }}
                    >
                      {set.key} : {set.data}
                    </div>
                  );
                })}

              <div
                className="my-2"
                style={{
                  color: `black`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                Mask Info
              </div>
              <div
                style={{
                  color: `#000`,
                  fontSize: `small`,
                  fontWeight: `600`,
                }}
              >
                {data?.mask?.type} {data?.mask?.size}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ minHeight: `298px` }} className="d-flex justify-content-center align-items-center">
          No data
        </div>
      )}
      <div className="mb-3 pt-1 w-100 d-flex">
        {data && selectedTab === `viewData` && (
          <InputField
            size='small'
            fullWidth={true}
            // disabled={summaryData ? false : true}
            type="text"
            name="from"
            id="from-date"
            onClick={() => setModal(true)}
            value={`${persistDateRangeSelection[0]?.startDate} - ${persistDateRangeSelection[0]?.endDate}`}
          />
        )}
        <CustomizedDialogs
          noTitle={true}
          open={showModal}
          setOpen={() => closeDateRangeModal()}
          size="md"
          fullWidth={true}
          showCloseButton={false}
          customButton={true}
          customButtonText="Apply"
          customButtonAction={() => applyDateRangeApiCall()}
        >
          <div style={{ margin: -7 }}>
            <DateRangePicker
              startDatePlaceholder="From"
              endDatePlaceholder="to"
              onChange={(item) => getDataHandler(item)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={[
                {
                  startDate: momentOriginal(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toDate(),
                  endDate: momentOriginal(dateRangeSelection[0].endDate, `MM/DD/YYYY`).endOf(`day`).toDate(),
                  key: `selection`,
                },
              ]}
              staticRanges={predefinedRanges}
              direction="horizontal"
            />
          </div>
        </CustomizedDialogs>
      </div>
      {selectedTab === `viewData` && (
        <ViewDataSummary
          setIframeRefreshFlag={null}
          data={summaryData}
          start={momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toDate()}
          end={momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).endOf(`day`).toDate()}
          userId={data?._id}
          reportId={data?.latestReport?._id}
          dateHandler={null}
          selectedPatient={data?.device?.manufacturer}
        />
      )}
      {selectedTab === `past` && <PatientPastData reportId={null} telemedicinePatientId={data?._id} patient={data?._id} />}
    </div>
  );
}