import { ListSubheader } from '@mui/material';
import React from 'react';
import { returnNull } from 'src/utils';
import { InputField } from '../InputField/InputField';

export const SearchInput = ({ label, setSearchValue, keyValue, isObj }) => {
  return (
    <ListSubheader sx={{ mt: 0.5 }}>
      <InputField
        onClick={(e) => {
          if (e.key !== `Escape`) {
            e.stopPropagation();
          }
        }}
        size="small"
        label={returnNull({
          key: label,
          successRetrun: label,
          faliure: `Type to search...`,
        })}
        fullWidth
        onChange={(e) => {
          let { value } = e.target;
          if (isObj) {
            setSearchValue((p) => ({ ...p, [keyValue]: value }));
          } else {
            setSearchValue(value);
          }
        }}
        onKeyDown={(e) => {
          if (e.key !== `Escape`) {
            e.stopPropagation();
          }
        }}
      />
    </ListSubheader>
  );
}
