import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { getRequest, LOG_OUT } from 'src/crud/crud';
import { errorToast } from '../components/snackBar/toast';
import { clearTimeIfUserIsLOggedOut, idleTimerTimeoutMilliSec, idleUserTimeoutMiliSec } from 'src/utils';
import { handleTimer, saveTimerUser } from 'src/redux/patient/patientActions';
import { store } from 'src/redux/store';
import { logoutHandlerS } from 'src/redux/action/logoutHandler';

const clickEvent = (cb) => cb();
const mouseEvent = (cb) => cb();
const keyEvent = (cb) => cb();

let time = null;
const restartTimerIdleUSer = () => {
  if (time) clearTimeout(time);
  time = setTimeout(logout, idleUserTimeoutMiliSec);
  clearTimeIfUserIsLOggedOut(time);
};
const logout = () => {
  const { timer } = store.getState();
  const { videoCall, audioCall } = timer || false;
  if (!videoCall && !audioCall) {
    newSocket.close();
    getRequest(LOG_OUT)
      .then(() => {
        document.removeEventListener(`click`, () => clickEvent(restartTimerIdleUSer));
        document.removeEventListener(`keydown`, () => keyEvent(restartTimerIdleUSer));
        document.removeEventListener(`mousemove`, () => mouseEvent(restartTimerIdleUSer));
        if (time) clearTimeout(time);
        store.dispatch(logoutHandlerS());
      })
      .catch(() => {
        errorToast(`Some error has occured please try again later!`);
      });
  }
};

export const IdleUserWrapper = ({ children }) => {
  useEffect(() => {
    restartTimerIdleUSer();
    document.addEventListener(`click`, () => clickEvent(restartTimerIdleUSer));
    document.addEventListener(`keydown`, () => keyEvent(restartTimerIdleUSer));
    document.addEventListener(`mousemove`, () => mouseEvent(restartTimerIdleUSer));

    return () => {
      document.removeEventListener(`click`, () => clickEvent(restartTimerIdleUSer));
      document.removeEventListener(`keydown`, () => keyEvent(restartTimerIdleUSer));
      document.removeEventListener(`mousemove`, () => mouseEvent(restartTimerIdleUSer));
    };
  }, []);
  return (
    <RestartTimerAfterIdleState>
      <StopTimerWhenIdle>{children}</StopTimerWhenIdle>
    </RestartTimerAfterIdleState>
  );
};

let idleTimerTime = null;
const restartTimer = () => {
  const {
    patient: {
      timer: { toggleButton },
    },
  } = store.getState();
  if (idleTimerTime) clearTimeout(idleTimerTime);
  if (toggleButton) {
    idleTimerTime = setTimeout(() => {
      store.dispatch(handleTimer({ status: false, reason: `idleUser` }));
      store.dispatch(
        saveTimerUser({
          videoOrAudioOn: null,
          updateVideoorAudioLogStatus: null,
          callSuccess: null,
          statuss: `View graphs and reports`,
          sources: `View Report`,
        })
      );
    }, idleTimerTimeoutMilliSec);
  }
};
const removeEventListeners = () => {
  if (idleTimerTime) {
    restartTimer();
    document.removeEventListener(`click`, () => clickEvent(restartTimer));
    document.removeEventListener(`keypress`, () => keyEvent(restartTimer));
    document.removeEventListener(`mousemove`, () => mouseEvent(restartTimer));
  }
};
const StopTimerWhenIdle = ({ children }) => {
  const {
    timer: { toggleButton },
  } = useSelector(({ patient }) => patient);

  useEffect(() => {
    if (toggleButton) {
      restartTimer();
      document.addEventListener(`click`, () => clickEvent(restartTimer));
      document.addEventListener(`keydown`, () => keyEvent(restartTimer));
      document.addEventListener(`mousemove`, () => mouseEvent(restartTimer));
    }
    return () => {
      removeEventListeners();
      clearTimeIfUserIsLOggedOut(idleTimerTime);
    };
  }, [toggleButton]);

  return children;
};

const restartAfterIdleState = () => {
  const {
    patient: {
      timer: { toggleButton, reason = false },
    },
  } = store.getState();
  if (!toggleButton && reason && reason === `idleUser`) {
    store.dispatch(handleTimer({ status: true }));
  }
};

const removeListeners = () => {
  document.removeEventListener(`click`, () => clickEvent(restartAfterIdleState));
  document.removeEventListener(`keydown`, () => keyEvent(restartAfterIdleState));
  document.removeEventListener(`mousemove`, () => mouseEvent(restartAfterIdleState));
};

const RestartTimerAfterIdleState = ({ children }) => {
  const {
    patient: {
      timer: { toggleButton },
    },
  } = useSelector((state) => state);
  useEffect(() => {
    if (!toggleButton) {
      document.addEventListener(`click`, () => clickEvent(restartAfterIdleState));
      document.addEventListener(`keydown`, () => keyEvent(restartAfterIdleState));
      document.addEventListener(`mousemove`, () => mouseEvent(restartAfterIdleState));
    }
    return () => removeListeners();
  }, [toggleButton]);
  return children;
};
