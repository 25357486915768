import { combineReducers } from 'redux';
import userReducer from './userReducer';
import rtDataReducer from './rtDataReducer';
import { messengerReducer } from '../messenger/messengerReducer';
import { timerReducer } from '../timer/timerReducer';
import { resmedReducer } from '../resmed/resmedReducer';
import { telemedicineReducer } from '../telemedicine/telemedicineReducer';
import { mountUnmountReducer } from '../mount/mountReducers';
import { twilioReducer } from '../twilio/twilioReducer';
import { patientReducer } from '../patient/patientReducer';
import { ticketReducer } from '../ticketsCenter/ticketsCenterReducer';
import { practiceReducer } from '../practices/practiceReducer';
import { videoCallReducer } from '../videoCall/videoCallReducer';
import { practiceGroupReducer } from '../PracticeGroups/Reducers';
import { countsReducer } from '../Counts/counts.reducers';
import { analyticsReducer } from '../systemadminAnalytics/Redux/Reducers/Analytics.reducers';
import { readyToSignModal } from '../PatientOverview/Reports.reducer';
import { tasksManagerReducer } from '../taskManager/tasksManagerReducer';
import { appTimerReducer } from '../appTimer/appTimer.reducers';

const appReducer = combineReducers({
  user: userReducer,
  patient: patientReducer,
  rtData: rtDataReducer,
  messenger: messengerReducer,
  timer: timerReducer,
  resmed: resmedReducer,
  telemedicine: telemedicineReducer,
  mountUmmount: mountUnmountReducer,
  twilio: twilioReducer,
  ticketsCenter: ticketReducer,
  practice: practiceReducer,
  videoCall: videoCallReducer,
  practiceGroup: practiceGroupReducer,
  counts: countsReducer,
  analytics: analyticsReducer,
  readyToSign: readyToSignModal,
  tasksManager: tasksManagerReducer,
  appTimer: appTimerReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === `LOGOUT`) return appReducer(undefined, action)
  return appReducer(state, action);
};
