import { SET_ALL_PRACTICES, GET_SMART_PHRASE } from './practiceTypes';

export const setAllPractices = (payload) => {
  return {
    type: SET_ALL_PRACTICES,
    payload,
  };
};

export const setSmartPhrases = (payload) => {
  return {
    type: GET_SMART_PHRASE,
    payload: payload,
  };
};