import React from 'react';
import { FormControl, MenuItem, Select, Pagination } from '@mui/material';

export const Paginator = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, value) => {
    props.setPage(value);
  };

  const selectPageNumber = (e) => {
    props.setPage(1);
    props.setRecordPerPage(e.target.value);
  };

  return (
    <div className="row justify-content-end mb-5">
      <div className="col-2">
        {
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              variant="standard"
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={props?.recordPerPage}
              onChange={(event) => selectPageNumber(event)}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={75}>75</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        }
      </div>

      <div className="col-4">
        <Pagination count={Math.ceil(props?.count / props?.recordPerPage)} page={props.page} onChange={handleChange} />
      </div>
    </div>
  );
};
