import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import css from './avator.module.css';
import Skeleton from '@mui/material/Skeleton';
import { GET_USER_DETAILS, getRequest, GET_USER_BY_ID } from '../../../../../../crud/crud';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { EditUser } from './editUser/editUser';
import { Fade, Grow, Zoom } from '@mui/material';
import { SwitchPractice } from 'src/pages/home/modules/administration/user/components/SwitchPractice/SwitchPractice';
import { Setting } from 'src/pages/home/modules/administration/user/components/settings/setting';
import { BootstrapSpinner } from '../../../Bootstrapspinner/BootstrapSpinner';
import { doIfUserIsntCom, timeLogAddOnSwitchPatientAndSorting, doLogOut } from 'src/utils';
import { useConfirm } from 'src/pages/common/hooks/useConfirm';

export const AvatorDropDown = () => {
  const { user: parentUser, timer, patient } = useSelector((state) => state);
  const { user, auth } = parentUser;
  const { audioCall, callType } = timer;
  const { timer: patientTimer } = patient;
  const { enableSwitch: enabledSwitch, systemRoles } = user;
  const [isLoggingOut, setLogout] = useState(false);
  const [editUserModalFlag, setEditUserModalFlag] = useState(false);
  const [settingModalFlag, setSettingModalFlag] = useState(false);
  const [userData, setUserData] = useState(``);
  const [isOpen, setIsOpen] = useState(false);
  const [profilePic, setProfilePic] = useState(``);
  const [listOfSignatures, setListOfSignatures] = useState([]);
  const [hasEdited, setHasEdited] = useState(false);
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const logOutModal = useSelector((state) => state.timer.logout);
  const [openSwitchPractice, setOpenSwitchPractice] = useState(false);
  const ref = React.useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (auth || hasEdited === `edited`) {
      setProfilePic();
      let isSysOrGrp = !!(user?.isSystemAdministrator || user?.isGroupAdmin);
      if (isSysOrGrp) {
        let route = `${GET_USER_BY_ID}${user?._id}`;
        getRequest(route)
          .then((res) => {
            setUserData(res?.data?.user);
            setListOfSignatures(res?.data?.signatures);
            setProfilePic(res?.data?.user?.profilePhotoPath);
            setHasEdited(false);
          })
          .then()
          .catch();
      } else {
        getRequest(GET_USER_DETAILS + user._id + `/` + user?._practiceId)
          .then((response) => {
            setUserData(response?.data?.user);
            setListOfSignatures(response?.data?.signatures);
            setProfilePic(response?.data?.user?.profilePhotoPath);
            setHasEdited(false);
          })
          .catch((err) => { });
      }
    }

    document.addEventListener(`click`, handleClickOutside, true);
    return () => {
      document.removeEventListener(`click`, handleClickOutside, true);
      setIsOpen(false);
      setOpenSwitchPractice(false);
    };
  }, [hasEdited]);

  let confirmationDescription;

  if (audioCall && logOutModal) {
    confirmationDescription = `In order to logout you must end the call first !`;
  } else if (patientTimer?.toggleButton && logOutModal) {
    confirmationDescription = `In order to logout you must stop patientTimer first !`;
  } else if (callType === `inComing` && audioCall) {
    confirmationDescription = `End call and logout`;
  }

  let confirmationForTimerOrCall = useConfirm({
    open:
      !!((audioCall && logOutModal) || (patientTimer?.toggleButton && logOutModal) || (callType === `inComing` && audioCall && logOutModal)),
    successFunc: () => {
      dispatchRedux({ type: `HANDLE_LOGOUT_MODAL`, payLoad: false });
      if (patientTimer?.toggleButton) {
        timeLogAddOnSwitchPatientAndSorting();
      }
      if (newSocket) {
        newSocket.close();
      }
      doLogOut(auth, setLogout, history);
      setLogout(true);
    },
    cancelFunc: () => {
      dispatchRedux({ type: `HANDLE_LOGOUT_MODAL`, payLoad: false });
    },
    successText:
      audioCall && logOutModal ? `End call in progress and logout!` : patientTimer?.toggleButton && logOutModal && `Stop Timer and logout!`,
    cancelText: `Cancel`,
    title: audioCall && logOutModal ? `End call in progress?` : patientTimer?.toggleButton && logOutModal && `Stop Timer`,
    description: confirmationDescription,
  });

  // LOGOUT HANDLER
  const logOutHandler = () => {
    if (!audioCall && !patientTimer?.toggleButton) {
      if (newSocket) {
        newSocket.close(); //  CLOSING THE SOCKET, WHEN LOGOUT
      }
      doLogOut(auth, setLogout, history); // API CALL - IN CASE OF USER LOGOUT
    } else {
      dispatchRedux({ type: `HANDLE_LOGOUT_MODAL`, payLoad: true });
    }
  };

  const isOnVideo = useSelector((state) => state.patient.isOnVideo);

  const conditionallyRenderAvatar = () => {
    if (!profilePic) {
      return <Skeleton variant="circular" width={44} height={44} />
    }
    return userData?.profilePhotoPath?.length > 40 ?
      <Fade timeout={500} in={userData?.profilePhotoPath?.length > 40} mountOnEnter unmountOnExit>
        <img
          src={userData.profilePhotoPath ? userData.profilePhotoPath : null}
          alt={userData?.firstName ? userData?.firstName.charAt(0).toUpperCase() + userData?.lastName.charAt(0).toUpperCase() : ``}
          width="100%"
          height="100%"
        />
      </Fade>
      :
      <p style={{ fontWeight: 600 }}>
        {userData?.firstName ? userData?.firstName.charAt(0).toUpperCase() + userData?.lastName.charAt(0).toUpperCase() : ``}
      </p>
  }

  return (
    <div ref={ref}>
      {confirmationForTimerOrCall}
      {openSwitchPractice && <SwitchPractice open={openSwitchPractice} setOpen={() => setOpenSwitchPractice(false)} />}

      {settingModalFlag && (
        <Setting
          open={settingModalFlag}
          setOpen={() => setSettingModalFlag(false)}
          setSettingModalFlag={setSettingModalFlag}
          setHasEdited={setHasEdited}
          userData={userData}
          hasEdited={hasEdited}
        />
      )}

      {editUserModalFlag && (
        <EditUser
          userData={userData}
          show={editUserModalFlag}
          setShow={setEditUserModalFlag}
          setUserData={setUserData}
          setProfilePic={setProfilePic}
          _listOfSignatures={listOfSignatures}
          setHasEdited={setHasEdited}
          hasEdited={hasEdited}
        />
      )}
      <button
        disabled={isOnVideo}
        id={css.avator}
        type="button"
        onClick={() => {
          setIsOpen((p) => !p);
        }}
      >
        {conditionallyRenderAvatar()}
      </button>
      <Grow style={{ transformOrigin: `0 0 0` }} {...(isOpen ? { timeout: 200 } : {})} mountOnEnter unmountOnExit in={isOpen}>
        <div className="mb-0 pb-0 pt-0" id={css.AvatorDropDown}>
          <span className={css.menuArrow}></span>
          <table className="table table-borderless table-hover mb-0">
            <tbody>
              <tr onClick={() => setIsOpen(false)} style={{ borderBottom: `1px solid rgb(211, 209, 209)` }} id={css.row}>
                <td className="pl-4">
                  <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                    <i className="fa fa-user-o" style={{ color: `#1699C5` }} aria-hidden="true"></i>
                  </Zoom>
                </td>

                <Fade timeout={700} in={isOpen}>
                  <td className="pr-4" style={{ textAlign: `end` }} onClick={() => doIfUserIsntCom(() => setEditUserModalFlag(true))}>
                    Profile
                  </td>
                </Fade>
              </tr>

              <tr
                onClick={() => setIsOpen(false)}
                style={{ borderBottom: `1px solid rgb(211, 209, 209)` }}
                id={css.row}
                className={`${user?.isSystemAdministrator || user?.isGroupAdmin ? `d-none` : ``}`}
              >
                <td className="pl-4">
                  <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                    <i className="fa fa-cog" style={{ color: `#1699C5` }} aria-hidden="true"></i>
                  </Zoom>
                </td>
                <Fade timeout={700} in={isOpen}>
                  <td className="pr-4" style={{ textAlign: `end` }} onClick={() => doIfUserIsntCom(() => setSettingModalFlag(true))}>
                    Settings
                  </td>
                </Fade>
              </tr>

              <tr
                onClick={() => setIsOpen(false)}
                style={{ borderBottom: `1px solid rgb(211, 209, 209)` }}
                id={css.row}
                className={`${user?.isSystemAdministrator || user?.isGroupAdmin ? `d-none` : ``}`}
              >
                <td className="pl-4">
                  <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                    <i className="fa fa-comments" style={{ color: `#1699C5` }} aria-hidden="true"></i>
                  </Zoom>
                </td>
                <Fade timeout={700} in={isOpen}>
                  <td
                    className={`pr-4 ${user?.isSystemAdministrator || user?.isGroupAdmin ? `d-none` : ``}`}
                    style={{ textAlign: `end` }}
                    onClick={() => history.push(`/smart/phrases`)}
                  >
                    Smart Phrase
                  </td>
                </Fade>
              </tr>
              {!user?.isSystemAdministrator && !user?.isGroupAdmin && enabledSwitch && systemRoles?.length > 1 && (
                <tr onClick={() => setIsOpen(false)} style={{ borderBottom: `1px solid rgb(211, 209, 209)` }} id={css.row}>
                  <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                    <td className="pl-4">
                      <i style={{ color: `#1699C5` }} aria-hidden="true" className="bi bi-toggles2"></i>
                    </td>
                  </Zoom>
                  <Fade timeout={700} in={isOpen}>
                    <td onClick={() => doIfUserIsntCom(() => setOpenSwitchPractice(true))} className="pr-4" style={{ textAlign: `end` }}>
                      Switch Practice
                    </td>
                  </Fade>
                </tr>
              )}
              <tr
                id={css.row}
                onClick={() => {
                  logOutHandler();
                }}
              >
                <td className="pl-4">
                  {isLoggingOut ? (
                    <BootstrapSpinner
                      spinnerProps={{
                        className: `spinner-border spinner-border-sm text-primary`,
                      }}
                    />
                  ) : (
                    <i className="fa fa-sign-out" style={{ color: `#1699C5` }} aria-hidden="true"></i>
                  )}
                </td>

                <Fade timeout={700} in={isOpen}>
                  <td className="pr-4" style={{ textAlign: `end` }}>
                    Logout
                  </td>
                </Fade>
              </tr>
            </tbody>
          </table>
        </div>
      </Grow>
    </div>
  );
};
