import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { billerRole, clinicalRole, providerRole, superAdminRole } from 'src/utils';

export const StripeProtectionWrapper = ({ children }) => {
  const {
    user: { user = { userRole: `` } },
  } = useSelector((state) => state);
  const { pathname } = useLocation();
  const { userRole, stripeSubscriptionId } = user;
  let invalidRoles = [clinicalRole, providerRole, billerRole];
  const stripeProtection = () => {
    if (userRole?.includes(superAdminRole || `SuperAdmin`) && !stripeSubscriptionId && pathname !== `/administration/payment`)
      return <Redirect to="/administration/payment" />;
    else if (invalidRoles.includes(userRole) && !stripeSubscriptionId && pathname !== `/subscription-error`)
      return <Redirect to="/subscription-error" />;
    return children;
  };
  return stripeProtection();
};
