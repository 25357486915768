import React, { useEffect, useState } from 'react';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';

export const Comp = ({ users, compProps = { selectProps: {} } }) => {
  const [mappeduSers, setMappedUsers] = useState([]);
  const { selectProps } = compProps;
  let prepareProps = {
    ...compProps,
    selectProps: {
      multiple: true,
      ...selectProps,
      MenuProps: {
        PaperProps: {
          style: {
            maxHeight: `250px`,
          },
        },
      },
    },
    multiSelectProps: {
      isSelectAll: true,
    },
    options: [...mappeduSers],
  };
  useEffect(() => {
    if (Array.isArray(users())) {
      setMappedUsers(() => users());
    }
  }, [users]);

  return (
    <MuiSelect
      searchInpProps={{
        isSearchAble: true,
        searchKeys: ['fullName'],
      }}
      {...prepareProps}
    />
  );
};

export const TemplateCompMultiSelect = React.memo(Comp);
