import React, { useRef, useState } from 'react';
import { TotalTelemedicineTime } from './totalTelemedicineTime/TotalTelemedicineTime';
import { Grid, Slide } from '@mui/material';

export const TelemedicineAnalytics = () => {
  const [clicked, setClicked] = useState(false);
  const container = useRef(null);

  return (
    <React.Fragment ref={container}>
      <div className="d-flex justify-content-between w-100 mt-4">
        <h4 style={{ fontWeight: 625 }}>Telemedicine Analytics</h4>
        <button type="button" onClick={() => setClicked((p) => !p)} style={{ outline: `none` }}>
          <i className={clicked ? `fa fa-chevron-up` : `fa fa-chevron-down`} aria-hidden="true"></i>
        </button>
      </div>
      <hr className="w-100 m-0" />
      <Slide in={clicked} direction="up" timeout={600} mountOnEnter unmountOnExit container={container.current}>
        <Grid sx={{ mt: 2 }} container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <TotalTelemedicineTime clicked={clicked} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            {' '}
            <AudioCallTime clicked={clicked} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            {' '}
            <VideoCallTime clicked={clicked} />
          </Grid> */}
        </Grid>
      </Slide>
    </React.Fragment>
  );
}

