export const IS_REAL_DATA = `IS_REAL_DATA`;
export const RESET_REAL_DATA_FLAG = `RESET_REAL_DATA_FLAG`;
export const PENDING_PATIENT_IS_AVAILABLE = `PENDING_PATIENT_IS_AVAILABLE`;
export const MANUALLY_NEW_PATIENT_ADDED = `MANUALLY_NEW_PATIENT_ADDED`;
export const IMPORT_NEW_PATIENT_SUCCESSFULLY = `IMPORT_NEW_PATIENT_SUCCESSFULLY`;
export const ADD_NEW_LOG = `ADD_NEW_LOG`;
export const ADD_NEW_PRACTICE = `ADD_NEW_PRACTICE`;
export const ADD_NEW_USER = `ADD_NEW_USER`;
export const ALERT_PATIENT_RISK_CHANGE = `ALERT_PATIENT_RISK_CHANGE`;
export const RISK_ALERT_PATIENT_REMOVE = `RISK_ALERT_PATIENT_REMOVE`;
export const INITIALIZE_RESMED_ARRAY_FOR_NOTIFICATION = `INITIALIZE_RESMED_ARRAY_FOR_NOTIFICATION`;
export const INCOMMING_RESMED_RISK_NOTIFICATION = `INCOMMING_RESMED_RISK_NOTIFICATION`;
