import React, { useEffect, useState } from 'react';
import css from './BillingReportData.module.css';
import { withRouter } from 'react-router-dom';
import { getRequest, PAST_BILLING_REPORT } from '../../../../../../../crud/crud';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { capitalizeFirstLetter, cptCodes } from 'src/utils';
import { useStartEndDateSelction } from 'src/pages/common/hooks/useStartEndDateSelction';
import moment from 'moment';

export const ComponentTable = ({ match }) => {
  const [billReports, setBillReports] = useState([]);
  const user = useSelector((state) => state?.user?.user);
  const lastMonth = moment().subtract(1, `months`);
  const [dateRangeSelection, setDateRangeSelection] = useState({ startDate: lastMonth, endDate: moment(lastMonth).endOf(`month`) });
  const [fetchReportsFlag, setFetchReportsFlag] = useState(0);
  const [loading, setLoading] = useState(false);
  const [csvButtonDisabled] = useState(false);

  const { startDate, endDate } = dateRangeSelection;
  const getFreshReports = () => setFetchReportsFlag((p) => p + 1);

  const renderDateSelection = useStartEndDateSelction({
    startDate,
    endDate,
    resetDef: !!fetchReportsFlag,
    datePickerContProps: {
      styles: {
        mt: 1,
      },
    },
    pickerProps: {
      startSign: `Start Month`,
      endSign: `End Month`,
    },
    actionProps: {
      btnAction: (startDate, endDate) => {
        setDateRangeSelection({ startDate, endDate });
        getFreshReports();
      },
      btnStyles: {
        padding: `5px`,
        width: `100%`,
      },
      text: `Fetch Reports`,
    },
    spacings: {
      columnSpacing: 1,
      rowSpacing: 1,
    },
  });

  useEffect(() => {
    if (fetchReportsFlag || !fetchReportsFlag) {
      setLoading(true);
      let route = `${user?.isSystemAdministrator || user?.isGroupAdmin ? `practiceGroup/billing/` : PAST_BILLING_REPORT}${match?.params?.id
        }?startDate=${startDate ? moment(startDate).format(`YYYY-MM`) : null}&endDate=${endDate ? moment(endDate).format(`YYYY-MM`) : null}`;
      getRequest(route)
        .then((response) => {
          setLoading(false);

          let billObject = response?.data?.reports?.map((report) => {
            let convertedRepDate =
              report?.month && typeof report?.month === `number` ? moment(report?.month?.toString()).format(`MMM YYYY`) : new Date(report?.createdAt);
            let reportDate = moment(convertedRepDate);
            let enrollmentDate = null;
            let firstNames = ``;
            let lastNames = ``;
            if (report?.dateOfEnrollment) {
              enrollmentDate = new Date(report?.dateOfEnrollment);
            }
            if (report?.lastName) {
              lastNames = report?.lastName[0].toUpperCase() + report?.lastName.slice(1);
            }
            if (report?.firstName) {
              firstNames = report?.firstName[0].toUpperCase() + report?.firstName.slice(1);
            }

            let minutes = Math.floor((report?.totalTime % 3600) / 60);
            let seconds = report?.totalTime % 60;
            let hours = Math.floor(report?.totalTime / 3600);
            minutes = minutes.toString().length === 1 ? `0` + minutes : minutes;
            seconds = seconds.toString().length === 1 ? `0` + seconds : seconds;
            hours = hours.toString().length === 1 ? `0` + hours : hours;

            let timeSpent = hours + `:` + minutes + `:` + seconds;

            let reportDateMonth = null;
            let enrollmentDateMonth = null;
            let reportDateYear = null;
            let enrollmentDateYear = null;
            let sameBill = 0;
            if (enrollmentDate) {
              reportDateMonth = reportDate.month();
              enrollmentDateMonth = enrollmentDate.getMonth();
              reportDateYear = reportDate.year();
              enrollmentDateYear = enrollmentDate.getFullYear();
            }
            if (reportDate && enrollmentDate && reportDateMonth === enrollmentDateMonth && reportDateYear === enrollmentDateYear) {
              sameBill = 1;
            }
            const { fiveSev, fiveFour, fiveEight } = cptCodes({ usage: report?.daysUsed, totalTime: report?.totalTime, interactiveCommunication: report?.interactiveCommunication })
            return {
              ...report,
              firstName: firstNames,
              lastName: lastNames,
              secondary: report?.secondary ? report?.secondary : `N/A`,
              _99453: sameBill,
              _99454: fiveFour,
              _99457: fiveSev,
              _99458: fiveEight,
              totalTime: timeSpent,
              downloadTime: report?.totalTime,
            };
          });

          setBillReports(billObject);
        })
        .catch(() => setLoading(false));
    }
  }, [fetchReportsFlag]);

  const downloadCSV = (csvStr) => {
    let hiddenElement = document.createElement(`a`);
    hiddenElement.href = `data:text/csv;charset=utf-8,` + encodeURI(csvStr);

    hiddenElement.target = `_blank`;
    hiddenElement.download = `Billing Reports.csv`;
    hiddenElement.click();
  };

  const createBillingCsvFile = () => {
    const csvObject = billReports?.map((report) => {
      let obj = {};
      obj[`MRN`] = report?.MRN ? report?.MRN : `-`;
      obj[`Last Name`] = report?.lastName ? report?.lastName[0].toUpperCase() + report?.lastName.slice(1) : `-`;
      obj[`First Name`] = report?.firstName ? report?.firstName[0].toUpperCase() + report?.firstName.slice(1) : `-`;
      obj[`Date of Birth`] = moment(report?.dateOfBirth, `YYYY-MM-DD`).format(`MM-DD-YYYY`);
      obj[`Provider Last Name`] = report?._providerId?.lastName ? report?._providerId?.lastName : `-`;
      obj[`Report Month`] = report?.month && typeof report?.month === `number` ? moment(report?.month.toString()).format(`MMM YYYY`) : ``;

      let minutes = Math.floor((report?.downloadTime % 3600) / 60);
      let seconds = report?.downloadTime % 60;
      let hours = Math.floor(report?.downloadTime / 3600);

      minutes = minutes.toString().length === 1 ? `0` + minutes : minutes;
      seconds = seconds.toString().length === 1 ? `0` + seconds : seconds;
      hours = hours.toString().length === 1 ? `0` + hours : hours;

      obj[`Time Spent (h:m:s)`] = hours + `:` + minutes + `:` + seconds;
      obj[`Days Spent on device`] = report?.daysUsed;

      obj[`Date of Enrollment`] = report?.dateOfEnrollment ? moment(report?.dateOfEnrollment).format(`MM-DD-YYYY`) : `N/A`;
      obj[`Insurance Company`] = report?.insuranceCompany ? report?.insuranceCompany : `-`;

      obj[`Primary`] = report?.primaryDiagnosisId;
      obj[`Secondary`] = report?.secondaryDiagnosisId;
      obj[` ` + `99453*`] = report?._99453;
      obj[` ` + `99454*`] = report?._99454;
      obj[` ` + `99457*`] = report?._99457;
      obj[` ` + `99458*`] = report?._99458;
      return obj;
    });

    let spaceObj = {};
    spaceObj[`Last Name`] = ``;
    spaceObj[`First Name`] = ``;
    csvObject.push(spaceObj);
    spaceObj[`Report Month`] = ``;
    csvObject.push(spaceObj);
    csvObject.push(spaceObj);

    // csvObject.push("*The Monitair program may organize, arrange and present coding data based solely on the information typed or scanned into the Monitair software by an Authorized User.  Client and other Authorized Users shall be solely responsible for coding any medical service provided by them and for the maintenance and production of all documentation required to support such coding.")
    let str = {};
    str[
      `Last Name`
    ] = `*The Monitair program may organize arrange and present coding data based solely on the information typed or scanned into the Monitair software by an Authorized User. Client and other `;
    let str1 = {};
    str1[
      `Last Name`
    ] = `Authorized Users shall be solely responsible for coding any medical service provided by them and for the maintenance and production of all documentation required to support such coding`;

    csvObject.push(str);
    csvObject.push(str1);
    let csv = ``;
    let header = Object.keys(csvObject[0]).join(`,`);
    let values = csvObject
      .map((o) => {
        return Object.values(o).join(`,`);
      })
      .join(`\n`);

    csv += header + `\n\n` + values;
    downloadCSV(csv);
  };

  return (
    <Box sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
      <div id={css.practiceContainer} style={{ position: `relative` }}>
        <div className="mb-3 pt-1 w-100 d-flex ml-3 mt-3" style={{ alignItems: `center`, justifyContent: `space-between` }}>
          <Box sx={{ display: `flex`, width: { xs: `70%`, sm: `70%`, md: `50%`, lg: `40%`, xl: `40%` }, alignItems: `center` }}>
            {renderDateSelection}
          </Box>
          <button
            type="button"
            onClick={() => createBillingCsvFile()}
            disabled={csvButtonDisabled}
            className="btn btn-outline-info btn-md"
            style={{ fontSize: `12px`, marginRight: `30px` }}
          >
            Download CSV
          </button>
        </div>

        <div className="table-responsive" style={{ borderRadius: `10px 10px 10px 10px` }}>
          <table className="table table-striped">
            <thead style={{ display: `block` }}>
              <tr
                style={{
                  width: `10%`,
                  textAlign: `center`,
                  verticalAlign: `middle`,
                }}
              >
                <th scope="col" style={{ width: `20%`, cursor: `pointer`, minWidth: `150px` }}>
                  MRN
                </th>
                <th scope="col" style={{ width: `10%`, cursor: `pointer`, minWidth: `106px` }}>
                  Last Name
                </th>
                <th
                  scope="col"
                  style={{
                    width: `20%`,
                    textAlign: `start`,
                    cursor: `pointer`,
                    minWidth: `106px`,
                  }}
                >
                  First Name
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `180px` }}>
                  DOB
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                  Report Month
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                  Time Spent
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                  Insurance Company
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `106px` }}>
                  Days Spent
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `180px` }}>
                  Enrollment Date
                </th>
                {/* <th scope="col" style={{ width: `20%`, minWidth: `180px` }}>
                  Inactivation Date
                </th> */}
                <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                  Primary ICD10
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                  Secondary ICD10
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                  99453
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                  99454
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                  99457
                </th>
                <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                  99458
                </th>
              </tr>
            </thead>
            <tbody id={css.body} style={{ display: `block`, height: `50vh`, overflowY: `auto`, overflowX: `hidden` }}>
              {billReports?.length > 0 ? (
                billReports.map((report) => {
                  return (
                    <tr
                      key={report?.id}
                      style={{
                        width: `10%`,
                        textAlign: `center`,
                        verticalAlign: `middle`,
                      }}
                    >
                      <td scope="col" style={{ width: `20%`, minWidth: `150px` }}>
                        {report?.MRN ? report?.MRN : `-`}
                      </td>
                      <td scope="col" style={{ width: `10%`, minWidth: `106px` }}>
                        {capitalizeFirstLetter(report?.lastName)}
                      </td>
                      <td
                        scope="col"
                        style={{
                          width: `20%`,
                          textAlign: `start`,
                          minWidth: `106px`,
                        }}
                      >
                        {capitalizeFirstLetter(report?.firstName)}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `180px` }}>
                        {moment(report?.dateOfBirth, `YYYY-MM-DD`).format(`MM-DD-YYYY`)}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                        {report?.month && typeof report?.month === `number` ? moment(report?.month?.toString()).format(`MMM YYYY`) : `-`}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                        {report?.totalTime}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                        {report?.insuranceCompany ? report?.insuranceCompany : `-`}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `106px` }}>
                        {report?.daysUsed}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `180px` }}>
                        {report?.dateOfEnrollment ? moment(report?.dateOfEnrollment).format(`MM-DD-YYYY`) : `N/A`}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                        {report?.primaryDiagnosisId ? report?.primaryDiagnosisId : `N/A`}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                        {report?.secondaryDiagnosisId ? report?.secondaryDiagnosisId : `N/A`}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                        {report?._99453}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                        {report?._99454}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                        {report?._99457}
                      </td>
                      <td scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                        {report?._99458}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="10" style={{ textAlign: `center` }}>
                    No Record Found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {loading && <PuffSpinner />}
      </div>
    </Box>
  );
};

export const BillingReportData = withRouter(ComponentTable);
