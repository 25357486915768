import React from 'react';
import css from './puff.module.css';
import Spinner from 'react-spinners/PuffLoader';

export const PuffSpinner = () => {
  return (
    <div id={css.customPosition}>
      <Spinner
        size={150}
        color={`#6dd0f2`}
        loading={true}
      />
    </div>
  );
};

