import { Box, Button, Stack } from '@mui/material'
import React from 'react'
import { toggleTasksCont, toggleTaskStack, toggleTaskBtnStyles } from 'src/utils'

export const ToggleTasks = ({ assignedByMe, setAssignedByMe, setTaskListFlag, customContStyles, customTexts }) => {
    const onClickHandler = (assignedByMeIsClicked) => {
        setAssignedByMe(!!assignedByMeIsClicked)
        if (setTaskListFlag) setTaskListFlag()
    }

    return (
        <Box sx={{ ...(customContStyles ? { ...customContStyles } : { ...toggleTasksCont }) }}>
            <Stack {...toggleTaskStack}>
                <Box>
                    <Button variant='contained' sx={{
                        ...toggleTaskBtnStyles(!assignedByMe)
                    }} onClick={() => onClickHandler(false)}> {customTexts?.active ? customTexts?.active : `Assigned To Me`}</Button>
                </Box>
                <Box>
                    <Button variant='contained' sx={{ ...toggleTaskBtnStyles(!!assignedByMe) }} onClick={() => onClickHandler(true)}> {customTexts?.closed ? customTexts?.closed : `Assigned By Me`}   </Button>
                </Box>
            </Stack>
        </Box>
    )
}
