import React, { useState } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import { Box, IconButton, Stack } from '@mui/material';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { useSelector } from 'react-redux';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';
import { capitalizeFirstLetter } from 'src/utils';
import css from './basicDetails.module.css';
export const ViewUsersList = () => {
  const [openList, setOpenList] = useState(false);
  const { patient = { patientData: {} } } = useSelector((state) => state);
  const { patientData = { data: {} } } = patient;
  const { data = { secondaryProviders: [], secondaryClinicalUsers: [], provider: {}, clinicalUser: {} } } = patientData;
  const {
    secondaryProviders = [],
    secondaryClinicalUsers = [],
    provider = { firstName: '', lastName: '' },
    clinicalUser = { firstName: '', lastName: '' },
  } = data;
  const { firstName: providerFname, lastName: providerLname } = provider;
  const { firstName: clinicalUserFname, lastName: clinicalUserLname } = clinicalUser;

  return (
    <div>
      <ToolTipProvider
        toolTipProps={{ arrow: true, placement: 'top', title: 'View Care Team!' }}
        element={
          <IconButton sx={{ color: '#1699c5' }} onClick={() => setOpenList((p) => !p)}>
            <GroupIcon />
          </IconButton>
        }
      />
      <CustomizedDialogs open={openList} setOpen={() => setOpenList((p) => !p)} title={'List of users'}>
        <div className={css.usersCont}>
          <Box sx={{ p: 1 }}>
            <Stack direction="row" spacing={2} justifyContent={'center'}>
              <Box>
                <RenderHeadings heading={'Primary Provider'} />
                {capitalizeFirstLetter(`${providerFname} ${providerLname}`)}
                <ConditionallyRenderWrapper con={secondaryProviders?.length}>
                  <Box>
                    <RenderHeadings heading={'Secondary Providers'} />
                    <MapArray users={secondaryProviders} />
                  </Box>
                </ConditionallyRenderWrapper>
              </Box>
              <Box>
                <RenderHeadings heading={'Primary Clinical Staff'} />
                {capitalizeFirstLetter(`${clinicalUserFname} ${clinicalUserLname}`)}
                <Box>
                  <ConditionallyRenderWrapper con={secondaryClinicalUsers?.length}>
                    <RenderHeadings heading={'Secondary Clinical staff'} />
                    <MapArray users={secondaryClinicalUsers} />
                  </ConditionallyRenderWrapper>
                </Box>
              </Box>
            </Stack>
          </Box>
        </div>
      </CustomizedDialogs>
    </div>
  );
};

const RenderHeadings = ({ heading }) => <Box sx={{ fontWeight: 600 }}>{heading}</Box>;
const MapArray = ({ users }) => {
  return (
    <ConditionallyRenderWrapper con={users?.length}>
      {users?.map((user) => (
        <Box key={user?._id}>{capitalizeFirstLetter(`${user?.firstName} ${user?.lastName}`)}</Box>
      ))}
    </ConditionallyRenderWrapper>
  );
};
