import React, { useState } from 'react';
import css from './monitoringWidget.module.css';
import { PuffSpinner } from '../../../../../common/components/spinner/puff/puff';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import $ from 'jquery';

$(function () {
  $(`[data-toggle="tooltip"]`).tooltip();
});

export const MonitoringWidget = ({ monitoringTime }) => {
  const [loading] = useState(false);

  return (
    <div id={css.main}>
      <div className="d-flex justify-content-between align-items-center ml-4 mr-4 mt-4">
        <h4 style={{ fontWeight: 400 }}>Monitoring Activity</h4>
        <Tooltip
          title={
            <>
              <i style={{ fontSize: `10px` }}>
                Patients <br />
                categorized by
                <br />
                time spent
                <br />
                monitoring
                <br />
                during the
                <br />
                current
                <br />
                calendar
                <br />
                month.
              </i>
            </>
          }
          placement="top"
          arrow
        >
          <button type="button" data-toggle="tooltip" data-placement="top" title="">
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </button>
        </Tooltip>
      </div>

      <h5 className="mt-2 ml-4" style={{ fontWeight: `bold`, fontSize: `x-small` }}>
        Period
      </h5>
      <h5 className="ml-4" style={{ fontSize: `small`, color: `rgb(201, 201, 201)` }}>
        Current calendar month
      </h5>

      {/* <div className="m-4" id={css.statsBar}></div> */}
      <div className="m-4">
        <div className="progress" style={{ height: `35px`, borderRadius: `0.35rem` }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${monitoringTime.unOpenedPercentage ? monitoringTime.unOpenedPercentage : 0}%`, backgroundColor: `#eb2f06` }}
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${monitoringTime.oneToTenPercentage ? monitoringTime.oneToTenPercentage : 0}%`, backgroundColor: `#a70909` }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${monitoringTime.elevenToTwentyPercentage ? monitoringTime.elevenToTwentyPercentage : 0}%`, backgroundColor: `#be2edd` }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${monitoringTime.moreThanTwentyPercentage ? monitoringTime.moreThanTwentyPercentage : 0}%`, backgroundColor: `#227093` }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${monitoringTime?.sentToMDPercentage ? monitoringTime?.sentToMDPercentage : 0}%`, backgroundColor: `#17a2b8` }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          {/* <div className="progress-bar" role="progressbar" style={{width: `0%`, backgroundColor : '#17a2b8'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div> */}
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-sm table-borderless table-striped">
          <tbody id={css.widgetTable}>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#eb2f06` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?monitoring=notMonitored`}>Not Opened</Link>
              </td>
              <td style={{ fontSize: `small` }}>{monitoringTime.numberOfUnopenedMinutes ? monitoringTime.numberOfUnopenedMinutes : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>{monitoringTime.unOpenedPercentage ? monitoringTime.unOpenedPercentage : 0}%</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#a70909` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?monitoring=zeroToTen`}>0 - 10 min</Link>
              </td>
              <td style={{ fontSize: `small` }}>{monitoringTime.numberOfOneToTenMinutes ? monitoringTime.numberOfOneToTenMinutes : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>{monitoringTime.oneToTenPercentage ? monitoringTime.oneToTenPercentage : 0}%</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#be2edd` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?monitoring=tenToTwenty`}>10 - 20 min</Link>
              </td>
              <td style={{ fontSize: `small` }}>{monitoringTime.numberOfElevenToTwentyMinutes ? monitoringTime.numberOfElevenToTwentyMinutes : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {monitoringTime.elevenToTwentyPercentage ? monitoringTime.elevenToTwentyPercentage : 0}%
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#227093` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?monitoring=greaterThanTwenty`}>{`> 20 min`}</Link>
              </td>
              <td style={{ fontSize: `small` }}>
                {monitoringTime.numberOfGreaterThanTwentyMinutes ? monitoringTime.numberOfGreaterThanTwentyMinutes : 0}
              </td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {monitoringTime.moreThanTwentyPercentage ? monitoringTime.moreThanTwentyPercentage : 0}%
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#17a2b8` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                <Link to={`/patient?monitoring=sentToMd`}>{`Sent to MD`}</Link>
              </td>
              <td style={{ fontSize: `small` }}>{monitoringTime?.sentToMd ? monitoringTime?.sentToMd : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {` `}
                {monitoringTime?.sentToMDPercentage ? monitoringTime?.sentToMDPercentage : 0}%{` `}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {loading ? <PuffSpinner /> : null}
    </div>
  );
}

