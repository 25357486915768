import { Skeleton } from '@mui/material';
import React from 'react';
import { PastDataNavigation } from 'src/pages/home/modules/patient2/components/patientDetails/moreDetails/pastData/pastDataPDFviewer/pastDataNavigation';

let skelton = [
  {
    type: `rectangular`,
  },
  {
    type: `rectangular`,
  },
  {
    type: `rectangular`,
  },
  {
    type: `rectangular`,
  },
  {
    type: `rectangular`,
  },
  {
    type: `rectangular`,
  },
  {
    type: `rectangular`,
  },
];
let skeltonHead = [
  {
    type: `rectangular`,
  },
];

export const TableSkelton = () => {
  return (
    <table className="table table-bordered">
      <thead style={{ backgroundColor: `transparent`, color: `black` }}>
        {skeltonHead.map((e, i) => {
          return (
            <tr key={i + `table`}>
              <th>Period</th>
              <th>Month</th>
              <th>Month</th>
              <th>Month</th>
              <th>Month</th>
              <th>Month</th>
              <th>Month</th>
            </tr>
          );
        })}
      </thead>
      <tbody>
        {skelton.map((i) => {
          return (
            <tr key={i + `sekelton`}>
              <td>
                <Skeleton height={40} width={40} type="rectangular" />
              </td>
              <td>
                {` `}
                <Skeleton height={40} width={40} type="rectangular" />
              </td>
              <td>
                {` `}
                <Skeleton height={40} width={40} type="rectangular" />
              </td>
              <td>
                {` `}
                <Skeleton height={40} width={40} type="rectangular" />
              </td>
              <td>
                {` `}
                <Skeleton height={40} width={40} type="rectangular" />
              </td>
              <td>
                {` `}
                <Skeleton height={40} width={40} type="rectangular" />
              </td>
              <td>
                {` `}
                <Skeleton height={40} width={40} type="rectangular" />
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={`7`}>
            {` `}
            <PastDataNavigation />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
