import { CREATE_TELEMEDICINE_ROOM, INDIVIDUAL_ROOM_TELEMEDICINE_TIME, DELETE_SPECIFIC_TELEMEDICINE_ROOM } from './telemedicineTypes';

export const createTelemedicineRoom = (room) => {
  return {
    type: CREATE_TELEMEDICINE_ROOM,
    payload: room,
  };
};
export const individualRoomTelemedicineVideoCallTime = (roomId, callDuration) => {
  return {
    type: INDIVIDUAL_ROOM_TELEMEDICINE_TIME,
    payload: { roomId: roomId, videoCallDurationTimeStamp: callDuration },
  };
};
export const deleteSpecificTelemedicineRoom = (roomId) => {
  return {
    type: DELETE_SPECIFIC_TELEMEDICINE_ROOM,
    payload: { roomId: roomId },
  };
};

// NOTE -> CREATE/LIST TELEMEDICINE VIDEO CALL ROOMS
// -> ASYNC SPECIAL USER CREATOR, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _createTelemedicineRoom = (roomId, token) => {
  let nestedObj = {};
  nestedObj[roomId] = {
    token: token,
    videoCallDurationTimeStamp: ``,
  };
  return (dispatch) => {
    dispatch(createTelemedicineRoom(nestedObj));
  };
};

// NOTE -> SET/UPDATE INDIVIDULA ROOM, VIDEO CALL DURATION
// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _individualRoomTelemedicineVideoCallTime = (roomId, videoCallDurationTimeStamp) => {
  return (dispatch) => {
    dispatch(individualRoomTelemedicineVideoCallTime(roomId, videoCallDurationTimeStamp));
  };
};

// NOTE -> REMOVE SPECIFIC TELEMEDICINE ROOM
// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _deleteSpecificTelemedicineRoom = (roomId) => {
  return (dispatch) => {
    dispatch(deleteSpecificTelemedicineRoom(roomId));
  };
};
