import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConditionallyRenderWrapper } from './ConditionallyRenderWrapper';
import { switchPractice } from 'src/redux/action/setUserHandler';
import { PuffSpinner } from '../components/spinner/puff/puff';
import { blankPatientModule } from 'src/pages/home/modules/patient2/PatientComponent';
export const SwitchPracticeWrapper = ({ children }) => {
  const isSwitched = useSelector((state) => state?.user?.hasSwitchedPractice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSwitched) {
      dispatch(switchPractice(false));
      blankPatientModule()
    }
  }, [isSwitched]);
  return (
    <ConditionallyRenderWrapper con={!isSwitched} renderKey={<PuffSpinner />}>
      {children}
    </ConditionallyRenderWrapper>
  );
};
