import {
  ADD_RISK_NOTIFICATIONS,
  INITIALIZE_SMS_ARRAY_FOR_NOTIFICATION,
  INCOMMING_TWILIO_SMS,
  OUTGOING_TWILIO_SMS,
  REMOVE_SMS_ARRAY_FOR_NOTIFICATION,
  MD_CLINICAL_STAFF_COMMON_SMS_THREAD,
  SMS_ARRAY_FOR_NOTIFICATION,
  REMOVE_INCOMMING_TWILIO_SMS_FROM_NOTIFICATION_ARRAY,
} from './twilioTypes';
import { store } from 'src/redux/store';

export const emptySMSArrayObject = () => {
  return {
    type: REMOVE_SMS_ARRAY_FOR_NOTIFICATION,
    payload: [],
  };
};

export const addSMSArrayObjectInitial = (payload) => {
  return {
    type: INITIALIZE_SMS_ARRAY_FOR_NOTIFICATION,
    payload,
  };
};
export const twillioNotify = (payLoad) => {
  return {
    type: `NOTIFICATION_FROM_PATIENT_MESSAGE`,
    payLoad: payLoad,
  };
};

export const twilioIncommingSMS = (inCommingSmsPayload) => {
  return {
    type: INCOMMING_TWILIO_SMS,
    payload: inCommingSmsPayload,
  };
};
export const twilioOutGoingSMS = (outGoingSmsPayload) => {
  return {
    type: OUTGOING_TWILIO_SMS,
    payload: outGoingSmsPayload,
  };
};
export const _pushSMSIntoNotificationArray = (inCommingSmsPayload) => {
  return {
    type: SMS_ARRAY_FOR_NOTIFICATION,
    payload: inCommingSmsPayload,
  };
};
export const _removeSMSFromNotificationArray = (id) => {
  return {
    type: REMOVE_INCOMMING_TWILIO_SMS_FROM_NOTIFICATION_ARRAY,
    payload: id,
  };
};
export const providerClinicalStaffCommonSmsThread = (inCommingSmsPayload) => {
  return {
    type: MD_CLINICAL_STAFF_COMMON_SMS_THREAD,
    payload: inCommingSmsPayload,
  };
};

// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _twilioInCommingSMS = (inCommingSmsPayload) => {
  return (dispatch) => {
    dispatch(twilioIncommingSMS(inCommingSmsPayload));
  };
};

export const _addRiskArrayObjectInitial = (riskNotifications) => {
  return {
    type: ADD_RISK_NOTIFICATIONS,
    payload: riskNotifications,
  };
};

// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _twilioOutGoingSMS = (outGoingSmsPayload) => {
  return (dispatch) => {
    dispatch(twilioOutGoingSMS(outGoingSmsPayload));
  };
};

// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _providerClinicalStaffCommonSmsThread = (inCommingSmsPayload) => {
  return (dispatch) => {
    dispatch(providerClinicalStaffCommonSmsThread(inCommingSmsPayload));
  };
};
// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const pushSMSIntoNotificationArray = (inCommingSmsPayload) => {
  return (dispatch, getState) => {
    //Logic : when a specific patient selected and twilio text sms come, don't need push notification

    const { patient } = getState();

    if (patient?.patientData?.data === inCommingSmsPayload.from) {
      return;
    }
    dispatch(_pushSMSIntoNotificationArray(inCommingSmsPayload));
  };
};
// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const removeSMSFromNotificationArray = (id) => {
  return (dispatch) => {
    dispatch(_removeSMSFromNotificationArray(id));
  };
};
export const notifyTwillioThunk = (data) => {
  let patientId = store.getState()?.patient?.patientData?.data?._id;
  return (dispatch) => {
    dispatch(twillioNotify({ ...data, patientId: patientId }));
  };
};
