import { COUNST_SUSPENSE, COUNTS_DATA } from './counts.types';
const initialState = {
  counts: {},
  suspense: { loading: false, error: false },
};
export const countsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNST_SUSPENSE:
      return { ...state, suspense: action.payLoad };
    case COUNTS_DATA:
      return { ...state, counts: action.payLoad };
    default:
      return state;
  }
};
