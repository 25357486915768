/* eslint-disable no-case-declarations */
import { returnIfArr } from 'src/utils';
import {
  HANDLE_TIMER,
  LIST_ALL_PATIENTS,
  LIST_ALL_PATIENTS_ERROR,
  LIST_ALL_PATIENTS_EMPTY,
  LIST_ALL_PATIENTS_LOADING,
  SELECTED_PATIENT,
  SELECTED_PATIENT_LOADING,
  SELECTED_PATIENT_ERROR,
  INITIALIZE_START_END_TIME,
  SELECTED_PATIENT_REPORT,
  ACTIVE_CONNECTION,
  PATIENTS_FILTERS,
  SELECTED_PATIENT_UNUSE,
  PATIENT_DATA_EMPTY,
  SET_VIDEO_CALL_STATUS,
  HANDLE_SELECTED_TAB,
  REPORT_LOADING,
  HIDE_TIMER,
  SET_NOTES,
  SET_MESSAGES,
  SET_MESSAGES_CURRENT_PAGE,
  SET_MESSAGES_HASMORE,
  SMSRESPONSE,
  SOCKET_RESPONSE,
  TXT_ERROR,
  ALL_TIME_LOGS,
  ALL_TIME_LOGS_LOADING,
  // GET_SMART_PHRASE,
  COMMUNICATION_PATIENT,
  CHANGE_LEFT_VOICE_MAIL_STATUS,
  PATIENT_SWITCHING,
  CHANGE_TIME_MONITORED,
  PATIENT_DATA_FLAG,
  SAVE_CSV_BULK_IMPORT_PATIENT,
  EMPTY_CSV_BULK_IMPORT_PATIENT,
  PATIENT_API_CAN_TOK_DETAIL,
  PATIENT_API_CAN_TOK_LIST,
} from './patientTypes';
import { momentWrapper } from 'src/momentWrapper';


const emptyFunc = { cancel: () => null }

const apiTokensInState = {
  patList: { ...emptyFunc }, patDetail: { ...emptyFunc }
}

const initialState = {
  apiTokens: { ...apiTokensInState },
  activeConnection: false,
  bulkImportCsv: [],
  hasPatientPastData: false,
  pageRefreshPatientState: {},
  isAdded: false,
  isChatting: false,
  descriptionText: ``,
  timer: {
    audioCall: false,
    videoCall: false,
    autoTimer: false,
    toggleButton: false,
    viewSource: ``,
    logDescription: ``,
    endTime: null,
    startTime: null,
  },
  hideTimer: false,
  isSwitchingPatient: false,
  patientsList: ``,
  patientData: ``,
  selectedPatient: null,
  patientReport: null,
  videoCall: null,
  isOnVideo: false,
  selectedTab: null,
  messages: [],
  messagesCurrentPage: 1,
  messagesHasMore: true,
  messageLoadingFlag: { loading: false, error: false },
  popedMessages: [],
  notes: [],
  notesFlag: { loadingCall: false, error: false },
  reportLoadingFlag: false,
  globalFlag: false,
  currenTab: `msg`,
  allTimeLogs: {
    loading: false,
    timeLogs: null,
  },
  patientsFilters: {
    sortField: `lastName`,
    sortOrder: `ASC`,
    pageNumber: 1,
    pageSize: 30,
    searchFilters: {
      monitoringTime: [],
      tier: [],
      isAdherent: [],
      online: [],
      status: [`active`],
      recurringMonth: [],
      assignedAs: [`primary`],
    },
  },
  smartPhrases: [],
};

export const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATIENT_DATA_FLAG:
      return { ...state, hasPatientPastData: action.payLoad };
    case SAVE_CSV_BULK_IMPORT_PATIENT:
      return { ...state, bulkImportCsv: action?.payLoad };
    case EMPTY_CSV_BULK_IMPORT_PATIENT:
      return { ...state, bulkImportCsv: [] };
    case HANDLE_TIMER:
      const { status, reason = false, timeEnd = false, videoOrAudio, autoTimer } = action.payload;
      const spreadReason = () => {
        return { reason: reason ? reason : `` };
      };
      if (status === false) {
        if (autoTimer === `true`) {
          return {
            ...state,
            timer: {
              ...state.timer,
              ...spreadReason(),
              toggleButton: false,
              audioCall: false,
              videoCall: false,
              autoTimer: true,
              logDescription: `View graphs and reports`,
              viewSource: `View Report`,
              endTime: timeEnd ? timeEnd : momentWrapper().toISOString(),
            },
          };
        } else {
          return {
            ...state,
            timer: {
              ...state.timer,
              ...spreadReason(),
              toggleButton: false,
              audioCall: false,
              videoCall: false,
              logDescription: `View graphs and reports`,
              viewSource: `View Report`,
              endTime: timeEnd ? timeEnd : momentWrapper().toISOString(),
            },
          };
        }
      }

      // else when to turn on the timer
      else {
        if (videoOrAudio === `video`) {
          if (autoTimer === `false`) {
            return {
              ...state,
              timer: {
                ...state.timer,
                toggleButton: false,
                ...spreadReason(),
                audioCall: false,
                videoCall: true,
                autoTimer: false,
                logDescription: `Video call with patient`,
                viewSource: `Video Call`,
                startTime: momentWrapper().toISOString(),
              },
            };
          } else {
            return {
              ...state,
              timer: {
                ...state.timer,
                toggleButton: false,
                ...spreadReason(),
                audioCall: false,
                videoCall: true,
                logDescription: `Video call with patient`,
                viewSource: `Video Call`,
                startTime: momentWrapper().toISOString(),
              },
            };
          }
        }

        // else if we have to turn on the audio timer
        else if (videoOrAudio === `audio`) {
          if (autoTimer === `false`) {
            return {
              ...state,
              timer: {
                ...state.timer,
                toggleButton: false,
                ...spreadReason(),
                audioCall: true,
                videoCall: false,
                autoTimer: false,
                viewSource: `Audio Call`,
                startTime: momentWrapper().toISOString(),
              },
            };
          } else {
            return {
              ...state,
              timer: {
                ...state.timer,
                toggleButton: true,
                ...spreadReason(),
                audioCall: true,
                videoCall: false,
                viewSource: `Audio Call`,
                startTime: momentWrapper().toISOString(),
              },
            };
          }
        }

        // else if we have to turn on the timer toggle
        else {
          if (autoTimer === `false`) {
            return {
              ...state,
              timer: {
                ...state.timer,
                toggleButton: true,
                ...spreadReason(),
                audioCall: false,
                videoCall: false,
                autoTimer: false,
                logDescription: `View graphs and reports`,
                viewSource: `View Report`,
                startTime: momentWrapper().toISOString(),
              },
            };
          } else {
            return {
              ...state,
              timer: {
                ...state.timer,
                toggleButton: true,
                ...spreadReason(),
                audioCall: false,
                videoCall: false,
                logDescription: `View graphs and reports`,
                viewSource: `View Report`,
                startTime: momentWrapper().toISOString(),
              },
            };
          }
        }
      }
    case INITIALIZE_START_END_TIME:
      return {
        ...state,
        timer: {
          ...state.timer,
          endTime: null,
          startTime: null,
        },
      };
    case PATIENT_SWITCHING:
      return { ...state, isSwitchingPatient: action.payLoad };

    case CHANGE_TIME_MONITORED:
      return {
        ...state,
        ...(action.payLoad?._patientId === state.patientData?.data?._id && Number.isInteger(action?.payLoad?.accumulatedTime) && { patientData: { data: { ...state.patientData?.data, timeMonitored: action?.payLoad?.accumulatedTime } } }),
        patientsList: {
          ...state.patientsList, data: {
            ...state.patientsList.data,
            patients: [...returnIfArr(state?.patientsList?.data?.patients).map((patient) => {
              if (action?.payLoad?._patientId === patient?._id) {
                return { ...patient, timeMonitored: action?.payLoad?.accumulatedTime };
              }
              return { ...patient }
            })]
          }
        }
      }
    case CHANGE_LEFT_VOICE_MAIL_STATUS:
      return {
        ...state,
        patientData: {
          ...state?.patientData,
          data: { ...state?.patientData?.data, currentMonth: { ...state?.patientData?.data?.currentMonth, leftVoiceMail: action?.payLoad?.flag } },
        },
        patientsList: {
          ...state?.patientsList,
          data: {
            ...state?.patientsList?.data,
            patients: [
              ...returnIfArr(state?.patientsList?.data?.patients).map((patient) => {
                if (action.payLoad?.id === patient?._id) {
                  return { ...patient, leftVoiceMail: action?.payLoad?.flag };
                } else {
                  return { ...patient };
                }
              }),
            ],
          },
        },
      };
    case ALL_TIME_LOGS:
      return {
        ...state,
        allTimeLogs: {
          timeLogs: action.payload,
          loading: false,
        },
      };
    case LIST_ALL_PATIENTS:
      return {
        ...state,
        patientsList: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };

    case LIST_ALL_PATIENTS_LOADING:
      return {
        ...state,
        patientsList: {
          ...state.patientsList,
          loading: true,
          error: false,
        },
      };
    case ALL_TIME_LOGS_LOADING:
      return {
        ...state,
        allTimeLogs: {
          ...state.allTimeLogs,
          loading: true,
        },
      };
    case LIST_ALL_PATIENTS_ERROR:
      return {
        ...state,
        patientsList: {
          ...state.patientsList,
          loading: false,
          error: true,
        },
      };
    case SELECTED_PATIENT_LOADING:
      return {
        ...state,
        patientData: {
          ...state.patientData,
          loading: true,
        },
      };
    case SELECTED_PATIENT_ERROR:
      return {
        ...state,
        patientData: {
          ...state.patientData,
          loading: false,
          error: true,
          success: false,
        },
      };

    case SELECTED_PATIENT:
      return {
        ...state,
        patientData: {
          data: action.payload,
          loading: false,
          error: false,
          success: false,
          currenTab: `msg`,
        },
      };
    case `ADD_PATIENT`:
      return { ...state, isAdded: action.payLoad };
    case LIST_ALL_PATIENTS_EMPTY:
      return {
        ...state,
        patientsList: action.payload,
      };
    case PATIENT_DATA_EMPTY:
      return {
        ...state,
        patientData: action.payload,
        apiTokens: { ...apiTokensInState },
      };
    case SET_VIDEO_CALL_STATUS:
      return { ...state, videoCall: action.payload };
    case `VIDEOFLAG`:
      return { ...state, isOnVideo: action.payLoad };
    case HANDLE_SELECTED_TAB:
      return { ...state, selectedTab: action.payload };
    case SET_MESSAGES:
      return { ...state, messages: action.payload };
    case SET_MESSAGES_CURRENT_PAGE:
      return { ...state, messagesCurrentPage: action.payload };
    case SET_MESSAGES_HASMORE:
      return { ...state, messagesHasMore: action.payload };
    case `SET_MESSAGES_REPLY`:
      return { ...state, messages: [...state.messages, action.payload] };
    case `MSGAPICALL`:
      return { ...state, messageLoadingFlag: action.payLoad };
    case SOCKET_RESPONSE:
      let messagess = state.messages;
      let ids = action?.payLoad?.id;
      let data = action?.payLoad?.data;
      let error = action?.payLoad?.error;
      let hasID = messagess.find((e) => {
        return e._uniqueId === ids;
      });
      if (error && hasID) {
        return {
          ...state,
          messages: state.messages.map((e) =>
            e._uniqueId === ids
              ? {
                ...e,
                error: true,
                status: `failed`,
              }
              : { ...e }
          ),
        };
      } else if (hasID) {
        return {
          ...state,
          messages: state.messages.map((e) =>
            e._uniqueId === ids
              ? {
                ...data,
                status: `delivered`,
              }
              : { ...e }
          ),
        };
      } else {
        return state;
      }
    case SMSRESPONSE:
      let message = action.payLoad;
      let uniqueId = message?._uniqueId;
      let hasUnique = state.messages.find((e) => e._uniqueId === uniqueId);
      if (hasUnique) {
        return {
          ...state,
          messages: state.messages.map((e) =>
            e._uniqueId === uniqueId
              ? {
                ...message,
                status: `delivered`,
              }
              : { ...e }
          ),
        };
      } else {
        return state;
      }

    case TXT_ERROR:
      let errors = action.payLoad.error;
      let uni = action.payLoad._uniqueId;
      let hasUni = state.messages.find((e) => e._uniqueId === uni);

      if (hasUni && errors) {
        return {
          ...state,
          messages: state.messages.map((e) =>
            e._uniqueId === uni
              ? {
                ...e,
                error: true,
                status: `failed`,
              }
              : {
                ...e,
              }
          ),
        };
      } else {
        return state;
      }
    case COMMUNICATION_PATIENT:
      return {
        ...state,
        isChatting: action?.payLoad?.chatting,
        descriptionText: action?.payLoad?.text,
      };

    case SET_NOTES:
      return { ...state, notes: action.payload };
    case `LOGFLAG`:
      return { ...state, notesFlag: action.payLoad };
    case REPORT_LOADING:
      return { ...state, reportLoadingFlag: action.payload };
    case HIDE_TIMER:
      if (action.payload) {
        return { ...state, timer: { ...state.timer, startTime: momentWrapper().toISOString() }, hideTimer: action.payload };
      } else {
        return { ...state, timer: { ...state.timer, endTime: momentWrapper().toISOString() }, hideTimer: action.payload };
      }
    // Not in use right now just doing for the patient 1 module
    case SELECTED_PATIENT_UNUSE:
      return {
        ...state,
        selectedPatient: action.payload,
      };
    //End
    case PATIENTS_FILTERS:
      return {
        ...state,
        patientsFilters: action.payload,
      };
    case SELECTED_PATIENT_REPORT:
      return {
        ...state,
        patientReport: action.payload,
      };
    case ACTIVE_CONNECTION:
      return { ...state, activeConnection: action.payLoad };
    case PATIENT_API_CAN_TOK_DETAIL:
      return { ...state, apiTokens: { ...state.apiTokens, patDetail: action.payLoad } }
    case PATIENT_API_CAN_TOK_LIST:
      return { ...state, apiTokens: { ...state.apiTokens, patList: action.payLoad } }
    // //  set smart phrases
    // case GET_SMART_PHRASE:
    //   return { ...state, smartPhrases: action.payload };
    default:
      return state;
  }
};
