import axios from 'axios';
import config from 'src/config.js';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { logoutHandlerS } from 'src/redux/action/logoutHandler';
import { store } from 'src/redux/store';
import { autoLogoutIncominCallProt, converBuffIntoJs, isBufferArray } from 'src/utils';

// config.SERVER_ADDRESSS;

export const SERVER_ADDRESS = config.SERVER_ADDRESSS;

export const axiosClient = axios.create();

axiosClient.defaults.baseURL = SERVER_ADDRESS;
axiosClient.interceptors.request.use((config) => {
  const token = store?.getState().user?.auth;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status == 401 && error?.response?.data?.isLoggedOut) {
      autoLogoutIncominCallProt();
      store.dispatch(logoutHandlerS());
      newSocket.close();
    } else if (error?.response?.status == 409) {
      errorToast(error?.response?.data?.message ? error.response.data.message : `Sorry given entry already exists!`);
    } else if (error?.response?.status == 413) {
      errorToast(`The maximum file size that you can upload is 10MB`);
    } else {
      const decideError = () => {
        const isBuff = isBufferArray(error?.response?.data);
        const buffConvertedVal = isBuff ? converBuffIntoJs(error?.response?.data) : null;
        let errObj = isBuff ? { response: { data: { ...buffConvertedVal } } } : error;
        let errorMsg = ``;
        if (errObj?.response?.data?.message) {
          errorMsg = errObj?.response?.data?.message;
        } else if (errObj?.response?.data?.err) {
          errorMsg = errObj?.response?.data?.err;
        } else if (errObj?.response?.data.error) {
          errorMsg = errObj?.response?.data.error;
        } else if (errObj?.response?.data.error?.message) {
          errorMsg = errObj?.response?.data.error?.message;
        }
        return errorMsg;
      };

      let errorMessage = decideError();
      if (errorMessage) errorToast(errorMessage?.message ? errorMessage?.messag : errorMessage || `error`);
    }
    return Promise.reject(error);
  }
);
