import React, { useState } from 'react';
import { CustomBtn } from '../Button/CustomBtn';
import { Box, Typography, Stack, IconButton, Fade, Zoom } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ToolTipProvider } from '../Tooltip/ToolTipProvider';
import CloseIcon from '@mui/icons-material/Close';

export const AskForConfirmation = ({ closeSignProps, acceptElem, rejectElem, confirmationProps, navigationProps, actionOnAccept, actionOnReject, initialValue }) => {
  const { containerStyles, confirmationText, acceptText, rejectText } = confirmationProps;
  const { hasSign, action: closeAction } = closeSignProps || false
  const { iconBtnBlockStyles, hasNavigation, action } = navigationProps;
  const [element, setElement] = useState(``);
  const decideConfTxtStyles = () => {
    if (hasSign) {
      return {
        display: `flex`, justifyContent: `space-between`, width: `100%`
      }
    }
    return { width: `100%` }
  }
  return (
    <>
      <Box sx={{ ...containerStyles }}>
        {initialValue
          ? rejectElem
          : !element && (
            <Zoom in={!element} mountOnEnter unmountOnExit>
              <div>
                <Box sx={{ ...decideConfTxtStyles() }} >
                  <Box sx={{ width: `100%`, textAlign: `center` }}>
                    <Typography variant="h6">{confirmationText}</Typography>
                  </Box>
                  {hasSign && <Box>
                    <IconButton sx={{ color: `red` }} onClick={closeAction}>
                      <CloseIcon />
                    </IconButton>
                  </Box>}
                </Box>

                <Box sx={{ width: `100%`, textAlign: `center`, mt: 1 }}>
                  <Stack direction="row" justifyContent={`center`} sx={{ width: `100%` }}>
                    <Zoom in={!element} style={{ transitionDelay: !element ? `200ms` : `0ms` }}>
                      <Box>
                        {` `}
                        <CustomBtn
                          element={acceptText}
                          btnProps={{
                            type: `button`,
                            onClick: () => {
                              setElement(acceptElem);
                              if (actionOnAccept) {
                                actionOnAccept();
                              }

                            },
                            className: `btn btn-outline-info btn-sm`,
                            size: `small`,
                          }}
                        />
                      </Box>
                    </Zoom>
                    <Zoom in={!element} style={{ transitionDelay: !element ? `300ms` : `0ms` }}>
                      <Box sx={{ ml: 1 }}>
                        {` `}
                        <CustomBtn
                          element={rejectText}
                          btnProps={{
                            type: `button`,
                            onClick: () => {
                              if (actionOnReject) {
                                actionOnReject();
                              }

                              setElement(rejectElem);
                            },
                            className: `btn btn-outline-info btn-sm`,
                            size: `small`,
                          }}
                        />
                      </Box>
                    </Zoom>
                  </Stack>
                </Box>
              </div>
            </Zoom>
          )}

        {element && (
          <Box sx={{ width: `100%` }}>
            {hasNavigation && (
              <Box sx={{ ...iconBtnBlockStyles }}>
                <ToolTipProvider
                  toolTipProps={{
                    placement: `top`,
                    arrow: true,
                    title: `Navigate Back to default step!`,
                  }}
                  element={
                    <IconButton
                      onClick={() => {
                        action();
                        setElement(false);
                      }}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                  }
                />
              </Box>
            )}

            <Fade in={element} mountOnEnter unmountOnExit>
              <Box>{element}</Box>
            </Fade>
          </Box>
        )}
      </Box>
    </>
  );
};
