import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { dynamicWriteCredential, setCharAtStr, validKeysCode } from 'src/utils';
import { Box, Menu, MenuItem } from '@mui/material';
import Highlighter from 'react-highlight-words';
import { capitalizationFirstLetterWithIAndCapitalizeTheMonitair } from 'src/utils';
import { ConditionallyRenderWrapper } from '../../HOC/ConditionallyRenderWrapper';



export const SmartPhraseModal = (props) => {

  const { newNote, children } = props
  const [smartPhrase, setSmartPhrase] = useState(false);
  const [topPosition, setTopPosition] = useState(`0`)
  const container = useRef(null)

  useEffect(() => {
    if (shortVerse(newNote).includes(`#`)) {
      const calc = () => {
        const elem = container.current
        const distanceScrolled = document.body.scrollTop;
        const elemRect = elem.getBoundingClientRect();
        const elemViewportOffset = elemRect.top;
        const totalOffset = distanceScrolled + elemViewportOffset;
        setTopPosition(totalOffset - 350)

      }
      calc()
      setSmartPhrase(true);

    }
  }, [newNote]);

  const shortVerse = (verse) => {
    let result = [];
    verse.split(` `).map((word) => (word.charAt(0) != `` ? result.push(word.charAt(0)) : ``));
    return result.join(` `);
  }

  return (
    <>
      <Box ref={container} >{children}</Box>
      <ConditionallyRenderWrapper con={smartPhrase}>
        <Modal props={{ ...props, setTopPosition, topPosition, container, smartPhrase, setSmartPhrase }} />
      </ConditionallyRenderWrapper>
    </>
  )

};





const Modal = ({ props: { setTopPosition, topPosition, container, setSmartPhrase, smartPhrase, setNewNote,
  newNote,
  setPhraseId,
  setPhraseScope,
  slectedPhraseId } }) => {

  const smartPhraseFromRedux = useSelector((state) => state?.practice?.smartPhrases);
  const [searchValue, setSearchValue] = useState(``)
  const [shouldSearch, setShouldSearch] = useState(false)

  useEffect(() => {
    const searchHandler = (e) => {
      if (e.keyCode === 8 || e.shiftKey) setSearchValue(``)
      else if (validKeysCode.includes(e.keyCode)) setSearchValue((p) => {
        return `${p}${e.key}`
      })
    }
    if (shouldSearch) document.addEventListener(`keyup`, searchHandler)
    return () => document.removeEventListener(`keyup`, searchHandler)
  }, [shouldSearch])



  const keyListener = (e, phrase) => {
    e.preventDefault()
    if (smartPhrase) {
      if (!shouldSearch) setShouldSearch(true)
      if (e.keyCode === 13 && e.shiftKey && phrase) getPhrase(phrase);
    }
  }

  const resetState = (newNote) => {
    setSmartPhrase(false)
    setSearchValue(``)
    setShouldSearch(false)
    setTopPosition(`0`)
    if (newNote) removeHashes(newNote)
  }

  const removeHashes = (newNote) => {
    let withoutHashing = newNote.replace(`#`, ``)
    setNewNote(`${withoutHashing}`)
  }

  const getPhrase = (phrase) => {
    const decideWhatToDo = () => {
      if (newNote) {
        return newNote.split(` `)?.length > 1 ? true : false
      }
      return false
    }

    const insterionPoint = () => {
      const hashWord = newNote.indexOf(`#`)
      if (hashWord !== 0 || hashWord !== newNote.length && newNote?.length) return setCharAtStr({ str: newNote, index: hashWord, chr: phrase.text })
      return newNote
    }
    let isMerge = decideWhatToDo()
    removeHashes(isMerge ? `${insterionPoint()}` : `${phrase.text}`)
    setPhraseId(phrase?._id);
    setPhraseScope(phrase?.scope);
    resetState(false)
  };

  const hoverAndFocusStyles = {
    cursor: `pointer`,
    transition: `box-shadow 0.3s ease-in-out`,
    boxShadow: `0 5px 15px rgba(0,0,0,0.3)`,
    background: `aliceblue`,
    borderLeft: `#1699c5 4px solid`
  }



  const filteredPhrases = useMemo(() => {
    return smartPhraseFromRedux?.filter((obj) => obj?.text.toLowerCase().includes(searchValue.toLowerCase()) || obj?.title.toLowerCase().includes(searchValue.toLowerCase()))
  }, [smartPhraseFromRedux, searchValue])
  return <Menu
    sx={{
      '& .MuiPaper-root': {
        height: `400px `,
        backgroundColor: `whitesmoke`,
        boxShadow: `5px 5px #f4fcfd `,
        width: `350px`,
        top: `${topPosition}px !important`
      }
    }}
    MenuListProps={{
      sx: {
        width: `350px`,
        '& .MuiMenuItem-root': {
          whiteSpace: `normal`,
          border: `0.3px solid #d5cccc`,
          borderRadius: `6px`,
          fontSize: `1rem`,
          m: 1,
          '&:hover': {
            ...hoverAndFocusStyles
          },
          '&:focus': {
            ...hoverAndFocusStyles
          }
        },
      },
    }}
    onClose={() => resetState(newNote)} anchorOrigin={{ vertical: `top`, horizontal: `center` }}
    transformOrigin={{ vertical: `top`, horizontal: `center` }} open={smartPhrase} id={slectedPhraseId} anchorEl={container.current}>
    {filteredPhrases?.length ? filteredPhrases.map((phrase) => {
      phrase.text = dynamicWriteCredential(phrase.text)
      return <MenuItem sx={{ p: `.3em` }} title={capitalizationFirstLetterWithIAndCapitalizeTheMonitair(phrase.text)} onKeyDown={(e) => keyListener(e, phrase)} onClick={() => getPhrase(phrase)} value={phrase?.text} key={phrase._id}>
        <div style={{ padding: `.1em` }}>
          <div style={{ fontSize: `.7em`, fontWeight: `bold` }}>
            <Highlighter
              highlightStyle={{ fontWeight: `normal`, background: `#9bdff7` }}
              searchWords={searchValue?.split(/\s/).filter((word) => word)}
              textToHighlight={phrase.title}
            />
          </div>
          <div style={{
            fontSize: `.7em`,
            fontWeight: `600`
          }}>
            <span className="text-muted">
              <Highlighter
                highlightStyle={{ fontWeight: `normal`, background: `#9bdff7` }}
                searchWords={searchValue?.split(/\s/).filter((word) => word)}
                textToHighlight={phrase.text.length > 80 ? phrase.text.substring(0, 80) + `...` : phrase.text}
              />
            </span>
          </div>
        </div>
      </MenuItem>
    }) :
      <MenuItem onKeyDown={keyListener} sx={{ mt: 4, mb: 4 }}>
        <div className="p-3 text-center" >
          <span onClick={() => setSmartPhrase(false)}>Phrase Not Found.</span>
        </div>
      </MenuItem>}
  </Menu >


}