import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  outlinedButton: {
    textTransform: 'capitalize',
    borderColor: '#7D798C',
    color: '#7D798C',
    paddingLeft: 16,
    paddingRight: 16,
  },
  containedButton: {
    backgroundColor: '#1699C5',
    textTransform: 'capitalize',
    paddingLeft: 16,
    paddingRight: 16,
    '&:hover': {
      backgroundColor: "#1689C5"
    },
  },
  containedButtonInTable: {
    backgroundColor: '#1699C5',
    borderRadius: "20px",
    textTransform: 'capitalize',
    paddingLeft: 30,
    paddingRight: 30,
    '&:hover': {
      backgroundColor: "#1689C5"
    },
  },
  assignedUserModalChipStyle: {
    backgroundColor: '#DCF0F7',
    borderRadius: '14px',
    padding: '10px',
    marginTop: '20px',
  },
  tableHeading: {
    color: '#DCF0F7',
  },
  tableHeader: {
    backgroundColor: `#1699C5`
  },
  iconColor: {
    color: "#7D798C",
    cursor: "pointer",
  },
  headingParagraph: {
    paddingTop: 6,
    paddingLeft: 6,
    paddingBottom: 2,
  },
  containedAssignButton: {
    backgroundColor: '#1699C5',
    textTransform: 'capitalize',
    paddingLeft: 80,
    paddingRight: 80,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: "#1689C5"
    },
  },
  tableRowEven: {
    backgroundColor: '#F2F2F2',
  },
  tableRowOdd: {
    backgroundColor: 'white',
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectAllPatients: {
    backgroundColor: '#1699C5',
    borderRadius: "20px",
    textTransform: 'capitalize',
    paddingLeft: 30,
    paddingRight: 30,
    '&:hover': {
      backgroundColor: "#1689C5"
    },
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
});

