import { Fade } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { videoCallAction } from 'src/redux/patient/patientActions';

export const PatientVideoCall = ({ reportId }) => {
  const dispatchRedux = useDispatch();
  const videoCallStatus = useSelector((state) => state.patient.videoCall);
  const patientId = useSelector((state) => state?.patient?.patientData?.data?._id);
  const patientNumber = useSelector((state) => state?.patient?.patientData?.data?.phoneNumber);
  let enterVideo = true;
  const setValuesToRedux = () => {
    const obj = {
      reportId: reportId,
      patientId: patientId,
      patientNumber: patientNumber,
      startFlag: true,
    };
    dispatchRedux(videoCallAction(obj));
  };

  return (
    <Fade in={enterVideo} mountOnEnter unmountOnExit>
      <div
        className="p-3"
        style={{
          backgroundColor: `white`,
          flex: 1,
          verticalAlign: `middle`,
          height: `88%`,
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
        }}
      >
        <h5 className="text-center mb-3" style={{ color: `rgb(220, 220, 220)` }}>
          Press button to start call
        </h5>
        <div className="d-flex justify-content-center">
          <button
            disabled={videoCallStatus?.startFlag}
            style={{ outline: `none` }}
            data-toggle="modal"
            data-target="#patientVideoCallModal"
            onClick={() => setValuesToRedux()}
          >
            <i
              className="fa fa-phone pt-2 pb-2 pl-5 pr-5"
              style={{
                fontSize: 28,
                backgroundColor: `green`,
                color: `white`,
                borderRadius: `32px`,
              }}
              aria-hidden="true"
            ></i>
          </button>
        </div>
        {videoCallStatus?.startFlag && (
          <h5 className="text-center mt-3" style={{ color: `rgb(220, 220, 220)` }}>
            Please Wait
          </h5>
        )}
      </div>
    </Fade>
  );
}

