import React, { useState, useEffect } from 'react';
import { BILLING_COMPLETED, postRequest } from '../../../../../../../crud/crud';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Grid, Zoom } from '@mui/material';
import { fromDateChangeHandler, pickerPropsHandler, toDateChangeHandler } from 'src/pages/home/modules/settings/Analytics/utilities';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { Filterbutton } from 'src/pages/home/modules/settings/Analytics/Components/Filterbutton';
import { analyticsUrlHandler, doIfArr, startDateAnalytics } from 'src/utils';
import { momentWrapper } from 'src/momentWrapper';

export const Completed = ({ clicked }) => {
  const [loading, setLoading] = useState({ loading: false, error: false });
  const [fromDate, setFromDate] = useState(startDateAnalytics());
  const [toDate, setToDate] = useState(moment(new Date()).endOf(`month`));
  const [filterBtn, setFilterBtn] = useState(false);
  const [graphLabels, setGraphLabels] = useState([]);
  const [showMsg, setErrorMsg] = useState(false);
  const [billedReports, setBilledReports] = useState([]);
  const [readyToSignReports, setReadyToSignReports] = useState([]);
  const [readyToBillReports, setReadyToBillReports] = useState([]);
  const view = useSelector((state) => state?.analytics?.view);

  useEffect(() => {
    apiCall(view);
  }, [view]);
  const apiCall = async (view) => {
    let labelsTempArray = [];
    let billedReports = [];
    let readyToSignReports = [];
    let readyToBillReports = [];
    setLoading({ loading: true, error: false });
    setFilterBtn(true);
    setGraphLabels([]);
    setBilledReports([]);
    setReadyToSignReports([]);
    setReadyToBillReports([]);
    let filters = await analyticsUrlHandler(view);
    postRequest(BILLING_COMPLETED + `?filters=${filters}`, {
      startDate: momentWrapper(fromDate).startOf(`month`).toISOString(),
      endDate: momentWrapper(toDate).endOf(`month`).toISOString(),
    })
      .then((res) => {
        setLoading({ loading: false, error: false });
        setFilterBtn(false);

        doIfArr(res?.data?.billingAnalytics, () => {
          if (res.data.billingAnalytics) {
            for (const data of res.data.billingAnalytics) {
              labelsTempArray.push(data.monthName);
              let {
                billedReportsCount,
                billedReportPercentage,
                readyToBillReportsCount,
                readyToBillReportPercentage,
                readyToSignReportPercentage,
                readyToSignReportsCount,
              } = data;

              billedReports.push({
                id: billedReportsCount,
                percentage: billedReportPercentage,
              });
              readyToSignReports.push({
                id: readyToSignReportsCount,
                percentage: readyToSignReportPercentage,
              });
              readyToBillReports.push({
                id: readyToBillReportsCount,
                percentage: readyToBillReportPercentage,
              });
            }
          }
        });

        setGraphLabels([...labelsTempArray]);
        setBilledReports([...billedReports]);
        setReadyToSignReports([...readyToSignReports]);
        setReadyToBillReports([...readyToBillReports]);
      })
      .catch(() => {
        setLoading({ loading: false, error: true });
        setFilterBtn(false);
      });
  };

  return (
    <Zoom in={clicked} timeout={500} unmountOnExit>
      <Box
        sx={{
          backgroundColor: `white`,
          boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 16px 0 rgba(0, 0, 0, 0.05) `,
          borderRadius: `8px`,
        }}
      >
        <h4 className="px-3 pt-3 pb-2 mb-0 mb-0" style={{ fontWeight: 400 }}>
          Billed reports:
        </h4>
        {showMsg ? (
          <span className="ml-3" style={{ color: `red`, fontSize: `12px` }}>
            * Invalid date selection
          </span>
        ) : null}
        <Grid sx={{ p: 2 }} container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            {` `}
            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`From`),
              }}
              dateInputValue={fromDate}
              onChange={(e) => fromDateChangeHandler(e, setFilterBtn, setErrorMsg, setFromDate, toDate)}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box sx={{ p: 0.7 }}>-</Box>
          </Grid>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            {` `}

            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`To`),
              }}
              dateInputValue={moment(toDate).toDate()}
              maxDate={true}
              onChange={(e) => toDateChangeHandler(e, setFilterBtn, setErrorMsg, fromDate, setToDate)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={12} lg={3} xl={3}>
            <Filterbutton
              arg={{ payLoad: {}, setData: null, setLoading: null }}
              apiCall={() => apiCall(view)}
              filterBtn={filterBtn}
              loading={loading}
              ownApi={true}
            />
          </Grid>
        </Grid>

        <Box sx={{ m: 3, height: 300 }}>
          <Bar
            data={{
              labels: graphLabels,
              datasets: [
                {
                  label: `Billed reports`,
                  backgroundColor: `#00b0f0`,
                  data: billedReports.map((item) => item.id),
                  percentage: billedReports.map((item) => item.percentage),
                },
                {
                  label: `Ready to sign reports`,
                  backgroundColor: `#7030a0`,
                  data: readyToSignReports.map((item) => item.id),
                  percentage: readyToSignReports.map((item) => item.percentage),
                },
                {
                  label: `Ready to bill reports`,
                  backgroundColor: `#c00000`,
                  data: readyToBillReports.map((item) => item.id),
                  percentage: readyToBillReports.map((item) => item.percentage),
                },
              ],
            }}
            width={100}
            height={70}
            options={{
              tooltips: {
                displayColors: true,
                callbacks: {
                  label: function (tooltipItem, data) {
                    let label = ``;
                    let percentage = data.datasets[tooltipItem.datasetIndex].percentage;
                    label += percentage[tooltipItem.index] + `%, ` + tooltipItem.value;
                    return label;
                  },
                },
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: false,
                    },
                    scaleLabel: {
                      display: true,
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: `Total # Reports`,
                    },
                    type: `linear`,
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                position: `top`,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Zoom>
  );
};
