import React, { useEffect, useState } from 'react'
import { Skeleton, Zoom } from '@mui/material'
import { useSelector } from 'react-redux'
import { getRequest, PRACTICE_INFO_URL } from 'src/crud/crud.js'
import { Image } from '../Image/Image.js'

// ${user?.practiceLogoPath}

export const PracticeLogo = ({ logoProps }) => {
  const { user } = useSelector((state) => state?.user);
  const [logoPath, setLogoPath] = useState(``);

  useEffect(() => {
    getRequest(PRACTICE_INFO_URL)
      .then((res) => {
        let practiceLogoPath;
        if (res?.data?.practice) {
          practiceLogoPath = res.data.practice.practiceLogoPath;
        } else {
          practiceLogoPath = null; // or any default value
        }
        setLogoPath(practiceLogoPath)
      }).catch(() => {

      })
  }, [user?.practiceLogoPath])

  return (
    !logoPath ? <Skeleton {...logoProps} />
      : <Zoom in={!!logoPath} mountOnEnter unmountOnExit>
        <div>
          <Image imgProps={{
            ...logoProps, src: logoPath
          }} />
        </div>
      </Zoom>
  )
}
