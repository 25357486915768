import moment from 'moment';
import { postRequest, SYSTEM_ADMIN_ANALYTICS } from 'src/crud/crud';
import { store } from 'src/redux/store';
import { startDateAnalytics } from 'src/utils';


export const pickerPropsHandler = (sign) => {
  return {
    label: sign,
    openTo: `month`,
    views: [`year`, `month`],
    style: { width: `100%` },
    inputFormat: `MMM/YY`,
    InputLabelProps: {
      shrink: true,
    },
    size: `small`,
  }
}

export const fromDateChangeHandler = (selectedDateFromModal, setFilterBtn, setErrorMsg, setFromDate, toDate) => {
  setFilterBtn(false);
  setErrorMsg(false);
  if (selectedDateFromModal?.toISOString()?.length > 0) {
    if (
      moment(moment(selectedDateFromModal).startOf(`month`)).isSameOrBefore(moment(toDate)) &&
      moment(selectedDateFromModal).startOf(`month`).isSameOrAfter(moment(startDateAnalytics()).startOf(`month`))
    ) {
      setFilterBtn(false);
      setErrorMsg(false);
    } else {
      setFilterBtn(true);
      setErrorMsg(true);
    }
    setFromDate(selectedDateFromModal);
  } else {
    setFromDate(``);
  }
};
export const toDateChangeHandler = (selectedDate, setFilterBtn, setErrorMsg, fromDate, setToDate) => {
  setFilterBtn(false);
  setErrorMsg(false);
  let endOfSelectedMonth = moment(selectedDate).endOf(`month`)
  let startOfSelection = moment(startDateAnalytics()).startOf(`month`)
  let endOfCurrentMonth = moment(new Date()).endOf(`month`)
  let startOfFromeDate = moment(fromDate).startOf(`month`)
  if (selectedDate?.toISOString()?.length > 0) {
    if (
      endOfSelectedMonth.isAfter(startOfSelection) &&
      endOfSelectedMonth.isAfter(startOfFromeDate) &&
      endOfCurrentMonth.isSameOrAfter(endOfSelectedMonth)
    ) {
      setToDate(selectedDate);
      setFilterBtn(false);
      setErrorMsg(false);
    } else {
      setFilterBtn(true);
      setErrorMsg(true);
    }


  } else {
    setToDate(``);
  }
};

export const fetchAnalytics = (payLoad, setData, setLoading) => {
  const { startDate, endDate, tab } = payLoad;
  let user = store?.getState().user?.user;
  let _practiceGroupId = store?.getState().user?.user?._practiceGroupId;
  let groupQuery = `?startDate=${startDate}&endDate=${endDate}&_practiceGroupId=${_practiceGroupId}`;
  let simpleQuery = `?startDate=${startDate}&endDate=${endDate}`;
  let query = user?.isGroupAdmin ? groupQuery?.replace(/ /g, ``) : simpleQuery?.replace(/ /g, ``);
  setLoading({ loading: true, error: false });
  setData([]);
  postRequest(SYSTEM_ADMIN_ANALYTICS + query, { tab: tab })
    .then((res) => {
      setLoading({ loading: false, error: false });
      setData(res?.data?.data);
    })
    .catch((e) => {
      setData([]);
      setLoading({ loading: false, error: true });
    });
};