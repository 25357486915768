import { Popover } from '@mui/material';
import React from 'react';

export const MuiPopOver = ({ children, ...otherProps }) => {
  const { anchorOrigin = { vertical: 'bottom', horizontal: 'left' }, ...others } = { ...otherProps };
  return (
    <Popover {...others} anchorOrigin={anchorOrigin}>
      {children}
    </Popover>
  );
};
