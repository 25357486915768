import React, { useState, useRef } from 'react';
import css from './Compose.module.css';
import { SmartPhraseModal } from 'src/pages/common/components/smartphrasemodal/SmartPhraseModal';
import { MentionsInput, Mention } from 'react-mentions';
import defaultStyles from './defaultStyles';
import { AutoWriteCredentials } from 'src/pages/common/components/AutoWriteCredentials/AutoWriteCredentials';
import { onKeyUpCredentialsHandler, shallowValueSetter } from 'src/utils';
import { setAddComment } from 'src/redux/taskManager/taskManagerActions';
import { useDispatch } from 'react-redux';

const style = { width: `76%`, right: `18%`, bottom: `54px` };

const Compose = (props) => {
  const inputRefs = useRef(null);
  const [newMsg, setNewMsg] = useState(``);
  const [patients] = useState([]);
  const [, setPhraseId] = useState(null);
  const [, setPhraseScope] = useState(null);
  const [shallowValue, setShallowValue] = useState(``);
  const [openTemplatesGate, setOpenTemplatesGate] = useState(false);

  const messageStateUpdate = (e) => {
    let { value } = e.target;
    shallowValueSetter({
      flag: openTemplatesGate,
      setValue: () => setShallowValue(value),
    });
    if (value.length <= 500) {
      setNewMsg(value.indexOf(`\n`) === 0 ? `` : value);
    } else {
      setNewMsg(value.slice(0, 500));
    }
  };

  const sendMessageHandler = (key) => {
    if (
      (key.keyCode === 13 && !key.shiftKey && newMsg.trim().length > 0 && newMsg !== `#`) ||
      (key === 1300 && newMsg.trim().length > 0 && newMsg !== `#`)
    ) {
      props.savingCommentHandler(newMsg);
      dispatch(
        setAddComment({
          _id: props.selectedTaskId,
          data: {
            createdDate: new Date(),
            commentDetail: newMsg,
            name: `Hussnain Javed`,
            direction: `other`,
          },
        })
      );
      setNewMsg(``);
      setPhraseScope(null);
      setPhraseId(null);
    }
  };

  const dispatch = useDispatch();

  return (
    <SmartPhraseModal
      slectedPhraseId="taskMangPhrases"
      setNewNote={setNewMsg}
      newNote={newMsg}
      setPhraseId={setPhraseId}
      style={style}
      setPhraseScope={setPhraseScope}
    >
      <div className={props?.isFromView ? css.composeView : css.compose}>
        <div className={css.composeInputs}>
          <AutoWriteCredentials setShallowValue={setShallowValue} value={shallowValue} open={openTemplatesGate} setValue={setNewMsg} />
          <MentionsInput
            // maxLength={500}
            value={newMsg}
            onChange={messageStateUpdate}
            onKeyDown={(e) => sendMessageHandler(e)}
            onKeyUp={(e) => {
              onKeyUpCredentialsHandler({
                event: e,
                openTemplatesHandler: (value) => setOpenTemplatesGate(value),
              });
            }}
            placeholder="Type a Comment..."
            allowSuggestionsAboveCursor={true}
            inputRef={inputRefs}
            //   disabled={!props.selectedUser ? true : false}
            style={defaultStyles}
            className={css.composeInput}
          >
            <Mention type="user" trigger="@" data={patients} style={{ backgroundColor: `#cee4e5` }} />
          </MentionsInput>
        </div>
        <div id={css.composeInputBtn}>
          <i
            className="ion-md-send"
            id={!props?.selectedUser?._id ? css.toolbarButtonDisabled : css.toolbarButton}
            onClick={() => newMsg?.length && sendMessageHandler(1300)}
          />
        </div>
      </div>
    </SmartPhraseModal>
  );
};

export default React.memo(Compose);
