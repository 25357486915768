import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

const TabWithLowerCaseLabel = (props) => (
  <Tab {...props} sx={{ textTransform: 'capitalize' }} />
);

export const TabsComponent = ({ value, handleChange }) => {
  return (
    <Tabs value={value} onChange={handleChange} aria-label="tabs">
      <TabWithLowerCaseLabel label="Assign Secondary Users" {...a11yProps(0)} />
      <TabWithLowerCaseLabel label="Primary Reassign Patient" {...a11yProps(1)} />
      <TabWithLowerCaseLabel label="Remove Secondary User" {...a11yProps(2)} />
    </Tabs>
  );
}

TabsComponent.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
