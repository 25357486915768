import React from 'react'
import { Box, Stack } from '@mui/material'
import { Footer } from 'src/pages/Footer/Footer'


export const FooterWrapper = ({ children, showOnlytLiscense }) => {
    return (
        <Box sx={{
            height: `100vh`, backgroundColor: `rgba(246, 252, 253, 1)`,
            width: `100%`
        }}>
            <Stack sx={{ height: `inherit` }} direction='column' justifyContent={`space-between`}>
                {children}
                <Box>
                    <Footer showOnlytLiscense={showOnlytLiscense} />
                </Box>
            </Stack>
        </Box>
    )
}
