import React from 'react';
import { Link } from 'react-router-dom';
import css from './statsWidget.module.css';

export const StatsWidget = ({ totalPatients, onlinePatients, offlinePatients }) => {
  return (
    <div className={css.gradientBorder}>
      <div className={css.innerContent}>
        <div style={{ textAlign: `center`, fontWeight: 600 }}>TOTAL</div>
        <div style={{ textAlign: `center` }}>
          <h2 style={{ fontSize: 60, color: `#1699C5`, margin: 0 }}>
            <Link to={`/patient?show=all`} style={{ fontSize: 60, color: `#1699C5`, margin: 0 }}>
              {totalPatients || 0}
              {` `}
            </Link>
          </h2>
        </div>
        <div className="d-flex justify-content-center align-items-center w-100" style={{ textAlign: `center` }}>
          <div style={{ borderRight: `1px solid grey`, width: `50%` }}>
            <h4 style={{ fontSize: `x-small` }}>
              <Link to={`/patient?status=online`}>{`Online: ${onlinePatients}`}</Link>
            </h4>
          </div>
          <div style={{ width: `50%` }}>
            <h4 style={{ fontSize: `x-small` }}>
              <Link to={`/patient?status=offline`}>{`Offline: ${offlinePatients}`}</Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};