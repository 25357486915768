import { useCallback, useEffect, useRef, useState } from "react"
import { assignUniqueId } from "src/utils"

export const useGenerateIds = (obj) => {
  const [goodToReturn, setGoodToReturn] = useState(false)
  let tempObj = useRef({ ...obj })
  const keysHandler = useCallback(() => {
    if (tempObj.current) {
      for (const key in tempObj.current) {
        tempObj.current = { ...tempObj.current, [key]: assignUniqueId() }
      }
      setGoodToReturn((p) => !p)
    }

  }, [tempObj])

  useEffect(() => {
    keysHandler()
  }, [keysHandler])

  return goodToReturn ? tempObj.current : false
}
