import React, { useState } from 'react';
import { Box, Container, Grow } from '@mui/material';
import { Logo } from 'src/pages/common/components/Logo/Logo';
import { SnackBar } from 'src/pages/common/components/Notifications/SnackBar';
import { PuffSpinner } from 'src/pages/common/components/spinner/puff/puff';
import { MapUserRoles } from './MapUserRoles';
import { loadingSetter } from 'src/utils';



export const UserRoles = ({ userData }) => {
  const [loadingState, setLoadingState] = useState({
    loading: false,
    error: false,
  });
  const [open, setOpen] = useState(true);
  const { systemRoles, uniqueString, _id } = userData;
  const loadingHandler = (key, value) => {
    loadingSetter({
      setLoading: setLoadingState,
      key: key,
      value: value
    })
  };
  const { loading } = loadingState;
  return (
    <Box sx={{ backgroundColor: `rgba(246, 252, 253, 1)` }}>
      <Box sx={{ display: `flex`, justifyContent: `center`, width: `100%`, pt: 5 }}>
        <Logo isFromRoles={true} />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grow in={true} style={{ transformOrigin: `0 0 0` }} direction="right" mountOnEnter unmountOnExit>
          <Box sx={{ ...(systemRoles?.length < 12 ? { height: `100vh` } : {}) }}>
            {loading && <PuffSpinner />}
            <Box sx={{ display: `flex`, justifyContent: `center`, width: `100%`, pt: 5 }}>
            </Box>
            <Box sx={{ width: `100%`, mt: `40px` }}>
              <Container maxWidth="xl">
                <SnackBar
                  open={open}
                  isObj={false}
                  setOpen={setOpen}
                  alertProps={{
                    variant: `filled`,
                    severity: `success`,
                  }}
                  alertMessage="You have Successfully passed the correct info please select a role to login with !"
                  alertDuration={3000}
                />
                <MapUserRoles systemRoles={systemRoles} uniqueString={uniqueString}
                  _id={_id} loadingHandler={loadingHandler} />
              </Container>
            </Box>
          </Box>
        </Grow>
      </Box>
    </Box>
  )


};
