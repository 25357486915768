'use strict';

import $ from 'jquery';
import { getUserFriendlyError } from './userfriendlyerror';

/**
 * Select your Room name and identity (screen name).
 * @param $($modal) - modal for selecting your Room name and identity
 * @param error - Error from the previous Room session, if any
 */

export const selectRoom = ($modal, error) => {
  const $alert = $(`div.alert`, $modal);
  const $changeMedia = $(`button.btn-secondary`, $modal);
  const $identity = $(`#screen-name`, $modal);
  const $join = $(`button.btn-primary`, $modal);
  const $roomName = $(`#room-name`, $modal);

  if (error) {
    $alert.html(`<h5>${error.name}${error.message ? `: ${error.message}` : ``}</h5>${getUserFriendlyError(error)}`);
    $alert.css(`display`, ``);
  } else {
    $alert.css(`display`, `none`);
  }

  return new Promise((resolve) => {
    $($modal).on(`shown.bs.modal`, function onShow() {
      $($modal).off(`shown.bs.modal`, onShow);
      $changeMedia.click(function onChangeMedia() {
        $changeMedia.off(`click`, onChangeMedia);
        $($modal).modal(`hide`);
        resolve(null);
      });

      $join.click(function onJoin() {
        $join.off(`click`, onJoin);
        $($modal).modal(`hide`);
      });
    });

    $($modal).on(`hidden.bs.modal`, function onHide() {
      $($modal).off(`hidden.bs.modal`, onHide);
      const identity = $identity.val();
      const roomName = $roomName.val();
      resolve({ identity, roomName });
    });

    $($modal).modal({
      backdrop: `static`,
      focus: true,
      keyboard: false,
      show: true,
    });
  });
};
