import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SmartPhraseModal } from 'src/pages/common/components/smartphrasemodal/SmartPhraseModal';
import {
  CREATE_ADDENDUM_REPORT, CREATE_ADDENDUM_REPORT_RESPIRONICS, PATIENT_CREATE_REPORT_URL, PATIENT_CREATE_REPORT_URL_RESPIRONICS,
  SEND_REPORT_TO_MD, SEND_ADDENDUM_REPORT, REPORT_ADD_NEW_NOTE, postRequest,
  CREATE_ADDENDUM_REPORT_REACT_HEALTH, PATIENT_CREATE_REPORT_URL_REACT_HEALTH,
} from '../../../../../../../crud/crud';
import { momentWrapper } from 'src/momentWrapper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import css from './logs.module.css';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useHistory, useLocation } from 'react-router-dom';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { handleTimer, setAllNotes, saveTimerUser, handleUpdatePatientData, handleReportLoadingFlag } from 'src/redux/patient/patientActions';
import {
  capitalizeFirstLetter,
  wrappWithTimerCheck5s,
  onKeyUpCredentialsHandler,
  redirectToDashBoard,
  returnNull,
  doIfUserIsFromCareTeam,
} from 'src/utils';
import { AutoWriteCredentials } from 'src/pages/common/components/AutoWriteCredentials/AutoWriteCredentials';

export const PatientLogs = ({ toggle, handleChartingToggle }) => {
  const dispatchRedux = useDispatch();
  const user = useSelector((state) => state.user.user);
  const timer = useSelector((state) => state.patient.timer);
  const notes = useSelector((state) => state.patient.notes);
  const reportLoadingFlag = useSelector((state) => state?.patient?.reportLoadingFlag);
  const patientDataFromReport = useSelector((state) => state.patient.patientReport?.patient);
  const selectedPatientReport = useSelector((state) => state?.patient?.patientReport?.selectedPatientReport?.data);
  const { startTime } = useSelector((state) => state.patient.timer);
  const [loading, setLoading] = useState(false);
  // const [fileURL, setFileURL] = useState(``);
  // const [reportCreatedFlag, setReportCreatedFlag] = useState(false);
  const [newNote, setNewNote] = useState(``);
  const [shallowValue, setShallowValue] = useState(``);
  const [openTemplatesGate, setopenTemplatesGate] = useState(false);
  const location = useLocation();
  const userData = useSelector((state) => state.patient?.patientData);
  const patientsList = useSelector((state) => state.patient?.patientsList);
  const selectedPatientIdFromReport = useSelector((state) => state.patient.patientReport?.patient?._id);
  const patientManufacturer = useSelector((state) => state?.patient?.patientReport?.patient?.device?.manufacturer);
  const [patientTimeInSeconds, setPatientTimeInSeconds] = useState(0);
  const [phraseId, setPhraseId] = useState(null);
  const [phraseScope, setPhraseScope] = useState(null);
  const history = useHistory();
  //  for Add smartPhrase
  const targetPatient = patientsList?.data?.patients?.filter((patient) => {
    return patient?._id === selectedPatientIdFromReport;
  })[0];

  const logBox = useRef(null);
  const msgLog = useRef(null)

  useEffect(() => {
    if (logBox.current) logBox.current.scrollTop = logBox.current.scrollHeight;
  }, [notes?.length, toggle]);

  const onlySpaces = (str) => {
    return /^\s*$/.test(str);
  };

  const addNewNoteHandler = (e) => {
    if (e.keyCode === 8 && newNote.length < 1) {
      e.target.style.height = 0;
    }
    if (e.keyCode === 46 && newNote.length < 1) {
      e.target.style.height = 0;
    }
    if (e.target.style.height.split(`p`)[0] < 140) {
      e.target.style.height = 0;
      e.target.style.height = e.target.scrollHeight + `px`;
    }
    if (e.keyCode === 13) {
      if (!e.shiftKey && selectedPatientIdFromReport) {
        if (!onlySpaces(newNote) && newNote !== `#`) {
          e.target.style.height = 0;
          let note = {
            note: newNote,
            added_by_provider: user?.isProvider,
            _created_by: {
              firstName: `${user?.firstName}`,
              lastName: `${user?.lastName}`,
              isClinicalStaff: user?.isClinicalStaff,
              isProvider: user?.isProvider,
              providerType: user?.providerType,
            },
            date: momentWrapper().format(`ddd MMM DD YYYY HH:mm:ss z`),
          };
          setNewNote(``);
          const copyNotesArray = notes;
          copyNotesArray.push(note);
          dispatchRedux(setAllNotes(copyNotesArray));
          let url = REPORT_ADD_NEW_NOTE + selectedPatientIdFromReport + `?reportId=${selectedPatientReport?._id ? selectedPatientReport?._id : null}`;
          postRequest(url, { notes: newNote, phraseId: phraseId, phraseScope: phraseScope })
            .then(() => {
              setNewNote(``);
              setPhraseId(null);
              setPhraseScope(null);
            })
            .catch(() => {
              setPhraseId(null);
              setPhraseScope(null);
            });
        } else {
          e.target.style.height = 0;
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (targetPatient) {
      setPatientTimeInSeconds(
        patientsList?.data?.patients?.filter((patient) => {
          return patient?._id === selectedPatientIdFromReport;
        })[0]?.timeMonitored
      );
    }
  }, [targetPatient?._id, targetPatient?.timeMonitored]);

  // Make Addendum
  const makeAddendumReport = async () => {
    if (user?.defaultSignature?.base64String) {
      setLoading(true);
      dispatchRedux(handleReportLoadingFlag(true));
      if (timer?.toggleButton) {
        dispatchRedux(handleTimer({ status: false }));
        const endTimeValue = momentWrapper().toISOString();
        const timeDiff = momentWrapper(endTimeValue).diff(startTime, `seconds`);
        if (timeDiff > 5) {
          await dispatchRedux(
            saveTimerUser({
              videoOrAudioOn: null,
              updateVideoorAudioLogStatus: null,
              callSuccess: null,
              statuss: `View graphs and reports`,
              sources: `View Report`,
            })
          );
        }
      }

      let temp = null;
      if (patientManufacturer === `resmed`) {
        let url =
          CREATE_ADDENDUM_REPORT +
          targetPatient?._id +
          `/?reportId=${location.reportId ? location.reportId : selectedPatientReport?._id ? selectedPatientReport?._id : null}`;
        postRequest(
          url,
          {
            signature: user.defaultSignature.base64String,
          },
          { responseType: `arraybuffer` }
        )
          .then((res) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Addendum successfully`);
              // STEP - 1
              // OPEN MODAL, TOO VIEW REPORT
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));

              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
            // errorToast(err?.response?.status === 404 && `Session data not available`);
          });
      } else if (patientManufacturer === `respironics`) {
        let url =
          CREATE_ADDENDUM_REPORT_RESPIRONICS +
          targetPatient?._id +
          `/?reportId=${location.reportId ? location.reportId : selectedPatientReport?._id ? selectedPatientReport?._id : null}`;
        postRequest(
          url,
          {
            signature: user.defaultSignature.base64String,
          },
          { responseType: `arraybuffer` }
        )
          .then((res) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Addendum successfully`);
              // STEP - 1
              // OPEN MODAL, TOO VIEW REPORT
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));

              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
            // errorToast(err?.response?.status === 404 && `Session data not available`);
          });
      }
      if (patientManufacturer === `reactHealth`) {
        let url =
          CREATE_ADDENDUM_REPORT_REACT_HEALTH +
          targetPatient?._id +
          `/?reportId=${location?.reportId ? location?.reportId : selectedPatientReport?._id}`;
        postRequest(
          url,
          {
            signature: user.defaultSignature.base64String,
          },
          { responseType: `arraybuffer` }
        )
          .then(() => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Addendum successfully`);
              // STEP - 1
              // OPEN MODAL, TOO VIEW REPORT
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));

              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
            errorToast(err?.response?.status === 404 && `Session data not available`);
          });
      }
    } else {
      errorToast(`Please create your Signature before signing the report`);
    }
  };

  // Sign as MD
  const signReport = async () => {
    let temp = null;
    if (user?.defaultSignature?.base64String) {
      setLoading(true);
      dispatchRedux(handleReportLoadingFlag(true));
      if (timer?.toggleButton) {
        dispatchRedux(handleTimer({ status: false }));
        const endTimeValue = momentWrapper().toISOString();
        const timeDiff = momentWrapper(endTimeValue).diff(startTime, `seconds`);
        if (timeDiff > 5) {
          await dispatchRedux(
            saveTimerUser({
              videoOrAudioOn: null,
              updateVideoorAudioLogStatus: null,
              callSuccess: null,
              statuss: `View graphs and reports`,
              sources: `View Report`,
            })
          );
        }
      }
      dispatchRedux(handleReportLoadingFlag(true));
      setLoading(true);
      if (patientManufacturer === `resmed`) {
        let url =
          PATIENT_CREATE_REPORT_URL +
          targetPatient?._id +
          `/?reportId=${location.reportId ? location.reportId : selectedPatientReport?._id ? selectedPatientReport?._id : null}`;
        postRequest(
          url,
          {
            signature: user.defaultSignature.base64String,
            startDate: momentWrapper().startOf(`month`).toISOString(),
            endDate: momentWrapper().toISOString(),
          },
          { responseType: `arraybuffer` }
        )
          .then((res) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Created successfully`);
              // STEP - 1
              // OPEN MODAL, TOO VIEW REPORT
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));

              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
            // errorToast(err?.response?.status === 404 && `Session data not available`);
          });
      } else if (patientManufacturer === `respironics`) {
        let url =
          PATIENT_CREATE_REPORT_URL_RESPIRONICS +
          targetPatient?._id +
          `/?reportId=${location.reportId ? location.reportId : selectedPatientReport?._id ? selectedPatientReport?._id : null}`;
        postRequest(
          url,
          {
            signature: user.defaultSignature.base64String,
            startDate: momentWrapper().startOf(`month`).toISOString(),
            endDate: momentWrapper().toISOString(),
          },
          { responseType: `arraybuffer` }
        )
          .then((res) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Created successfully`);
              // STEP - 1
              // OPEN MODAL, TOO VIEW REPORT
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));
              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
            errorToast(err?.response?.status === 404 && `Session data not available`);
          });
      }
      if (patientManufacturer === `reactHealth`) {
        let url =
          PATIENT_CREATE_REPORT_URL_REACT_HEALTH +
          targetPatient?._id +
          `/?reportId=${location.reportId ? location.reportId : selectedPatientReport?._id ? selectedPatientReport?._id : null}`;
        postRequest(
          url,
          {
            signature: user.defaultSignature.base64String,
            startDate: momentWrapper().startOf(`month`).toISOString(),
            endDate: momentWrapper().toISOString(),
          },
          { responseType: `arraybuffer` }
        )
          .then((response) => {
            redirectToDashBoard(history, () => {
              dispatchRedux(handleReportLoadingFlag(false));
              setLoading(false);
              dispatchRedux(handleUpdatePatientData(patientDataFromReport));
              successToast(`Report Created successfully`);
              // STEP - 1
              // OPEN MODAL, TOO VIEW REPORT
              // temp = new Blob([res.data], { type: `application/pdf` });
              // setFileURL(URL.createObjectURL(temp));
              // setReportCreatedFlag(true);
            });
          })
          .catch((err) => {
            dispatchRedux(handleReportLoadingFlag(false));
            setLoading(false);
          });
      }
    } else {
      errorToast(`Please create your Signature before signing the report`);
    }
  };

  const sendReportToMedicalDoctor = () => {
    setLoading(true);
    postRequest(SEND_REPORT_TO_MD + targetPatient?._id, {
      reportMonth: Number(momentWrapper().format(`YYYYMM`)),
    })
      .then((response) => {
        setLoading(false);
        dispatchRedux(handleUpdatePatientData(patientDataFromReport));
        successToast(response?.data?.message);
      })
      .catch((err) => {
        // errorToast(err.response.data.message ? err.response.data.message : err.response.data.error);
        setLoading(false);
      });
  };

  const sendToMd = () => wrappWithTimerCheck5s(sendReportToMedicalDoctor, true);

  // CLINICAL STAFF - SEND ADDENDUM HANDLER
  const sendAddendumHandler = () => {
    setLoading(true);
    postRequest(SEND_ADDENDUM_REPORT + selectedPatientReport?._id)
      .then((res) => {
        setLoading(false);
        dispatchRedux(handleUpdatePatientData(patientDataFromReport));
        successToast(res?.data?.message);
      })
      .catch((err) => {
        setLoading(false);
        // errorToast(err.response.data.message ? err.response.data.message : err.response.data.error);
      });
  };

  const sendAddendum = () => wrappWithTimerCheck5s(sendAddendumHandler, true);

  const style = { top: `98px` };

  let isValidUser = doIfUserIsFromCareTeam();

  return (
    <div id={css.log}>
      <div
        style={{
          display: `flex`,
          borderTop: `1px solid #c1c1c1`,
          borderBottom: `1px solid #c1c1c1`,
          backgroundColor: `#1699c5`,
          height: `30px`,
          color: `white`,
          width: `100%`,
        }}
      >
        {toggle === true ? <ExpandLessIcon onClick={handleChartingToggle} /> : <ExpandMoreIcon onClick={handleChartingToggle} />}
        Charting
      </div>
      {toggle && (
        <div id={css.msgActualBody}>
          <div
            id="customBody"
            className="p-2"
            style={{
              borderBottom: `1px solid #c1c1c1`,
              backgroundColor: `white`,
              overflow: `auto`,
            }}
            ref={logBox}
          >
            <AutoWriteCredentials setShallowValue={setShallowValue} value={shallowValue} open={openTemplatesGate} setValue={setNewNote} />
            {notes?.map((note, index) => {
              return (
                <>
                  <p
                    key={index}
                    className={`${css.cardMainMDPending} ${css.cardBody}`}
                    style={{
                      backgroundColor: note?.added_by_provider ? `rgba(223, 212, 119, 0.5)` : `rgba(119, 223, 218, 0.5)`,
                    }}
                  >
                    {note.note}
                    <p key={notes.length + 1} className={css.cardTimeStamp}>
                      {momentWrapper(note.dateCreated).format(`MM/DD hh:mm A`)}
                      <br />
                      {capitalizeFirstLetter(note?._created_by?.firstName) +
                        ` ` +
                        capitalizeFirstLetter(note?._created_by?.lastName) +
                        returnNull({
                          key: note?._created_by?.providerType,
                          successRetrun: `, ${note?._created_by?.providerType}`,
                          faliure: ``,
                        })}
                    </p>
                  </p>
                </>
              );
            })}
          </div>

          <div>
            {patientTimeInSeconds / 60 > 20 &&
              user?.isProvider &&
              userData?.data?.interactiveCommunication &&
              (selectedPatientReport
                ? selectedPatientReport?.reportStatus === `READY_TO_SIGN` && selectedPatientReport?.billingStatus === `NOT_READY_TO_BILL`
                : true) ? (
              <button
                type="button"
                disabled={!isValidUser || loading || reportLoadingFlag ? true : false}
                onClick={signReport}
                className="btn btn-outline-info btn-sm"
                style={{ fontSize: `9px`, height: `24px` }}
              >
                Sign as MD
              </button>
            ) : null}

            {user?.isProvider &&
              (selectedPatientReport?.reportStatus === `SIGNED` ||
                selectedPatientReport?.reportStatus === `READY_TO_ADDENDUM` ||
                selectedPatientReport?.reportStatus == `ADDENDUMED`) && (
                <button
                  type="button"
                  disabled={!isValidUser || loading || reportLoadingFlag ? true : false}
                  onClick={makeAddendumReport}
                  className="btn btn-outline-info btn-sm"
                  style={{ fontSize: `9px`, height: `24px` }}
                >
                  Make Addendum
                </button>
              )}

            {user?.isClinicalStaff && patientTimeInSeconds / 60 > 20 && userData?.data?.interactiveCommunication && !selectedPatientReport && (
              <button
                type="button"
                disabled={loading ? true : false}
                onClick={sendToMd}
                className="btn btn-outline-info btn-sm"
                style={{ fontSize: `9px`, height: `24px` }}
              >
                Send to MD
              </button>
            )}

            {user?.isClinicalStaff &&
              (selectedPatientReport?.reportStatus === `SIGNED` || selectedPatientReport?.reportStatus === `ADDENDUMED`) &&
              (selectedPatientReport?.billingStatus === `READY_TO_BILL` || selectedPatientReport?.billingStatus === `BILLED`) && (
                <button
                  type="button"
                  disabled={loading ? true : false}
                  onClick={sendAddendum}
                  className="btn btn-outline-info btn-sm"
                  style={{ fontSize: `9px`, height: `24px` }}
                >
                  Send Addendum
                </button>
              )}
          </div>
          <div style={{ position: `relative` }}>
            <SmartPhraseModal
              slectedPhraseId="chartingPhrases"
              setNewNote={setNewNote}
              newNote={newNote}
              setPhraseId={setPhraseId}
              style={style}
              setPhraseScope={setPhraseScope}
            >
              <textarea
                ref={msgLog}
                disabled={selectedPatientIdFromReport ? false : true}
                value={newNote}
                className="p-1"
                style={{
                  bottom: 0,
                  dispaly: `table`,
                  minHeight: 100,
                  maxHeight: 100,
                  height: `unset`,
                  resize: `none`,
                  boxShadow: `0 0 3px 0px #5e575794`,
                  borderRadius: `3px`,
                  // position: `absolute`,
                }}
                id={css.textBox}
                placeholder="Add note ..."
                onChange={(e) => {
                  if (openTemplatesGate) {
                    setShallowValue(e.target.value);
                  }
                  setNewNote(e.target.value);
                }}
                onKeyDown={addNewNoteHandler}
                onKeyUp={(e) =>
                  onKeyUpCredentialsHandler({
                    event: e,
                    openTemplatesHandler: (value) => setopenTemplatesGate(value),
                  })
                }
              />
            </SmartPhraseModal>
          </div>
        </div >
      )}
      {loading && <PuffSpinner />}
      {/* <ReportPdfViewer show={reportCreatedFlag} setShow={setReportCreatedFlag} fileURL={fileURL} /> */}
    </div >
  );
};
