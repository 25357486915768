import { Grid, Box, Container, Grow, CircularProgress, createMuiTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { ListAllPractices } from 'src/pages/home/modules/practices/components/listAllPractice/listAllPractice';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getRequest, TOTAL_STATS_URL_GRP_ADMIN } from 'src/crud/crud';
import ErrorIcon from '@mui/icons-material/Error';
import { capitalizeFirstLetter } from 'src/utils';

const BlocksComponent = ({ txt, value, loading, timeout }) => {
  const color = `#009cc6`
  const renderIcon = {
    practices: <LibraryBooksIcon sx={{ color: loading?.error ? `white` : color }} />,
    providers: <LocalHospitalIcon sx={{ color: loading?.error ? `white` : color }} />,
    patients: <AccessibilityIcon sx={{ color: loading?.error ? `white` : color }} />,
    openTickets: <ConfirmationNumberIcon sx={{ color: loading?.error ? `white` : color }} />,
  }
  const spreadTimeout = () => {
    if (!loading?.loading) {
      return { timeout: timeout }
    }
    return {}
  }

  return (
    <>
      {loading.loading ? (
        <Grid sx={{ display: `flex`, justifyContent: `center` }} item xs={6} sm={6} md={3} lg={3} xl={3}>
          <Box
            sx={{
              width: `200px`,
              textAlign: `center`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              height: `140px`,
              backgroundColor: `white`,
              borderRadius: `15px`,
              boxShadow: `0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)`,
            }}
          >
            <Box sx={{ textAlign: `center` }}>
              {renderIcon[value]}
            </Box>
            <h6>
              {value === `practices` && `practices`}
              {value === `providers` && `providers`}
              {value === `patients` && `patients`}
              {value === `openTickets` && `openTickets`}
            </h6>
            {loading.loading && (
              <Box sx={{ textAlign: `center`, mt: 1 }}>
                <CircularProgress size={30} thickness={6} />
              </Box>
            )}
          </Box>
        </Grid>
      ) : (
        <Grow in={!loading.loading} {...spreadTimeout()} >
          <Grid sx={{ display: `flex`, justifyContent: `center` }} item xs={6} sm={6} md={3} lg={3} xl={3}>
            <Box
              sx={{
                width: `200px`,
                textAlign: `center`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
                height: `140px`,
                backgroundColor: loading?.error ? `red` : `white`,
                borderRadius: `15px`,
                boxShadow: `0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)`,
              }}
            >
              <Box sx={{ textAlign: `center` }}>
                {renderIcon[value]}
              </Box>
              <h6 style={{ color: loading?.error ? `white` : `#009cc6` }}>
                {value === `practices` && `Practices`}
                {value === `providers` && `Providers`}
                {value === `patients` && `Patients`}
                {value === `openTickets` && `OpenTickets`}
              </h6>
              {loading.error ? (
                <Box sx={{ display: `flex`, justifyContent: `center`, mt: 1 }}>
                  <ErrorIcon sx={{ color: loading?.error ? `white` : `red` }} fontSize="small" />
                </Box>
              ) : (
                <h3 style={{ fontSize: `xx-large` }}>{txt}</h3>
              )}
            </Box>
          </Grid>
        </Grow>
      )}
    </>
  );
}

export const GroupAdminDashboard = () => {
  const { auth, user } = useSelector((state) => state.user);
  const refreshGroups = useSelector((state) => state?.practiceGroup?.isRegistered);

  const _practiceGroupId = user?.user?._practiceGroupId;
  const [stats, setStats] = useState([]);
  const [stateLoading, setstateLoading] = useState({ loading: false, error: false });

  useEffect(() => {
    setstateLoading({ loading: true, error: false });
    if (auth || refreshGroups) {
      getRequest(TOTAL_STATS_URL_GRP_ADMIN).then((res) => {
        setstateLoading({ loading: false, error: false });
        setStats(res.data);
      }).catch(() => setstateLoading({ loading: false, error: true }))
    }
  }, [auth, _practiceGroupId, refreshGroups]);
  const breakpointValues = {
    xxs: 0,
    xs: 400,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1400,
  };
  const theme = createMuiTheme({ breakpoints: { values: breakpointValues } });
  const isExtrasmall = useMediaQuery(theme.breakpoints.down(`md`));
  return (
    <Box sx={{ mt: 5 }}>
      <Box sx={{ ml: 5, mb: 5 }}>
        {` `}
        <h6>
          Welcome,
          <div style={{ display: `inline-block`, marginLeft: `4px` }}>
            <h5> {capitalizeFirstLetter(user?.firstName.concat(` `).concat(user?.lastName))}</h5>
          </div>
          {` `}
        </h6>
      </Box>
      <Container>
        <Grid sx={{ mb: 5, mt: 5 }} container columnSpacing={isExtrasmall ? 0 : -0.5} rowSpacing={5}>
          <BlocksComponent txt={stats?.practicesCount} value="practices" loading={stateLoading} timeout={300} />
          <BlocksComponent txt={stats?.providersCount} value="providers" loading={stateLoading} timeout={600} />
          <BlocksComponent txt={stats?.patientsCount} value="patients" loading={stateLoading} timeout={800} />
          <BlocksComponent txt={stats?.openTicketsCount} value="openTickets" loading={stateLoading} timeout={1000} />
        </Grid>
      </Container>
      <Box sx={{ mt: `30px`, pr: 3, pl: 3 }}>
        <ListAllPractices />
      </Box>
    </Box>
  );
}