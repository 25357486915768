import { Box } from '@mui/material';
import React from 'react';
import { ListAllPractices } from 'src/pages/home/modules/practices/components/listAllPractice/listAllPractice';
import { BlockStatsWidget } from '../systemAdminStatsWidget/BlockStatsWidget';

export const ListAllPracticeWithCount = () => {
  return (
    <Box sx={{ p: 5, overflowX: `hidden` }}>
      <BlockStatsWidget isGroupView={true} />
      <ListAllPractices />
    </Box>
  );
}
