import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AnimatedSpinner } from 'src/pages/common/components/AnimatedSpinner/AnimatedSpinner';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, IconButton, Stack } from '@mui/material';
import css from './previewMmms.module.css'
import { momentWrapper } from 'src/momentWrapper';
import { GET_S3_FILE, postRequest, REMOVE_FILE_MESSENGER } from 'src/crud/crud';
import {
    appValidExt, audValidExt, convertToBase64, imgValidExt,
    printInBrowser, returnIfArr, vidValidExt
} from 'src/utils';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import Spinner from 'react-spinners/PuffLoader';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { useSelector } from 'react-redux';



const FileSuspenser = ({ status, children, isMine }) => {
    let content;

    if (status === `error`) {
        content = <ErrorOutlineIcon sx={{ color: `white` }} />;
    } else if (status === `gettingFile`) {
        content = <AnimatedSpinner hasBlueColor={!isMine} />;
    } else {
        content = children;
    }

    return (
        <>
            {content}
        </>
    );
}

const RenderFileName = ({ mms }) => {
    let textColor;
    let fileName;

    if (mms) {
        const { fileName, isMine, status } = mms;
        if (fileName) {
            if (status === `error` || status === `realTimeSentError`) {
                textColor = `white`;
            } else {
                textColor = isMine ? `white` : `black`;
            }
        } else {
            textColor = null;
        }
    } else {
        textColor = null;
    }

    if (fileName) {
        return (
            <Box sx={{ overflowWrap: `break-word`, fontSize: `small`, p: 1, color: textColor }}>
                {fileName}
            </Box>
        );
    } else {
        return null;
    }
}

const RenderDeleteBtn = ({ mms }) => {

    const individualUser = useSelector((state) => state.messenger.individualUser);
    const selectedUserRedux = useSelector((state) => state.messenger.selectedUser);

    const [isDeleting, setIsDeleting] = useState(false)
    const { isMine, status, blobId, setMessages, _id: msgId, _uniqueId } = mms || false
    if (isMine) {
        if (status === `error` || status === `realTimeSentError` || status === `realTimeSent` || status === `received`) {
            return isDeleting ?
                <Box sx={{
                    display: `flex`,
                    alignItems: `center`
                }}>
                    <Spinner
                        color={`white`}
                        size={25}
                        loading={isDeleting}
                    /></Box> :
                <ToolTipProvider toolTipProps={{
                    arrow: true,
                    placement: `top`,
                    title: `Delete This File`
                }} element={<Box sx={{ mt: 1 }}>
                    <IconButton onClick={() => {
                        if (status === `realTimeSentError`) {
                            setMessages((p) => {
                                return [...returnIfArr(p).filter((msg) => msg?._uniqueId !== _uniqueId)];
                            });
                        } else {
                            setIsDeleting((p) => !p)
                            postRequest(REMOVE_FILE_MESSENGER, {
                                blobId: blobId,
                                senderId: individualUser[selectedUserRedux].senderId,
                                practiceId: individualUser[selectedUserRedux].practiceId,
                                receiverId: individualUser[selectedUserRedux].receiverId
                            }).then(() => {
                                setMessages((p) => {
                                    return p ? [...p.filter((msg) => msg && msg._id !== msgId)] : [];
                                });
                                setIsDeleting((p) => !p)
                            }).catch(() => setIsDeleting((p) => !p))
                        }
                    }}>
                        <DeleteIcon sx={{ color: `white` }} />
                    </IconButton></Box>} />
        } else {
            return null
        }
    } else {
        return null
    }
}

const TimeStampAndDownload = ({ mms, date }) => {
    const { status, isMine, message, fileName } = mms || false
    return (<>
        <RenderFileName mms={mms} />
        <Stack sx={{ padding: `0x 10px 0px 10px` }} direction='row' justifyContent={`space-between`}>
            <Box sx={{ alignSelf: `center`, textAlign: `end`, width: `100%` }}>
                <p {...(status === `error` ? { className: css.errorTimeStamp } : {})} id={isMine ? css.inBoundCardTimeStamp : css.outBoundCardTimeStamp}>{date}</p>
            </Box>
            {status === `realTimeSent` || status === `received` ?
                <ToolTipProvider toolTipProps={{
                    arrow: true,
                    placement: `top`,
                    title: `Download This File`
                }} element={<Box><IconButton>
                    <a download={fileName || `download`} href={`${message}`} rel="noopener noreferrer" target="_blank" > <IconButton><DownloadForOfflineIcon sx={{ color: isMine ? `white` : `black` }} /></IconButton> </a>
                </IconButton> </Box>} />

                : null}
            <RenderDeleteBtn mms={mms} />
        </Stack >
    </>
    )
}


const RenderImg = ({ mms }) => {
    const [openImagePreview, setOpenImagePreview] = useState(false)
    const { status, date, isMine } = mms || false
    return (
        <div className={css[status]} id={isMine ? css.inboubdMmsImageContainer : css.outBoubdMmsImageContainer}>
            <div id={isMine ? css.inboubdMmsImage : css.outBoubdMmsImage}>
                <FileSuspenser status={status} isMine={isMine}>
                    <img onClick={() => setOpenImagePreview((p) => !p)} id={css.patientImage} src={mms?.message} alt="chatMessage" />
                </FileSuspenser>
            </div>
            <TimeStampAndDownload mms={{ ...mms }} date={date} />
            <CustomizedDialogs noTitle={true} open={openImagePreview} setOpen={() => setOpenImagePreview((p) => !p)} size="md" fullWidth={true}>
                <div id={css.fullModalImageContainer}>
                    <img src={mms?.message} id={css.fullModalImage} alt="chatMessage" />
                </div>
            </CustomizedDialogs>
        </div >
    )
}

const RenderAud = ({ mms }) => {
    const { mimeType, date, isMine, status } = mms || false
    return (

        <div className={css[status]} id={isMine ? css.inboubdMmsAud : css.outBoubdMmsAud}>
            <FileSuspenser status={status} isMine={isMine}>
                <audio controls>
                    <source src={mms?.message} type={`${mimeType || `audio/ogg`}}`} />
                    <source src={mms?.message} type="audio/ogg" />
                </audio>
            </FileSuspenser>
            <TimeStampAndDownload mms={mms} date={date} />
        </div>
    )
}

const RenderVid = ({ mms }) => {
    const { mimeType, date, isMine, status } = mms || false
    return (<div className={css[status]} id={isMine ? css.inBoundmmsVideoContainer : css.outBoundMmsvidContainer}>
        <div id={isMine ? css.inboubdMmsVid : css.outBoundMmsVid}>
            <FileSuspenser status={status} isMine={isMine}>
                <video controls>
                    <source src={mms?.message} type={`${mimeType || `video/ogg`}`} />
                    <source src={mms?.message} type="video/ogg" />
                </video>
            </FileSuspenser>
        </div>
        <TimeStampAndDownload mms={mms} date={date} />
    </div>

    )
}

const RenderApp = ({ mms }) => {
    const { date, isMine, status, mimeType, fileName } = mms || false
    return (
        <div className={css[status]} id={isMine ? css.inboundAppFile : css.outBoundAppFile} >
            <FileSuspenser status={status} isMine={isMine}>
                <Box sx={{ mt: -0.2 }}>
                    <Box component="div" sx={{ display: `inline-block`, marginLeft: 1, color: isMine ? `white` : `red` }}>
                        <i style={{ fontSize: `40px` }} title={`${fileName}`} className={
                            (mimeType === `application/pdf`)
                                ? `bi bi-file-earmark-pdf-fill`
                                : (mimeType === `application/msword` || mimeType === `application/vnd.openxmlformats-officedocument.wordprocessingml.document`)
                                    ? `bi bi-file-earmark-word-fill`
                                    : (mimeType === `application/vnd.ms-excel` || mimeType === `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`)
                                        ? `bi bi-file-earmark-spreadsheet-fill`
                                        : (mimeType === `application/vnd.ms-powerpoint` || mimeType === `application/vnd.openxmlformats-officedocument.presentationml.presentation`)
                                            ? `bi bi-file-earmark-ppt-fill`
                                            : (mimeType === `text/csv`)
                                                ? `bi bi-filetype-csv`
                                                : `bi bi-file-text-fill`
                        }></i>
                    </Box>
                </Box>
            </FileSuspenser>
            <TimeStampAndDownload mms={mms} date={date} />
        </div >
    )
}

export const PreviewMmsFile = ({ children, setMessages }) => {
    const renderCount = useRef(0)
    const { mimeType, blobId, _id: msgId, status } = children || false
    printInBrowser({ key: `mimeTYe`, value: { child: children, renderCount: renderCount.current } })

    useEffect(() => {
        if (blobId && renderCount.current === 0 || status === `sent` || status === `realTimeReceived`) {
            const setMessagesHandler = (key, value) => {
                setMessages((p) => {
                    return [
                        ...p.map((message) => {
                            if (message?._id === msgId) {
                                return { ...message, [key]: value };
                            } else {
                                return { ...message };
                            }
                        }),
                    ];
                });
            };
            renderCount.current = renderCount.current + 1;
            setMessagesHandler(`status`, `gettingFile`);
            postRequest(
                GET_S3_FILE,
                {
                    blobId,
                },
                { responseType: `arraybuffer` }
            )
                .then(async (res) => {
                    let file = new Blob([res.data], { type: `${mimeType}` });
                    printInBrowser({ key: `fileCheck`, value: children });
                    let fileUrl = await convertToBase64(file);
                    setMessagesHandler(`status`, `received`);
                    setMessagesHandler(`message`, fileUrl);
                })
                .catch(() => setMessagesHandler(`status`, `error`));
        }
    }, [blobId, msgId, status, renderCount]);


    const decideFileExt = useMemo(() => {
        if (mimeType && children) {
            if (imgValidExt?.find((mime) => mime?.includes(mimeType))) {
                return `img`
            } else if (vidValidExt?.find((mime) => mime?.includes(mimeType))) {
                return `vid`
            } else if (audValidExt?.find((mime) => mime?.includes(mimeType))) {
                return `aud`
            } else if (appValidExt?.find((mime) => mime?.includes(mimeType))) {
                return `app`
            }
        } else {
            return `noMime`
        }

    }, [mimeType, children])

    let date = children?.date || children?.createdAt ? momentWrapper(children?.date || children?.createdAt).format(`LLLL`) : ``

    const renderFilePreview = {
        img: <RenderImg mms={{ ...children, date: date, setMessages: setMessages }} />,
        vid: <RenderVid mms={{ ...children, date: date, setMessages: setMessages }} />,
        aud: <RenderAud mms={{ ...children, date: date, setMessages: setMessages }} />,
        app: <RenderApp mms={{ ...children, date: date, setMessages: setMessages }} />,
        noMime: `No MimeType`
    }

    return (
        <> {renderFilePreview[decideFileExt]}</>
    )
}
