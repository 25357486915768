import { Button } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { logoutHandlerS } from 'src/redux/action/logoutHandler'

export const RenderLogoutBtn = () => {
    const dispatch = useDispatch()
    return (
        process.env.NODE_ENV === `development` ? <Button sx={{
            textTransform: `none`
        }} variant='contained' onClick={() => dispatch(logoutHandlerS())}>Logout</Button> : null
    )
}
