import React, { useEffect, useState, useRef } from 'react';
import './videoWindow.css';
import { useSelector, useDispatch } from 'react-redux';
import { isSupported } from 'twilio-video';
import { isMobile } from './components/browser';
import joinRoom, { room } from './components/joinroom';
import { micLevel } from './components/miclevel';
import selectMedia from './components/selectmedia';
import { selectRoom } from './components/selectroom';
import { showError } from './components/showerror';
import ScreenShare from './components/screenshare';
import Button from '@mui/material/Button';
import $ from 'jquery';
import { LocalMediaControls } from './components/localmediacontrols';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { momentWrapper } from 'src/momentWrapper';
import { postRequest, PATIENT_SEND_TEXT_MESSAGE_URL, PATIENT_TWILIO_VIDEO_CALL_TOKEN_VERIFY_URL, getRequest } from 'src/crud/crud';
import { videoCallAction, messagesApiCall, handleTimer, saveTimerUser } from 'src/redux/patient/patientActions';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import HeightIcon from '@mui/icons-material/Height';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// ConnectOptions settings for a video web application.
const connectOptions = {
  bandwidthProfile: {
    video: {
      dominantSpeakerPriority: `high`,
      mode: `collaboration`,
      renderDimensions: {
        high: { height: 720, width: 1280 },
        standard: { height: 90, width: 160 },
      },
    },
  },

  dominantSpeaker: true,

  // Comment this line to disable verbose logging.
  logLevel: `debug`,

  // Comment this line if you are playing music.
  maxAudioBitrate: 16000,

  preferredVideoCodecs: [{ codec: `VP8`, simulcast: true }],

  video: { height: 720, frameRate: 24, width: 1280 },
};

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile) {
  connectOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
}

const deviceIds = {
  audio: isMobile ? null : localStorage.getItem(`audioDeviceId`),
  video: isMobile ? null : localStorage.getItem(`videoDeviceId`),
};

let roomName = ``;
let token = ``;

let tInterval = null;

export const VideoWindow = ({ children }) => {
  const { timer } = useSelector((state) => state.patient);
  const videoCallStatus = useSelector((state) => state.patient.videoCall);
  const patientData = useSelector((state) => state?.patient?.patientData);
  const { startTime } = useSelector((state) => state.patient.timer);
  const videoCallActionToDispatch = useDispatch();
  const dispatchRedux = useDispatch();
  const [autoTimer, setAutoTimer] = useState(false);
  const [roomLink, setRoomLink] = useState(``);
  const [isVideoCallStart, setIsVideoCallStart] = useState(false);
  const [initialVideoCallTime, setInitialVideoCallTime] = useState(0);
  const [videoCamera, setVideoCamera] = useState(false);
  const [audioVolume, setAudioVolume] = useState(false);
  const [, setScreenShare] = useState(false);
  const [resizeScreen, setResizeScreen] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const $main = useRef(null);
  const $modals = useRef(null);
  const $selectMicModal = useRef(null);
  const $selectCameraModal = useRef(null);
  const $showErrorModal = useRef(null);
  const $joinRoomModal = useRef(null);

  const videoBtnRef = useRef(null);
  const screenShareRef = useRef(null);

  useEffect(() => {
    if (videoCallStatus?.startFlag) {
      if ($showErrorModal.current && isSupported) {
        selectMicrophone();
      } else {
        showError($showErrorModal.current, new Error(`This browser is not supported.`));
      }
    }
  }, [videoCallStatus?.startFlag]);

  async function selectMicrophone() {
    if (deviceIds.audio === null) {
      try {
        deviceIds.audio = await selectMedia(`audio`, $selectMicModal.current, (stream) => {
          const $levelIndicator = $(`svg rect`, $selectMicModal.current);
          const maxLevel = Number($levelIndicator.attr(`height`));
          micLevel(stream, maxLevel, (level) => $levelIndicator.attr(`y`, maxLevel - level));
        });
      } catch (error) {
        showError($showErrorModal.current, error);
        return;
      }
    }
    return selectCamera();
  }

  async function selectCamera() {
    if (deviceIds.video === null) {
      try {
        deviceIds.video = await selectMedia(`video`, $selectCameraModal.current, (stream) => {
          const $video = $(`video`, $selectCameraModal.current);
          $video.get(0).srcObject = stream;
        });
      } catch (error) {
        showError($showErrorModal.current, error);
        return;
      }
    }
    return sendRequestForToken();
  }

  async function sendRequestForToken() {
    try {
      // Fetch an AccessToken to join the Room.
      let url = PATIENT_TWILIO_VIDEO_CALL_TOKEN_VERIFY_URL + videoCallStatus?.patientId;
      const response = await getRequest(url);
      // Extract the AccessToken from the Response.
      token = await response.data.token;
      // Extract the RoomName from the Response.
      roomName = await response.data.roomName;
      // Extract the RoomLink from the Response.
      setRoomLink(await response.data.roomLink);
      return selectAndJoinRoom();
    } catch (error) {
      return selectAndJoinRoom(error);
    }
  }

  /**
   * Select your Room name, your screen name and join.
   * @param [error=null] - Error from the previous Room session, if any
   */
  async function selectAndJoinRoom(error = null) {
    const formData = await selectRoom($joinRoomModal.current, error);

    if (!formData) {
      // User wants to change the camera and microphone.
      // So, show them the microphone selection modal.
      deviceIds.audio = null;
      deviceIds.video = null;
      return selectMicrophone();
    }
  }

  const toHHMMSS = (v) => {
    let sec_num = parseInt(v, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0` + hours;
    }
    if (minutes < 10) {
      minutes = `0` + minutes;
    }
    if (seconds < 10) {
      seconds = `0` + seconds;
    }
    return hours + `:` + minutes + `:` + seconds;
  };

  useEffect(() => {
    if (isVideoCallStart) {
      tInterval = setInterval(() => {
        //
        setInitialVideoCallTime((preState) => preState + 1);
      }, 1000);
    } else {
      setInitialVideoCallTime(0);
    }
    return () => {
      setInitialVideoCallTime(0);
      clearInterval(tInterval);
    };
  }, [isVideoCallStart]);

  // Send Video Call using SMS
  const sendSMSHandler = () => {
    // Send Link to Patient.
    postRequest(PATIENT_SEND_TEXT_MESSAGE_URL + videoCallStatus?.patientId, {
      patientPhoneNumber: patientData?.data?.phoneNumber,
      MDPhoneNumber: `+12402976385`,
      doctorMessage: `Hi, Please click on the following link to join the video conference with the doctor ${roomLink}
      Note: Link will expire in 1 hour`,
    })
      .then(() => {
        successToast(`SMS Sent !`);
      })
      .catch(() => {
        // errorToast(`Error ! Unable to send sms.`);
      });
  };
  const isChatting = useSelector((state) => state?.patient?.isChatting);
  const descriptionForTimer = useSelector((state) => state?.patient?.descriptionText);
  const stopTimerHandler = async () => {
    setIsVideoCallStart(true);
    if (timer?.toggleButton) {
      const endTimeValue = momentWrapper().toISOString();
      // if timer toggle is already running first remove the timer
      dispatchRedux(handleTimer({ status: false, timeEnd: endTimeValue, videoOrAudio: null, autoTimer: `true` }));

      const timeDiff = momentWrapper(endTimeValue).diff(startTime, `seconds`);

      // if time toggle is running more than 5 seconds then save the time first
      if (timeDiff > 5) {
        dispatchRedux(
          saveTimerUser({
            videoOrAudioOn: `video`,
            updateVideoorAudioLogStatus: null,
            callSuccess: null,
            statuss: isChatting ? descriptionForTimer : `View graphs and reports`,
            sources: `View Report`,
          })
        );
      } else {
        dispatchRedux(handleTimer({ status: true, timeEnd: null, videoOrAudio: `video` }));
      }
    } else {
      dispatchRedux(handleTimer({ status: true, timeEnd: null, videoOrAudio: `video` }));
    }
  };

  const leaveRoom = () => {
    let $mainModal = $(`#main-modal`, $main.current);
    if (room) room.disconnect();
    $mainModal.modal(`hide`);
    $($joinRoomModal.current).modal(`hide`);
  };

  const videoCallEndHandler = async () => {
    dispatchRedux({ type: `VIDEOFLAG`, payLoad: false });
    dispatchRedux(messagesApiCall(patientData?.data?._id));
    setIsVideoCallStart(false);
    setInitialVideoCallTime(0);
    if (!patientData?.data?.interactiveCommunication) {
      setFeedbackModal(true);
      await postRequest(`url/deleteToken`, {
        encodedToken: roomLink,
      });

      dispatchRedux(handleTimer({ status: false }));

      if (timer?.autoTimer === true) {
        // On the timer after video call ended
        setAutoTimer(true);
      } else {
        setAutoTimer(false);
      }
    } else {
      await postRequest(`url/deleteToken`, {
        encodedToken: roomLink,
      });

      // ContactsOutlined
      if (timer?.autoTimer === true) {
        //
        dispatchRedux(handleTimer({ status: false }));

        // On the timer after video call ended
        dispatchRedux(
          saveTimerUser({
            videoOrAudioOn: `timerOn`,
            updateVideoorAudioLogStatus: true,
            callSuccess: true,
            statuss: `Interactive Video Conference Completed`,
            sources: `Video Call`,
          })
        );
      } else {
        dispatchRedux(handleTimer({ status: false }));
        dispatchRedux(
          saveTimerUser({
            videoOrAudioOn: null,
            updateVideoorAudioLogStatus: true,
            callSuccess: true,
            statuss: `Interactive Video Conference Completed`,
            sources: `Video Call`,
          })
        );
      }
      videoCallActionToDispatch(videoCallAction(null));
    }
    leaveRoom();
  };

  const handleCloseFeedbackModal = (success) => {
    setFeedbackModal(false);
    dispatchRedux(messagesApiCall(patientData?.data?._id));
    if (success) {
      if (autoTimer) {
        dispatchRedux(
          saveTimerUser({
            videoOrAudioOn: `timerOn`,
            updateVideoorAudioLogStatus: true,
            callSuccess: true,
            statuss: `Interactive Video Conference Completed`,
            sources: `Video Call`,
          })
        );
      } else {
        dispatchRedux(
          saveTimerUser({
            videoOrAudioOn: null,
            updateVideoorAudioLogStatus: true,
            callSuccess: true,
            statuss: `Interactive Video Conference Completed`,
            sources: `Video Call`,
          })
        );
      }
    } else {
      if (autoTimer) {
        dispatchRedux(
          saveTimerUser({
            videoOrAudioOn: `timerOn`,
            updateVideoorAudioLogStatus: true,
            callSuccess: false,
            statuss: `No Interactive Communication`,
            sources: `Video Call`,
          })
        );
      } else {
        dispatchRedux(
          saveTimerUser({
            videoOrAudioOn: null,
            updateVideoorAudioLogStatus: true,
            callSuccess: false,
            statuss: `No Interactive Communication`,
            sources: `Video Call`,
          })
        );
      }
    }
    videoCallActionToDispatch(videoCallAction(null));
  };
  const openVideoWindowModal = async () => {
    dispatchRedux({ type: `VIDEOFLAG`, payLoad: true });
    stopTimerHandler();
    sendSMSHandler();
    try {
      // Add the specified audio device ID to ConnectOptions.
      connectOptions.audio = { deviceId: { exact: deviceIds.audio } };

      // Add the specified Room name to ConnectOptions.
      connectOptions.name = roomName;

      // Add the specified video device ID to ConnectOptions.
      connectOptions.video.deviceId = { exact: deviceIds.video };

      // Join the Room.
      await joinRoom(token, connectOptions, $main.current, videoCallStatus?.startFlag);

      // After the video session.
      return;
    } catch (error) {
      return selectAndJoinRoom(error);
    }
  };
  const closeVideoCallModalAndStatus = () => {
    dispatchRedux(messagesApiCall(patientData?.data?._id));
    videoCallActionToDispatch(videoCallAction(null));
  };

  return (
    <>
      <>
        <Dialog
          open={feedbackModal}
          onClose={handleCloseFeedbackModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Did this call satisfy the requirements for interactive communication?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: `center` }}>
            <Button onClick={() => handleCloseFeedbackModal(true)} variant="contained" style={{ backgroundColor: `green`, color: `white` }}>
              YES
            </Button>
            <Button onClick={() => handleCloseFeedbackModal(false)} variant="contained" style={{ backgroundColor: `red`, color: `white` }} autoFocus>
              NO
            </Button>
          </DialogActions>
        </Dialog>

        <div id="minimizeScreen" ref={$main}>
          <div>
            <div
              style={{
                userSelect: `none`,
                margin: `0 auto`,
                width: resizeScreen ? `35%` : `100%`,
                zIndex: `4000`,
              }}
              className="modal fade"
              id="main-modal"
              data-backdrop="false"
              data-keyboard="false"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="main-modal-label"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div
                  className="modal-content"
                  style={{
                    position: `relative`,
                    display: `flex`,
                    flexDirection: `column`,
                    width: `100%`,
                    pointerEvents: `auto`,
                    backgroundColor: `#072e3c`,
                    backgroundClip: `padding-box`,
                    border: `2px solid #eee`,
                    borderRadius: `2.3rem`,
                    outline: `0`,
                  }}
                >
                  <div className="modal-body m-1" style={{ textAlign: `center` }}>
                    <div className="row" id="room">
                      <div id="participants" className="col-xs-12 col-sm-3 col-md-2" style={{ textAlign: `center` }}></div>
                      <div id="active-participant" className="col-xs-12 col-sm-9 col-md-10" style={{ textAlign: `center`, paddingRight: `2%` }}>
                        <div id="mainPreviewWindow" className="participant main">
                          <video id="videopreview" className="myMainVidepWindow" autoPlay playsInline muted style={{ objectFit: `contain` }}></video>
                          <video id="screenpreview" autoPlay></video>
                          <div id="controls">
                            <h6 style={{ color: `white`, marginTop: `20px` }}>{toHHMMSS(initialVideoCallTime)}</h6>
                            {!videoCamera ? (
                              <Tooltip title="Turn off camera">
                                <Fab
                                  size="small"
                                  color="primary"
                                  id="muteVideoBtn"
                                  ref={videoBtnRef}
                                  onClick={() => {
                                    LocalMediaControls($main.current, `video`);
                                    setVideoCamera((p) => !p);
                                  }}
                                >
                                  <VideocamIcon />
                                </Fab>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Turn on camera">
                                <Fab
                                  size="small"
                                  color="primary"
                                  id="muteVideoBtn"
                                  ref={videoBtnRef}
                                  onClick={() => {
                                    LocalMediaControls($main.current, `video`);
                                    setVideoCamera((p) => !p);
                                  }}
                                >
                                  <VideocamOffIcon />
                                </Fab>
                              </Tooltip>
                            )}
                            {!audioVolume ? (
                              <Tooltip title="Mute Audio">
                                <Fab
                                  size="small"
                                  color="primary"
                                  id="muteAudioBtn"
                                  onClick={() => {
                                    LocalMediaControls($main.current, `audio`);
                                    setAudioVolume((p) => !p);
                                  }}
                                >
                                  <MicIcon />
                                </Fab>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Unmute Audio">
                                <Fab
                                  size="small"
                                  color="primary"
                                  id="muteAudioBtn"
                                  onClick={() => {
                                    LocalMediaControls($main.current, `audio`);
                                    setAudioVolume((p) => !p);
                                  }}
                                >
                                  <MicOffIcon />
                                </Fab>
                              </Tooltip>
                            )}
                            <Tooltip title="Leave room">
                              <Fab color="secondary" id="leave-room" onClick={() => videoCallEndHandler()}>
                                <PhoneDisabledIcon />
                              </Fab>
                            </Tooltip>

                            <Tooltip title="Screen share">
                              <Fab
                                size="small"
                                color="primary"
                                id="capturescreen"
                                ref={screenShareRef}
                                onClick={() => {
                                  ScreenShare($main.current, `start`);
                                  setScreenShare((p) => !p);
                                }}
                              >
                                <ScreenShareIcon />
                              </Fab>
                            </Tooltip>

                            <Tooltip title="Stop screen share">
                              <Fab
                                size="small"
                                color="primary"
                                id="stopscreencapture"
                                ref={screenShareRef}
                                onClick={() => {
                                  ScreenShare($main.current, `stop`);
                                  setScreenShare((p) => !p);
                                }}
                              >
                                <StopScreenShareIcon />
                              </Fab>
                            </Tooltip>
                            <Tooltip title="Resize the screen">
                              <Fab
                                size="small"
                                color="primary"
                                onClick={() => {
                                  setResizeScreen(!resizeScreen);
                                }}
                              >
                                <HeightIcon />
                              </Fab>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
            <div ref={$modals} id="modals">
              <div
                className="modal fade"
                ref={$selectMicModal}
                id="select-mic"
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="select-mic-label"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="select-mic-label">
                        Microphone
                      </h5>
                    </div>
                    <div className="modal-body" style={{ textAlign: `center` }}>
                      <select style={{ width: `100%` }}></select>
                      <svg focusable="false" viewBox="0 0 100 100" aria-hidden="true" height="100" width="100" style={{ margin: `10px 0` }}>
                        <defs>
                          <clipPath id="level-indicator">
                            <rect x="0" y="100" width="100" height="100" />
                          </clipPath>
                        </defs>
                        <path
                          fill="rgb(220, 220, 220)"
                          d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
                        ></path>
                        <path
                          fill="rgb(220, 220, 220)"
                          d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
                        ></path>
                        <path
                          fill="#080"
                          clipPath="url(#level-indicator)"
                          d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
                        ></path>
                        <path
                          fill="#080"
                          clipPath="url(#level-indicator)"
                          d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
                        ></path>
                      </svg>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary">
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="select-camera"
                ref={$selectCameraModal}
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="select-camera-label"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="select-camera-label">
                        Camera
                      </h5>
                    </div>
                    <div className="modal-body" style={{ textAlign: `center` }}>
                      <select style={{ width: `100%` }}></select>
                      <video autoPlay muted className="myParticipant" playsInline style={{ margin: `10px 0`, width: `60%` }}></video>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary">
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="join-room"
                ref={$joinRoomModal}
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="join-room-label"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="join-room-label">
                        Video Chat
                      </h5>
                      <button type="button" onClick={() => closeVideoCallModalAndStatus()} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label htmlFor="joinLinkTextarea">Room Join Link:</label>
                        <textarea className="form-control" id="joinLinkTextarea" rows="3" value={roomLink} readOnly />
                      </div>
                      <div className="alert alert-warning" role="alert"></div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary">
                        Change Settings
                      </button>
                      <button type="button" className="btn btn-primary" onClick={() => openVideoWindowModal()}>
                        Join
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="show-error"
                ref={$showErrorModal}
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="show-error-label"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="show-error-label">
                        Error
                      </h5>
                      <button type="button" onClick={() => closeVideoCallModalAndStatus()} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="alert alert-warning" role="alert"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {children}
    </>
  );
};
