import React from 'react';
import { Button } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { MuiSpinner } from '../MuiSpinner/MuiSpinner';

export const CustomBtn = ({ btnProps, element }) => {
  const { className = `` } = btnProps || { btnProps: { className: `` } };
  return (
    <button {...(!className ? { className: `btn btn-outline-info btn-sm` } : {})} {...btnProps}>
      {element}
    </button>
  );
};

export const MuiBtn = ({ children, btnProps = { styles: false }, exctProps, loading = { loading: false, error: false } }) => {
  const {
    loading: loadingState,
    error,
    spinnerProps = {
      sx: {
        color: `white`,
      },
      size: 25,
      thickness: 8,
    },
  } = loading;
  const { styles = false, sx = false } = btnProps;
  let preparePros = {
    ...btnProps,
    sx: {
      backgroundColor: error ? `red` : `#1699c5`,
      ...(sx && { ...sx }),
      ...(styles && { ...styles }),
      color: `white`,
      textTransform: `none`,
      '&:hover': {
        backgroundColor: `#1c84a6`,
      },
    },
  };
  return (
    <Button {...(exctProps && { ...exctProps })} {...preparePros}>
      {error && <ReportProblemIcon />}
      {loadingState && <MuiSpinner {...spinnerProps} />}
      {!error && !loadingState && children}
    </Button>
  );
};
