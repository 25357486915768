import React, { useCallback, useEffect, useRef } from 'react';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import css from './bulkImport.module.css';
import { Avatar, AvatarGroup, Box, IconButton, Stack } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { capitalizeFirstLetter, defFontColor, findDiffElemArr, renderDeviceName, returnIfArr } from 'src/utils';
import { useState } from 'react';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';
import { MuiPopOver } from 'src/pages/common/components/MuiPopOver/MuiPopOver';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { DateFormat } from 'src/pages/common/components/DateFormat/DateFormat';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { MuiCheckBox } from 'src/pages/common/components/MuiCheckBox/MuiCheckBox';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { SecProv } from './SecProv';
import { SecClinical } from './SecClinical';
import { PrimaryProv } from './PrimaryProv';
import { PrimirayClinical } from './PrimirayClinical';
import CloseIcon from '@mui/icons-material/Close';
import { RotationAnimationWrapper } from 'src/pages/common/HOC/RotationAnimationWrapper';
import { ZoomWrapper } from 'src/pages/common/HOC/ZoomWrapper';

export const PatientList = ({ list, setList, hasError, onBlur, fixedUsers }) => {

  const isAllChecked = list?.filter((item) => item?.isChecked)?.length === list?.length;
  const isAllAssigned = list?.filter((item) => item?.isChecked && item?.isAssigned)?.length === list?.length;

  const onChangeHandler = ({ key, value, patientId = ``, userKey = `` }) => {
    const isMatches = (patient, patientId) => patient?._id === patientId;
    if (key === `patients` && value === `all`) {
      setList((prevState) => {
        return prevState?.map((patient) => {
          return { ...patient, isChecked: !isAllChecked };
        });
      });
    } else if (key === `patients` && value !== `all`) {
      setList((prevState) => {
        return prevState?.map((patient) => {
          if (isMatches(patient, patientId)) return { ...patient, isChecked: !patient.isChecked };
          return { ...patient };
        });
      });
    } else if (key === `users` && typeof value === `string` && userKey?.toLowerCase()?.includes(`_`)) {
      setList((prevState) => {
        return prevState?.map((patient) => {
          if (isMatches(patient, patientId)) return { ...patient, users: { ...patient?.users, [userKey]: value } };
          return { ...patient };
        });
      });
    } else if (key === `users` && typeof value === `string` && userKey?.toLowerCase()?.includes(`sec`)) {
      setList((prevState) => {
        return prevState?.map((patient) => {
          if (isMatches(patient, patientId))
            return {
              ...patient,
              users: { ...patient?.users, [userKey]: [...returnIfArr(patient?.users?.[userKey]?.filter((user) => user !== value))] },
            };
          return { ...patient };
        });
      });
    } else if (key === `users` && Array.isArray(value) && userKey?.toLowerCase()?.includes(`sec`)) {
      setList((prevState) => {
        return prevState?.map((patient) => {
          if (isMatches(patient, patientId)) return { ...patient, users: { ...patient?.users, [userKey]: [...value] } };
          return { ...patient };
        });
      });
    } else if (key === `assignToNewlyAddedUsers`) {
      setList((prevState) => {
        return prevState?.map((patient) => {
          if (isMatches(patient, patientId)) return { ...patient, assignToNewlyAddedUsers: !patient?.assignToNewlyAddedUsers };
          return { ...patient };
        });
      });
    }
  };


  return (
    <div className={`${css.table} card`}>
      <div className="table-responsive">
        <table className={`table  table-lg table-borderless table-striped ${css.tableInner}`}>
          <thead {...(hasError && { className: css.errorAnimation })}>
            <tr>
              <td className={css.tableHead}>
                Select All
                <MuiCheckBox
                  disabled={isAllAssigned}
                  checked={isAllChecked}
                  onBlur={onBlur}
                  value={`all`}
                  onChange={({ target: { value } }) => onChangeHandler({ key: `patients`, value })}
                  size="small"
                  sx={{
                    color: `aliceblue`,
                    '&.Mui-checked': {
                      color: `aliceblue`,
                    },
                  }}
                />
              </td>
              <td className={css.tableHead}>First Name</td>
              <td className={css.tableHead}>Last Name</td>
              <td className={css.tableHead}>Dob</td>
              <td className={css.tableHead}>Mfg</td>
              <td className={css.tableHead}>Care Team</td>
              <td className={css.tableHead}>Assignment to new users</td>
            </tr>
          </thead>
          <tbody>
            {list?.map((patient, ind) => {
              return (
                <tr key={patient?._id} className={css.tableBodyRow}>
                  <RenderPatientInfo ind={ind} patient={patient} onBlur={onBlur} onChange={onChangeHandler} fixedUsers={fixedUsers} />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const RenderPatientInfo = React.memo(
  ({ patient, onBlur, onChange, ind, fixedUsers }) => {
    const { deviceManufacturer = ``, firstName, lastName, dateOfBirth = ``, isChecked = false, _id: patientId = ``, users = [] } = patient;
    const isCheckedAndAssigned = patient?.isAssigned && patient?.isChecked;
    return (
      <>
        <td>
          <MuiCheckBox
            disabled={isCheckedAndAssigned}
            checked={isChecked}
            onChange={({ target: { value } }) => onChange({ key: `patients`, value, patientId })}
            onBlur={onBlur}
            value={patient?._id}
            size="small"
            sx={{
              '&.Mui-checked': {
                color: `#1699c5`,
              },
              '&.Mui-disabled': {
                color: `black`,
              },
            }}
          />
        </td>
        <td>{capitalizeFirstLetter(firstName)}</td>
        <td>{capitalizeFirstLetter(lastName)}</td>
        <td>
          <DateFormat date={dateOfBirth} />
        </td>
        <td>{renderDeviceName(deviceManufacturer)}</td>
        <td>
          <ConditionallyRenderWrapper con={isCheckedAndAssigned} renderKey="-">
            <RenderUsers users={users} fixedUsers={fixedUsers} onChange={onChange} patientId={patientId} isAssigned={patient?.isAssigned} />
          </ConditionallyRenderWrapper>
        </td>
        <td>
          <ConditionallyRenderWrapper con={isCheckedAndAssigned} renderKey="-">
            <IconButton onClick={() => onChange({ key: `assignToNewlyAddedUsers`, patientId })}>
              {patient?.assignToNewlyAddedUsers ? <ThumbUpIcon sx={{ color: `#1699c5` }} /> : <ThumbDownAltIcon sx={{ color: `#1699c5` }} />}
            </IconButton>
          </ConditionallyRenderWrapper>
        </td>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps?.patient?.isChecked === nextProps?.patient?.isChecked &&
      prevProps?.patient?.isAssigned === nextProps?.patient?.isAssigned &&
      prevProps?.patient?.assignToNewlyAddedUsers === nextProps?.patient?.assignToNewlyAddedUsers &&
      prevProps?.patient?.users?.secondaryProviders?.length === nextProps?.patient?.users?.secondaryProviders?.length &&
      prevProps?.patient?.users?.secondaryClinicalUsers?.length === nextProps?.patient?.users?.secondaryClinicalUsers?.length &&
      prevProps?.patient?.users?._clinicalUserId === nextProps?.patient?.users?._clinicalUserId &&
      prevProps?.patient?.users?._providerId === nextProps?.patient?.users?._providerId
    );
  }
);

const RenderUsers = ({ users = {}, fixedUsers = {}, onChange, patientId = ``, isAssigned }) => {
  const { clinicalStaffs, providers } = fixedUsers;
  const [openPopOverSecUsers, setOpenPopOverSecUsers] = useState(false);
  const [openPopOverPrimaryUsers, setOpenPopOverPrimaryUsers] = useState(false);
  const [anchorElem, setAnchorElem] = useState(null);
  const [preparedUsers, setPreparedUsers] = useState([]);
  const contRef = useRef(null);
  let secUsers = preparedUsers?.length ? preparedUsers?.filter((user) => user?.isSecProv || user?.isSecClinical) : [];
  useEffect(() => {
    const { _providerId: primProv = ``, _clinicalUserId: primClininical = ``, secondaryClinicalUsers = [], secondaryProviders = [] } = users;
    if (primProv && primClininical) {
      const findAndGive = (arr, id) => {
        let find = arr?.find((elem) => elem?._id === id);
        return find ? { ...find } : {};
      };
      let prepareSecClinicals = secondaryClinicalUsers?.length
        ? secondaryClinicalUsers?.map((secClinical) => {
          return { ...findAndGive(clinicalStaffs, secClinical), isSecClinical: true };
        })
        : [];
      let prepareSecProvs = secondaryProviders?.length
        ? secondaryProviders?.map((secProv) => {
          return { ...findAndGive(providers, secProv), isSecProv: true };
        })
        : [];
      setPreparedUsers([
        ...prepareSecClinicals,
        ...prepareSecProvs,
        { isPrimProv: true, ...findAndGive(providers, primProv) },
        { isPrimClinical: true, ...findAndGive(clinicalStaffs, primClininical) },
      ]);
    }
  }, [users]);

  const usersOpt = useCallback(
    (userArr, key) => {
      let decideArray = key?.toLowerCase()?.includes(`prov`) ? providers : clinicalStaffs;
      return findDiffElemArr({ arrayToFilter: decideArray, arrayFilterFrom: userArr, key: `_id` });
    },
    [users]
  );

  const spreadCond = (arrKey) => {
    if (users?.[arrKey]?.length) return users[arrKey];
    return [];
  };

  const paperProps = {
    sx: {
      backgroundColor: `#c7e1f7a1`,
      backdropFilter: `blur(20px)`,
      opacity: `.9`,
      boxShadow: `none`,
      width: `500px`,
    },
  };

  return (
    <ConditionallyRenderWrapper con={preparedUsers?.length}>
      <Box>
        <Stack direction="row" justifyContent={`center`}>
          <Box>
            <ToolTipProvider
              toolTipProps={{
                title: `Edit primary users.`,
              }}
              element={
                <Box>
                  <RotationAnimationWrapper animationProps={{ horizontDir: `right` }}>
                    <IconButton
                      size="medium"
                      onClick={() => {
                        setOpenPopOverPrimaryUsers((p) => !p);
                        setAnchorElem(contRef.current);
                      }}
                    >
                      <SettingsOutlinedIcon sx={{ color: defFontColor }} />
                    </IconButton>
                  </RotationAnimationWrapper>
                </Box>
              }
            />
          </Box>
          <Box>
            <MuiPopOver
              anchorEl={anchorElem}
              title="Additional Care Team"
              open={openPopOverSecUsers}
              onClose={() => {
                setOpenPopOverSecUsers((p) => !p);
                setAnchorElem(null);
              }}
              PaperProps={{
                ...paperProps,
              }}
            >
              <Box sx={{ width: `100%` }}>
                <RenderTitle>Edit secondary users</RenderTitle>
                <Stack direction={`row`} spacing={2} sx={{ p: 2, pt: 1 }} justifyContent={`center`}>
                  <Box>
                    <Box sx={{ fontWeight: `600` }}>Secondary Providers</Box>
                    <Box sx={{ pt: 1, pb: 1, width: `200px` }}>
                      <SecProv
                        value={users?.secondaryProviders}
                        users={() => usersOpt([users?._providerId, ...spreadCond(users?.secondaryProviders)], `isprov`)}
                        onChange={(value) => onChange({ value, key: `users`, patientId, userKey: `secondaryProviders` })}
                      />
                    </Box>

                    {secUsers
                      ?.filter((user) => user?.isSecProv)
                      ?.map((user) => (
                        <Stacked key={user?._id}>
                          <Box>{user?.fullName}</Box>
                          <Box>
                            <RemoveIcon>
                              <IconButton
                                onClick={() => onChange({ value: user?._id, key: `users`, patientId, userKey: `secondaryProviders` })}
                                size="small"
                              >
                                <CloseIcon sx={{ color: `red` }} />
                              </IconButton>
                            </RemoveIcon>
                          </Box>
                        </Stacked>
                      ))}
                  </Box>
                  <Box>
                    <Box sx={{ fontWeight: `600` }}>Secondary Clinical Users</Box>
                    <Box sx={{ pt: 1, pb: 1, width: `200px` }}>
                      <SecClinical
                        value={users?.secondaryClinicalUsers}
                        users={() => usersOpt([users?._clinicalUserId, ...spreadCond(users?.secondaryClinicalUsers)], `isClinical`)}
                        onChange={(value) => onChange({ value, key: `users`, patientId, userKey: `secondaryClinicalUsers` })}
                      />
                    </Box>
                    {secUsers
                      ?.filter((user) => user?.isSecClinical)
                      ?.map((user) => (
                        <Stacked key={user?._id}>
                          <Box>{user?.fullName}</Box>
                          <Box>
                            <RemoveIcon>
                              <IconButton
                                onClick={() => onChange({ value: user?._id, key: `users`, patientId, userKey: `secondaryClinicalUsers` })}
                                size="small"
                              >
                                <CloseIcon sx={{ color: `red` }} />
                              </IconButton>
                            </RemoveIcon>
                          </Box>
                        </Stacked>
                      ))}
                  </Box>
                </Stack>
              </Box>
            </MuiPopOver>
            <MuiPopOver
              PaperProps={{
                ...paperProps,
              }}
              anchorEl={anchorElem}
              open={openPopOverPrimaryUsers}
              onClose={() => {
                setOpenPopOverPrimaryUsers((p) => !p);
                setAnchorElem(null);
              }}
            >
              <Box sx={{ width: `100%`, p: 1 }}>
                <RenderTitle>Edit primary users</RenderTitle>
                <Stack direction="row" justifyContent={`space-between`} spacing={2}>
                  <Box sx={{ width: `100%` }}>
                    <PrimaryUserProtection
                      isProv={true}
                      users={() => usersOpt([users?._providerId, ...spreadCond(`secondaryProviders`)], `isPrimProv`)}
                    >
                      <PrimaryProv
                        label="Edit primiray provider"
                        value={users?._providerId}
                        users={() => usersOpt([users?._providerId, ...spreadCond(`secondaryProviders`)], `isPrimProv`)}
                        onChange={({ target: { value } }) => onChange({ value, key: `users`, patientId, userKey: `_providerId` })}
                      />
                    </PrimaryUserProtection>

                    <RenderFullName users={preparedUsers} filterKey={`isPrimProv`} />
                  </Box>
                  <Box sx={{ width: `100%` }}>
                    <PrimaryUserProtection
                      isProv={false}
                      users={() => usersOpt([users?._clinicalUserId, ...spreadCond(`secondaryClinicalUsers`)], `clinical`)}
                    >
                      <PrimirayClinical
                        label="Edit primiray clinical staff"
                        value={users?._clinicalUserId}
                        users={() => usersOpt([users?._clinicalUserId, ...spreadCond(`secondaryClinicalUsers`)], `clinical`)}
                        onChange={({ target: { value } }) => onChange({ value, key: `users`, patientId, userKey: `_clinicalUserId` })}
                      />
                    </PrimaryUserProtection>
                    <RenderFullName users={preparedUsers} filterKey={`isPrimClinical`} />
                  </Box>
                </Stack>
              </Box>
            </MuiPopOver>
            <AvatarGroup
              ref={contRef}
              onClick={(e) => {
                if (e.target.innerHTML === `+${secUsers?.length}`) {
                  setOpenPopOverSecUsers((p) => !p);
                  setAnchorElem(e.currentTarget);
                }
              }}
            >
              {preparedUsers
                ?.filter((user) => user?.isPrimProv || user?.isPrimClinical)
                .map((user) => (
                  <ToolTipProvider
                    key={user?._id}
                    toolTipProps={{
                      title: `${user?.fullName}  (${user?.isPrimProv ? `Provider` : `Clinical staff`})`,
                    }}
                    element={
                      <Avatar sx={{ backgroundColor: user?.isPrimProv ? defFontColor : `#83d5f0`, width: 40, height: 40 }}>
                        <span>
                          <ConditionallyRenderWrapper
                            con={user?.fullName?.split(` `)?.length > 1}
                            renderKey={`${user?.fullName?.charAt(0)}${user?.fullName?.charAt(1)}`}
                          >
                            {user?.fullName?.split(` `)?.[0]?.charAt(0)?.toUpperCase()}
                            {user?.fullName?.split(` `)?.[1]?.charAt(0)?.toUpperCase()}
                          </ConditionallyRenderWrapper>
                        </span>
                      </Avatar>
                    }
                  />
                ))}
              <ConditionallyRenderWrapper con={isAssigned}>
                <ToolTipProvider
                  toolTipProps={{ title: `View and edit secondary users.` }}
                  element={<Avatar sx={{ backgroundColor: `darkgray` }}>{`+${secUsers?.length}`}</Avatar>}
                />
              </ConditionallyRenderWrapper>
            </AvatarGroup>
          </Box>
        </Stack>
      </Box>
    </ConditionallyRenderWrapper>
  );
};

const Stacked = ({ children }) => {
  return (
    <Box
      sx={{
        '&:hover': {
          p: 1,
          borderRadius: 4,
          transition: `.5s`,
          backgroundColor: `aliceblue`,
        },
      }}
    >
      <ZoomWrapper
        zoomProps={{
          in: true,
          style: { transitionDelay: `300ms` },
        }}
      >
        <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`}>
          {children}
        </Stack>
      </ZoomWrapper>
    </Box>
  );
};

const RemoveIcon = ({ children }) => {
  return <ToolTipProvider toolTipProps={{ title: `Remove user` }} element={children} />;
};

const RenderFullName = ({ filterKey, users }) => {
  return users
    ?.filter((user) => user?.[filterKey])
    .map((user) => (
      <Box key={user?._id} sx={{ fontWeight: 600, pt: 1, pb: 1 }}>
        {user?.fullName}
      </Box>
    ));
};

const RenderTitle = ({ children }) => {
  return <Box sx={{ fontWeight: 600, pt: 1, pb: 1, display: `flex`, justifyContent: `center` }}>{children}</Box>;
};

const PrimaryUserProtection = ({ children, users, isProv }) => {
  return users()?.length ? (
    children
  ) : (
    <Box
      sx={{
        padding: `4px`,
        marginTop: `-6px`,
        backgroundColor: `#f400008a`,
        color: `white`,
        borderTopRightRadius: `32px`,
        borderBottomRightRadius: `12px`,
      }}
    >
      <Stack direction={`row`} justifyContent={`space-between`}>
        <Box
          sx={{
            textAlign: `start`,
            fontSize: `14px`,
            lineHeight: `20px`,
            fontWeight: `500`,
          }}
        >
          {`Please remove user/users from secondary ${isProv ? `provider` : `clinical staff`}s in order to change the  primary ${isProv ? `provider` : `clinical staff`
            }`}
        </Box>
        <Box sx={{ alignSelf: `end` }}>
          <GppMaybeIcon size="small" />
        </Box>
      </Stack>
    </Box>
  );
};
