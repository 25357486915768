import React, { useState, useEffect } from 'react';
import css from '../viewData.module.css';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import {
  getRequest,
  MONITAIR_TREND_USAGE_REPORT,
  MONITAIR_TREND_AHI_REPORT,
  MONITAIR_TREND_REPORT_RISK_FACTOR
} from 'src/crud/crud';

// MONITAIR TREND REPORT

export const ViewDataMonitAir = ({ user }) => {
  //GRAPH - Line
  const [labels, setLabels] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  //AHI
  const [dataSetAHI, setDataSetAHI] = useState([]);
  const [ahiLabels, setAhiLabels] = useState([]);
  //Usage
  const [dataSetUsage, setDataSetUsage] = useState([]);
  const [usageLabels, setUsageLabels] = useState([]);

  useEffect(() => {
    if (user) {
      let monthLabel = [`Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`];

      // Risk Factor Graph Payload

      getRequest(MONITAIR_TREND_REPORT_RISK_FACTOR + user)
        .then((response) => {
          const averageRiskPerMonthResponse = response.data?.riskStratificationPerMonth;
          let riskFactorFilteredData = [];
          let riskFactorLabels = [];
          let dataObject = { x: 0, y: 0 };
          for (let i = 0; i < averageRiskPerMonthResponse.length; i++) {
            if (averageRiskPerMonthResponse[i].tier === `LOW RISK`) {
              dataObject.x = 0;
              dataObject.y = 0.5;
            } else if (averageRiskPerMonthResponse[i].tier === `MODERATE RISK`) {
              dataObject.x = 0;
              dataObject.y = 1.5;
            } else if (averageRiskPerMonthResponse[i].tier === `HIGH RISK`) {
              dataObject.x = 0;
              dataObject.y = 2.5;
            } else if (averageRiskPerMonthResponse[i].tier === `VERY HIGH RISK`) {
              dataObject.x = 0;
              dataObject.y = 3.5;
            } else if (averageRiskPerMonthResponse[i].tier === null) {
              dataObject.x = 0;
              dataObject.y = 4.5;
            }
            riskFactorFilteredData.push(dataObject);
            dataObject = { x: 0, y: 0 };
            let monthNumber = Number(averageRiskPerMonthResponse[i].month.split(` `)[0]);

            riskFactorLabels.push(monthLabel[monthNumber - 1]);
          }

          setLabels(riskFactorLabels);
          setDataSet(riskFactorFilteredData);
        })
        .catch(() => {

        });

      // AHI Graph Payload

      getRequest(MONITAIR_TREND_AHI_REPORT + user)
        .then((response) => {
          const averageAHIPerMonthResponse = response.data?.averageAHIPerMonth;
          let AHIFilteredData = [];
          let AHILabels = [];

          for (let i = 0; i < averageAHIPerMonthResponse.length; i++) {
            AHIFilteredData.push(averageAHIPerMonthResponse[i].averageAHI);
            let monthNumber = Number(averageAHIPerMonthResponse[i].month.split(` `)[0]);

            AHILabels.push(monthLabel[monthNumber - 1]);
          }

          setDataSetAHI(AHIFilteredData);
          setAhiLabels(AHILabels);
        })
        .catch(() => { });

      // Usage Graph Payload

      getRequest(MONITAIR_TREND_USAGE_REPORT + user)
        .then((response) => {
          const averageUsagePerMonthResponse = response.data?.usagePerMonth;
          let UsageFilteredData = [];
          let UsageLabels = [];
          for (let i = 0; i < averageUsagePerMonthResponse.length; i++) {
            let avgSortedNumber = (averageUsagePerMonthResponse[i].averageUsage * 60) / 3600;
            UsageFilteredData.push(avgSortedNumber);
            let monthNumber = Number(averageUsagePerMonthResponse[i].month.split(` `)[0]);
            UsageLabels.push(monthLabel[monthNumber - 1]);
          }

          setDataSetUsage(UsageFilteredData);
          setUsageLabels(UsageLabels);
        })
        .catch(() => { });
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: `298px`, color: `rgb(193, 193, 193)` }}>
          No record found.
        </div>
      );
    }
  }, [user]);

  return (
    <>
      <div id={css.viewSummaryData} className="pt-3">
        <table className="table table-borderless ml-1">
          <tbody>
            <tr>
              <td colSpan="2" className="pt-0 pb-0">
                <h5 className="mb-0" style={{ color: `black` }}>
                  Risk Category:
                </h5>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <Line
                  data={{
                    labels: labels,
                    datasets: [
                      {
                        label: `Tier`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `#141E27`,
                        borderColor: `#141E27`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `#141E27`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `#141E27`,
                        pointHoverBorderColor: `#141E27`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: [...dataSet],
                      },
                      {
                        label: `VERY HIGH RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(235, 47, 6)`,
                        borderColor: `rgb(235, 47, 6)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(235, 47, 6)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(235, 47, 6)`,
                        pointHoverBorderColor: `rgb(235, 47, 6)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `HIGH RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(167, 9, 9)`,
                        borderColor: `rgb(167, 9, 9)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(167, 9, 9)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(167, 9, 9)`,
                        pointHoverBorderColor: `rgb(167, 9, 9)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `MEDIUM RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(34, 112, 147)`,
                        borderColor: `rgb(34, 112, 147)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(34, 112, 147)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(34, 112, 147)`,
                        pointHoverBorderColor: `rgb(34, 112, 147)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `LOW RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(23, 162, 184)`,
                        borderColor: `rgb(23, 162, 184)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(23, 162, 184)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(23, 162, 184)`,
                        pointHoverBorderColor: `rgb(23, 162, 184)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `NO DATA`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(149, 175, 192)`,
                        borderColor: `rgb(149, 175, 192)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(149, 175, 192)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(149, 175, 192)`,
                        pointHoverBorderColor: `rgb(149, 175, 192)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                    ],
                  }}
                  width={20}
                  height={10}
                  options={{
                    annotation: {
                      annotations: [
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 5,
                          backgroundColor: `rgb(149, 175, 192)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 4,
                          backgroundColor: `rgb(235, 47, 6)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 3,
                          backgroundColor: `rgb(167, 9, 9)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 2,
                          backgroundColor: `rgb(34, 112, 147)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 1,
                          backgroundColor: `rgb(23, 162, 184)`,
                        },
                      ],
                    },
                    legend: {
                      display: true,
                      onClick: null,
                      labels: {
                        filter: function (item) {
                          if (item.text == `Tier`) {
                            return false;
                          } else {
                            return item;
                          }
                        },
                      },
                    },

                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                          if (tooltipItem.yLabel === 0.5) {
                            label += ` - LOW RISK`;
                          } else if (tooltipItem.yLabel === 1.5) {
                            label += ` - MEDIUM RISK`;
                          } else if (tooltipItem.yLabel === 2.5) {
                            label += ` - HIGH RISK`;
                          } else if (tooltipItem.yLabel === 3.5) {
                            label += ` - VERY HIGH RISK`;
                          } else if (tooltipItem.yLabel === 4) {
                            label += ` - NO DATA`;
                          }
                          return label;
                        },
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            display: true,
                            min: 0,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Months`,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: `#0000`,
                            // borderDash: [5, 3],
                          },
                          ticks: {
                            display: false,
                            min: 0,
                            // suggestedMin: 0,
                            // suggestedMax: 4
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Tier`,
                          },
                        },
                      ],
                    },
                  }}
                />
              </td>
            </tr>

            {/* AHI */}
            <tr>
              <td colSpan="2" className="pt-0 pb-0">
                <h5 className="mb-0" style={{ color: `black` }}>
                  AHI:
                </h5>
              </td>
            </tr>

            {/* AHI Graph */}
            <tr>
              <td colSpan="2">
                <Line
                  data={{
                    labels: ahiLabels,
                    datasets: [
                      {
                        label: `AHI`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `#141E27`,
                        borderColor: `#141E27`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `#141E27`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `#141E27`,
                        pointHoverBorderColor: `#141E27`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: [...dataSetAHI],
                      },
                      {
                        label: `HIGH RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(167, 9, 9)`,
                        borderColor: `rgb(167, 9, 9)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(167, 9, 9)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(167, 9, 9)`,
                        pointHoverBorderColor: `rgb(167, 9, 9)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `MEDIUM RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(34, 112, 147)`,
                        borderColor: `rgb(34, 112, 147)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(34, 112, 147)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(34, 112, 147)`,
                        pointHoverBorderColor: `rgb(34, 112, 147)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `LOW RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(23, 162, 184)`,
                        borderColor: `rgb(23, 162, 184)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(23, 162, 184)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(23, 162, 184)`,
                        pointHoverBorderColor: `rgb(23, 162, 184)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                    ],
                  }}
                  width={20}
                  height={10}
                  options={{
                    legend: {
                      display: true,
                      onClick: null,
                      labels: {
                        filter: function (item) {
                          if (item.text == `AHI`) {
                            return false;
                          } else {
                            return item;
                          }
                        },
                      },
                    },
                    annotation: {
                      annotations: [
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 30,
                          backgroundColor: `rgb(167, 9, 9)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 15,
                          backgroundColor: `rgb(34, 112, 147)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 5,
                          backgroundColor: `rgb(23, 162, 184)`,
                        },
                      ],
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            display: true,
                            min: 0,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Months`,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: `#0000`,
                            // borderDash: [5, 3],
                          },
                          ticks: {
                            display: true,
                            min: 0,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Events per hour`,
                          },
                        },
                      ],
                    },
                  }}
                />
              </td>
            </tr>
            {/* Usage */}
            <tr>
              <td colSpan="2" className="pt-0 pb-0">
                <h5 className="mb-0" style={{ color: `black` }}>
                  Usage:
                </h5>
              </td>
            </tr>
            {/* Usage Graph */}
            <tr>
              <td colSpan="2">
                <Line
                  data={{
                    labels: usageLabels,
                    datasets: [
                      {
                        label: `Usage`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `#141E27`,
                        borderColor: `#141E27`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `#141E27`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `#141E27`,
                        pointHoverBorderColor: `#141E27`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: [...dataSetUsage],
                      },
                      {
                        label: `HIGH RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(167, 9, 9)`,
                        borderColor: `rgb(167, 9, 9)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(167, 9, 9)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(167, 9, 9)`,
                        pointHoverBorderColor: `rgb(167, 9, 9)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `MEDIUM RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(34, 112, 147)`,
                        borderColor: `rgb(34, 112, 147)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(34, 112, 147)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(34, 112, 147)`,
                        pointHoverBorderColor: `rgb(34, 112, 147)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `LOW RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(23, 162, 184)`,
                        borderColor: `rgb(23, 162, 184)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(23, 162, 184)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(23, 162, 184)`,
                        pointHoverBorderColor: `rgb(23, 162, 184)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                    ],
                  }}
                  width={20}
                  height={10}
                  options={{
                    legend: {
                      display: true,
                      onClick: null,
                      labels: {
                        filter: function (item) {
                          if (item.text == `Usage`) {
                            return false;
                          } else {
                            return item;
                          }
                        },
                      },
                    },
                    annotation: {
                      annotations: [
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 12,
                          backgroundColor: `rgb(23, 162, 184)`,
                        },

                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 6,
                          backgroundColor: `rgb(34, 112, 147)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 4,
                          backgroundColor: `rgb(167, 9, 9)`,
                        },
                      ],
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {

                          const hours = Math.floor(tooltipItem.yLabel);
                          const minutes = Math.round(((tooltipItem.yLabel) - hours) * 60);

                          let label = `Usage: ${hours}h ${minutes}m`;

                          return label;
                        },
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            display: true,
                            min: 0,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Months`,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: `#0000`,
                            // borderDash: [5, 3],
                          },
                          ticks: {
                            display: true,
                            min: 0,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Hours`,
                          },
                        },
                      ],
                    },
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}


