import { Collapse, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { CHECK_EMAIL_AND_LAST_NAME, postRequest } from 'src/crud/crud';
import { extractUserInfo } from 'src/utils';
import { BootstrapSpinner } from '../Bootstrapspinner/BootstrapSpinner';
import { CustomBtn } from '../Button/CustomBtn';
import { SearchField } from '../SearchField/SearchField';
import { errorToast, successToast } from '../snackBar/toast';

export const EmailAndLastNameCheck = ({ action, succesElem, isMultiple, onblurEmail,
  hasSearchValidation = false }) => {
  const [values, setValues] = useState({
    email: ``,
    lastName: ``,
  });
  const [userData, setUserData] = useState(``);
  const [errors, setErrors] = useState({ email: ``, lastName: `` })
  const [isChecking, setisChecking] = useState(false);
  const { email, lastName } = values;
  const onChangeHandler = (e, key) => {
    let { value } = e.target;
    setValues((p) => ({ ...p, [key]: value }));
  };

  useEffect(() => {
    return () => {
      setUserData(``);
    };
  }, []);
  return userData ? (
    succesElem
  ) : (
    <Collapse orientation="vertical" mountOnEnter unmountOnExit in={!userData}>
      <Grid container rowSpacing={2.5} columnSpacing={2}>
        <ToastContainer />
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <SearchField
            fieldProps={{
              ...(hasSearchValidation && {
                error: errors.email,
                helperText: errors.email,
              }),
              size: `small`,
              fullWidth: true,
              label: `Email`,
              onChange: (e) => onChangeHandler(e, `email`),
              InputLabelProps: {
                style: {
                  left: `-1.5%`,
                },
              },
              ...(onblurEmail && {
                onBlur: ({ target: { value } }) => onblurEmail({ value, setError: (value) => setErrors((p) => ({ ...p, email: value })) })
              })
            }}
            action={false}
            secParam={(value) => {
              return {
                userName: value,
              };
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <SearchField
            fieldProps={{
              size: `small`,
              fullWidth: true,
              label: `LastName`,
              onChange: (e) => onChangeHandler(e, `lastName`),
              InputLabelProps: {
                style: {
                  left: `-1.5%`,
                },
              },
            }}
            action={false}
            secParam={(value) => {
              return {
                userName: value,
              };
            }}
          />
        </Grid>
        <Grid sx={{ display: `flex`, justifyContent: `end` }} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <CustomBtn
            element={
              isChecking ? (
                <BootstrapSpinner
                  spinnerProps={{
                    className: `spinner-border spinner-border-sm text-primary`,
                  }}
                />
              ) : (
                `Check`
              )
            }
            btnProps={{
              style: { width: `20%`, borderRadius: `20px` },
              type: `button`,
              className: `btn btn-outline-info btn-sm`,
              onClick: async () => {
                if (!email || !lastName) {
                  errorToast(`Please enter both values`);
                } else if (errors.email || errors.lastName) {
                  errorToast(`Please make sure you have entered correct values in the fields.`);
                } else {
                  setisChecking(true);
                  postRequest(CHECK_EMAIL_AND_LAST_NAME, {
                    email: email,
                    lastName: lastName,
                  })
                    .then(async (res) => {
                      setisChecking(false);
                      successToast(`Available`);
                      if (action) {
                        let info = await extractUserInfo(res?.data?.user);
                        action(isMultiple ? info : res?.data?.user?.email, `EXISTING`);
                      }
                      setUserData(res?.data?.user?.email);
                    })
                    .catch(() => {
                      setisChecking(false);
                    });
                }
              },
            }}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};
