import React, { useState, useEffect } from 'react';
import { ANALYTICS_NO_OF_PATIENTS, postRequest } from '../../../../../../../crud/crud';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import { Box, Grid, Zoom, createMuiTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { fromDateChangeHandler, pickerPropsHandler, toDateChangeHandler } from 'src/pages/home/modules/settings/Analytics/utilities';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { Filterbutton } from 'src/pages/home/modules/settings/Analytics/Components/Filterbutton';
import { analyticsUrlHandler, doIfArr, startDateAnalytics } from 'src/utils';
import { momentWrapper } from 'src/momentWrapper';
import moment from 'moment';

export const PatientVolume = ({ clicked }) => {
  const [loading, setLoading] = useState({ loading: false, error: false });
  const [fromDate, setFromDate] = useState(startDateAnalytics());
  const [toDate, setToDate] = useState(moment(new Date()).endOf(`month`));
  const [filterBtn, setFilterBtn] = useState(false);
  const [graphLabels, setGraphLabels] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [showMsg, setErrorMsg] = useState(false);

  const breakpointValues = {
    xxs: 0,
    xs: 400,
    sm: 600,
    md: 900,
    lg: 1080,
    mdl: 1200,
    xl: 1400,
  };
  const theme = createMuiTheme({ breakpoints: { values: breakpointValues } });

  const matches = useMediaQuery(theme.breakpoints.down(`lg`));

  const matchesMd = useMediaQuery(theme.breakpoints.down(`mdl`));
  const view = useSelector((state) => state?.analytics?.view);

  const apiCall = async (view) => {
    let labelsTempArray = [];
    let dataTempArray = [];
    setLoading({ loading: true, error: false });

    setGraphLabels([]);
    setGraphData([]);
    let filters = await analyticsUrlHandler(view);
    // concerting year & month back to ISO strings w.r.t practice timezone
    postRequest(ANALYTICS_NO_OF_PATIENTS + `?filters=${filters}`, {
      startDate: momentWrapper(fromDate).startOf(`month`).toISOString(),
      endDate: momentWrapper(toDate).endOf(`month`).toISOString(),
    })
      .then((res) => {
        setLoading({ loading: false, error: false });
        doIfArr(res?.data?.numOfPatientsPerMonthAnalytics, () => {
          for (const data of res.data.numOfPatientsPerMonthAnalytics) {
            labelsTempArray.push(data.monthName);

            dataTempArray.push(data.patientsCount);
          }
        });
        setGraphLabels([...labelsTempArray]);
        setGraphData([...dataTempArray]);
      })
      .catch(() => {
        setLoading({ loading: false, error: true });
        setFilterBtn(false);
      });
  };

  useEffect(() => {
    apiCall(view);
  }, [view]);

  return (
    <Zoom in={clicked} unmountOnExit timeout={500}>
      <Box
        in={clicked}
        timeout={100}
        sx={{
          backgroundColor: `white`,
          boxShadow: `0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 16px 0 rgba(0, 0, 0, 0.05) `,
          borderRadius: `8px`,
          height: matches || matchesMd ? 520 : 500,
        }}
      >
        <h4 className="px-3 pt-3 pb-2 mb-0 mb-0" style={{ fontWeight: 400 }}>
          Patient Volume:
        </h4>
        {showMsg ? (
          <span className="ml-3" style={{ color: `red`, fontSize: `12px` }}>
            * Invalid date selection
          </span>
        ) : null}
        <Grid container sx={{ p: 2 }} rowSpacing={1} columnSpacing={1}>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`From`),
              }}
              dateInputValue={fromDate}
              onChange={(e) => fromDateChangeHandler(e, setFilterBtn, setErrorMsg, setFromDate, toDate)}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Box sx={{ p: 0.7 }}>-</Box>
          </Grid>
          <Grid item xs={5.5} sm={4} md={5.5} lg={4} xl={4}>
            <DatePickerCustomize
              pickerProps={{
                ...pickerPropsHandler(`To`),
              }}
              maxDate={true}
              dateInputValue={toDate}
              onChange={(e) => toDateChangeHandler(e, setFilterBtn, setErrorMsg, fromDate, setToDate)}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={12} lg={3} xl={3}>
            <Filterbutton
              arg={{ payLoad: {}, setData: null, setLoading: null }}
              apiCall={() => apiCall(view)}
              filterBtn={filterBtn}
              loading={loading}
              ownApi={true}
            />
          </Grid>
        </Grid>
        <Box sx={{ height: 320, m: 3 }}>
          <Bar
            data={{
              labels: graphLabels,
              datasets: [
                {
                  label: `Total # of Patients Enrolled`,
                  backgroundColor: `#1699C5`,
                  data: graphData,
                },
              ],
            }}
            width={100}
            height={300}
            options={{
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: false,
                    },
                    scaleLabel: {
                      display: true,
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: `# of Patients`,
                    },
                    type: `linear`,
                  },
                ],
              },
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                position: `top`,
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Zoom>
  );
};
