import React, { useState, useEffect } from 'react';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { Box, Grid } from '@mui/material';
import logo from '../../../assets/monitair-logo.svg';
import css from './signup.module.css';
import { PATIENT_SIGNUP, postRequest } from '../../../crud/crud';
import { PulseSpinner } from '../../common/components/spinner/spinner';
import { withRouter } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/success-check';
import playStore from '../../../../src/assets/play-store-logo.png';
import appStore from '../../../../src/assets/app-store-logo.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { FooterWrapper } from 'src/pages/common/HOC/FooterWrapper';
import { AcceptTermsPrivacyPolicy } from '../verify/acceptTermsPrivacyPolicy';

export const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: `xMidYMid slice`,
  },
};

const Signup = (props) => {
  const [successAccountCreated, setSuccessAccountCreated] = useState(false);
  const [patientId, setPatientId] = useState(``);
  const [userName, setUserName] = useState(``);
  const [errorResponse, setErrorResponse] = useState(``);
  const [userNameError, setUserNameError] = useState({
    status: false,
    msg: ``,
  });
  const [passwordError, setPasswordError] = useState({
    status: false,
    msg: ``,
  });
  const [reConfirmationPasswordError, setReconfirmationPasswordError] = useState({
    status: false,
    msg: ``,
  });
  const [passwordRequirementsError, setPasswordRequirementsError] = useState(false);
  const [password, setPassword] = useState(``);
  const [confirmPassword, setConfirmPassword] = useState(``);
  const [loading, setLoading] = useState(false);
  const [agreeWithTermsAndPolicy, setAgreeWithTermsAndPolicy] = useState(false);
  const [agreeError, setAgreeError] = useState(``);
  const [modal, setModal] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: `xMidYMid slice`,
    },
  };

  useEffect(() => {
    setPatientId(props.match.params.id);
  }, []);

  const theme = useTheme();

  const isSmaller = useMediaQuery(theme.breakpoints.down(`sm`));

  // OnBlur Call This Function.
  const validateInput = () => {
    let tempForUsername = { status: false, msg: `` };
    if (!userName) {
      tempForUsername.status = true;
      tempForUsername.msg = `Username is required`;
      setUserNameError(tempForUsername);
    } else {
      setUserNameError(tempForUsername);
    }

    let tempForPassword = { status: false, msg: `` };
    if (!password) {
      tempForPassword.status = true;
      tempForPassword.msg = `Password is required`;
      setPasswordError(tempForPassword);
    } else {
      setPasswordError(tempForPassword);
    }
  };

  // Login Handler
  const loginHandler = (e) => {
    e.preventDefault();
    setErrorResponse(``);

    let tempForConfirmation = { status: false, msg: `` };
    if (password !== confirmPassword) {
      tempForConfirmation.status = true;
      tempForConfirmation.msg = `Those passwords didn’t match. Try again.`;
      setReconfirmationPasswordError(tempForConfirmation);
      setPasswordRequirementsError(false);
      return;
    } else {
      setReconfirmationPasswordError(tempForConfirmation);
      setPasswordRequirementsError(false);
    }

    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[.,#?!@$%^&*_]).{8,}$/;
    if (!regex.test(password) && !regex.test(confirmPassword)) {
      setPasswordRequirementsError(true);
      return;
    }

    if (!agreeWithTermsAndPolicy) {
      setAgreeError(`Please accept privacy policies`);
      return;
    }

    // Check username status is true to stop the API call.
    if (userNameError?.status) {
      return;
    }

    setLoading(true);
    setPasswordRequirementsError(false);
    // API Call
    const signObject = {
      userName: userName,
      password: password,
      confirmPassword: confirmPassword,
      id: patientId,
    };
    postRequest(PATIENT_SIGNUP, { ...signObject })
      .then(() => {
        setSuccessAccountCreated(true);
        setLoading(false);
      })
      .catch((err) => {
        setErrorResponse(err?.response?.data?.error);
        setLoading(false);
      });
  };

  const passwordRequirementsErrorMessage = passwordRequirementsError ? (
    <div className='row'>
      <div className="col-06">
        <div
          className='p-3 border border-1 shadow-sm text-danger bg-black'
          style={{
            background: `#ffd2d2`,
            padding: 3,
            border: 1,
            color: `black`,
            boxShadow: `box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;`,
          }}
        >
          <p>Please enter a password that meets the following criteria:</p>
          <p>-At least 8 characters.</p>
          <p>-A mix of both uppercase and lowercase letters.</p>
          <p>-A mix of letters and numbers.</p>
          <p>-Include at least one special character (e.g., ! @ # ? ).</p>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <FooterWrapper showOnlytLiscense={true}>
      <Box>
        <div id={css.container}>
          <img id={css.logo} src={logo} alt="Logo" />
          {userNameError.status ? null : passwordRequirementsErrorMessage}
          <form id={css.form} onSubmit={loginHandler}>
            {/* Input For Username */}
            <InputField
              fullWidth={true}
              sx={{ mt: 2 }}
              InputLabelProps={{
                style: {
                  left: `-1.5%`,
                },
              }}
              size={`small`}
              helperText={userNameError.msg}
              error={!!userNameError.status}
              type={`text`}
              label={`Username`}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              onBlur={() => validateInput()}
              value={userName}
            />

            {/* Input For Password */}
            <InputField
              fullWidth={true}
              sx={{ mt: 2 }}
              InputLabelProps={{
                style: {
                  left: `-1.5%`,
                },
              }}
              size={`small`}
              helperText={passwordError.msg}
              error={!!passwordError.status}
              type={`password`}
              label={`Password`}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onBlur={() => validateInput()}
              value={password}
            />

            {/* Input For Confirm Password */}
            <InputField
              fullWidth={true}
              sx={{ mt: 2 }}
              InputLabelProps={{
                style: {
                  left: `-1.5%`,
                },
              }}
              size={`small`}
              helperText={reConfirmationPasswordError.msg}
              error={!!reConfirmationPasswordError.status}
              type={`password`}
              label={`Confirm Password`}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              value={confirmPassword}
            />

            {/* Terms And Condition */}
            <div id={css.remember}>
              <label style={{ cursor: `pointer`, color: `blue` }} onClick={() => setModal(true)}>
                Accept Terms of Use and Privacy Policy
              </label>
            </div>

            <AcceptTermsPrivacyPolicy modal={modal} setModal={() => setModal(false)} setAgreeWithTermsAndPolicy={setAgreeWithTermsAndPolicy} />

            {/* Agree Terms And Condition Error*/}
            {!agreeWithTermsAndPolicy && <div style={{ color: `red`, fontSize: `12px` }}>{agreeError}</div>}

            {/* Button For Submit The Account */}
            <div id={css.buttonHolder}>
              <button type="submit" id={css.button} onClick={() => loginHandler}>
                Create Account
              </button>
            </div>

            {/* API failure Response */}
            {errorResponse?.length > 0 && (
              <div style={{ color: `red`, fontSize: `12px` }}>
                <center>{errorResponse}</center>
              </div>
            )}
          </form>
        </div>

        <CustomizedDialogs
          noTitle={true}
          open={successAccountCreated}
          setOpen={() => setSuccessAccountCreated(false)}
          size="sm"
          fullWidth={true}
          showCloseButton={false}
        >
          <Lottie options={defaultOptions} height={200} width={200} />
          <h4 style={{ textAlign: `center` }}>Success!</h4>
          <h5 style={{ textAlign: `center` }}>Thank you for registering with MonitAir, your account is now active.</h5>
          <Grid container columnSpacing={isSmaller ? 2 : 4}>
            <Grid item sx={{ textAlign: `end` }} xs={6} sm={6} md={6} lg={6} xl={6}>
              <a
                style={{ cursor: `pointer` }}
                href="https://play.google.com/store/apps/details?id=com.ars_monitair.healthapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img style={{ height: 125, marginTop: 5 }} src={playStore} alt="logo" />
              </a>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <a
                style={{ cursor: `pointer` }}
                href="https://apps.apple.com/us/app/monitair-health/id1589404993"
                target="_blank"
                rel="coopered noreferrer"
              >
                {` `}
                <img style={{ height: 136, padding: 0 }} src={appStore} alt="logo" />
              </a>
            </Grid>
          </Grid>
        </CustomizedDialogs>
        {loading ? <PulseSpinner /> : null}
      </Box>
    </FooterWrapper>
  );
};

export default withRouter(Signup);
