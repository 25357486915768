import React, { useState } from 'react';
import { Box, Grid, Collapse } from '@material-ui/core';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { patientStatusFilterFields } from 'src/utils';

export const FilterInputFields = ({ showFilterFields, onSelectedStatusChange }) => {
  const [selectedStatus, setSelectedStatus] = useState(``);

  const handleStatusChange = (event) => {
    const newSelectedStatus = event.target.value;
    setSelectedStatus(newSelectedStatus);
    onSelectedStatusChange(newSelectedStatus); // Call the callback function
  };

  return (
    <Collapse in={showFilterFields}>
      <Grid container>
        <Grid item xs={4} sm={4} md={4}>
          <Box sx={{ display: 'flex', gap: 0, pb: 6, ml: 6 }}>
            <MuiSelect
              controlProps={{ size: `small`, fullWidth: true, id: `patientStatusFilterFields` }}
              label="Patient Status Search"
              isSimple={false}
              options={[...patientStatusFilterFields]}
              optionKey={`label`}
              optionValue={`value`}
              selectProps={{
                label: `Patient Status Search`,
                id: `patientStatusFilterFields`,
                name: `selectedStatus`,
                onChange: handleStatusChange,
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: '420px', // set the maximum height of the options
                      width: '280px', // set the width of the options
                    },
                  },
                },
                value: selectedStatus,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Collapse>
  );
};