import React from 'react'
import { useSelector } from 'react-redux';
import { useBrowserConfirmation } from 'src/pages/common/hooks/useBrowserConfirmation'

export const TimerConfimation = () => {
    const { hasSwitchedPractice } = useSelector((state) => state.user);
    const timer = useSelector((state) => state?.patient?.timer)
    let confirm = useBrowserConfirmation({ condition: !hasSwitchedPractice && timer?.toggleButton ? true : false })
    return (
        <div>{confirm}</div>
    )
}
