import React from 'react';
import { useSelector } from 'react-redux';
import { CANCEL_SUBSCRIPTION, postRequest } from 'src/crud/crud';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import Swal from 'sweetalert2';
import css from './listAllUsers.module.css';

export const MuiSelectForActiveAndInActivePractice = () => {

  const { stripeSubscriptionId: subscriptionId } = useSelector((state) => state?.practice?.practiceInfo || {});

  const alertForConfirmation = () => {
    return (
      Swal.fire({
        title: `Attention!`,
        text: `This will inactive practice and cancel subscription.`,
        footer: `<strong>Status active will cause a new subscription to create.</strong>`,
        icon: `warning`,
        showCancelButton: true,
        confirmButtonColor: `#3085d6`,
        cancelButtonColor: `#d33`,
        confirmButtonText: `Yes`,
      }).then((result) => {
        if (result?.isConfirmed) {
          postRequest(CANCEL_SUBSCRIPTION, {
            subscriptionId,
          }).then(() => {
            successToast(`Successfully Inactivated`);
          }).catch((error) => { })
        }
      })
    );
  };

  return (
    <button
      className="btn btn-outline-info"
      id={css.headButton}
      type="button"
      onClick={() => {
        alertForConfirmation()
      }}
    >
      InActive Practice
    </button>
  )
};
