import { Box, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { GET_ALL_VOICE_MAILS, UPDATE_PATIENT_UNREAD_NOTIFICATION_STATUS, patchRequest, postRequest } from 'src/crud/crud';
import css from './PatientVoiceMails.module.css';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { PuffSpinner } from 'src/pages/common/components/spinner/puff/puff';
import WarningIcon from '@mui/icons-material/Warning';
import { useLocation } from 'react-router-dom';
import { momentWrapper } from 'src/momentWrapper';
import { REMOVE_VM_NOTIFICATION } from 'src/redux/twilio/twilioTypes';

export const PatientVoiceMails = () => {
  const [voiceMailList, setVoiceMailList] = useState([]);
  const [loadingState, setLoading] = useState({ loading: false, error: false });
  const { patient } = useSelector((state) => state);
  const { isSwitchingPatient } = patient;
  const { loading, error } = loadingState;
  const loadingSetter = ({ key = false, value = false, isWhole = false }) => {
    setLoading((p) => {
      if (isWhole) return { ...isWhole };
      return { ...p, [key]: value };
    });
  };
  const { patientId: patientRedir = '' } = useLocation();
  let patientId = patient?.patientData?.data?._id;

  useEffect(() => {
    if ((!isSwitchingPatient && patientId) || patientRedir) {
      loadingSetter({ isWhole: { loading: true, error: false } });
      postRequest(GET_ALL_VOICE_MAILS, {
        patientId: patientId,
        notifyOnly: false,
      })
        .then((res) => {
          const { data = {} } = res;
          const { voicemails = [] } = data;
          loadingSetter({ key: 'loading', value: false });
          setVoiceMailList(voicemails);
        })
        .catch(() => {
          loadingSetter({ isWhole: { loading: false, error: true } });
        });
    }
  }, [isSwitchingPatient, patientId, patientRedir]);
  console.log(loading, 'voiceMailList', voiceMailList, error);
  return (
    <Box sx={{ height: '88%', overflowY: 'scroll', overflowX: 'hidden', pt: 3, pb: 3 }}>
      {loading && <PuffSpinner />}
      {error && (
        <Box sx={{ textAlign: 'center' }}>
          <WarningIcon sx={{ color: 'red', fontSize: 'xxx-large' }} />
        </Box>
      )}
      {!loading &&
        !error &&
        voiceMailList?.map((voiceMail) => <Audio patientId={patient?.patientData?.data?._id} voiceMail={voiceMail} key={voiceMail?.media} />)}
    </Box>
  );
};

const Audio = ({ voiceMail, patientId }) => {
  const { media: audSrc = '' } = voiceMail;
  const contRef = useRef(null);
  const audioRef = useRef(null);
  const [hasRead, setHasRead] = useState(false);
  const isPlayed = useRef(0);
  const dispatch = useDispatch();
  useEffect(() => {
    if (audioRef?.current)
      audioRef.current.onplaying = () => {
        isPlayed.current = isPlayed.current + 1;
        if (isPlayed?.current === 1 && !voiceMail?.isMediaRead) {
          patchRequest(`${UPDATE_PATIENT_UNREAD_NOTIFICATION_STATUS}${patientId}`, {
            vmId: voiceMail?._id,
          })
            .then(() => {
              setHasRead(true);
              dispatch({ type: REMOVE_VM_NOTIFICATION, payLoad: voiceMail?._id });
            })
            .catch();
        }
      };
  }, [audioRef, isPlayed]);

  useEffect(() => {
    if (contRef.current) contRef.current.scrollIntoView({ behavior: 'smooth' });
    setHasRead(voiceMail?.isMediaRead);
    return () => {
      isPlayed.current = 0;
      audioRef.current = null;
      setHasRead(false);
    };
  }, []);
  return (
    <Box ref={contRef} sx={{ backgroundColor: 'rgba(119, 223, 218, 0.5)', m: 1, borderRadius: 3, p: 1 }}>
      <Box>
        <audio ref={audioRef} className={css.audio} controls>
          <source src={audSrc} type={`audio/ogg`} />
          <source src={audSrc} type="audio/ogg" />
        </audio>
      </Box>
      <Box>
        <Stack justifyContent={hasRead ? 'space-between' : 'end'} direction="row" alignItems={'end'}>
          <ConditionallyRenderWrapper con={hasRead}>
            <Box>
              <DoneAllSharpIcon sx={{ color: 'green' }} />
            </Box>
          </ConditionallyRenderWrapper>
          <Box sx={{ color: 'black', textAlign: 'end', fontWeight: 600, fontSize: '15px' }}>
            <ConditionallyRenderWrapper con={voiceMail?.createdAt} renderKey={'-'}>
              {momentWrapper(voiceMail?.createdAt).format('MM/DD/YYYY hh:mm A')}
            </ConditionallyRenderWrapper>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
