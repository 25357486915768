import React, { useState, useEffect, useMemo, useRef } from 'react';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import {
  Grid,
  TextField,
  Typography,
  Box,
  MenuItem,
  Collapse,
  LinearProgress,
  InputAdornment,
  Zoom,
  Fade,
  Stack,
  IconButton,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stepper,
  StepLabel,
  Step,
} from '@mui/material';
import { Controller, useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import InputMask from 'react-input-mask';
import css from './addNewPractice.module.css';
import {
  GET_ALL_GROUP_OPTIONS,
  GROUP_PRACTICE_REGISTER_URL,
  PRACTICE_REGISTER_URL,
  postRequest,
  CHECK_PRICTICE_GROUP_AVAILABILITY,
  getRequest,
  CHECK_PRICTICE_CCREDENTIALS_AVAILABILITY,
  CHECK_EMAIL_USERNAME_AVAILABILITY_URL,
} from '../../../../../../crud/crud';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { ToastContainer, toast } from 'react-toastify';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { Asktwillio } from './Asktwillio';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import { keyframes } from '@emotion/react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertToBase64,
  providerType,
  usStateName,
  practiceSpeciality,
  practiceType,
  capitalizeFirstLetter,
  purifyInputValue,
  faxNumberValidation,
  printInBrowser,
  npiMaskHandler,
  defaultValidationPhrase,
  invalidNoGiver,
  unslelectedProvidersPhrase,
  setValuesFromArrRegPrac,
  returnIfArr,
} from 'src/utils';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import { refreshPracticeGroup } from 'src/redux/PracticeGroups/Actions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { momentWrapper } from 'src/momentWrapper';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import CloseIcon from '@mui/icons-material/Close';
import { RegisterProvider } from './RegisterProvider';
import { AskForConfirmation } from 'src/pages/common/components/AskForconfirmation/AskForConfirmation';
import { EmailAndLastNameCheck } from 'src/pages/common/components/EmailAndLastNameCheck/EmailAndLastNameCheck';
import { emailRegex, npiRegex } from 'src/regexes';
const steps = [`Add Group`, `Group Admin Information`, `Practice Information `, `Practice Phone Number`];
const groupAdminSteps = [``, `Admin Information`, `Practice Information`, `Providers Information`, `Practice Phone Number`];

//<> mapping into optionsArray for better user interface with the usage of MUI
export const providerTypeOptions = providerType.map((e) => (
  <MenuItem key={e.name} value={e.name}>
    {e.name}
  </MenuItem>
));
export const stateNameOptions = usStateName.map((e) => (
  <MenuItem key={e.name} value={e.name}>
    {e.name}
  </MenuItem>
));
export const practiceSpecialityOptions = practiceSpeciality.map((e) => (
  <MenuItem key={e.name} value={e.name}>
    {e.name}
  </MenuItem>
));
export const practiceTypeOptions = practiceType.map((e) => (
  <MenuItem key={e.name} value={e.name}>
    {e.name}
  </MenuItem>
));
//</> mapping into optionsArray for better user interface with the usage of MUI

const Role = ({
  append,
  getValues,
  setValue,
  clearErrors,
  setadminAdditionalRole,
  remove,
  setpracticeLogoUrl,
  auth,
  emailRegex,
  errorss,
  practiceGroupsLoading,
  control,
  adminAdditionalRole,
  setError,
  setshoulAddProviders,
  unregister,
}) => {
  const [roleValue, setRoleValue] = useState(``);
  const providersValues = getValues(`providers`);
  useEffect(() => {
    if (roleValue === `provider`) remove();
    else {
      if (!providersValues?.length && adminAdditionalRole !== `provider`) {
        append({
          firstName: ``,
          lastName: ``,
          phoneNumber: ``,
          email: ``,
          providerType: ``,
          licenseState: ``,
          NPI: ``,
          medicalLicense: ``,
        });
      }
    }
  }, [roleValue]);

  const setadminAdditionValue = (value) => {
    let adminStatus = getValues(`superAdminStatus`);
    if (roleValue === value && adminStatus === `NEW`) {
      setValue(`adminProviderLicenseState`, ``);
      setValue(`adminProviderLicenseno`, ``);
      setValue(`adminProviderNPI`, ``);
      setValue(`adminProviderType`, ``);
      clearErrors(`adminProviderNPI`);
      clearErrors(`adminProviderLicenseState`, ``);
      clearErrors(`adminProviderLicenseno`, ``);
      clearErrors(`adminProviderType`, ``);
    }
  };
  const addtionalValuesSetter = (isClear) => {
    if (isClear) {
      setValue(`adminProviderLicenseState`, ``);
      setValue(`adminProviderLicenseno`, ``);
      setValue(`adminProviderType`, ``);
      setValue(`adminProviderNPI`, ``);
    } else {
      let adminStatus = getValues(`superAdminStatus`);
      let getAdditionalValues = getValues(`addtionalValues`);

      if (adminStatus === `EXISTING`) {
        let getAdditionalValues = getValues(`addtionalValues`);

        if (getAdditionalValues?.licenseState) {
          setValue(`adminProviderLicenseState`, getAdditionalValues?.licenseState);
        }
        if (getAdditionalValues?.medicalLicense) {
          setValue(`adminProviderLicenseno`, getAdditionalValues?.medicalLicense);
        }
        if (getAdditionalValues?.providerType) {
          setValue(`adminProviderType`, getAdditionalValues?.providerType);
        }
        if (getAdditionalValues?.NPI) {
          setValue(`adminProviderNPI`, getAdditionalValues?.NPI);
        }
      }
    }
  };
  return (
    <>
      {` `}
      <Grid sx={{ mt: 1 }} container>
        <Grid item xs={62} sm={3} md={3} lg={3} xl={3}>
          {` `}
          <label htmlFor="addtionRole">Additional Role</label>
        </Grid>
        <Grid item xs={62} sm={3} md={3} lg={3} xl={3}>
          <input
            className={css.radio}
            onChange={(e) => {
              addtionalValuesSetter(false);
              setadminAdditionalRole(e.target.value);
              setRoleValue(e.target.value);
              // remove();
              setadminAdditionValue(`clinical staff`);
            }}
            type="radio"
            name="roleCheck"
            id="roleCheck"
            value="provider"
            checked={roleValue === `provider` || adminAdditionalRole === `provider` ? true : false}
          />
          <label className={css.roleLabel}>Provider</label>
        </Grid>
        <Grid item xs={62} sm={3} md={3} lg={3} xl={3}>
          <input
            className={css.radio}
            onChange={(e) => {
              addtionalValuesSetter(false);
              setadminAdditionalRole(e.target.value);
              setRoleValue(e.target.value);
              setshoulAddProviders(false);
              setadminAdditionValue(`provider`);
            }}
            type="radio"
            name="roleCheck"
            id="roleCheck"
            value="clinical staff"
            checked={roleValue === `clinical staff` || adminAdditionalRole === `clinical staff` ? true : false}
          />
          <label className={css.roleLabel}>Clinical Staff</label>
        </Grid>
        {` `}
        <Grid item xs={62} sm={3} md={3} lg={3} xl={3}>
          <Collapse mountOnEnter unmountOnExit in={roleValue?.length > 0 || adminAdditionalRole} timeout={500}>
            <Stack sx={{ width: `100%` }} direction="row">
              <Box>
                <input
                  className={css.radio}
                  onChange={(e) => {
                    addtionalValuesSetter(true);
                    setadminAdditionalRole(e.target.value);
                    setRoleValue(e.target.value);
                    setshoulAddProviders(false);
                    unregister([`adminProviderNPI`, `adminProviderType`, `adminProviderLicenseno`, `adminProviderLicenseState`]);
                  }}
                  type="radio"
                  name="roleCheck"
                  id="roleCheck"
                  value=""
                />
              </Box>
              <Box sx={{ width: `100%` }}>
                <label className={css.roleLabel}>No Additional role</label>
              </Box>
            </Stack>
          </Collapse>
        </Grid>
      </Grid>
      <Collapse in={roleValue?.length > 0 || adminAdditionalRole} timeout={700} orientation="vertical">
        {roleValue?.length > 0 || adminAdditionalRole ? (
          <Grid container columnSpacing={1.5} rowSpacing={1.5}>
            <Grid item xs={62} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ borderBottom: `1px solid rgb(0 0 0 / 13%)` }}>
                <Typography variant="h6" component="div">
                  {roleValue === `clinical staff` ? `Clinical staff Informaion` : `Provider Information`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
              <FormInput
                setpracticeLogoUrl={setpracticeLogoUrl}
                auth={auth}
                emailRegex={emailRegex}
                errorss={errorss}
                practiceGroupsLoading={practiceGroupsLoading}
                name="adminProviderNPI"
                control={control}
                label={`${roleValue === `provider` || adminAdditionalRole === `provider` ? `*NPI` : `NPI`}`}
                type="number"
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                unregister={unregister}
                adminAdditionalRole={adminAdditionalRole || roleValue}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
              <FormInput
                setpracticeLogoUrl={setpracticeLogoUrl}
                auth={auth}
                emailRegex={emailRegex}
                errorss={errorss}
                practiceGroupsLoading={practiceGroupsLoading}
                isSelect={true}
                name="adminProviderType"
                control={control}
                label={`${roleValue === `provider` || adminAdditionalRole === `provider` ? `*Provider Type` : `Provider Type`}`}
                type="text"
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                adminAdditionalRole={adminAdditionalRole || roleValue}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
              <FormInput
                setpracticeLogoUrl={setpracticeLogoUrl}
                auth={auth}
                emailRegex={emailRegex}
                errorss={errorss}
                practiceGroupsLoading={practiceGroupsLoading}
                name="adminProviderLicenseno"
                control={control}
                label={`${roleValue === `provider` || adminAdditionalRole === `provider` ? `*License No.` : `Liscense No.`}`}
                type="text"
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                adminAdditionalRole={adminAdditionalRole || roleValue}
              />
            </Grid>
            <Grid item xs={62} sm={12} md={12} lg={12} xl={12}>
              <FormInput
                setpracticeLogoUrl={setpracticeLogoUrl}
                auth={auth}
                emailRegex={emailRegex}
                errorss={errorss}
                practiceGroupsLoading={practiceGroupsLoading}
                isSelect={true}
                name="adminProviderLicenseState"
                control={control}
                label={`${roleValue === `provider` || adminAdditionalRole === `provider` ? `*LicenseState` : `LicenseState`}`}
                type="text"
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                adminAdditionalRole={roleValue || adminAdditionalRole}
              />
            </Grid>
          </Grid>
        ) : null}
      </Collapse>
    </>
  );
};
const AdminInfo = (props) => {
  const { errors, setpracticeLogoUrl, auth, emailRegex, errorss, practiceGroupsLoading, control, setValue, getValues, setError } = props;

  return (
    <>
      {` `}
      <Grid container columnSpacing={1.5} rowSpacing={1.5}>
        <Grid sx={{ mb: 1 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ borderBottom: `1px solid rgb(0 0 0 / 13%)`, mt: 1 }}>
            <Typography variant="h6" component="div">
              Admin Information
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="adminFirstName"
            control={control}
            label="*First Name"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="adminLasttName"
            control={control}
            label="*Last Name"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          <FormInput
            setpracti
            ceLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="adminTitle"
            control={control}
            label="Title"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="adminPhoneNumber"
            control={control}
            label="*Phone Number"
            type="number"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            error={errors}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="adminEmail"
            control={control}
            label="*Email"
            type="email"
            getValues={getValues}
            setError={setError}
            error={errors}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="adminFax"
            control={control}
            label="Fax"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="adminDateOfBirth"
            control={control}
            label="Date OF Birth"
            type="date"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            dateFormt={`MM/DD/YYYY`}
          />
        </Grid>
      </Grid>
      <Role {...props} />
    </>
  );
};

//<> here you will find each and every input showing on screen
const FormInput = ({
  practiceGroups,
  setpracticeLogoUrl,
  auth,
  emailRegex,
  errorss,
  practiceGroupsLoading,
  isSelect,
  name,
  control,
  label,
  type,
  setValue,
  getValues,
  setError,
  error,
  setgroupName,
  variant,
  clearErrors,
  adminAdditionalRole,
  dateFormt,
}) => {
  const [loading, setLoading] = useState({ loading: false, error: false });

  const queryParams = (queryObj) => {
    let queryString = ``;
    for (const item in queryObj) {
      queryString += `?${item}=${queryObj[item]}`;
      return queryString;
    }
  };

  //<> these are animation for loading and error states from server
  const animate = keyframes`   0% {
    transform: rotate(0deg);
}

50% {
    transform: rotate(360deg);
}
100%{
  transform: scale(0.5,0.5);
}`;

  const animateLoading = keyframes`   0% {
  transform: scale(1,1)
}

50% {
  transform: scale(0.5,0.5)
}
100%{
transform: scale(1,1);
}`;

  //</> these are animation for loading and error states from server
  //<> here i am checking what input is comming from name prop
  let isPhoneNumber = name.includes(`PhoneNumber`);
  let isEmail = name.includes(`Email`);
  let isNpi = name.includes(`NPI`);
  let isFax = name.includes(`Fax`);
  let isType = name.includes(`adminProviderType`);
  let isState = name.includes(`State`);
  let ispracticeType = name.includes(`practiceType`);
  let isPracticeSpeciality = name.includes(`practiceSpeciality`);
  let isExistingGroup = name === `existingGroupName` ? true : false;
  let isGroupName = name === `groupAdminGroupName` ? true : false;
  let isPracticeEmail = name === `practiceEmail` ? true : false;
  let ispracticeNumber = name === `practicePhoneNumber` ? true : false;
  let isPracticeName = name === `practiceName` ? true : false;
  let isNotes = name === `notes` ? true : false;
  let isStreet = name === `practiceStreet` ? true : false;
  let isDate = name?.toLowerCase()?.includes(`dateofbirth`) ? true : false;
  let isTitle = name?.includes(`Title`);
  let isPracticeLogo = name?.includes(`practiceLogo`);
  let isZipCode = name === `practiceZipCode` ? true : false;
  let isName = name?.toLowerCase()?.includes(`firstname`) || name?.toLowerCase()?.includes(`lasttname`);
  let isAdditionalInfo = name?.toLowerCase()?.includes(`adminprovider`) ? true : false;
  let clinicallStaffValidation = [`adminProviderNPI`, `adminProviderType`, `adminProviderLicenseno`, `adminProviderLicenseState`];
  let hasValidatedClinicalStaff = adminAdditionalRole === `clinical staff` && clinicallStaffValidation.find((e) => e === name);

  //</> here i am checking what input is comming from name prop
  let getAdminStatus = getValues(`superAdminStatus`);
  const hasDisabled = useMemo(() => {
    let isDisabled = false;
    let getAdminStatus = getValues(`superAdminStatus`);
    let isNewProviderOrClinicalStaff = getValues(`isNewProviderOrClinicalStaff`);
    if (name?.includes(`admin`) && getAdminStatus === `EXISTING`) {
      if (!isNewProviderOrClinicalStaff) {
        if (isFax || isPhoneNumber || isEmail || isTitle || isDate || isName || isAdditionalInfo) {
          isDisabled = true;
        }
      } else {
        if (isFax || isPhoneNumber || isEmail || isTitle || isDate || isName) {
          isDisabled = true;
        }
      }

      return isDisabled;
    }
  }, [name, isPhoneNumber, isEmail, isTitle, isDate, getValues, isFax, isName, isAdditionalInfo]);

  // <> sending request for availabe entry
  const isAvailable = async (val) => {
    //<> getting values for specific enteries
    let getgroupAdminNumber = getValues(`groupAdminPhoneNumber`);
    printInBrowser({ key: `asasasas`, value: getgroupAdminNumber });
    let getGroupAdminEmail = getValues(`groupAdminEmail`);
    let getAdminNumber = getValues(`adminPhoneNumber`);
    let getAdminEmail = getValues(`adminEmail`);
    let getPracticeEmail = getValues(`practiceEmail`);
    let getPracticdePhoneNumber = getValues(`practicePhoneNumber`);
    //</> getting values for specific enteries
    let { value } = val.target;
    let errors = [];

    async function validateInputs() {
      if (name === `practicePhoneNumber` && getPracticdePhoneNumber?.length > 0) {
        if (value === getgroupAdminNumber || value === getAdminNumber) {
          errors.push(`error`);
          setError(name, { type: `custom`, message: `Oops Already Taken !` });
        }
      }
      if (name === `practiceEmail` && getPracticeEmail?.length > 0) {
        if (value === getAdminEmail || value === getGroupAdminEmail) {
          errors.push(`error`);
          setError(name, { type: `custom`, message: `Oops Already Taken !` });
        }
      }

      if (name === `adminEmail` && getAdminEmail?.length > 0) {
        if (value === getGroupAdminEmail) {
          errors.push(`error`);
          setError(name, { type: `custom`, message: `Oops Already Taken !` });
        }
      }
      if (name === `adminPhoneNumber` && getAdminNumber?.length > 0) {
        if (value === getgroupAdminNumber) {
          errors.push(`error`);
          setError(name, { type: `custom`, message: `Oops Already Taken !` });
        }
      }

      return true;
    }
    let hasValidated = await validateInputs();
    let errorObject = await error;
    if (hasValidated) {
      if (errors.length === 0 && !errorObject?.[name] && !isPracticeEmail && !ispracticeNumber && !isPracticeName) {
        setLoading({ error: false, loading: true });
        postRequest(CHECK_EMAIL_USERNAME_AVAILABILITY_URL, { userName: value })
          .then(() => {
            setLoading({ loading: false, error: false });
            toast.success(() => <>Available</>, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          })
          .catch((e) => {
            setLoading({ loading: false, error: true });
            // if (e.response) {
            if (e.response?.status === 409) {
              setError(name, { type: `custom`, message: `Oops given enrty value already exists!` });
            }
            // else {
            //     toast.error(() => <>Some error occurred try again later</>, {
            //       position: toast.POSITION.BOTTOM_RIGHT,
            //     });
            //   }
            // }
          });
      } else if (errors.length === 0 && !errorObject[name] && value?.length > 0 && (ispracticeNumber || isPracticeEmail || isPracticeName)) {
        let query = `?checkByThisAttribute=${value?.toLowerCase()}`;
        setLoading({ error: false, loading: true });
        getRequest(CHECK_PRICTICE_CCREDENTIALS_AVAILABILITY + query)
          .then(() => {
            setLoading({ loading: false, error: false });
            toast.success(() => <>Available</>, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          })
          .catch((e) => {
            setLoading({ loading: false, error: true });
            if (e.response?.status === 409) {
              setError(name, {
                type: `custom`,
                message: `Oops give entry already exists`,
              });
            }

            // else {
            //   toast.error(() => <>Some error occurred try again later</>, {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //   });
            // }
          });
      } else {
        return null;
      }
    }
  };
  //</>sending request for availabe entry

  const showOptions = () => {
    if (isExistingGroup) {
      return errorss ? (
        <Box sx={{ color: `red`, textAlign: `center`, mt: 2, pb: 2 }}>
          <Box sx={{ display: `inline-block`, mt: 0.2, mr: 0.3 }}>Sorry try again later an error has occured</Box>
          {` `}
          <Box sx={{ display: `inline-block` }}>
            <GppMaybeOutlinedIcon sx={{ animation: `${animate} 1s linear infinite alternate ` }} fontSize="large" />
          </Box>
        </Box>
      ) : practiceGroupsLoading ? (
        <LinearProgress sx={{ animation: `${animateLoading} 1s linear infinite alternate `, mt: 2 }} />
      ) : (
        practiceGroups?.data?.map((e) => (
          <MenuItem
            key={e._id}
            onClick={() => {
              setgroupName(e.name);
            }}
            value={e._id}
          >
            {capitalizeFirstLetter(e?.name)}
          </MenuItem>
        ))
      );
    } else {
      return isType
        ? providerTypeOptions
        : isState
          ? stateNameOptions
          : ispracticeType
            ? practiceTypeOptions
            : isPracticeSpeciality
              ? practiceSpecialityOptions
              : null;
    }
  };
  const isAvalaibleGroupName = (e, name) => {
    let value = e.target.value;
    let query = queryParams({ name: value?.toLowerCase() });
    if (value?.length > 0) {
      setLoading({ error: false, loading: true });
      getRequest(CHECK_PRICTICE_GROUP_AVAILABILITY + query)
        .then(() => {
          setLoading({ loading: false, error: false });
          toast.success(() => <>Available</>, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((e) => {
          setLoading({ loading: false, error: true });
          if (e.response?.status === 409) {
            setError(name, {
              type: `custom`,
              message: `Oops give entry already exists !`,
            });
          }
          // } else {
          //   toast.error(() => <>Some error occurred try again later</>, {
          //     position: toast.POSITION.BOTTOM_RIGHT,
          //   });
          // }
        });
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{
        required:
          hasDisabled || isDate || isFax || isNotes || isStreet || isTitle || isPracticeLogo || hasValidatedClinicalStaff ? false : `*Required`,
        pattern: isEmail
          ? {
            value: emailRegex,
            message: `Invalid Email`,
          }
          : null,
        ...(isEmail && {
          value: emailRegex,
          mesage: `invalid Email`,
        }),
        ...(isPhoneNumber && {
          validate: (value) => {
            return invalidNoGiver(value);
          },
        }),
        ...(isNpi || isFax || isZipCode
          ? {
            validate: (value) => {
              return npiMaskHandler(value, isZipCode);
            },
          }
          : isFax
            ? {
              validate: (value) => {
                return faxNumberValidation(value);
              },
            }
            : null),
      }}
      render={({ field: { onChange, value, onBlur, name }, fieldState: { invalid, error } }) =>
        isPracticeLogo ? (
          <ToolTipProvider
            toolTipProps={{
              title: `Upload Practice Logo!`,
              placement: `top`,
              arrow: true,
            }}
            element={
              <IconButton color={invalid ? `error` : `primary`} aria-label="upload picture" component="label">
                <input
                  onChange={async (e) => {
                    let { files } = e.target;

                    let base64File = await convertToBase64(files[0], { size: false, validateWidth: 270, validateHeight: 100 });
                    if (base64File) {
                      setpracticeLogoUrl({ url: base64File, practiceLogoName: files[0]?.name });
                      setValue(name, base64File);
                      clearErrors(name);
                    } else {
                      setpracticeLogoUrl({ url: ``, practiceLogoName: `` });
                      setError(name, { type: `custom`, message: `Image dimension should be 100*270 or less than 100*270` });
                    }
                  }}
                  hidden
                  accept="image/*"
                  type="file"
                  onClick={(e) => (e.target.value = ``)}
                />
                <PhotoCamera />
              </IconButton>
            }
          />
        ) : isDate ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disabled={hasDisabled}
              label={label}
              openTo="month"
              style={{ width: `100%` }}
              views={[`year`, `month`, `day`]}
              inputFormat={dateFormt ? dateFormt : `YYYY/MM/DD`}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              maxDate={momentWrapper().toDate()}
              value={value || null}
              onChange={(date) => {
                onChange(date?.$d);
              }}
              renderInput={(params) => {
                return <TextField size="small" fullWidth {...params} />;
              }}
            />
          </LocalizationProvider>
        ) : isPhoneNumber || isNpi || isFax || isZipCode ? (
          <InputMask
            key={name}
            mask={isPhoneNumber ? `(999) 999-9999` : isNpi ? `9999999999` : isFax ? `(999) 999-9999` : isZipCode && `99999`}
            value={value}
            onChange={(e) => purifyInputValue({ event: e, onChange: onChange })}
            disabled={isPhoneNumber || isAdditionalInfo ? hasDisabled : false}
            onBlur={(e) => isPhoneNumber && isAvailable(e)}
          >
            {(inputProps) => {
              return (
                // <div className={css.withoutBorder}>
                <TextField
                  disabled={isPhoneNumber || isAdditionalInfo ? hasDisabled : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {loading?.error ? (
                          <ErrorOutlineIcon fontSize="small" sx={{ color: `red` }} />
                        ) : loading.loading ? (
                          <div className="spinner-border spinner-border-sm text-info"></div>
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  {...inputProps}
                  label={label}
                  size="small"
                  error={error?.type === `validate` ? true : invalid}
                  helperText={
                    error?.type === `validate`
                      ? isPhoneNumber
                        ? `Invalid No.`
                        : isNpi
                          ? `Invalid NPI`
                          : isFax
                            ? `Invalid Fax`
                            : isZipCode && `Invalid Zip Code`
                      : error?.message
                  }
                />
              );
            }}
          </InputMask>
        ) : isSelect ? (
          <TextField
            disabled={isAdditionalInfo ? hasDisabled : false}
            InputLabelProps={{
              style: { backgroundColor: `white`, left: isGroupName ? `-2%` : `-1.5%` },
            }}
            inputProps={{
              onChange: onChange,
              onBlur: (e) => (isPhoneNumber || isEmail ? isAvailable(e) : onBlur),
              id: name,
            }}
            key={name}
            select
            fullWidth
            name={name}
            size="small"
            error={invalid}
            helperText={error?.message}
            value={value}
            label={label}
          >
            {showOptions()}
          </TextField>
        ) : isNotes ? (
          <TextField
            InputLabelProps={{
              style: { backgroundColor: `white`, left: isGroupName ? `-2%` : `-1.5%` },
            }}
            inputProps={{
              onChange: (e) => purifyInputValue({ event: e, onChange: onChange }),
            }}
            label={label}
            variant={variant}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ width: `100%`, backgroundColor: `#0000001f`, borderRadius: 2, p: 0.5 }}>
                    <NotesRoundedIcon fontSize="large" sx={{ color: `white` }} />
                  </Box>
                </InputAdornment>
              ),
            }}
            multiline
            fullWidth
            minRows={7}
            maxRows={10}
            value={value}
          />
        ) : (
          // </div>
          // <div className={css.withoutBorder}>
          <TextField
            disabled={isTitle || isEmail || isName || isAdditionalInfo ? hasDisabled : false}
            InputLabelProps={{
              style: { backgroundColor: `white`, left: isGroupName ? `-2%` : `-1.5%` },
            }}
            inputProps={{
              onChange: (e) => purifyInputValue({ event: e, onChange: onChange }),
              onBlur: (e) => {
                if (isPhoneNumber || isEmail || isPracticeName) {
                  isAvailable(e);
                } else if (isGroupName) {
                  isAvalaibleGroupName(e, name);
                } else {
                  onBlur(e);
                }
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {loading?.error ? (
                    <ErrorOutlineIcon fontSize="small" sx={{ color: `red` }} />
                  ) : loading.loading ? (
                    <div className="spinner-border spinner-border-sm text-info"></div>
                  ) : null}
                </InputAdornment>
              ),
            }}
            fullWidth
            type={type}
            name={name}
            size="small"
            error={invalid}
            helperText={error?.type === `maxLength` ? `Npi length should be 10` : error?.message}
            value={value}
            label={label}
          />
          // </div>
        )
      }
    />
  );
};
//</> here you will find each and every input showing on screen

// <> providers array to show on screen
const ProvidersArray = ({
  onBlurValidForExRoleAdd,
  emailsArr,
  getValues,
  practiceGroupValue,
  setError,
  errors,
  auth,
  fields,
  append,
  remove,
  control,
  setValue,
  isGroupAdmin,
  adminAdditionalRole,
  setisLoading,
  isLoading,
  setshoulAddProviders,
  setPersistValues,
  persistValues,
}) => {
  let providersValues = getValues(`providers`);

  const isAAvailableProvider = async (e, name, index) => {
    let value = e.target.value;
    let hasErrorField = errors?.providers?.[index]?.[name.split(`.`)[1]]
    let getgroupAdminNumber = getValues(`groupAdminPhoneNumber`);
    let getGroupAdminEmail = getValues(`groupAdminEmail`);
    let getAdminNumber = getValues(`adminPhoneNumber`);
    let getAdminEmail = getValues(`adminEmail`);
    let getPracticeEmail = getValues(`practiceEmail`);
    let getPracticdePhoneNumber = getValues(`practicePhoneNumber`);
    let isNumber = name.includes(`Number`);
    let isEmail = name.includes(`email`);

    let emails = [];
    let numbres = [];

    for (let i = 0; i < providersValues?.length; i++) {
      if (isEmail) {
        emails.push(providersValues[i].email);
      } else if (isNumber) {
        numbres.push(providersValues[i].phoneNumber);
      }
    }

    let hasAlreadyEmail = emails?.filter((e) => e.includes(value));
    let hasAlreadyNumber = numbres?.filter((e) => e.includes(value));

    let errorss = [];

    const validateInputs = () => {
      if (practiceGroupValue === `new` || practiceGroupValue === `existing` || !practiceGroupValue) {
        if (isNumber && value?.length > 0) {
          if (value === getAdminNumber || value === getPracticdePhoneNumber || value === getgroupAdminNumber) {
            errorss.push(`error`);
            setError(name, {
              type: `custom`,
              message: `Oops Already Taken !`,
            });
          }

          if (value?.length > 0) {
            if (hasAlreadyNumber?.length > 1) {
              errorss.push(`error`);
              setError(name, {
                type: `custom`,
                message: `Oops Already Taken !`,
              });
            }
          }
        }
        if (isEmail && value?.length > 0) {
          if (value === getAdminEmail || value === getPracticeEmail || value === getGroupAdminEmail) {
            errorss.push(`error`);
            setError(name, {
              type: `custom`,
              message: `Oops Already Taken !`,
            });
          }

          if (value?.length > 0) {
            if (hasAlreadyEmail?.length > 1) {
              errorss.push(`error`);
              setError(name, {
                type: `custom`,
                message: `Oops Already Taken !`,
              });
            }
          }
        }
      } else {
        return null;
      }
    };

    validateInputs();

    let copLoadingBefore = [...isLoading];
    if (isNumber) {
      copLoadingBefore[index] = { ...copLoadingBefore[index], num: { loading: true, error: false } };
    } else if (isEmail) {
      copLoadingBefore[index] = { ...copLoadingBefore[index], mail: { loading: true, error: false } };
    }
    setisLoading(copLoadingBefore);
    if (!errorss.length && !hasErrorField) {
      postRequest(CHECK_EMAIL_USERNAME_AVAILABILITY_URL, { userName: value })
        .then(() => {
          let copyLoadingSucces = [...isLoading];
          if (isNumber) {
            copyLoadingSucces[index] = { ...copyLoadingSucces[index], num: { loading: false, error: false } };
          } else if (isEmail) {
            copyLoadingSucces[index] = { ...copyLoadingSucces[index], mail: { loading: false, error: false } };
          }

          setisLoading(copyLoadingSucces);
          toast.success(() => <>Available</>, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((e) => {
          let copyLaodingAfter = [...isLoading];
          if (isNumber) {
            copyLaodingAfter[index] = { ...copyLaodingAfter[index], num: { loading: false, error: true } };
          } else if (isEmail) {
            copyLaodingAfter[index] = { ...copyLaodingAfter[index], mail: { loading: false, error: true } };
          }
          setisLoading(copyLaodingAfter);
          if (e.response) {
            if (e.response?.status === 409) {
              errorss.push(`error`);
              setError(name, { type: `custom`, message: `Oops given enrty value already exists!` });
            }
          }
        });
    }
  };

  const removeIndexHandler = (index) => {
    index ? remove(index) : remove();
    let persistValuesHandle = [...returnIfArr(persistValues?.providers)];
    let removeFromPersists = persistValuesHandle?.filter((e, i) => i !== index);
    setPersistValues((p) => ({ ...p, providers: index ? removeFromPersists : [false] }));
    let copLoading = [...isLoading];
    let removeIndex = copLoading?.filter((e, i) => i !== index);
    setisLoading(
      index
        ? removeIndex
        : [
          {
            num: { loading: false, error: false },
            userName: { loading: false, error: false },
            mail: { loading: false, error: false },
          },
        ]
    );
    isGroupAdmin && index === 0 && setshoulAddProviders(false);
  };
  const getProviderValue = (name, index) => {
    return providersValues[index][name];
  };
  return (
    <>
      {fields.map((field, index) => (
        <RegisterProvider
          onBlurValidForExRoleAdd={onBlurValidForExRoleAdd}
          emailsArr={emailsArr}
          isLoading={isLoading}
          isAAvailableProvider={isAAvailableProvider}
          setisLoading={setisLoading}
          control={control}
          errors={errors}
          append={append}
          removeIndexHandler={(index) => removeIndexHandler(index)}
          field={field}
          index={index}
          adminAdditionalRole={adminAdditionalRole}
          isGroupAdmin={isGroupAdmin}
          setValue={setValue}
          getValue={(name, index) => getProviderValue(name, index)}
          getValues={getValues}
          setPersistValues={setPersistValues}
          persistValues={persistValues}
          dateFormat={`MM/DD/YYYY`}
          resetValues={() => {
            let valuesObj = fields[index];
            for (const field in valuesObj) {
              setValue(`providers[${index}].${field}`);
              setValue(`providers[${index}].providerStatus`, `SELECTING`);
            }
          }}
        />
      ))}
    </>
  );
};
// </> providers array to show on screen
export const AddNewPracticeFormik = ({ auth, addnewPracticeModalformik, setAddnewPracticeModalformik, isGroupAdmin }) => {
  const [adminAdditionalRole, setadminAdditionalRole] = useState(``);
  const [isLoading, setisLoading] = useState([
    {
      num: { loading: false, error: false },
      userName: { loading: false, error: false },
      mail: { loading: false, error: false },
    },
  ]);
  const [practiceLogoUrl, setpracticeLogoUrl] = useState({ url: ``, practiceLogoName: `` });
  const [practiceGroupValue, setpracticeGroupValue] = useState(``);
  const [collapsed, setcollapsed] = useState(false);
  const [screen, setScreen] = useState(0);
  const [practiceGroupsLoading, setpracticeGroupsLoading] = useState(false);
  const [errorss, setErrors] = useState(false);
  const [practiceGroups, setpracticeGroups] = useState([]);
  const [practiceId, setpracticeId] = useState(``);
  const [groupName, setgroupName] = useState(``);
  const [submitLoading, setsubmitLoading] = useState({ loading: false, error: false });
  const [isRegistredGrpandPractice, setisRegistredGrpandPractice] = useState(false);
  const [shoulAddProviders, setshoulAddProviders] = useState(false);
  const [persistValues, setPersistValues] = useState({
    providers: [false],
    admin: false,
  });

  const firstScreenBtn = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const theme = useTheme();
  const isSmaller = useMediaQuery(theme.breakpoints.down(`sm`));
  const {
    handleSubmit,
    formState: { errors },
    trigger,
    control,
    getValues,
    setValue,
    setError,
    reset,
    unregister,
    clearErrors,
  } = useForm({
    mode: `onChange`,
    reValidateMode: `onChange`,
    defaultValues: {
      providers: [
        {
          firstName: ``,
          lastName: ``,
          phoneNumber: ``,
          email: ``,
          providerType: ``,
          licenseState: ``,
          NPI: ``,
          medicalLicense: ``,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: `providers`,
  });

  const [errorIndex, setErrorIndex] = useState(false);
  const providersValues = getValues(`providers`);
  let errorsArray = Object.keys(errors);
  let errorsGroupAdmin = errorsArray[0]?.split(``)?.[0] === `g` ? true : false;
  let errorsGroupAdminExisting = errorsArray[0]?.split(``)?.[0] === `e` ? true : false;
  let errorsAdmin = errorsArray[0]?.split(``)?.[0] === `a` ? true : false;
  let errorsPractice = errorsArray[0]?.includes(`practice`) ? true : false;
  let errorsProviders = errorsArray?.includes(`providers`) ? true : false;

  useEffect(() => {
    if (screen === 0) {
      reset();
    }

    return () => {
      setsubmitLoading({ loading: false, error: false });
      // setPracticeType(``)
      // setpracticeTypeError(``)
      if (practiceLogoUrl) {
        setpracticeLogoUrl({ url: ``, practiceLogoName: `` });
      }
    };
  }, [reset]);



  const onBlurValidForExRoleAdd = ({ value, setError }) => {
    let providersArray = getValues(`providers`)?.reduce((prev, next) => [...prev, next?.email], []) || []
    let emailsArr = [getValues(`practiceEmail`) || ``, getValues(`adminEmail`) || ``, getValues(`groupAdminEmail`) || ``, ...providersArray]
    if (emailsArr?.includes(value) && value) setError(`Please  enter the email which you are not using before in the form fields.`)
    else setError(``)
  }


  useEffect(() => {
    const getGroups = () => {
      if (collapsed && screen === 1) {
        setpracticeGroupsLoading(true);
        getRequest(GET_ALL_GROUP_OPTIONS)
          .then((res) => {
            setpracticeGroups(res?.data);
            setpracticeGroupsLoading(false);
          })
          .catch((e) => {
            if (e.response) {
              setErrors(true);
            }
          });
      }
    };
    getGroups();
    if (isGroupAdmin) {
      if (screen === 1 && errorsAdmin) {
        setErrorIndex(true);
      } else if (screen === 2 && errorsPractice) {
        setErrorIndex(true);
      } else if (screen === 3 && errorsProviders) {
        setErrorIndex(true);
      } else {
        setErrorIndex(false);
      }
    } else {
      if (screen === 1 && (errorsGroupAdmin || errorsGroupAdminExisting)) {
        setErrorIndex(true);
      } else if (screen === 2 && (errorsPractice || errorsProviders)) {
        setErrorIndex(true);
      } else {
        setErrorIndex(false);
      }
    }
  }, [
    adminAdditionalRole,
    shoulAddProviders,
    auth,
    collapsed,
    screen,
    errorsAdmin,
    errorsPractice,
    errorsProviders,
    isGroupAdmin,
    errorsGroupAdmin,
    errorsGroupAdminExisting,
  ]);
  const onError = () => errorToast(defaultValidationPhrase);

  const onSubmit = async (data) => {
    // dateOfBirth
    let dotIndex = practiceLogoUrl?.practiceLogoName && practiceLogoUrl?.practiceLogoName.indexOf(`.`);
    let trimmedPracticeLogoName = practiceLogoUrl?.practiceLogoName && practiceLogoUrl?.practiceLogoName?.slice(0, dotIndex).trim();
    let providersArray = data?.providers;
    let hasProvidersInSelection = [];
    const providersHandler = () => {
      if (providersArray?.length) {
        hasProvidersInSelection = providersArray.filter((provider) => provider.providerStatus === `SELECTING`);
        providersArray = providersArray.filter((provider) => provider.providerStatus !== `SELECTING`);
        providersArray = providersArray?.length
          ? providersArray.map((e) => {
            let hasDate = e?.dateOfBirth?.toString()?.length > 0 ? true : false;
            let obj = { ...e };
            if (obj?.additionalValues && Object.keys(obj?.additionalValues)?.length && obj?.providerStatus === `EXISTING`) {
              delete obj.isNewProvider;
            }
            delete obj.additionalValues;
            if (!hasDate) {
              delete obj.dateOfBirth;
            } else {
              obj = { ...obj, dateOfBirth: momentWrapper(e?.dateOfBirth)?.format(`MM-DD-YYYY`) };
            }

            return obj;
          })
          : [];
      }
    };

    providersHandler();
    if (hasProvidersInSelection?.length) errorToast(unslelectedProvidersPhrase);
    else {
      let newGroupAdmin = {
        firstName: data?.groupAdminFirstName,
        lastName: data?.groupAdminLastName,
        ...(data?.groupAdminTitle?.length > 0 && { title: data?.groupAdminTitle }),
        phoneNumber: data?.groupAdminPhoneNumber,
        email: data?.groupAdminEmail,
        ...(data?.groupAdminFax?.length > 0 && { faxNumber: data?.groupAdminFax }),
        ...(data?.groupAdminDateOfBirth?.toString()?.length > 0 && { dateOfBirth: momentWrapper(data?.groupAdminDateOfBirth)?.format(`MM-DD-YYYY`) }),
      };

      const decideAdminPayLoad = () => {
        let adminRoles = {
          isProvider: adminAdditionalRole === `provider` ? true : false,
          isClinicalStaff: adminAdditionalRole === `clinical staff` ? true : false,
        };
        let providerAndCstaffObj = {
          ...(adminAdditionalRole?.length > 0 && data?.adminProviderNPI && { NPI: data?.adminProviderNPI }),
          ...(adminAdditionalRole?.length > 0 && data?.adminProviderType && { providerType: data?.adminProviderType }),
          ...(adminAdditionalRole?.length > 0 && data?.adminProviderLicenseno && { medicalLicense: data?.adminProviderLicenseno }),
          ...(adminAdditionalRole?.length > 0 && data?.adminProviderLicenseState && { licenseState: data?.adminProviderLicenseState }),
        };
        let existingRoleObj = {
          _id: data?.superAdminId,
          newRole: [adminRoles],
        };

        if (data?.superAdminStatus === `NEW`) {
          return {
            firstName: data?.adminFirstName,
            lastName: data?.adminLasttName,
            ...(data?.adminTitle?.length > 0 && { title: data?.adminTitle }),
            phoneNumber: data?.adminPhoneNumber,
            email: data?.adminEmail,
            superAdminStatus: data?.superAdminStatus,
            systemRoles: [adminRoles],
            ...providerAndCstaffObj,
            ...(data?.adminFax?.length > 0 && { faxNumber: data?.adminFax }),
            ...(data?.adminDateOfBirth?.toString().length > 0 && { dateOfBirth: momentWrapper(data?.adminDateOfBirth)?.format(`MM-DD-YYYY`) }),
          };
        } else {
          if (data?.addtionalValues && Object.keys(data?.addtionalValues)?.length) {
            return {
              ...existingRoleObj,
              superAdminStatus: data?.superAdminStatus,
            };
          } else {
            return {
              superAdminStatus: data?.superAdminStatus,
              ...existingRoleObj,
              ...providerAndCstaffObj,
              isNewProviderOrClinicalStaff: data?.isNewProviderOrClinicalStaff,
            };
          }
        }
      };

      let payLoad = {
        practice: {
          practiceName: data?.practiceName,
          practiceType: data?.practiceType,
          practiceSpeciality: data?.practiceSpeciality,
          line1: data?.practiceAddress,
          ...(data?.practiceStreet?.length > 0 && { line2: data?.practiceStreet }),
          city: data?.practiceCity,
          state: data?.practiceState,
          zipCode: data?.practiceZipCode,
          email: data?.practiceEmail,
          practicePhoneNumber: data?.practicePhoneNumber,
          ...(practiceLogoUrl?.url && { practiceLogo: data?.practiceLogo }),
          ...(practiceLogoUrl?.url && { practiceLogoName: trimmedPracticeLogoName }),
          ...(data?.notes?.length > 0 && { notes: data?.notes }),
        },
        administrator: {
          ...decideAdminPayLoad(),
        },
        ...(!collapsed && { groupAdmin: newGroupAdmin }),

        practiceGroup: {
          name: collapsed ? groupName : data?.groupAdminGroupName,
          ...(collapsed ? { _id: data?.existingGroupName } : {}),
        },
        providers: providersArray,
      };
      const { providers: payLoadProviders } = payLoad;
      let filteredAdminAndProviders = () => {
        let providersValidation = adminAdditionalRole !== `provider` ? [] : [`goodToGo`];
        let adminValidation = Object.values(payLoad?.administrator)?.filter((value) => {
          return (
            (adminAdditionalRole?.length > 0 && typeof value === `object`) ||
            (typeof value === `string` && value?.length > 0) ||
            typeof value === `boolean`
          );
        });
        if (!payLoadProviders?.length && adminAdditionalRole !== `provider`) {
          providersValidation = [];
        } else {
          providersValidation = [`goodToGo`];
        }
        return { adminValidation, providersValidation };
      };

      const validateFirst = () => {
        let { adminValidation, providersValidation } = filteredAdminAndProviders();

        if (!adminValidation?.length) {
          errorToast(`Please enter the values of Super Admin`);
          return false;
        } else if (!providersValidation?.length) {
          errorToast(`Please enter atleast one provider`);
          return false;
        } else {
          return true;
        }
      };
      let validate = validateFirst();
      if (validate) {
        if (isGroupAdmin) {
          let toSend = {
            ...payLoad,
            practiceGroup: {
              _id: user?._practiceGroupId,
            },
          };
          if (toSend[`groupAdmin`]) delete toSend[`groupAdmin`];
          setsubmitLoading({ loading: true, error: false });
          postRequest(GROUP_PRACTICE_REGISTER_URL, toSend)
            .then((res) => {
              setsubmitLoading({ loading: false, error: false });
              setScreen(4);
              successToast(`New Practice Created`);
              setpracticeId(res?.data?.practice?._id);
              setisRegistredGrpandPractice(true);
              dispatch(refreshPracticeGroup(false));
            })
            .catch((err) => setsubmitLoading({ loading: false, error: true }));
        } else {
          let route = collapsed ? GROUP_PRACTICE_REGISTER_URL : PRACTICE_REGISTER_URL;
          setsubmitLoading({ loading: true, error: false });
          postRequest(route, payLoad)
            .then((res) => {
              setsubmitLoading({ loading: false, error: false });
              successToast(`New Practice Created`);
              setpracticeId(res?.data?.practice?._id);
              setScreen(3);
              setisRegistredGrpandPractice(true);
              dispatch(refreshPracticeGroup(false));
            })
            .catch(() => setsubmitLoading({ loading: false, error: true }));
        }
      }
    }
  };

  const onClose = () => {
    setisLoading([
      {
        num: { loading: false, error: false },
        userName: { loading: false, error: false },
        mail: { loading: false, error: false },
      },
    ]);
    setpracticeGroupsLoading(false);
    setError(false);
    setAddnewPracticeModalformik(false);
    if (isRegistredGrpandPractice) {
      dispatch(refreshPracticeGroup(true));
    }
    setisRegistredGrpandPractice(false);
    setadminAdditionalRole(``);
    setpracticeGroupValue(``);
    setScreen(0);
    setshoulAddProviders(false);
    reset();
  };
  const setNext = () => {
    setTimeout(() => {
      setScreen(1);
    }, 500);
  };

  // Form inputs screens
  let groupAdmin = (
    <Grid container columnSpacing={1.5} rowSpacing={1.5}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ borderBottom: `1px solid rgb(0 0 0 / 13%)`, mt: 3 }}>
          {` `}
          <Typography variant="h6" component="div">
            Group Admin Information
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {` `}
        <FormInput
          setpracticeLogoUrl={setpracticeLogoUrl}
          auth={auth}
          emailRegex={emailRegex}
          errorss={errorss}
          practiceGroupsLoading={practiceGroupsLoading}
          name="groupAdminGroupName"
          control={control}
          label="*Group Name"
          type="text"
          setValue={setValue}
          getValues={getValues}
          setError={setError}
          practiceGroupValue={practiceGroupValue}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        {` `}
        <FormInput
          setpracticeLogoUrl={setpracticeLogoUrl}
          auth={auth}
          emailRegex={emailRegex}
          errorss={errorss}
          practiceGroupsLoading={practiceGroupsLoading}
          name="groupAdminFirstName"
          control={control}
          label="*First Name"
          type="text"
          setValue={setValue}
          getValues={getValues}
          setError={setError}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        {` `}
        <FormInput
          setpracticeLogoUrl={setpracticeLogoUrl}
          auth={auth}
          emailRegex={emailRegex}
          errorss={errorss}
          practiceGroupsLoading={practiceGroupsLoading}
          name="groupAdminLastName"
          control={control}
          label="*Last Name"
          type="text"
          setValue={setValue}
          getValues={getValues}
          setError={setError}
        />
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
        {` `}
        <FormInput
          setpracticeLogoUrl={setpracticeLogoUrl}
          auth={auth}
          emailRegex={emailRegex}
          errorss={errorss}
          practiceGroupsLoading={practiceGroupsLoading}
          name="groupAdminTitle"
          control={control}
          label="Title"
          type="text"
          setValue={setValue}
          getValues={getValues}
          setError={setError}
        />
      </Grid>
      <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        {` `}
        <FormInput
          setpracticeLogoUrl={setpracticeLogoUrl}
          auth={auth}
          emailRegex={emailRegex}
          errorss={errorss}
          practiceGroupsLoading={practiceGroupsLoading}
          name="groupAdminPhoneNumber"
          control={control}
          label="*Phone Number"
          type="text"
          setValue={setValue}
          getValues={getValues}
          setError={setError}
          error={errors}
        />
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
        {` `}
        <FormInput
          setpracticeLogoUrl={setpracticeLogoUrl}
          auth={auth}
          emailRegex={emailRegex}
          errorss={errorss}
          practiceGroupsLoading={practiceGroupsLoading}
          name="groupAdminEmail"
          control={control}
          label="*Email"
          type="email"
          setValue={setValue}
          getValues={getValues}
          setError={setError}
          error={errors}
        />
      </Grid>
      <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        {` `}
        <FormInput
          setpracticeLogoUrl={setpracticeLogoUrl}
          auth={auth}
          emailRegex={emailRegex}
          errorss={errorss}
          practiceGroupsLoading={practiceGroupsLoading}
          name="groupAdminFax"
          control={control}
          label="Fax"
          type="text"
          setValue={setValue}
          getValues={getValues}
          setError={setError}
        />
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
        {` `}
        <FormInput
          setpracticeLogoUrl={setpracticeLogoUrl}
          auth={auth}
          emailRegex={emailRegex}
          errorss={errorss}
          practiceGroupsLoading={practiceGroupsLoading}
          name="groupAdminDateOfBirth"
          control={control}
          label="Date Of Birth"
          type="date"
          setValue={setValue}
          getValues={getValues}
          setError={setError}
          error={errors}
          dateFormt={`MM/DD/YYYY`}
        />
      </Grid>
    </Grid>
  );

  let providers = (
    <ProvidersArray
      onBlurValidForExRoleAdd={onBlurValidForExRoleAdd}
      getValues={getValues}
      practiceGroupValue={practiceGroupValue}
      setError={setError}
      errors={errors}
      auth={auth}
      fields={fields}
      append={append}
      remove={remove}
      control={control}
      npiRegex={npiRegex}
      emailRegex={emailRegex}
      isGroupAdmin={isGroupAdmin}
      adminAdditionalRole={adminAdditionalRole}
      setisLoading={setisLoading}
      isLoading={isLoading}
      setValue={setValue}
      setshoulAddProviders={setshoulAddProviders}
      setPersistValues={setPersistValues}
      persistValues={persistValues}
    />
  );

  // let dmePractice =
  //   <Zoom in={screen === 2 && !isGroupAdmin || isGroupAdmin && screen === 2} mountOnEnter unmountOnExit style={{ transitionDelay: screen === 2 && !isGroupAdmin ? `1000ms` : `0ms` }}>
  //     <Box sx={{
  //       marginBottom: `-23px`,
  //       marginTop: `29px`,
  //       width: `100%`,
  //       textAlign: `end`
  //     }}>
  //       <TextField error={practiceTypeError ? true : false} helperText={practiceTypeError ? practiceTypeError : ``} autoFocus onBlur={() => {
  //         if (!practiceType) {
  //           setpracticeTypeError(`Please make a selection for your Practice!`)
  //         } else {
  //           if (practiceTypeError) {
  //             setpracticeTypeError(``)
  //           }
  //         }
  //       }} label='Want to Register Practice as DME?' InputLabelProps={{
  //         style: {
  //           left: `-2%`
  //         }
  //       }}
  //         sx={{ width: `50%` }}

  //         size='small' select value={practiceType} onChange={(e) => setPracticeType(e.target.value)}>
  //         <MenuItem value='DME'>
  //           Yes
  //         </MenuItem>
  //         <MenuItem value='NO'>
  //           No
  //         </MenuItem>
  //       </TextField>

  //     </Box>
  //   </Zoom>
  const practiceInfo = (
    <>
      {/* {isGroupAdmin && dmePractice} */}
      <Grid container columnSpacing={1.5} rowSpacing={1.5}>
        <Grid sx={{ mb: 1 }} item xs={62} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ borderBottom: `1px solid rgb(0 0 0 / 13%)` }}>
            <Typography variant="h6" component="div">
              Practice Information
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          {` `}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="practiceName"
            control={control}
            label="*Practice Name"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            error={errors}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          {` `}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            isSelect={true}
            name="practiceType"
            control={control}
            label="*Practice Type"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          {` `}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            isSelect={true}
            name="practiceSpeciality"
            control={control}
            label="*Practice Speciality"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {` `}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="practiceAddress"
            control={control}
            label="*Practice Address"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          {` `}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="practiceStreet"
            control={control}
            label="Street"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          {` `}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="practiceCity"
            control={control}
            label="*City"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={62} sm={12} md={12} lg={12} xl={12}>
          {` `}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            isSelect={true}
            name="practiceState"
            control={control}
            label="*State"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
          {` `}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="practiceZipCode"
            control={control}
            label="*Zip Code"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
        </Grid>
        <Grid item xs={6} sm={5} md={5} lg={5} xl={5}>
          {` `}
          {/* this */}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="practiceEmail"
            control={control}
            label="*Email"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            error={errors}
          />
        </Grid>
        <Grid item xs={6} sm={5} md={5} lg={5} xl={5}>
          {` `}
          <FormInput
            setpracticeLogoUrl={setpracticeLogoUrl}
            auth={auth}
            emailRegex={emailRegex}
            errorss={errorss}
            practiceGroupsLoading={practiceGroupsLoading}
            name="practicePhoneNumber"
            control={control}
            label="*PhoneNumber"
            type="text"
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            error={errors}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ position: `relative` }}>
            <p className={errors?.practiceLogo ? css.logoLabelEror : css.practiceLogoLabel}>Practice Logo</p>
            <Zoom in={practiceLogoUrl?.url} mountOnEnter unmountOnExit>
              <IconButton
                onClick={() => setpracticeLogoUrl({ url: ``, practiceLogoName: `` })}
                sx={{ padding: `2px !important`, marginLeft: `10px` }}
              >
                <CloseIcon sx={{ color: `red` }} />
              </IconButton>
            </Zoom>
            <Box
              sx={{
                width: `${errors?.practiceLogo ? `270px` : `auto`}`,
                border: `1px solid ${errors?.practiceLogo ? `red` : `white`}`,
                borderRadius: `10px`,
                height: `100px`,
              }}
            >
              <Zoom in={practiceLogoUrl?.url}>
                <img className={css.practiceLogo} src={practiceLogoUrl?.url ? practiceLogoUrl?.url : null} alt="" />
              </Zoom>
            </Box>
            <Box sx={{ position: `absolute`, zIndex: 5, bottom: errors?.practiceLogo ? `17px` : 0, left: 0 }}>
              <FormInput
                setpracticeLogoUrl={setpracticeLogoUrl}
                auth={auth}
                emailRegex={emailRegex}
                errorss={errorss}
                practiceGroupsLoading={practiceGroupsLoading}
                name="practiceLogo"
                control={control}
                label="*Prctice Logo"
                type="file"
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                error={errors}
                clearErrors={clearErrors}
              />
            </Box>
            <p className={css.fileError}>{errors?.practiceLogo && errors?.practiceLogo?.message}</p>
          </Box>
        </Grid>
      </Grid>
    </>
  );
  let notesInput = (
    <Box sx={{ mt: 2, mb: 1 }}>
      <FormInput
        practiceGroups={practiceGroups}
        setpracticeLogoUrl={setpracticeLogoUrl}
        auth={auth}
        emailRegex={emailRegex}
        errorss={errorss}
        practiceGroupsLoading={practiceGroupsLoading}
        isSelect={false}
        name="notes"
        control={control}
        label="Notes..."
        type="text"
        setValue={setValue}
        getValues={getValues}
        setError={setError}
        setgroupName={setgroupName}
        variant="standard"
      />
    </Box>
  );
  const renderProviders = useMemo(() => {
    if (adminAdditionalRole === `clinical staff` || adminAdditionalRole === ``) {
      return (
        <>
          {providers}
          {notesInput}
        </>
      );
    } else {
      return (
        <>
          {shoulAddProviders && providers}
          {notesInput}
        </>
      );
    }
  }, [adminAdditionalRole, providers, notesInput, shoulAddProviders]);

  let reqPhrase = (
    <Typography variant="h6" component="div">
      If you want to add more provider press add, otherwise hit submit
    </Typography>
  );
  let askForProviders = (
    <Fade mountOnEnter unmountOnExit in={(!shoulAddProviders && adminAdditionalRole === `provider`) || providersValues?.length === 0}>
      <div>
        {isGroupAdmin && <Box sx={{ display: `flex`, justifyContent: `center`, marginTop: `30px` }}>{reqPhrase}</Box>}
        <Grid sx={{ ...(isGroupAdmin && { mt: 5, mb: 2, px: 2 }) }} container>
          {!isGroupAdmin && (
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {reqPhrase}
            </Grid>
          )}

          {isGroupAdmin && (
            <Grid sx={{ textAlign: `center` }} item xs={6} sm={4} md={4} lg={4} xl={4}>
              <button
                type="button"
                id={css.addProviderBtn}
                size="small"
                className="btn btn-outline-info btn-sm"
                color="primary"
                onClick={() => setScreen(2)}
              >
                Back
              </button>
            </Grid>
          )}

          <Grid
            sx={{ textAlign: isGroupAdmin ? `center` : `end` }}
            item
            xs={6}
            sm={isGroupAdmin ? 4 : 6}
            md={isGroupAdmin ? 4 : 6}
            lg={isGroupAdmin ? 4 : 6}
            xl={isGroupAdmin ? 4 : 6}
          >
            <button
              type="button"
              id={css.addProviderBtn}
              //  disabled={errors?.providers?.[index] && true}
              size="small"
              className="btn btn-outline-info btn-sm"
              color="primary"
              onClick={() => {
                setshoulAddProviders(true);
                append({
                  firstName: ``,
                  lastName: ``,
                  phoneNumber: ``,
                  email: ``,
                  providerType: ``,
                  licenseState: ``,
                  NPI: ``,
                  medicalLicense: ``,
                });
              }}
            >
              ADD
            </button>
          </Grid>
          {isGroupAdmin && (
            <Grid sx={{ mt: isSmaller ? 2.5 : 0, textAlign: `center` }} item xs={12} sm={4} md={4} lg={4} xl={4}>
              <button
                type="submit"
                id={css.addProviderBtn}
                size="small"
                className={`btn ${submitLoading.error ? `btn-outline-danger` : `btn-outline-info`}  btn-sm ${!submitLoading.loading && !submitLoading.error ? `pl-5 pr-5` : null
                  }`}
                color="primary"
                onClick={() => handleSubmit(onSubmit, onError)}
              >
                {submitLoading.error ? (
                  <ErrorOutlineSharpIcon fontSize="small" />
                ) : submitLoading.loading ? (
                  <span className="spinner-border spinner-border-sm text-primary"></span>
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </Grid>
          )}
        </Grid>
      </div>
    </Fade>
  );

  const setAdminEmail = (paramobj, status) => {
    let param = {
      ...paramobj,
      adminPhoneNumber: paramobj?.phoneNumber,
      adminEmail: paramobj?.email,
      adminFirstName: paramobj?.firstName,
      adminLasttName: paramobj?.lastName,
      adminDateOfBirth: paramobj?.dateOfBirth,
      adminFax: paramobj?.faxNumber,
      adminTitle: paramobj?.title,
      superAdminStatus: status,
      superAdminId: paramobj?._id,
    };
    setValuesFromArrRegPrac(
      setValue,
      [
        `adminPhoneNumber`,
        `adminEmail`,
        `adminFirstName`,
        `adminLasttName`,
        `adminDateOfBirth`,
        `adminFax`,
        `adminTitle`,
        `superAdminStatus`,
        `superAdminId`,
      ],
      param,
      false,
      false
    );

    let additionalValues = {
      ...(param?.licenseState ? { licenseState: param?.licenseState } : {}),
      ...(param?.medicalLicense ? { medicalLicense: param?.medicalLicense } : {}),
      ...(param?.providerType ? { providerType: param?.providerType } : {}),
      ...(param?.NPI ? { NPI: param?.NPI } : {}),
    };

    setValue(`addtionalValues`, {
      ...additionalValues,
    });

    if (!Object.keys(additionalValues)?.length) {
      setValue(`isNewProviderOrClinicalStaff`, true);
    }
  };

  let adminInfoProps = {
    providersValues: providersValues,
    append: append,
    getValues: getValues,
    setValue: setValue,
    clearErrors: clearErrors,
    setadminAdditionalRole: setadminAdditionalRole,
    remove: remove,
    setpracticeLogoUrl: setpracticeLogoUrl,
    auth: auth,
    emailRegex: emailRegex,
    errorss: errorss,
    practiceGroupsLoading,
    control: control,
    adminAdditionalRole: adminAdditionalRole,
    setError: setError,
    setshoulAddProviders: setshoulAddProviders,
    unregister: unregister,
    errors: errors,
    dateFormat: `MM/DD/YYYY`,
  };
  let adminAddConfirmation = (
    <AskForConfirmation
      initialValue={persistValues?.admin}
      acceptElem={
        <EmailAndLastNameCheck
          onblurEmail={onBlurValidForExRoleAdd}
          hasSearchValidation={true}
          action={(param, status) => {
            setAdminEmail(param, status);
            if (isGroupAdmin) firstScreenBtn.current.disabled = false;
          }}
          isMultiple={true}
          succesElem={<AdminInfo {...adminInfoProps} />}
        />
      }
      rejectElem={<AdminInfo {...adminInfoProps} />}
      actionOnAccept={() => {
        if (isGroupAdmin) firstScreenBtn.current.disabled = true;
      }}
      actionOnReject={() => setValue(`superAdminStatus`, `NEW`)}
      confirmationProps={{
        containerStyles: { width: `100%`, mt: 0.5 },
        acceptText: `Existing`,
        rejectText: `New`,
        confirmationText: `Do you want to add a new user or an existing for Admin role?`,
      }}
      navigationProps={{
        hasNavigation: true,
        iconBtnBlockStyles: { marginTop: `-13px`, marginLeft: `-14px` },
        action: async () => {
          const emptyValues = async () => {
            let arrayValues = [
              `adminFirstName`,
              `adminLasttName`,
              `adminTitle`,
              `adminPhoneNumber`,
              `adminEmail`,
              `superAdminStatus`,
              `adminProviderNPI`,
              `adminProviderType`,
              `adminProviderLicenseno`,
              `adminProviderLicenseState`,
              `adminFax`,
              `adminDateOfBirth`,
            ];
            let getAdminValues = await getValues([...arrayValues]);
            let valuesObj = {};
            if (adminAdditionalRole?.length) setadminAdditionalRole(``);
            if (getAdminValues) {
              for (let i = 0; i < getAdminValues?.length; i++) {
                valuesObj = { ...valuesObj, [arrayValues[i]]: getAdminValues[i] };
              }
            }
            if (valuesObj) {
              for (const key in valuesObj) {
                if (valuesObj[key]) {
                  setValue(key, ``);
                  clearErrors(key, ``);
                }
              }
            }
          };
          emptyValues();
        },
      }}
    />
  );

  const screenTwo = (
    <>
      {/* {dmePractice} */}
      {/* <Box sx={{ mt: 5 }}> */}
      {adminAddConfirmation}
      {/* </Box> */}
      <hr />
      {practiceInfo}
      <hr />
      {askForProviders ? askForProviders : null}
      {renderProviders}
      {!askForProviders && notesInput}
    </>
  );
  // Form inputs screens

  // screen navigation buttons
  const NavigateButtons = ({ currentScreen, nextScreen, backScreen }) => {
    const checkForPersist = () => {
      const providersValues = getValues(`providers`);
      if (providersValues?.length) {
        let flags = [...returnIfArr(persistValues?.providers)];
        for (let i = 0; i < providersValues?.length; i++) {
          if (providersValues[i].providerStatus) {
            flags[i] = true;
          }
        }
        setPersistValues((p) => ({ ...p, providers: flags }));
      }
      let getAdminStatus = getValues(`superAdminStatus`);
      if (getAdminStatus) {
        setPersistValues((p) => ({ ...p, admin: true }));
      }
      setScreen(backScreen);
    };

    let firstScreenButtons = (
      <Grid sx={{ textAlign: `center`, mt: 2 }} container columnSpacing={1} rowSpacing={1}>
        {!isGroupAdmin && (
          <Grid sx={{ textAlign: isSmaller ? `center` : `end` }} item xs={12} sm={6} md={6} lg={6} xl={6}>
            <button
              id={css.addProviderBtn}
              size="small"
              className="btn btn-outline-info btn-sm pr-5 pl-5"
              color="primary"
              type="button"
              onClick={() => {
                setPersistValues({
                  providers: [false],
                  admin: false,
                })
                setpracticeGroupValue(``);
                reset();
                setScreen(backScreen);
                if (!isGroupAdmin && practiceGroupValue === `existing`) {
                  setErrors(false);
                  setpracticeGroupsLoading(false);
                }
                adminAdditionalRole?.length > 0 && setadminAdditionalRole(``);
                practiceLogoUrl?.url && setpracticeLogoUrl({ url: ``, practiceLogoName: `` });
              }}
            >
              Back
            </button>
          </Grid>
        )}

        <Grid
          sx={{ ...(isGroupAdmin ? { textAlign: `center` } : { display: `flex`, justifyContent: isSmaller ? `center` : `start` }) }}
          item
          xs={12}
          sm={isGroupAdmin ? 12 : 6}
          md={isGroupAdmin ? 12 : 6}
          lg={isGroupAdmin ? 12 : 6}
          xl={isGroupAdmin ? 12 : 6}
        >
          <button
            ref={firstScreenBtn}
            id={css.addProviderBtn}
            size="small"
            className="btn btn-outline-info btn-sm "
            color="primary"
            type="button"
            onClick={async () => {
              const handleGroupPersistance = () => {
                let getAdminStatus = getValues(`superAdminStatus`);
                if (getAdminStatus) {
                  setPersistValues((p) => ({ ...p, admin: true }));
                }
              };
              if (isGroupAdmin && adminAdditionalRole === `provider`) {
                let result = await trigger([
                  `adminFirstName`,
                  `adminLasttName`,
                  `adminPhoneNumber`,
                  `adminEmail`,
                  `adminProviderNPI`,
                  `adminProviderType`,
                  `adminProviderLicenseno`,
                  `adminProviderLicenseState`,
                ]);
                if (result) {
                  setScreen(nextScreen);
                  handleGroupPersistance();
                } else {
                  errorToast(defaultValidationPhrase);
                }
              } else if (isGroupAdmin && adminAdditionalRole !== `provider`) {
                let result = await trigger([`adminFirstName`, `adminLasttName`, `adminPhoneNumber`, `adminEmail`, `adminProviderNPI`]);
                if (result) {
                  setScreen(nextScreen);
                  handleGroupPersistance();
                } else {
                  errorToast(defaultValidationPhrase);
                }
              } else if (!isGroupAdmin && practiceGroupValue === `new`) {
                let result = await trigger([
                  `groupAdminFirstName`,
                  `groupAdminLastName`,
                  `groupAdminPhoneNumber`,
                  `groupAdminEmail`,
                  `groupAdminGroupName`,
                ]);
                if (result) {
                  setScreen(nextScreen);
                } else {
                  errorToast(defaultValidationPhrase);
                }
              } else if (!isGroupAdmin && practiceGroupValue === `existing`) {
                let result = await trigger([`existingGroupName`]);
                if (result) {
                  setScreen(nextScreen);
                } else {
                  errorToast(defaultValidationPhrase);
                }
              }
            }}
          >
            Next
          </button>
        </Grid>
      </Grid>
    );
    let secondScreenButtons = (
      <Grid sx={{ textAlign: `center`, mt: 2 }} container columnSpacing={1} rowSpacing={1}>
        <Grid sx={{ textAlign: isSmaller ? `center` : `end` }} item xs={12} sm={6} md={6} lg={6} xl={6}>
          <button
            id={css.addProviderBtn}
            size="small"
            className="btn btn-outline-info btn-sm pr-5 pl-5"
            color="primary"
            type="button"
            onClick={() => setScreen(backScreen)}
          >
            Back
          </button>
        </Grid>
        <Grid sx={{ textAlign: isSmaller ? `center` : `start` }} item xs={12} sm={6} md={6} lg={6} xl={6}>
          <button
            id={css.addProviderBtn}
            className={`btn ${submitLoading.error ? `btn-outline-danger` : `btn-outline-info`}  btn-sm ${!submitLoading.loading && !submitLoading.error ? `pl-5 pr-5` : null
              }`}
            type="button"
            color="primary"
            size="small"
            onClick={async () => {
              const result = await trigger([
                `practiceName`,
                `practiceType`,
                `practiceSpeciality`,
                `practiceAddress`,
                `practiceStreet`,
                `practiceCity`,
                `practiceState`,
                `practiceZipCode`,
                `practiceEmail`,
                `practicePhoneNumber`,
                `practiceLogo`,
              ]);
              if (result) {
                setScreen(nextScreen);
              } else {
                errorToast(defaultValidationPhrase);
              }
            }}
          >
            Next
          </button>
        </Grid>
      </Grid>
    );
    let lastScreenButtons = (
      <Container>
        <Grid sx={{ textAlign: `center`, mt: 5 }} container columnSpacing={1} rowSpacing={1}>
          <Grid sx={{ textAlign: isSmaller ? `center` : `start` }} item xs={12} sm={6} md={6} lg={6} xl={6}>
            <button
              id={css.addProviderBtn}
              className="btn btn-outline-info btn-sm"
              style={{ marginRight: `5px` }}
              type="button"
              onClick={() => {
                let getAdminStatus = getValues(`superAdminStatus`);
                if (!isGroupAdmin) {
                  checkForPersist();
                  if (getAdminStatus) {
                    setPersistValues((p) => ({ ...p, admin: true }));
                  }
                } else {
                  checkForPersist();
                }
              }}
            >
              Back
            </button>
          </Grid>
          <Grid sx={{ textAlign: isSmaller ? `center` : `end` }} item xs={6} sm={6} md={6} lg={6} xl={6}>
            {` `}
            <button
              id={css.addProviderBtn}
              className={`btn ${submitLoading.error ? `btn-outline-danger` : `btn-outline-info`}  btn-sm ${!submitLoading.loading && !submitLoading.error ? `pl-5 pr-5` : null
                }`}
              type="submit"
              color="primary"
              size="small"
              onClick={() => handleSubmit(onSubmit, onError)}
            >
              {submitLoading.error ? (
                <ErrorOutlineSharpIcon fontSize="small" />
              ) : submitLoading.loading ? (
                <span className="spinner-border spinner-border-sm text-primary"></span>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </Grid>
        </Grid>
      </Container>
    );
    return <>{currentScreen === `first` ? firstScreenButtons : currentScreen === `second` ? secondScreenButtons : lastScreenButtons}</>;
  };
  // screen navigation buttons

  let screenZero = (
    <>
      {!isGroupAdmin && screen === 0 ? (
        <>
          {screen === 0 && (
            <Container>
              <Box sx={{ mt: 4, ml: isSmaller ? 2 : 5, width: `100%` }}>
                <FormControl sx={{ width: `100%` }}>
                  <Grid container>
                    <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                      <Box sx={{ fontWeight: 600, fontSize: `small`, lineHeight: `17px`, mt: 1.5 }}>Select Group</Box>
                    </Grid>
                    <Grid item xs={8} sm={9} md={9} lg={9} xl={9}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={practiceGroupValue}
                        onChange={(e) => {
                          setpracticeGroupValue(e.target.value);
                          if (e.target.value === `existing`) {
                            setcollapsed(true);
                            setScreen(1);
                          } else {
                            setcollapsed(false);
                            setScreen(1);
                          }
                        }}
                        row
                      >
                        <Grid container>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {` `}
                            <FormControlLabel value="new" control={<Radio size="small" />} label="New" />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <FormControlLabel value="existing" control={<Radio size="small" />} label="Existing" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Box>
            </Container>
          )}
        </>
      ) : (
        isGroupAdmin && screen === 0 && setNext()
      )}
    </>
  );

  let labels = (
    <>
      {isGroupAdmin ? (
        <Container>
          <Stepper activeStep={screen - 1} alternativeLabel>
            {groupAdminSteps.map((label, index) =>
              index === 0 ? null : (
                <Step key={label}>
                  <StepLabel error={index === screen && errorIndex}>{label}</StepLabel>
                </Step>
              )
            )}
          </Stepper>
        </Container>
      ) : (
        <Container>
          <Stepper activeStep={screen} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel error={index === screen && errorIndex}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Container>
      )}
    </>
  );

  let systemAdminForm = (
    <>
      <FormProvider>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {!isGroupAdmin && (
            <Collapse in={practiceGroupValue === `new` && screen === 1} orientation="vertical" timeout={1000}>
              {practiceGroupValue === `new` && screen === 1 ? groupAdmin : null}
              <NavigateButtons currentScreen={`first`} nextScreen={screen + 1} backScreen={screen - 1} />
            </Collapse>
          )}
          {!isGroupAdmin && (
            <Collapse in={practiceGroupValue === `existing` && screen === 1} orientation="vertical" timeout={1000}>
              {practiceGroupValue === `existing` && screen === 1 && (
                <Grid sx={{ mt: 3 }} container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormInput
                      practiceGroups={practiceGroups}
                      setisLoading={setisLoading}
                      auth={auth}
                      emailRegex={emailRegex}
                      errorss={errorss}
                      practiceGroupsLoading={practiceGroupsLoading}
                      isSelect={true}
                      name="existingGroupName"
                      control={control}
                      label="*Group Name"
                      type="text"
                      setValue={setValue}
                      getValues={getValues}
                      setError={setError}
                      setgroupName={setgroupName}
                    />
                  </Grid>
                </Grid>
              )}
              <NavigateButtons currentScreen={`first`} nextScreen={screen + 1} backScreen={screen - 1} />
            </Collapse>
          )}
          {!isGroupAdmin && (
            <Collapse in={practiceGroupValue === `new` && screen === 2} mountOnEnter unmountOnExit orientation="horizontal" timeout={1000}>
              {practiceGroupValue === `new` && screen === 2 ? screenTwo : null}
              <NavigateButtons currentScreen={`last`} nextScreen={screen + 1} backScreen={screen - 1} />
              <hr />
            </Collapse>
          )}
          {!isGroupAdmin && (
            <Collapse in={practiceGroupValue === `existing` && screen === 2} orientation="horizontal" mountOnEnter unmountOnExit timeout={1000}>
              {practiceGroupValue === `existing` && screen === 2 ? screenTwo : null}
              <hr />
              <NavigateButtons currentScreen={`last`} nextScreen={screen + 1} backScreen={screen - 1} />
            </Collapse>
          )}
        </form>
      </FormProvider>
      {!isGroupAdmin && (
        <Collapse in={screen === 3} orientation="vertical" timeout={1000}>
          <Asktwillio auth={auth} practiceId={practiceId} onClose={onClose} />
        </Collapse>
      )}
    </>
  );
  let groupAdminForm = (
    <>
      <FormProvider>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {isGroupAdmin && screen === 1 && isGroupAdmin ? (
            <>
              {adminAddConfirmation}
              <NavigateButtons currentScreen={`first`} nextScreen={screen + 1} backScreen={screen - 1} />
            </>
          ) : null}
          {isGroupAdmin && (
            <Zoom in={screen === 2 && isGroupAdmin} timeout={500}>
              <Box>
                {screen === 2 && isGroupAdmin ? (
                  <>
                    {practiceInfo}
                    <NavigateButtons currentScreen={`second`} nextScreen={screen + 1} backScreen={screen - 1} />
                  </>
                ) : null}
              </Box>
            </Zoom>
          )}
          {isGroupAdmin && (
            <Fade in={screen === 3 && isGroupAdmin} timeout={500}>
              <Box>
                {screen === 3 && isGroupAdmin ? (
                  <>
                    {renderProviders}
                    {askForProviders ? askForProviders : null}
                    {!shoulAddProviders && adminAdditionalRole === `provider` ? null : (
                      <NavigateButtons currentScreen={`last`} nextScreen={screen + 1} backScreen={screen - 1} />
                    )}
                  </>
                ) : null}
              </Box>
            </Fade>
          )}
        </form>
      </FormProvider>
      {isGroupAdmin && (
        <Collapse in={screen === 4} orientation="vertical" timeout={1000}>
          <Asktwillio auth={auth} practiceId={practiceId} onClose={onClose} />
        </Collapse>
      )}
    </>
  );
  return (
    <CustomizedDialogs
      title="Register Practice"
      open={addnewPracticeModalformik}
      setOpen={() => onClose()}
      size="md"
      fullWidth={true}
      notShowDividers={true}
      showClosebutton={false}
      disabled={false}
    >
      <ToastContainer />
      {labels}
      {screenZero}
      {isGroupAdmin ? groupAdminForm : systemAdminForm}
    </CustomizedDialogs>
  );
};
