import React, { useRef, useEffect, useState } from 'react'
import { Box, Collapse, Grid, IconButton, } from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const CollapseSingleWords = ({ children, props }) => {
    const { validHeight = 0, descTxt = `Click on icon`, descTxtStyles = false, contStyles = false } = props || {}
    const [convertIntoCollapse, setConvertIntoCollapse] = useState(false)
    const [showTitle, setShowTitle] = useState(false)
    const container = useRef(null)

    useEffect(() => {
        if (container.current.clientHeight >= validHeight && validHeight) setConvertIntoCollapse(true)
        return () => {
            container.current = null
            setConvertIntoCollapse(false)
        }
    }, [container, validHeight])

    const boxProps = descTxtStyles ? { sx: { ...descTxtStyles } } : {};

    return (
        <Box ref={container} {...contStyles ? { sx: { ...contStyles } } : {}}>
            {convertIntoCollapse ? <Grid container>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Box >
                        <IconButton onClick={() => setShowTitle((p) => !p)}>
                            {showTitle ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </IconButton>
                    </Box>
                </Grid>
                {!showTitle ? <Grid item xs={10} sm={10} md={10} lg={10} xl={10}><Box {...boxProps}>
                    {descTxt}
                </Box></Grid> : null}
                <Collapse in={showTitle} mountOnEnter unmountOnExit >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box>
                            {children}
                        </Box>
                    </Grid>
                </Collapse>

            </Grid> : children}
        </Box>
    )
}
