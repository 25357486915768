import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { Box, Button, Grid, Paper, Stack, Typography, Zoom } from '@mui/material';
import { momentWrapper } from 'src/momentWrapper';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { ADD_COMMENT_TO_SPECIFIC_TASK, getRequest, GET_TASK_BY_ID, postRequest, UPDATE_TASK_DESCRIPTION } from 'src/crud/crud';
import { PuffSpinner } from 'src/pages/common/components/spinner/puff/puff';
import { HtmlFormatter } from 'src/pages/common/components/HtmlFormatter';
import Compose from '../Compose/Compose';
import { CustomBtn } from 'src/pages/common/components/Button/CustomBtn';
import { ConfirmationWrapper } from 'src/pages/common/HOC/ConfirmationWrapper';
import { TaskCreationAndEditing } from '../CreateTask/CreateTask';
import {
  capitalizeFirstLetter,
  invalidDateTimePhraseTaskMang,
  priorityColorTaskMang,
  taskMangPriorityBadgeStyle,
  taskStatusPillCol,
  updateNotificationStatusTaskMang,
} from 'src/utils';
import { FilePreview } from '../CreateTask/FilePreview';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { removeFromTaskNotifications, saveOpenedTaskId } from 'src/redux/taskManager/taskManagerActions';
import { TaskComment } from '../common/TaskComment';
import { BadgePill, MuiPill } from 'src/pages/common/components/BadgePill/BadgePill';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { DateTimePickerMui } from 'src/pages/common/components/datePicker/DatePickerCustomize';

let commonStyles = {
  assigned: {
    fontSize: `11px`,
    color: `#4e4848`,
  },
  pillBorderAndColor: {
    color: `white`,
    borderRadius: `none`,
  },
  fontBold: {
    fontWeight: `bold`,
  },
};
const { assigned: assignedStyles, fontBold, pillBorderAndColor } = commonStyles;

const RenderDueDate = ({ value, setValue, renderDueDateCont }) => {
  const [openDateSelection, setOpenDateSelection] = useState(false);
  const [onCloseHappened, setOnCloseHappened] = useState(false);
  const [openDueDateConf, setOpenDueDateConf] = useState({ open: false, confirmed: false });
  const [error, setError] = useState();
  useEffect(() => {
    if (onCloseHappened && value) {
      const dueDateValidation = (value) => {
        let dateDiff = moment(value).diff(moment(new Date()), `minutes`);
        let isBeforeCurrentDate = moment(new Date()).isAfter(moment(value));
        if (isBeforeCurrentDate) {
          setOpenDueDateConf((p) => ({ ...p, open: true, isBeforSelection: true }));
        } else if (dateDiff <= 5) setOpenDueDateConf((p) => ({ ...p, open: true }));
      };
      dueDateValidation(value);
    }
  }, [value, onCloseHappened]);
  return (
    <Zoom in={renderDueDateCont} mountOnEnter unmountOnExit>
      <Box sx={{ width: `100%` }}>
        <ConfirmationWrapper
          sx={{ zIndex: 1000 }}
          open={openDueDateConf.open}
          title="Attention Due Date Settings!"
          description={invalidDateTimePhraseTaskMang(value)}
          successText="Ok"
          onClose={() => setOpenDueDateConf((p) => ({ confirmed: true, open: false }))}
          successFunc={() => {
            setOnCloseHappened(false);
            setOpenDueDateConf((p) => ({ confirmed: true, open: false }));
            if (openDueDateConf?.isBeforSelection) setOpenDateSelection(true);
          }}
        >
          {` `}
          <DateTimePickerMui
            {...(openDateSelection ? { disablePast: true } : { disablePast: false })}
            disableOpenPicker={true}
            onBlur={(value) => {
              if (!value) setError(`Required`);
              else setError(``);
            }}
            onClose={() => {
              setOpenDateSelection(false);
              setOnCloseHappened(true);
            }}
            open={openDateSelection}
            onOpen={() => setOpenDateSelection(true)}
            onClick={() => setOpenDateSelection(true)}
            error={!!error}
            heplerText={error || ``}
            label="Due Date*"
            value={value ? moment(value).toDate() : null}
            onChange={(date) => setValue(moment(date?.$d))}
          />
          {error && <Box sx={{ color: `red` }}>{error}</Box>}
        </ConfirmationWrapper>
      </Box>
    </Zoom>
  );
};

export const TaskAssigneeBar = ({ taskDetail, setSelectedTaskModalHandler }) => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const assignedBy = taskDetail?.assignedBy ? taskDetail?.assignedBy : {};
  const recurring = taskDetail?.recurring ? taskDetail?.recurring : { recurrStatus: `off` };
  const assignedTo = taskDetail?.assignedTo ? taskDetail?.assignedTo : {};
  const { recurrStatus } = recurring;
  const { firstName: assignedByFirstName = ``, lastName: assignedByLasName = `` } = assignedBy;
  const { firstName: assignedToFirstName = ``, lastName: assignedToLasName = `` } = assignedTo;
  let _patientId = taskDetail?._patientId ? taskDetail?._patientId : {};
  const { firstName: assignedPatientFirstName = ``, lastName: assignedPatientLasName = ``, _id: patientId = `` } = _patientId;
  const isPatientWindow = pathname === `/patient`;
  const showPatientInfo = !!(typeof taskDetail?._patientId === `object` && taskDetail?._patientId);
  const moveToPatientModule = (patientId) => {
    if (!isPatientWindow) history.push({ pathname: `/patient`, patientId });
    setSelectedTaskModalHandler();
  };

  return (
    <Paper
      sx={{
        position: `sticky`,
        top: 0,
        p: 1,
        zIndex: 11,
      }}
    >
      <Stack sx={{ height: `100%` }} direction={`row`} justifyContent={`space-between`}>
        <Box sx={{ ...assignedStyles }}>
          <Typography>Assigned By</Typography>
          <Typography sx={{ color: `#777`, ...fontBold }}>{capitalizeFirstLetter(`${assignedByFirstName} ${assignedByLasName}`)}</Typography>
        </Box>
        <Box sx={{ ...assignedStyles }}>
          <Typography>Assigned To</Typography>
          <Typography sx={{ color: `#ea4346`, ...fontBold }}>{capitalizeFirstLetter(`${assignedToFirstName} ${assignedToLasName}`)}</Typography>
        </Box>
        {showPatientInfo ? (
          <ToolTipProvider
            toolTipProps={{
              arrow: true,
              placement: `top`,
              title: isPatientWindow ? `` : `Click to get patient details!`,
            }}
            element={
              <Box sx={{ ...assignedStyles, ...(!isPatientWindow ? { cursor: `pointer` } : {}) }} onClick={() => moveToPatientModule(patientId)}>
                <Typography>Patient</Typography>
                <Typography sx={{ ...fontBold, color: `#1699c5` }}>
                  {capitalizeFirstLetter(`${assignedPatientFirstName} ${assignedPatientLasName}`)}
                </Typography>
              </Box>
            }
          />
        ) : null}

        <Box>
          <Stack spacing={1} direction="row" justifyContent={`space-between`}>
            {taskDetail?.taskStatus && (
              <Box>
                <MuiPill
                  pillProps={{
                    sx: {
                      backgroundColor: taskStatusPillCol[taskDetail?.taskStatus],
                      ...pillBorderAndColor,
                    },
                    size: `small`,
                  }}
                >
                  {capitalizeFirstLetter(taskDetail?.taskStatus)}
                </MuiPill>
              </Box>
            )}
            {recurrStatus === `on` ? (
              <Box>
                <MuiPill
                  pillProps={{
                    sx: {
                      backgroundColor: `#56c516`,
                      ...pillBorderAndColor,
                    },
                    size: `small`,
                  }}
                >
                  Recurring
                </MuiPill>
              </Box>
            ) : null}
            {taskDetail?.taskPriority && <Box>{priorityColorTaskMang[taskDetail?.taskPriority]}</Box>}
            {taskDetail?.taskType && (
              <Box>
                <MuiPill
                  pillProps={{
                    sx: {
                      backgroundColor: `#1699c5`,
                      ...pillBorderAndColor,
                    },
                    size: `small`,
                  }}
                >
                  {taskDetail?.taskType}
                </MuiPill>
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export const ViewTask = ({ isNotify, selectedTaskModalHandler, setSelectedTaskModalHandler, selectedTask, setTaskListFlag }) => {
  const { _id: taskId } = selectedTask || false || [];
  const { user } = useSelector(({ user }) => user);
  const { firstName: userFirstName, lastName: userLastName, _id: userId } = user;
  const dispatch = useDispatch();
  const renderCount = useRef(0);
  const [openEditings, setOpenEditings] = useState({
    confirmation: false,
    editing: false,
  });
  const { confirmation, editing } = openEditings;
  const editingsHandler = ({ key, value }) => {
    setOpenEditings((p) => ({ ...p, [key]: value }));
  };

  const [taskDetail, setTaskDetail] = useState({});
  const [taskComments, setTaskComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(``);
  const [fetchTask, setFetchTask] = useState(0);
  const [openDueDateConf, setOpenDueDateConf] = useState(false);
  const [dueDateVal, setDueDateVal] = useState(``);
  const [renderDueDateCont, setRenderDueDateCont] = useState(false);
  const makeDueDateConfInverse = () => setOpenDueDateConf((p) => !p);
  const makeRenderDueDateConfInverse = () => setRenderDueDateCont((p) => !p);
  const resetDueDateSettings = () => {
    setDueDateVal(``);
    if (renderDueDateCont) makeRenderDueDateConfInverse();
    if (openDueDateConf) makeDueDateConfInverse();
  };
  const commentBox = useRef(null);
  console.log(taskDetail, `taskDetail`);
  useEffect(() => {
    if (commentBox.current) commentBox.current.scrollTop = commentBox.current.scrollHeight;
  }, [taskComments.length]);
  const setDetails = useCallback(() => {
    if (selectedTask || fetchTask) {
      setLoading(true);
      getRequest(`${GET_TASK_BY_ID}${taskId}`)
        .then((res) => {
          setLoading(false);
          const { data } = res?.data || {};
          const { comments } = data || [];
          setTaskDetail(data);
          setTaskComments(comments);
        })
        .catch(() => setLoading(false));
    }
  }, [selectedTask, fetchTask]);

  useEffect(() => {
    if (renderCount.current === 0) {
      dispatch(saveOpenedTaskId(taskId));
      if (isNotify) {
        updateNotificationStatusTaskMang(selectedTask);
      }
      dispatch(removeFromTaskNotifications(selectedTask));
      renderCount.current = renderCount.current + 1;
    }
    setDetails();
  }, [setDetails, renderCount, isNotify, taskId]);

  // SAVING COMMENT HANDLER
  const savingCommentHandler = (taskId) => {
    if (!comment) return;
    setLoading(true);
    let payLoad = { comment: comment, _added_by: user?._id };
    postRequest(ADD_COMMENT_TO_SPECIFIC_TASK + taskId, { ...payLoad })
      .then(() => {
        setLoading(false);
        setTaskComments((p) => [
          ...p,
          {
            ...payLoad,
            _added_by: { _id: payLoad._added_by, firstName: userFirstName, lastName: userLastName },
            createdAt: momentWrapper(new Date()),
          },
        ]);
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
      });
  };

  const doOnUpdateTask = (isRecMsg) => {
    if (isRecMsg) successToast(isRecMsg);
    setLoading(false);
    setFetchTask((p) => p + 1);
    setTaskListFlag((p) => p + 1);
  };

  const updateTask = async (taskId, payLoad, isRecMsg) => {
    setLoading(true);
    return postRequest(UPDATE_TASK_DESCRIPTION + taskId, { ...payLoad, updatedModules: `onlyEnteries` })
      .then(() => doOnUpdateTask(isRecMsg))
      .catch(() => {
        setLoading(false);
      });
  };

  const saveComment = useCallback(() => {
    if (comment) savingCommentHandler(taskId);
  }, [comment, taskId]);
  useEffect(() => {
    const appendInComments = (newComment) => {
      const { _id: commentTaskId } = newComment;
      if (taskId === commentTaskId) {
        setTaskComments((p) => [...p, { ...newComment }]);
      }
    };
    newSocket.on(`taskComment`, appendInComments);
    saveComment();
    return () => newSocket.off(`taskComment`, appendInComments);
  }, [saveComment, taskId]);
  const closeModal = () => {
    dispatch(saveOpenedTaskId(``));
    setOpenEditings({
      confirmation: false,
      editing: false,
    });
    setTaskDetail({});
    setTaskComments([]);
    setLoading(false);
    setComment(``);

    setSelectedTaskModalHandler((selectedTaskModalHandler) => !selectedTaskModalHandler);
  };

  let isAssigneeOrRepoter = !!(taskDetail?.assignedBy?._id === userId || taskDetail?.assignedTo?._id === userId);

  const doForDueDateChnage = (payLoad) => {
    updateTask(taskDetail?._id, payLoad)
      .then(() => {
        doOnUpdateTask();
        resetDueDateSettings();
      })
      .catch();
  };

  let taskListFlagUpdateHandler;

  if (setTaskListFlag) {
    taskListFlagUpdateHandler = () => {
      setFetchTask((p) => p + 1);
      setTaskListFlag((p) => p + 1);
      setTaskDetail({});
    };
  } else {
    taskListFlagUpdateHandler = () => {
      setTaskDetail({});
      setFetchTask((p) => p + 1);
    };
  }

  return (
    <>
      <CustomizedDialogs
        title={
          <Stack sx={{ width: `90%` }} direction={`row`} justifyContent="space-between">
            <Box>Task Detail</Box>
            {taskDetail?.taskStatus === `closed` ? (
              <Box>
                <Stack direction="row" spacing={1}>
                  <ConfirmationWrapper
                    loading={loading}
                    open={openDueDateConf}
                    successFunc={() => {
                      if (!renderDueDateCont) makeRenderDueDateConfInverse();
                      else {
                        const validateAndUpdate = () => {
                          if (dueDateVal) {
                            let payLoad = {
                              taskStatus: `opened`,
                              updatedModules: `onlyEnteries`,
                              dueDate: moment(dueDateVal).toISOString(),
                            };
                            doForDueDateChnage(payLoad);
                          } else errorToast(`Please select date/time.`);
                        };
                        validateAndUpdate();
                      }
                    }}
                    cancelFunc={() => doForDueDateChnage({ taskStatus: `opened`, updatedModules: `onlyEnteries` })}
                    successText={`Yeah change the due date!`}
                    cancelText={`No just re-open the task!`}
                    title={`Attention Re-open Task!`}
                    description="You should alter due date if you want to Re-open the task!"
                    renderContent={<RenderDueDate renderDueDateCont={renderDueDateCont} value={dueDateVal} setValue={setDueDateVal} />}
                  >
                    <Box>
                      <CustomBtn
                        btnProps={{
                          onClick: makeDueDateConfInverse,
                          style: { borderRadius: `15px`, padding: `2px`, fontSize: `11px`, width: `110px` },
                        }}
                        element="Re-open Task"
                      />
                    </Box>
                  </ConfirmationWrapper>

                  {taskDetail?.taskStatus === `closed` ? (
                    <Box>
                      <BadgePill type={`badge-danger`}>
                        Closed Date: {taskDetail?.lastClosedAt ? moment(taskDetail?.lastClosedAt).format(`MM/DD/YYYY hh:mm A`) : `-`}
                      </BadgePill>
                    </Box>
                  ) : null}
                </Stack>
              </Box>
            ) : null}
          </Stack>
        }
        open={selectedTaskModalHandler}
        setOpen={() => closeModal()}
        size="xl"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <Box sx={{ height: `75vh` }}>
          {loading && <PuffSpinner />}
          <Grid container>
            <Zoom style={{ transitionDelay: `150ms` }} in={!!Object.keys(taskDetail)?.length} mountOnEnter unmountOnExit>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <Box
                  sx={{ height: `75vh`, pr: 2, pl: 2, borderRight: 1, borderColor: `#cacaca`, overflowY: `scroll` }}
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <TaskAssigneeBar taskDetail={taskDetail} setSelectedTaskModalHandler={() => setSelectedTaskModalHandler((p) => !p)} />
                  {/* Task Body ------ Title and Desc */}
                  <Stack sx={{ height: `90%` }} direction={`column`} justifyContent={`space-between`}>
                    <Box>
                      <Typography variant="h4" sx={{ mt: 2, wordWrap: `break-word` }}>
                        {taskDetail?.taskName}
                      </Typography>
                      <Box sx={{ p: 2, pt: 1 }}>
                        <HtmlFormatter title={taskDetail?.taskDescription} />
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        <Grid container spacing={1.5}>
                          {taskDetail?.taskAttachments?.length && !editing
                            ? taskDetail?.taskAttachments?.map((file, index) => (
                                <Grid key={file?.id} xs={4} sm={4} md={4} lg={4} xl={4} item>
                                  <FilePreview file={file} />
                                </Grid>
                              ))
                            : null}
                        </Grid>
                      </Box>
                      {editing ? (
                        <TaskCreationAndEditing
                          hasValues={taskDetail}
                          mount={editing}
                          isFromEditing={true}
                          setCreateTaskModalHandler={() => editingsHandler({ key: `editing`, value: false })}
                          setTaskListFlag={taskListFlagUpdateHandler}
                        />
                      ) : (
                        <ConfirmationWrapper
                          open={confirmation}
                          successFunc={() => {
                            editingsHandler({ key: `confirmation`, value: false });
                            editingsHandler({ key: `editing`, value: true });
                          }}
                          cancelFunc={() => editingsHandler({ key: `confirmation`, value: false })}
                          successText={`Edit`}
                          cancelText="Cancel"
                          title="Edit Task Details!"
                          description="Do you want to edit the task details?"
                        >
                          <Box sx={{ width: `100%`, textAlign: `end`, alignSelf: `end` }}>
                            <CustomBtn
                              element={<>Edit Task</>}
                              btnProps={{
                                style: { width: `30%`, borderRadius: `5px`, marginTop: `10px` },
                                type: `button`,
                                className: `btn btn-outline-info btn-sm`,
                                onClick: () => editingsHandler({ key: `confirmation`, value: true }),
                              }}
                            />
                          </Box>
                        </ConfirmationWrapper>
                      )}
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Zoom>
            <Zoom style={{ transitionDelay: `300ms` }} in={!!Object.keys(taskDetail)?.length} mountOnEnter unmountOnExit>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} sx={{ p: 1, position: `relative` }}>
                <Box sx={{ height: `75vh` }}>
                  <Paper sx={{ p: 1 }}>
                    <Stack direction="row" justifyContent={`space-between`}>
                      <Box>
                        <Typography>CREATED</Typography>
                        <Typography sx={{ fontWeight: `bold`, color: `#777` }}>{moment(taskDetail?.createdAt).format(`MM/DD/YYYY`)}</Typography>
                      </Box>
                      <Box>
                        <Typography>DUE DATE</Typography>
                        <Typography sx={{ fontWeight: `bold`, color: `#ea4346` }}>
                          {moment(taskDetail?.dueDate).format(`MM/DD/YYYY hh:mm A`)}
                        </Typography>
                      </Box>
                      {isAssigneeOrRepoter ? (
                        <Box>
                          <Button
                            variant={taskDetail?.taskStatus === `closed` ? `contained` : `outlined`}
                            color={taskDetail?.taskStatus === `closed` ? `success` : `primary`}
                            sx={{
                              ...(taskDetail?.taskStatus === `closed` ? { pointerEvents: `none` } : {}),
                              ...taskMangPriorityBadgeStyle,
                              color: taskDetail?.taskStatus === `closed` ? `white` : `black`,
                            }}
                            onClick={() =>
                              taskDetail?.taskStatus === `closed`
                                ? null
                                : updateTask(
                                    taskDetail?._id,
                                    { taskStatus: `closed` },
                                    taskDetail?.recurring?.recurrStatus === `on` ? `The task is completed and due date is also updated` : false
                                  )
                            }
                          >
                            {taskDetail?.taskStatus === `closed` ? `Completed` : `Mark Completed`}
                          </Button>
                        </Box>
                      ) : null}
                    </Stack>
                  </Paper>
                  <Box ref={commentBox} sx={{ margin: `8px 0px`, pr: 0.5, pl: 0.5, overflowY: `scroll`, height: `58vh` }}>
                    {taskComments?.length > 0 ? (
                      taskComments?.map((comment, index) => <TaskComment comment={comment} key={index} taskDetail={taskDetail} />)
                    ) : (
                      <Box>No Comments Added</Box>
                    )}
                  </Box>
                  <Box>
                    <Compose selectedTaskId={taskId} savingCommentHandler={setComment} />
                  </Box>
                </Box>
              </Grid>
            </Zoom>
          </Grid>
        </Box>
      </CustomizedDialogs>
    </>
  );
};
