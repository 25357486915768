import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import avatar from '../../../../../../../assets/default-img.png';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { PulseSpinner } from '../../../../../../common/components/spinner/spinner';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { ToastContainer } from 'react-toastify';
import {
  UPDATE_USER_PROFILE, patchRequest, UPDATE_ADMIN_USER_PROFILE, ADD_SIGNATURE_URL,
  postRequest, CHECK_PRICTICE_GROUP_AVAILABILITY, getRequest, CHECK_EMAIL_USERNAME_AVAILABILITY_URL,
} from '../../../../../../../crud/crud';
import { setUserHandler, setUserName } from '../../../../../../../redux/action/setUserHandler';
import css from './editUser.module.css';
import { momentWrapper } from 'src/momentWrapper';
import { capitalizeFirstLetter, phoneMask, spreadUniqueKeyProp } from 'src/utils';
import { FileInput, InputField } from 'src/pages/common/components/InputField/InputField';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { useUniqueId } from 'src/pages/common/hooks/useUniqueId';
import { emailRegex, phoneRegex } from 'src/regexes';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const EditUser = ({ userData, show, setShow, setUserData, _listOfSignatures, setHasEdited }) => {
  const { user } = useSelector((state) => state?.user)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const [firstName, setFirstName] = useState(``);
  const [lastName, setLastName] = useState(``);
  const [dateOfBirth, setDateOfBirth] = useState();
  const [phoneNumber, setPhoneNumber] = useState(``);
  const [email, setEmail] = useState(``);
  const [userId, setUserId] = useState(``);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [isPhoneAvailable, setIsPhoneAvailable] = useState(true);
  const [profilePic, setProfilePic] = useState(``);
  const [signatureError, setSignatureError] = useState(false);
  const [signatureList, setSignatureList] = useState([]);
  const [TFA, setTFA] = useState(false);
  const [validPhoneFlag, setValidPhoneFlag] = useState(false)
  const phoneRef = useRef(null);
  // CONVERTING IMAGE TO BASE-64

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function () { };
  }

  // UPLOADING SIGNATURE
  const uploadSignature = (picture) => {
    if (picture.target.files.length === 0) {
      return;
    }

    const validImageTypes = [`image/jpeg`, `image/png`];
    if (!validImageTypes.includes(picture.target.files[0].type) || picture.target.files[0].size > MAX_FILE_SIZE) {
      // invalid file type code goes here.
      setSignatureError(true);
      return;
    } else {
      setSignatureError(false);
    }
    getBase64(picture.target.files[0], (result) => {
      if (result) {
        setLoading(true);
        postRequest(ADD_SIGNATURE_URL, { signature: result })
          .then((response) => {
            dispatch(setUserHandler({ ...user, defaultSignature: response?.data.signature }))
            setSignatureList([response?.data?.signature]);
            setLoading(false);
            successToast(response?.data?.message);
          })
          .catch(() => {
            setLoading(false);

          });
      }
    });
  };

  // VALIDATE FIRST NAME
  const validateFirstName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setFirstName(val);
    }
  };

  // VALIDATE LAST NAME
  const validateLastName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setLastName(val);
    }
  };



  const checkValidPhone = () => {
    if (!phoneRegex.test(phoneRef.current.value)) {
      setValidPhoneFlag(true);
    } else {
      setValidPhoneFlag(false);
    }
  };

  // CHECK VALIDATIONS, EMAIL & PHONE NUMBER
  const isAvailable = (val, key) => {
    if (key == `groupName`) {
      if (val?.length > 0) {
        setLoading(true);
        let route = `${CHECK_PRICTICE_GROUP_AVAILABILITY}?name=${val}`
        getRequest(route)
          .then(() => {
            setLoading(false);
            successToast(`Availabe`);
          })
          .catch(() => {
            errorToast(`Some Error Occured!`);
            setLoading(false);

          });
      } else {
        return null;
      }
    } else if (!key == `groupName`) {
      if (key === `email`) {
        if (userData.email === val) {
          setIsEmailAvailable(true);
          return;
        }

        if (!emailRegex.test(val)) {
          errorToast(`Invalid email address`);
          return;
        }
      }
      if (key === `phoneNumber`) {
        if (userData.phoneNumber === val) {
          setIsPhoneAvailable(true);
          return;
        }
      }
      setLoading(true);
      postRequest(CHECK_EMAIL_USERNAME_AVAILABILITY_URL, { userName: val })
        .then(() => {
          //
          setLoading(false);
          successToast(`Available`);
          if (key === `email`) {
            setIsEmailAvailable(true);
          }
          if (key === `phoneNumber`) {
            setIsPhoneAvailable(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorToast(err?.response?.data?.message ? err?.response?.data?.message : err?.response?.data?.error);
          if (key === `email`) {
            setIsEmailAvailable(false);
          }
          if (key === `phoneNumber`) {
            setIsPhoneAvailable(false);
          }
        });
    }
  };

  useEffect(() => {
    setFirstName(capitalizeFirstLetter(userData.firstName));
    setLastName(capitalizeFirstLetter(userData.lastName));
    setTFA(userData?.tfa ? userData?.tfa : false);
    if (userData?.dateOfBirth) {
      setDateOfBirth(momentWrapper(userData?.dateOfBirth).toDate());
    }
    setPhoneNumber(userData.phoneNumber);
    setEmail(userData.email);
    setUserId(userData?._id);
    setProfilePic(userData.profilePhotoPath);
    setSignatureList(_listOfSignatures);
  }, [userData]);

  // FORM SUBMIT HANDLER
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isPhoneAvailable || !isEmailAvailable) {
      return;
    }
    const validImageTypes = [`image/jpeg`, `image/png`];
    const formData = new FormData(e.target);
    formData.append(`tfa`, TFA);
    for (let [key, value] of formData.entries()) {
      if (key === `firstName`) {
        if (!value) {
          errorToast(`First name is required`);
          return;
        }
      }
      if (key === `lastName`) {
        if (!value) {
          errorToast(`Last name is required`);
          return;
        }
      }
      if (key === `email`) {
        if (!emailRegex.test(value)) {
          errorToast(`Invalid email address`);
          return;
        }
      }
      if (key === `profilePhoto`) {
        if (value.size) {
          if (!validImageTypes.includes(value.type) || value.size > MAX_FILE_SIZE) {
            errorToast(`Only JPEG, PNG are allowed & Max allowed file size is 5 MB`);
            return;
          }
        }
      }

      if (key === `day`) {
        formData.delete(`day`);
      }
      if (key === `month`) {
        formData.delete(`month`);
      }
      if (key === `year`) {
        formData.delete(`year`);
      }
    }

    formData.delete(`day`);
    formData.delete(`month`);
    formData.delete(`year`);
    const DOB = formData.get(`dateOfBirth`);
    formData.set(`dateOfBirth`, momentWrapper(DOB, `MM/DD/YYYY`).format(`YYYY-MM-DD`));

    if (user?.isSystemAdministrator) {
      setLoading(true);
      patchRequest(UPDATE_ADMIN_USER_PROFILE + user._id, formData)
        .then((response) => {
          setUserData(response?.data?.user);
          dispatch(setUserName({
            firstName: response?.data?.user?.firstName,
            lastName: response?.data?.user?.lastName,
          }))

          successToast(`User profile edit successfully`);
          setShow(false);
          setLoading(false);
          setHasEdited(true);
        })
        .catch((err) => {
          setLoading(false);

        });
    } else {
      setLoading(true);
      let route = `${UPDATE_USER_PROFILE}${user._id}/${user?._practiceId}`
      patchRequest(route, formData)
        .then((response) => {
          setUserData(response?.data?.user);
          dispatch(setUserName({
            firstName: response?.data?.user?.firstName,
            lastName: response?.data?.user?.lastName,
          }))
          successToast(`User profile edit successfully`);
          setShow(false);
          setLoading(false);
          setHasEdited(true);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    try {
      const base64 = await convertBase64(file);
      if (base64) {
        setProfilePic(base64);
      }
    } catch (error) {
      return;
    }
  };

  const convertBase64 = (file) => {
    if (file === undefined) {
      return;
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  let fileClass = signatureError ? `is-invalid` : ``
  const signatureKey = useUniqueId()
  return (
    <div>
      <CustomizedDialogs noTitle={true} title="Edit Profile" open={show} setOpen={() => handleClose()} size="md" fullWidth={true}>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row">
              <div className="col-6 d-flex flex-wrap">
              </div>
              <div className="col-6 d-flex justify-content-end pr-4">
                <img
                  src={profilePic || avatar}
                  alt="Profile"
                  width="50px"
                  height="50px"
                  style={{
                    border: `1px solid darkgray`,
                    borderRadius: `26px`,
                  }}
                />
              </div>
            </div>
            <div className="d-flex w-100">
              <div className="mt-2" style={{ width: `100%` }}>
                <div className="w-100">
                  <div className="d-flex flex-wrap">
                    <div style={{ marginLeft: 0, width: `32%` }}>
                      <div className="form-group">
                        <InputField
                          size='small'
                          fullWidth={true}
                          label='First Name'
                          onChange={(e) => validateFirstName(e.target.value)}
                          value={firstName}
                          name="firstName"
                          type="text"
                          id="firstName"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: `1%`, width: `32%` }}>
                      <div className="form-group">
                        <InputField
                          size='small'
                          fullWidth={true}
                          label='Last Name'
                          onChange={(e) => validateLastName(e.target.value)}
                          value={lastName}
                          type="text"
                          name="lastName"
                          id="lastName"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: `1%`, width: `32%` }}>
                      <div className="form-group">
                        <InputField
                          size='small'
                          fullWidth={true}
                          label='Email'
                          type="text"
                          onBlur={() => isAvailable(email, `email`)}
                          onChange={(e) => setEmail(e.target.value)}
                          name="email"
                          id="email"
                          value={email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 mt-2">
                  <div className="d-flex flex-wrap">
                    <div style={{ marginLeft: 0, width: `32%` }}>
                      <div className="form-group">
                        <InputField
                          size='small'
                          fullWidth={true}
                          label='Phone Number'
                          onBlur={() => {
                            isAvailable(phoneNumber, `phoneNumber`)
                            phoneMask(phoneNumber, setPhoneNumber);
                            checkValidPhone();
                          }}
                          inputProps={{
                            ref: phoneRef
                          }}
                          onChange={(e) => {
                            phoneMask(e.target.value, setPhoneNumber)
                            checkValidPhone()
                          }}
                          name="phoneNumber"
                          value={phoneNumber}
                          type="text"
                          id="phoneNumber"
                        />
                        {validPhoneFlag && (
                          <p style={{ color: `red` }}>Phone Number Must Be Valid</p>
                        )}
                      </div>
                    </div>
                    <div style={{ marginLeft: `1%`, width: `32%` }}>
                      <div className="form-group">
                        <DatePickerCustomize
                          name="dateOfBirth"
                          dateInputValue={dateOfBirth || null}
                          setDateInputValue={setDateOfBirth}
                          pickerProps={{ size: `small`, label: `DOB` }}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: `1%`, width: `32%` }}>
                      <FileInput
                        inputProps={{
                          onChange: (e) => handleImageUpload(e),
                          accept: `.jpg, .jpeg, .png`,
                          id: `profilePhoto`,
                          name: `profilePhoto`
                        }}
                        fileBtnProps={{
                          endIcon: <UploadFileRoundedIcon sx={{ color: `white` }} />,
                          fullWidth: true,
                          variant: `contained`, sx: { backgroundColor: `#1699c5`, textTransform: `none` }, component: `span`,
                          uploadText: `Profile Picture`
                        }}
                      />

                    </div>
                    <div className='mt-2' style={{ width: `32%` }}>
                      <div className="form-group">
                        <InputField
                          size='small'
                          fullWidth={true}
                          label='User ID'
                          type="text"
                          disabled
                          name="User_Id"
                          id="User_Id"
                          value={userId}
                        />
                      </div>
                    </div>

                  </div>
                </div>
                {!user?.isClinicalStaff && (
                  <>
                    <hr />
                    {user?.isGroupAdmin && (
                      <>
                        <span className={css.groupNameSpan}>Group Name :</span>
                        <span className={css.groupName}>{capitalizeFirstLetter(user?.groupName)}</span>
                      </>
                    )}

                    {(!user.isSystemAdministrator && !user.isSuperAdmin) || user.isProvider || user.isClinicalStaff || user.isBiller ? (
                      <div className="w-100 mt-2">
                        {!user?.isBiller && !user?.isGroupAdmin ? <div className="d-flex flex-wrap">
                          <div style={{ width: `65%` }}>
                            <div className="input-group mb-4">
                              <div className={`custom-file ${fileClass}`}>
                                <FileInput
                                  inputProps={{
                                    onChange: uploadSignature,
                                    accept: `.jpg, .jpeg, .png`,
                                    id: `inputGroupFile04`,
                                  }}
                                  fileBtnProps={{
                                    endIcon: <UploadFileRoundedIcon sx={{ color: `white` }} />,
                                    fullWidth: true,
                                    variant: `contained`, sx: { backgroundColor: `#1699c5`, textTransform: `none` }, component: `span`,
                                    uploadText: `Choose signature`
                                  }}
                                />
                              </div>
                              <div className="invalid-feedback">
                                <ul
                                  style={{
                                    listStyle: `initial`,
                                    paddingLeft: `4%`,
                                  }}
                                >
                                  <li>Only JPEG and PNG files are allowed</li>
                                  <li>Max allowed file size is 5 MB</li>
                                </ul>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 d-flex">
                                {signatureList?.map((signature, ind) => {
                                  let key = `${signatureKey}${ind + 1}`
                                  return <img
                                    src={signature?.base64String}
                                    {...spreadUniqueKeyProp(key)}
                                    alt="Signature"
                                    width="60px"
                                    height="60px"
                                    style={{
                                      border: `1px solid darkgray`,
                                      marginRight: `5px`,
                                    }}
                                  />
                                })}
                              </div>
                            </div>
                          </div>
                        </div> : null}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
          {!user?.isGroupAdmin && <hr style={{ marginTop: 20 }} />}
          <div style={{ display: `flex`, justifyContent: `flex-end` }}>
            <button className="btn btn-outline-info" disabled={!!(validPhoneFlag || loading)} type="submit" id={css.addNewUserModalButton}>
              Save
            </button>
          </div>
        </form>
        {loading && <PulseSpinner />}
      </CustomizedDialogs>
    </div>
  );
}