import React from 'react';
import { Patients } from './PatientComponent';

export const Patient = () => {
  return (
    // <PatientContextProvider>
    <Patients />
    // </PatientContextProvider>
  );
};
