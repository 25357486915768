import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import css from './addNewPatient.module.css';
import { appDevices } from 'src/utils';
import momentOriginal from 'moment';
import {
  postRequest,
  ADD_PATIENT_WITN_NO_DEVICE,
} from '../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../common/components/spinner/spinner';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { newPatientAddedManully } from '../../../../../../redux/resmed/resmedActions';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { Box, Grid } from '@mui/material';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';



export const AddNewPatient = ({ addNewPatientModal, setAddNewPatientModal }) => {
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState(``);
  const [firstNameError, setFirstNameError] = useState({
    status: false,
    msg: ``,
  });
  const [lastName, setLastName] = useState(``);
  const [lastNameError, setLastNameError] = useState({
    status: false,
    msg: ``,
  });
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateOfBirthError, setDateOfBirthError] = useState({
    status: false,
    msg: ``,
  });
  const [manufacturer, setManufacturer] = useState(``);
  const [manufacturerError, setManufacturerError] = useState({
    status: false,
    msg: ``,
  });

  const [loading, setLoading] = useState(false);
  const validateInput = async (field) => {
    let temp = { status: false, msg: `` };
    switch (field) {
      case `firstName`:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
        } else {
          setFirstNameError(temp);
        }
        break;
      case `lastName`:
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
        } else {
          setLastNameError(temp);
        }
        break;
      case `dateOfBirth`:
        if (!dateOfBirth) {
          temp.status = true;
          temp.msg = `*Required`;
          setDateOfBirthError(temp);
        } else {
          setDateOfBirthError(temp);
        }
        break;

      case `manufacturer`:
        if (!manufacturer.length) {
          temp.status = true;
          temp.msg = `*Required`;
          setManufacturerError(temp);
        } else {
          setManufacturerError(temp);
        }
        break;
      default:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
          return false;
        }
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
          return false;
        }
        if (!dateOfBirth) {
          temp.status = true;
          temp.msg = `*Required`;
          setDateOfBirthError(temp);
          return false;
        }
        if (!manufacturer.length) {
          temp.status = true;
          temp.msg = `*Required`;
          setManufacturerError(temp);
          return false;
        }

        return true;
    }
  };

  const resetForm = () => {
    setFirstName(``);
    setFirstNameError({ status: false, msg: `` });
    setLastName(``);
    setLastNameError({ status: false, msg: `` });
    setDateOfBirth(null);
    setDateOfBirthError({ status: false, msg: `` });
    setManufacturer(``);
    setManufacturerError({ status: false, msg: `` });
  };

  const submitHandler = async () => {
    try {
      const temp = await validateInput();
      if (temp) {
        let dob = momentOriginal(dateOfBirth).format(`YYYY-MM-DD`);
        setLoading(true);
        postRequest(ADD_PATIENT_WITN_NO_DEVICE, {
          firstName,
          lastName,
          dateOfBirth: dob,
          deviceManufacturer: manufacturer,
        })
          .then(() => {
            successToast(`Patient is being added. Please Wait!`);
            dispatch(newPatientAddedManully());
            resetForm();
            setLoading(false);
          })
          .catch((err) => {
            errorToast(err?.response?.data?.message ? err.response?.data?.message : err.response?.data?.error);
            setLoading(false);
          });

      } else {
        errorToast(`Unable to send request`);
      }
    } catch (error) {
      errorToast(`Failed`);
    }
  };

  return (
    <div>
      <CustomizedDialogs
        title="ADD PATIENT AWAITING DEVICE SET UP"
        open={addNewPatientModal}
        setOpen={() => setAddNewPatientModal(false)}
        size="md"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <div className="modal-body" id={css.addNewUserModalBody}>
          <Grid container>
            <Grid item xs={9} sm={9} md={9} lg={9} xl={9} style={{ marginTop: `9px` }}>
              <p className={css.sectionHeadingText} >
                Basic Information
              </p>
            </Grid>

          </Grid>
          <hr style={{ margin: `0px` }} />
          <div className="d-flex flex-wrap pb-1">
            <Box sx={{ mt: 2, }}>
              <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputField
                    size='small'
                    fullWidth={true}
                    error={firstNameError.status && !firstName ? true : false}
                    onBlur={() => validateInput(`firstName`)}
                    autoComplete="off"
                    onChange={(event) => {
                      setFirstName(event.target.value);
                      validateInput(`firstName`);
                    }}
                    value={firstName}
                    type="text"
                    label="First Name"
                  />
                  <p className={firstNameError.status && !firstName ? `d-block text-danger` : `d-none`}>{firstNameError.msg}</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputField
                    size='small'
                    fullWidth={true}
                    error={lastNameError.status && !lastName ? true : false}
                    onBlur={() => validateInput(`lastName`)}
                    autoComplete="off"
                    onChange={(event) => {
                      setLastName(event.target.value);
                      validateInput(`lastName`);
                    }}
                    value={lastName}
                    type="text"
                    label="Last Name"
                  />
                  <p className={lastNameError.status && !lastName ? `d-block text-danger` : `d-none`}>{lastNameError.msg}</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div
                    className={`${dateOfBirthError.status && !dateOfBirth ? css.errorCustom : null}
                              ${css.datePickerCustomeStyle}`}
                  >
                    <DatePickerCustomize

                      dateInputValue={dateOfBirth}
                      setDateInputValue={setDateOfBirth}
                      pickerProps={{ size: `small`, label: `DOB*`, fullWidth: true }}
                    />
                  </div>
                  <p className={dateOfBirthError.status && !dateOfBirth ? `d-block text-danger` : `d-none`}>{dateOfBirthError.msg}</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <MuiSelect
                    controlProps={{
                      size: `small`,
                      fullWidth: true,
                      id: `deviceManufacturerAddNew`
                    }}
                    label="Device Manufacturer*"
                    options={[...appDevices]}
                    optionKey={`name`}
                    optionValue={`value`}
                    selectProps={{
                      error: manufacturerError.status && !manufacturer ? true : false,
                      label: `Device Manufacturer*`,
                      value: manufacturer,
                      name: `manufacturer`,
                      onBlur: () => validateInput(`manufacturer`),
                      onChange: (event) => {
                        setManufacturer(event.target.value);
                        validateInput(`manufacturer`);
                      },
                    }}
                  />
                  <p className={manufacturerError.status && !manufacturer ? `d-block text-danger` : `d-none`}>{manufacturerError.msg}</p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <hr style={{ marginTop: 17 }} />
        <div className="d-flex justify-content-center">
          <button type="button" className="btn btn-outline-info" id={css.addNewUserModalButton} onClick={submitHandler}>
            ADD PATIENT
          </button>
        </div>
        {loading && <PulseSpinner />}
      </CustomizedDialogs>
    </div>
  );
}

