import React from 'react';
import { SMART_PHRASE_LIST, patchRequest } from '../../../../../../crud/crud';
import {
  Button, Grid
} from '@mui/material';
import { setSmartPhrases } from 'src/redux/practices/practiceActions';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { capitalizationFirstLetterWithIAndCapitalizeTheMonitair } from 'src/utils';


export const EditPhrase = (props) => {
  const dispatchRedux = useDispatch();
  const smartPhraseFromRedux = useSelector((state) => state.practice.smartPhrases);
  const [smartPhrase, setSmartPhraseValue] = React.useState(``);
  const [smartPhraseTitle, setSmartPhraseTitle] = React.useState(``);
  const [isGoneForUpdate, setIsGoneForUpdate] = React.useState(false)
  const resetValues = () => {
    setSmartPhraseValue(``)
    setSmartPhraseTitle(``)
    setIsGoneForUpdate(false)
  }
  React.useEffect(() => {
    setSmartPhraseValue(props?.phrase?.text);
    setSmartPhraseTitle(props?.phrase?.title);
    return () => resetValues()
  }, [props?.phrase?.text, props?.phrase?.title]);


  const addSmartPhrase = () => {
    if (!smartPhrase?.trim() || !smartPhraseTitle?.trim()) {
      errorToast(`Please fill the field is required`);
      return false;
    } else {
      let phrase = {
        phraseId: props?.phrase?._id,
        title: smartPhraseTitle,
        text: smartPhrase,
        favourite: false,
        scope: props?.phrase?.scope,
      };
      setIsGoneForUpdate(true)
      patchRequest(SMART_PHRASE_LIST + phrase?.phraseId, phrase)
        .then((response) => {
          props.setLoading(false);
          if (response.status === 200) {
            let smartPhrasesArray = smartPhraseFromRedux;
            successToast(`Smart Phrase Updated Successfully`);
            let objIndex = smartPhrasesArray.findIndex((obj) => obj._id == props?.phrase?._id);
            smartPhrasesArray[objIndex].title = response?.data?.phrase?.title;
            smartPhrasesArray[objIndex].text = response?.data?.phrase?.text;
            smartPhrasesArray[objIndex].scope = response?.data?.phrase?.scope;
            dispatchRedux(setSmartPhrases(smartPhrasesArray));
            props.setToggle(!props.toggle);
            onClose()

          }
        })
        .catch((e) => {
          process.env.NODE_ENV === `development` && console.log(`err`, e)
          props.setLoading(false);
        });
    }
  };

  let style = {
    borderRadius: `25px`,
    height: `30px`,
    minHeight: `30px`,
    marginTop: `2px`,
    float: `right`,
  }

  const styleForViewPhrases = {
    backgroundColor: `#F4FCFD`,
    boxShadow: `rgb(28 0 241 / 36%) 0px 0px 3px 0px, rgb(122 153 230 / 82%) 0px 0px 1px 0px`,
    padding: `20px`,
    fontSize: `20px !important`,
    fontFamily: `serif`,
    lineHeight: `1.6`,
  }

  const capitalizeFirstLetter = (str) => {
    if (str) {
      // converting first letter to uppercase
      const capitalized = str.replace(/^./, str[0].toUpperCase());

      return capitalized;
    }
    return `-`
  }

  const onClose = () => {
    props.setOpen(false);
    resetValues()
  }
  return (
    <CustomizedDialogs title={
      <>
        <span className={props?.viewPhrase ? `` : `d-none`}>{capitalizeFirstLetter(props?.phrase?.title)}</span>
        <span className={props?.viewPhrase ? `d-none` : ``}>{`Update Phrase`}</span>
      </>
    } open={props.open}
      setOpen={onClose}
      size="md"
      fullWidth={true}
    >
      <Grid xs={9} sm={9} lg={9} md={9} marginTop={`8px`}>
        <div className={props?.viewPhrase ? `d-none` : ``}>
          <div className="form-group">
            <input
              disabled={props?.phrase?.scope?.toLowerCase() !== `normal` ? true : false}
              type="text"
              className="form-control"
              placeholder="Phrase Title"
              value={smartPhraseTitle}
              onChange={(e) => setSmartPhraseTitle(e.target.value)} />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="7"
              placeholder="Update Phrase..."
              value={smartPhrase}
              onChange={(e) => setSmartPhraseValue(e.target.value)}
            />
          </div>
        </div>
        <div className={props?.viewPhrase ? `` : `d-none`}>
          <Typography sx={{
            ...styleForViewPhrases
          }}>
            {`${capitalizationFirstLetterWithIAndCapitalizeTheMonitair(props?.phrase?.text)}`}
          </Typography>
        </div>
      </Grid>

      <Grid xs={9} sm={9} lg={9} md={9} marginTop={`8px`}>
        <Button
          className={props?.viewPhrase ? `d-none` : ``}
          variant="outlined"
          color="primary"
          sx={{
            ...style
          }}
          disabled={isGoneForUpdate}
          onClick={() => addSmartPhrase()}
        >
          Update
        </Button>
      </Grid>
    </CustomizedDialogs>
  );
};