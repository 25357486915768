import React from 'react';
import { Grid } from '@mui/material';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { REACT_HEALTH, fixNum } from 'src/utils';

export const PressureDecider = ({ deviceMenuFacturer, pressure }) => {

  let bothPressure = Boolean(pressure?.pressure95IPAP && pressure?.pressure95EPAP);

  let bothPressure90 = Boolean(pressure?.pressure90IPAP && pressure?.pressure90EPAP);








  return deviceMenuFacturer === `resmed` ? <ResmedPressure pressure={pressure} bothPressure={bothPressure} /> : deviceMenuFacturer === REACT_HEALTH ? <ReactHealthPressure pressure={pressure} /> : <RespironicsPressure pressure={pressure} bothPressure90={bothPressure90} />;
};
const ShowNull = () => {
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        -
      </Grid>
    </>
  );
};
const keyReturner = (key) => {
  return (
    <div>
      <div>{fixNum(key, 1)}</div>
    </div>
  );
};
const ReactHealthPressure = ({ pressure }) => {
  const showBothPressure = Boolean(pressure?.epap95);
  return (
    <Grid container>
      {showBothPressure ?
        <>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {keyReturner(pressure?.ipap95)}
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            |
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {keyReturner(pressure?.epap95)}
          </Grid>
        </>
        : <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ToolTipProvider
            toolTipProps={{
              arrow: true,
              placement: `top`,
              title: typeof pressure?.p95 === `number` ? `cmH₂O` : `No-values`,
            }}
            element={
              <div>
                <div>{keyReturner(pressure?.p95)}</div>
              </div>
            }
          />
        </Grid>
      }
    </Grid>
  );
};
const RespironicsPressure = ({ pressure, bothPressure90 }) => {
  return (
    <Grid container>
      {pressure?.pressure90IPAP && pressure?.pressure90EPAP ? (
        <Grid
          item
          xs={bothPressure90 ? 4 : 12}
          sm={bothPressure90 ? 4 : 12}
          md={bothPressure90 ? 4 : 12}
          lg={bothPressure90 ? 4 : 12}
          xl={bothPressure90 ? 4 : 12}
        >
          <ToolTipProvider
            toolTipProps={{
              arrow: true,
              placement: `top`,
              title: typeof pressure?.pressure90IPAP === `number` ? `cmH₂O` : `No-values`,
            }}
            element={
              <div>
                <div>{keyReturner(pressure?.pressure90IPAP)}</div>
              </div>
            }
          />
        </Grid>
      ) : null}
      {pressure?.pressure90IPAP > 0 && pressure?.pressure90EPAP > 0 && (
        <Grid
          item
          xs={bothPressure90 ? 4 : 12}
          sm={bothPressure90 ? 4 : 12}
          md={bothPressure90 ? 4 : 12}
          lg={bothPressure90 ? 4 : 12}
          xl={bothPressure90 ? 4 : 12}
        >
          |
        </Grid>
      )}
      {pressure?.pressure90IPAP && pressure?.pressure90EPAP ? (
        <Grid
          item
          xs={bothPressure90 ? 4 : 12}
          sm={bothPressure90 ? 4 : 12}
          md={bothPressure90 ? 4 : 12}
          lg={bothPressure90 ? 4 : 12}
          xl={bothPressure90 ? 4 : 12}
        >
          <ToolTipProvider
            toolTipProps={{
              arrow: true,
              placement: `top`,
              title: typeof pressure?.pressure90EPAP === `number` ? `cmH₂O` : `No-values`,
            }}
            element={
              <div>
                <div>{keyReturner(pressure?.pressure90EPAP)}</div>
              </div>
            }
          />
        </Grid>
      ) : null}

      {!pressure?.pressure90IPAP && !pressure?.pressure90EPAP ? (
        <Grid
          item
          xs={bothPressure90 ? 4 : 12}
          sm={bothPressure90 ? 4 : 12}
          md={bothPressure90 ? 4 : 12}
          lg={bothPressure90 ? 4 : 12}
          xl={bothPressure90 ? 4 : 12}
        >
          <ToolTipProvider
            toolTipProps={{
              arrow: true,
              placement: `top`,
              title: typeof pressure?.pressure90CPAP === `number` ? `cmH₂O` : `No-values`,
            }}
            element={
              <div>
                <div>{keyReturner(pressure?.pressure90CPAP)}</div>
              </div>
            }
          />
        </Grid>
      ) : null}

      {!pressure?.pressure90IPAP && !pressure?.pressure90EPAP && !pressure?.pressure90CPAP && <ShowNull />}
    </Grid>
  );
};
const ResmedPressure = ({ pressure, bothPressure }) => {
  return (
    <Grid container>
      {pressure?.pressure95IPAP ? (
        <Grid
          item
          xs={bothPressure ? 4 : 12}
          sm={bothPressure ? 4 : 12}
          md={bothPressure ? 4 : 12}
          lg={bothPressure ? 4 : 12}
          xl={bothPressure ? 4 : 12}
        >
          <ToolTipProvider
            toolTipProps={{
              arrow: true,
              placement: `top`,
              title: typeof pressure?.pressure95IPAP === `number` ? `cmH₂O` : `No-values`,
            }}
            element={
              <div>
                <div>{keyReturner(pressure?.pressure95IPAP)}</div>
              </div>
            }
          />
        </Grid>
      ) : null}
      {pressure?.pressure95IPAP > 0 && pressure?.pressure95EPAP > 0 && (
        <Grid
          item
          xs={bothPressure ? 4 : 12}
          sm={bothPressure ? 4 : 12}
          md={bothPressure ? 4 : 12}
          lg={bothPressure ? 4 : 12}
          xl={bothPressure ? 4 : 12}
        >
          |
        </Grid>
      )}
      {pressure?.pressure95EPAP ? (
        <Grid
          item
          xs={bothPressure ? 4 : 12}
          sm={bothPressure ? 4 : 12}
          md={bothPressure ? 4 : 12}
          lg={bothPressure ? 4 : 12}
          xl={bothPressure ? 4 : 12}
        >
          <ToolTipProvider
            toolTipProps={{
              arrow: true,
              placement: `top`,
              title: typeof pressure?.pressure95EPAP === `number` ? `cmH₂O` : `No-values`,
            }}
            element={
              <div>
                <div>{keyReturner(pressure?.pressure95EPAP)}</div>
              </div>
            }
          />
        </Grid>
      ) : null}
      {!pressure?.pressure95IPAP && !pressure?.pressure95EPAP && <ShowNull />}
    </Grid>
  );
};