import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppReclount } from 'src/redux/action/setUserHandler';
import { doInProdMode } from 'src/utils';

export const CachesBusterWrapper = ({ children }) => {
  const {
    user: { appRelCount },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const reloadHandler = () => {
      if (appRelCount === 0) {
        dispatch(updateAppReclount());
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      }
    };
    const clearCache = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          console.log(names, 'WrapedUPAgainokokoko');
          for (const name of names) {
            caches.delete(name);
          }
          if (names?.length) window.location.reload(true);
          console.log(names, 'WrapedUPAgainokokoko');
        });
      }
    };
    doInProdMode(() => {
      clearCache();
      reloadHandler();
    });
  }, []);

  return <>{children}</>;
};
