import React, { useState } from 'react';
import logo from '../../../assets/monitair-logo.svg';
import css from './forgetPassword.module.css';
import { FORGET_PASSWORD_URL, postRequest } from '../../../crud/crud';
import { PulseSpinner } from '../../common/components/spinner/spinner';
import {
  InputField
} from 'src/pages/common/components/InputField/InputField';
import { FooterWrapper } from 'src/pages/common/HOC/FooterWrapper';

export const ForgetPassword = () => {
  const [email, setEmail] = useState(``);
  const [alert, setAlert] = useState({ status: ``, msg: `` });
  const [loading, setLoading] = useState(false);

  const resetHandler = (e) => {
    e.preventDefault();
    setAlert({ status: ``, msg: `` });
    setLoading(true);
    postRequest(FORGET_PASSWORD_URL, { userName: email })
      .then((res) => {
        setLoading(false);
        if (res.status === 204) {
          setAlert({ status: `error`, msg: `Email has been sent to ${email} Check your email.` });
        } else {
          setAlert({ status: `success`, msg: `Email has been sent to ${email} Check your email.` });
          setEmail(``);
        }
      })
      .catch((err) => {
        setAlert({ status: `error`, msg: `User Not Found.` });
        setLoading(false);
      });
  };

  return (
    <FooterWrapper showOnlytLiscense={true}>
      <div id={css.container}>
        <img id={css.logo} src={logo} alt="Logo" />
        <div id={css.title}>
          <h5 id={css.titleText}>Reset Password</h5>
        </div>
        {alert.status === `error` ? (
          <div id={css.bannerError}>
            <p id={css.bannerErrorText}>{alert.msg}</p>
          </div>
        ) : null}
        {alert.status === `success` ? (
          <div id={css.bannerSuccess}>
            <p id={css.bannerSuccessText}>{alert.msg}</p>
          </div>
        ) : null}
        <form id={css.form} onSubmit={resetHandler}>
          <InputField
            size='small'
            type="text"
            className={css.field}
            placeholder="Username or Email"
            onChange={(e) => {
              setEmail(e.target.value);
              setAlert({ status: ``, msg: `` });
            }}
            value={email}
          />
          <div id={css.buttonHolder}>
            <button type="submit" id={css.button}>
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      {loading ? <PulseSpinner /> : null}

    </FooterWrapper>

  );
}


